import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import './EditAddress.scss'
import Header from '../../../component/header/header';
import { Cascader, TextArea, Toast } from 'antd-mobile'
import { RightOutline, CheckCircleOutline, DeleteOutline } from 'antd-mobile-icons'
import { options } from './location'
import { CheckListValue } from 'antd-mobile/es/components/check-list';

import type { PropsType } from '../../../global'


const EditAddress: React.FC<PropsType> = ({ AppState }) => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState<CheckListValue[]>([])
  const [defaultFlag, setDefaultFlag] = useState(false)
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [address, setAddress] = useState('')
  const location = useLocation()
  const data = JSON.parse(location.state)

  const saveHandle = () => {
    if (
      name === '' ||
      mobile === '' ||
      address === ''
    ) {
      return Toast.show('信息不完整')
    }
    const resquestBody = {
      name,
      mobile,
      address: `${value.join('-')},${address}`,
      defaultFlag,
    }
    if (data) {
      AppState.updateDeliveryInfo({ ...resquestBody, id: data.id }).then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            Toast.show({ icon: 'success', content: '编辑成功' })
          }, 100);
          navigate(-1)
        }
      })
      return
    }
    AppState.addDeliveryInfo(resquestBody).then((res) => {
      if (res.code === 0) {
        setTimeout(() => {
          Toast.show({ icon: 'success', content: '保存成功' })
        }, 100);
        navigate(-1)
      }
    })
  }

  useEffect(() => {
    if (data) {
      setValue(data?.address?.split(',')[0].split('-'))
      setDefaultFlag(data?.defaultFlag)
      setName(data.name)
      setMobile(data.mobile)
      setAddress(data.address.split(',')[1])
    }
  }, [])

  return (
    <div id='EditAddress'>
      <Header text='编辑收货地址' fontColor="black" menu={<span onClick={saveHandle}>保存</span>} />
      <div className='EditAddress-center'>
        <div className='EditAddress-center-box'>
          <div className='EditAddress-center-box-people'>收货人</div>
          <input
            className='EditAddress-center-box-input'
            value={name}
            onChange={x => setName(x.target.value)}
            type="text"
            placeholder='请输入收货人姓名'
          />
        </div>
        <div className='EditAddress-center-box'>
          <div className='EditAddress-center-box-people'>手机号码</div>
          <input
            className='EditAddress-center-box-input'
            type="tel"
            maxLength={11}
            placeholder='请输入收货人手机号'
            value={mobile}
            onChange={x => setMobile(x.target.value)}
          />
        </div>
        <div className='EditAddress-center-box'>
          <div className='EditAddress-center-box-people'>所在地区</div>
          <div className='EditAddress-center-box-location'
            onClick={() => setVisible(true)}
          >
            <Cascader
              options={options}
              visible={visible}
              value={value}
              onConfirm={x => {
                setValue(x)
                setVisible(false)
              }}
              onCancel={() => setVisible(false)}
            >
              {items => {
                if (items.every(item => item === null)) {
                  return value.length > 0 ? value.join('-') : '未选择'
                } else {
                  return items.map(item => item?.label ?? '未选择').join('-')
                }
              }}
            </Cascader>
            <RightOutline fontSize={16} color='#666' />
          </div>
        </div>
        <div className='EditAddress-center-details'>
          <div className='EditAddress-center-details-div'>详细地址</div>
          <p className='EditAddress-center-details-p'>
            <TextArea value={address} onChange={(e) => setAddress(e)} />
          </p>
        </div>
        <div className='EditAddress-center-default' onClick={() => setDefaultFlag(!defaultFlag)}>
          <div className='EditAddress-center-default-div'>设为默认地址</div>
          <CheckCircleOutline style={defaultFlag ? { color: '#fff', backgroundColor: 'blue', borderRadius: '50%' } : {}} fontSize={21} color='#666' />
        </div>
      </div>
    </div>
  )
}

export default inject('AppState')(observer(EditAddress));