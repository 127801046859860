import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import Header from '../../component/header/header'
import AuthModal from '../../component/authModal/authModal'
import { PropsType } from '../../global'

import './realName.scss'

const RealName: React.FC<PropsType> = ({ AppState }) => {
  const [realName, setRealName] = useState('')
  const [idCard, seIdCard] = useState('')
  const [checked, setChecked] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)
  const navigate = useNavigate()



  const submit = () => {
    if (checked) {
      if (realName) {
        if (idCard !== '' && idCard.length === 18) {
          AppState.bindIdentityNumber({ name: realName, identity_number: idCard }).then(data => {
            if (data.code === 0) {
              Toast.show({ icon: 'success', content: '实名信息认证成功' })
              AppState.usersMy()
              navigate(-1)
            }
          })
        } else {
          return Toast.show('请输入正确的身份证格式')
        }
      } else {
        Toast.show('请输入真实姓名')
      }
    } else {
      Toast.show('请同意用户协议和隐私条款')
    }
  }

  return (

    <div id='RealName'>
      {/* <AnimateBg /> */}
      <Header />

      <div className='RealName-ctx1'>
        <div className='RealName-ctx1-left'>
          <span className='RealName-ctx1-left-text1'>实人认证</span>
          <span className='RealName-ctx1-left-text2'>请输入与注册手机号一致的实名信息</span>
        </div>
      </div>
      <input
        className='RealName-input'
        type="text"
        placeholder='请输入真实姓名'
        autoComplete='on'
        value={realName}
        maxLength={11}
        onInput={(e: any) => {
          setRealName(e.target.value)
        }}
        autoFocus
      />
      <input
        className='RealName-input'
        type="text"
        placeholder='请输入身份证号'
        autoComplete='on'
        value={idCard}
        maxLength={18}
        onInput={(e: any) => {
          seIdCard(e.target.value)
        }}
      />
      <div className='RealName-ctx2'>
        <Checkbox className='RealName-ctx2-check' checked={checked} onChange={(e: any) => setChecked(e)} />

        <p className='RealName-ctx2-p'>我已同意 <a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a>与<a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a></p>
      </div>

      <div className='RealName-ctx3' onClick={submit}>
        立即认证
      </div>

      <AuthModal type='authFail' show={alertVisible} close={() => setAlertVisible(false)} />
    </div>
  )
}

export default inject('AppState')(observer(RealName))