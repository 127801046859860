import { useEffect } from 'react'
import Typed from 'typed.js'
import './typer.scss'
import { Checkbox } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

let typed: Typed | null = null

const TypedPage: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!typed) {
      typed = new Typed('#typed', {
        strings: [
          '风险告知：您的购买行为应当基于真实的消费、收藏、欣赏艺术品需求，而非投机炒作！\n\n寄售交易发生在您和其他用户之间。如有争议，由您自行解决，平台仅代为储存、保管和记录您的数字艺术品及相应权属信息。\n\n请在自己的经济能力和心理承受能力范围内决策购买。\n因宏观经济、市场环境、政策、不可抗力、技术风险等因素，都有可能导致数字艺术品的市场价格产生波动！'
        ],
        typeSpeed: 40, //打印速度
        loop: false, //是否循环
        smartBackspace: false,
      });
    }
  }, [])

  return (
    <div id='Typer'>
      <div className="dialog">
        <span className="typing" id="typed"></span>
      </div>
      <div className='Typer-footer animate__animated animate__slideInUp animate__delay-1s'>
        <div className='Typer-footer-top'>
          <Checkbox className='Typer-footer-top-check' checked={true} />
          <p className='Typer-footer-top-p'>我已同意Wyper <a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a>、<a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a>、<a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/riskterms.html', text: '风险提示' } })}>《风险提示》</a></p>
        </div>
        <div className='Typer-footer-btn' onClick={() => navigate('/secondaryMarket/TradeCountdown')}>
          我已知晓且同意
        </div>
      </div>
    </div>
  )
}

export default TypedPage