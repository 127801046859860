import './onChainInformation.scss';
import Header from '../../../component/header/header';
import { SendOutline, RightOutline, CloseCircleOutline } from 'antd-mobile-icons';
import React, { useState } from 'react';
import { Popup, Modal, } from 'antd-mobile';
import { Navigate, useNavigate } from 'react-router-dom';

const OnChainInformation: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate()
  const [type, setType] = useState('0')


  return (
    <div id='onChainInformation'>
      <Header text="藏品详情" menu={<SendOutline fontSize={20} color='black' />} />
      <div className='onChainInformation-box'>
        <img className="nftDetail-img" src={require('../../../assets/imgs/commodityBj.png')} />
        <div className='onChainInformation-title-box1'>
          <div className='onChainInformation-title'>
            <div className='onChainInformation-title-one'>
              <img className='nChainInformation-title-one-img' src={require('../../../assets/imgs/onChainInformation-yes.svg').default} alt="" />
              <p>该作品拥有&nbsp;Wyper&nbsp;官方认证</p>
            </div>
            <h3 className='onChainInformation-title-h3'>一个藏品#25154</h3>
            <div className='onChainInformation-title-two'>
              <div className='onChainInformation-title-box'>
                <div className='onChainInformation-title-box-left'>发行</div>
                <div className='onChainInformation-title-box-right'>650份</div>
              </div>
              <div className='onChainInformation-title-box2'>
                <div className='onChainInformation-title-box-left'>流通</div>
                <div className='onChainInformation-title-box-right'>650份</div>
              </div>
            </div>
          </div>
        </div>


        <div className='onChainInformation-center-box'>
          <div className='onChainInformation-center'>
            <h4 className='onChainInformation-center-h4'>链上信息</h4>
            <div className='onChainInformation-center-div'>藏品地址:<span className='onChainInformation-center-span'>0x841a8a575741a8a41a8a</span></div>
            <div className='onChainInformation-center-div'>持有藏品:<span className='onChainInformation-center-span'>1232145523</span></div>
            <div className='onChainInformation-center-div'>藏品编号:<span className='onChainInformation-center-span'>125415</span></div>
            <div className='onChainInformation-center-div2'>
              <div>认证网络:</div>
              <img className='onChainInformation-center-div2-img' src={require('../../../assets/imgs/onChainInformation-logo.svg').default} alt="" />
            </div>
          </div>
          <div className='onChainInformation-record'>
            <div className='onChainInformation-record-top'>
              <h4>链上记录</h4>
              <div className='onChainInformation-record-right'>
                <div className='onChainInformation-record-right-text' onClick={() => navigate('/secondaryMarket/OnChainRecords')}>查看更多</div>
                <RightOutline fontSize={10} />
              </div>
            </div>

            <div className='onChainInformation-record-over'>
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>159**&**7293</h3>
                  <p className='onChainInformation-record-box-left-p'>2022.06.22&nbsp;15:44</p>
                </div>
                <div className='onChainInformation-record-box-right'>买入&nbsp;¥444</div>
              </div>
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>159**&**7293</h3>
                  <p className='onChainInformation-record-box-left-p'>2022.06.22&nbsp;15:44</p>
                </div>
                <div className='onChainInformation-record-box-right2'>卖出&nbsp;¥444</div>
              </div>
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>159**&**7293</h3>
                  <p className='onChainInformation-record-box-left-p'>2022.06.22&nbsp;15:44</p>
                </div>
                <div className='onChainInformation-record-box-right'>买入&nbsp;¥444</div>
              </div>
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>159**&**7293</h3>
                  <p className='onChainInformation-record-box-left-p'>2022.06.22&nbsp;15:44</p>
                </div>
                <div className='onChainInformation-record-box-right'>买入&nbsp;¥444</div>
              </div>
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>159**&**7293</h3>
                  <p className='onChainInformation-record-box-left-p'>2022.06.22&nbsp;15:44</p>
                </div>
                <div className='onChainInformation-record-box-right'>买入&nbsp;¥444</div>
              </div>
            </div>


          </div>
        </div>


        <div className={`onChainInformation-btn ${type === '0' ? '' : 'noStyle'}`}>
          <div className='onChainInformation-btn-box'>
            <div className='onChainInformation-btn-left'>
              <div className='onChainInformation-btn-left-shop'>价格</div>
              <div>¥&nbsp;199.50</div>
            </div>
            <div className='onChainInformation-btn-right' onClick={() => navigate('/secondaryMarket/paymentPage')}>立即购买</div>
          </div>
        </div>

        <div className={`onChainInformation-btn ${type === '1' ? '' : 'noStyle'}`}>
          <div className='onChainInformation-btn-box'>
            <div className='onChainInformation-btn-left'>
              <div className='onChainInformation-btn-left-shop'>价格</div>
              <div>¥&nbsp;199.50</div>
            </div>
            <div className='onChainInformation-btn-right' onClick={() => setVisible(true)}>发布寄售</div>
          </div>
        </div>
      </div>

      {/*留着有用*/}
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
      >
        <div className='Popup-top'>
          <div className='Popup-top-title'>
            <img className='Popup-top-img' src={require('../../../assets/imgs/onChainInformation-btn-img.svg').default} alt="" />
            <div className='Popup-top-right'>
              <div className='Popup-top-right-name'>藏品名称</div>
              <div className='Popup-top-right-two'>
                <img className='Popup-top-right-img' src={require('../../../assets/imgs/onChainInformation-yes.svg').default} alt="" />
                <div className='Popup-top-right-number'>#1/1140</div>
              </div>
            </div>
          </div>
          <div className='Popup-top-money'>
            <div className='Popup-top-money-tet'>¥</div>
            <input className='Popup-top-money-inp' type="text" />
          </div>
          <div className='Popup-top-money-service'>服务费5%,&nbsp;<span className='Popup-top-money-service-span'>预计到手&nbsp;51600</span></div>
        </div>

        <div className='Popup-top-center'>
          <h4 className='Popup-h4'>收款方式</h4>
          <div className='Popup-wallet-sd'>
            <div className='Popup-wallet-sd-left'>
              <img className='Popup-wallet-sd-left-img' src={require('../../../assets/imgs/onChainInformation-money1.svg').default} alt="" />
              <div className='Popup-wallet-sd-left-text'>杉德钱包&nbsp;(未开通)</div>
            </div>
            <div className='Popup-wallet-sd-left-checkbox'>
              <input type="checkbox" className="Checkbox" id="check1" />
              <label htmlFor="check1"></label>
            </div>
          </div>

          <div className='Popup-wallet-hf'>
            <div className='Popup-wallet-hf-left'>
              <img className='Popup-wallet-hf-left-img' src={require('../../../assets/imgs/onChainInformation-money2.svg').default} alt="" />
              <div className='Popup-wallet-hf-left-text'>汇付钱包&nbsp;(未开通)</div>
            </div>
            <div className='Popup-wallet-hf-right'>
              <div className='Popup-wallet-hf-right-add'>立即开通</div>
              <img className='Popup-wallet-hf-left-img' src={require('../../../assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
            </div>
          </div>
        </div>

        <div className='Popup-top-foot'>
          <h3 className='Popup-top-foot-h3'>寄售须知</h3>
          <p className='Popup-top-foot-p'>数字藏品仅用于欣赏及收藏,&nbsp;而不是用于投资、投机或炒作！</p>
        </div>

        <div className='Popup-top-btn-box'>
          <div className='Popup-top-btn'
          >发布寄售</div>
        </div>
      </Popup>
    </div>
  )
}
export default OnChainInformation