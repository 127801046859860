import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router';
import { InfiniteScroll, Toast } from 'antd-mobile'
import html2canvas from 'html2canvas';
import moment from 'moment';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard/index';
import AppState from '@/store/appState';
import PrimaryMarket from '@/store/primaryMarket';

import CommonDialog from '../../component/commonDialog/commonDialog';

import './invite.scss'

const Invite: React.FC = () => {
  const { commonData } = AppState
  const navigate = useNavigate()
  const [state, setState] = useState<any>({
    current: 1,
    visible: false,
    wxVisible: false,
    wxVisible2: false,
  })



  const [canvas, setCanvas] = useState('')
  const inviteHandler = () => {
    setState({ ...state, visible: true })
    if (canvas === '') {
      setTimeout(() => {
        html2canvas(document.getElementById("invite-canvas") as HTMLElement, {
          useCORS: true,
          imageTimeout: 15000,
          backgroundColor: "null",
          scale: window.devicePixelRatio * 3,
          allowTaint: true,
        }).then((canvas) => {
          setCanvas(canvas.toDataURL())
        });
      }, 500)
    }
  }


  const init = () => {
    PrimaryMarket.inviteRecord({ invite_level: 1 })
    PrimaryMarket.inviteRecord({ invite_level: 2 })
  }


  useEffect(() => {
    init()
  }, [])


  const loadMore = async () => {
    const skip = state.current === 1 ? PrimaryMarket.inviteRecordList1.invitees.length : PrimaryMarket.inviteRecordList2.invitees.length
    await PrimaryMarket.inviteRecord({ invite_level: state.current, skip })
  }


  return (
    <div id='invite'>
      <div className='invite-header'>
        <img onClick={() => navigate(-1)} width={24} src={require('../../assets/imgs/arrow-left-white.svg').default} alt="" />
        <span className='invite-header-text1'>邀请好友</span>
        <div onClick={() => navigate('/invite/InviteRankingList')}>
          <img height={35} className='invite-header-text2' src={require('../../assets/imgs/Frame 1000001983.png')} alt="" />
        </div>
      </div>

      <div className='invite-nav'>
        <img className='invite-nav-img1' width={128} src={require('../../assets/imgs/邀请好友 奖励无上限.png')} alt="" />
        <img className='invite-nav-img2' width={185} src={require('../../assets/imgs/image 387.png')} alt="" />
      </div>

      <div className='invite-box1'>
        <div className='invite-box1-item'>
          <span className='invite-box1-item-text'>邀请链接</span>
          <div className='invite-box1-item-right' onClick={() => { copy(`wyper.art/${commonData?.invite_code}`); Toast.show('复制成功') }}>
            <span className='invite-box1-item-right-text'>{`wyper.art/${commonData?.invite_code}`}</span>
            <span className='invite-box1-item-right-img'>
              <img width={16} src={require('../../assets/imgs/copy.svg').default} alt="" />
            </span>
          </div>
        </div>
        <div className='invite-box1-item'>
          <span className='invite-box1-item-text'>邀请码</span>
          <div className='invite-box1-item-right' onClick={() => { copy(commonData?.invite_code); Toast.show('复制成功') }}>
            <span className='invite-box1-item-right-text'>{commonData?.invite_code}</span>
            <span className='invite-box1-item-right-img'>
              <img width={16} src={require('../../assets/imgs/copy.svg').default} alt="" />
            </span>
          </div>
        </div>
      </div>

      <div className='invite-btn btn' onClick={inviteHandler}>生成邀请海报</div>

      <div className='invite-box2'>
        <div className='invite-box2-top'>
          <span className='invite-box2-top-text'>我的邀请奖励</span>
          <span className='invite-box2-top-text2' onClick={() => navigate('/mining/MyMiningCave?type=TEAM')}>奖励详情 {'>'}</span>
        </div>
        <p>
          受邀人每成功购买一张纪元闪卡并打开基石原卡盲盒，邀请人即可获得一个小源矿，并且可永久获得受邀人挖出原石的一定比例奖励
        </p>
        <div className='invite-box2-bottom'>
          <div className='invite-box2-bottom-item'>
            <span className='invite-box2-bottom-item-text1'>{commonData?.inviter_reward_mine_count || 0}</span>
            <span className='invite-box2-bottom-item-text2'>源矿奖励</span>
          </div>
          <div className='invite-box2-bottom-item'>
            <span className='invite-box2-bottom-item-text1'>{commonData?.total_inviter_score || 0}</span>
            <span className='invite-box2-bottom-item-text2'>原石奖励</span>
          </div>
          <img width={92} src={require('../../assets/imgs/box_open_red 1.png')} alt="" />
        </div>
      </div>

      <img width={'100%'} onClick={() => {
        if (commonData.meet_leader_conditions) {
          setState({ ...state, wxVisible: true })
        } else {
          setState({ ...state, wxVisible2: true })
        }
      }} style={{ marginTop: 14 }} src={require('../../assets/imgs/Frame 1000001940.png')} alt="" />

      <div className='invite-list'>
        <div className='invite-list-title'>
          <div className='invite-list-title-item' onClick={() => setState({ ...state, current: 1 })}>
            <span className='invite-list-title-item-text' style={state.current === 1 ? {} : { color: ' #AAB8C2' }}>亲密好友({PrimaryMarket.inviteRecordList1?.invite_count})</span>
            {state.current === 1 && <img className='invite-list-title-item-img' src={require('../../assets/imgs/invite-1.svg').default} />}
          </div>
          <div className='invite-list-title-item' onClick={() => setState({ ...state, current: 2 })}>
            <span className='invite-list-title-item-text' style={state.current === 2 ? {} : { color: ' #AAB8C2' }}>间接好友({PrimaryMarket.inviteRecordList2?.invite_count})</span>
            {state.current === 2 && <img className='invite-list-title-item-img' src={require('../../assets/imgs/invite-1.svg').default} />}
          </div>
        </div>
        <div className='invite-list-data'>
          {state.current === 1 && (PrimaryMarket.inviteRecordList1?.invitees?.length > 0 ? PrimaryMarket.inviteRecordList1?.invitees?.map((item, i) => (
            <div className='invite-list-data-item' key={i}>
              <div className='invite-list-data-item-left'>
                <img className='invite-list-data-item-left-img' src={item.avatar_url} />
                <span className='invite-list-data-item-left-text'>{item.nick_name}</span>
              </div>
              <div className='invite-list-data-item-right'>
                <span className='invite-list-data-item-right-text1' style={item.task_desc === '未购买纪元闪卡' ? { color: '#AAB8C2' } : {}}>{item.task_desc}</span>
                <span className='invite-list-data-item-right-text2'>{moment(item.create_time).format('YYYY.MM.DD HH:mm')}</span>
              </div>
            </div>
          ))
            :
            <div className='invite-list-data-noData'>
              <img className='synthesis-img' src={require('../../assets/imgs/synthesis.png')} />
              <span className='synthesis-text'>暂无数据</span>
            </div>)
          }

          {state.current === 2 && (PrimaryMarket.inviteRecordList2?.invitees?.length > 0 ? PrimaryMarket.inviteRecordList2?.invitees?.map((item, i) => (
            <div className='invite-list-data-item' key={i}>
              <div className='invite-list-data-item-left'>
                <img className='invite-list-data-item-left-img' src={item.avatar_url} />
                <span className='invite-list-data-item-left-text'>{item.nick_name}</span>
              </div>
              <div className='invite-list-data-item-right'>
                <span className='invite-list-data-item-right-text1' style={item.task_desc === '未购买纪元闪卡' ? { color: '#AAB8C2' } : {}}>{item.task_desc}</span>
                <span className='invite-list-data-item-right-text2'>{moment(item.create_time).format('YYYY.MM.DD HH:mm')}</span>
              </div>
            </div>
          ))
            :
            <div className='invite-list-data-noData'>
              <img className='synthesis-img' src={require('../../assets/imgs/synthesis.png')} />
              <span className='synthesis-text'>暂无数据</span>
            </div>)
          }
        </div>

        {<InfiniteScroll
          hasMore={state.current === 1 ? PrimaryMarket.hasMore1 : PrimaryMarket.hasMore2}
          loadMore={loadMore}
        />}
      </div>
      <div className='invite-desc'>
        <span className='invite-desc-text1'>活动说明</span>
        <p className='invite-desc-text2'>1、A用户通过邀请B用户注册并填写A用户的邀请码后，B用户每购买1张纪元闪卡，A用户可获得1个平台赠送的白银源矿；B用户每购买3张纪元闪卡，A用户可额外获得1个纪元闪卡购买资格，当前普通用户通过邀新活动获得的纪元闪卡购买资格数量最多不超过50个。</p>
        <p className='invite-desc-text2'>2、A用户可获得B用户(亲密好友)采矿产生的原石数量的一定比例收益；B用户若成功邀请C用户，B用户可获得C用户采矿产生的原石数量的一定比例收益，A用户可获得C用户(间接好友)采矿产生的原石数量的一定比例收益。</p>
        <p className='invite-desc-text2'>3、成为纪元主理人: A用户若同时满足【邀请50名以上用户成功购买任意数量纪元闪卡、持有至少60张基石原卡、持有至少800个原石】条件，即可申请成为纪元主理人。纪元主理人可获得更高比例的额外邀新收益和更高的纪元闪卡购买资格权益(可超过50个) 。纪元主理人需定期完成平台发布的纪元主理人任务维持纪元主理人身份。纪元主理人名额当前限定100位。</p>
        <p className='invite-desc-text2'>本活动最终解释权归 WYPER 官方所有。</p>
      </div>





      {state.visible &&
        <div className='invite-dialog' onClick={() => { setState({ ...state, visible: false }); setCanvas('') }}>
          {canvas ?
            <img src={canvas} style={{ width: 345, height: 379.67, borderRadius: 22, opacity: 1 }} onClick={(e) => e.stopPropagation()} /> :
            <div id='invite-canvas' style={{ width: 345, height: 379.67, borderRadius: 22, opacity: 1 }} onClick={(e) => e.stopPropagation()}>
              <div className='invite-canvas-box'>
                <div className='invite-canvas-box-left'>
                  <div className='invite-canvas-box-left-top'>
                    <span>{commonData?.invite_code}</span>
                    <img src={require('../../assets/imgs/2.svg').default} alt="" />
                  </div>
                  <span className='invite-canvas-box-left-text'>邀请码</span>
                </div>
                <div style={{ padding: 5, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignContent: 'center', borderRadius: 2 }}>
                  <QRCode
                    value={`https://www.wyper.art?inviteCode=${commonData?.invite_code}`}// 生成二维码的内容
                    size={67} // 二维码的大小
                    fgColor="#000" // 二维码的颜色
                  />
                </div>
              </div>
            </div>
          }
          <div className='copy'>
            <img className='copy-img' src={require('../../assets/imgs/copy-btn.png')} />
            <span className='copy-text'>长按保存图片，或点击按钮复制链接</span>
          </div>
        </div>
      }

      <CommonDialog show={state.wxVisible}>
        <div className='invit-wxbox'>
          <img width={200} src={require('../../assets/imgs/WechatIMG333.png')} alt="" />
          <span>微信内扫码联系成为纪元主理人</span>
          <img className='invit-wxbox-close' width={32} onClick={() => setState({ ...state, wxVisible: false })} src={require('../../assets/imgs/close.svg').default} alt="" />
        </div>
      </CommonDialog>
      <CommonDialog show={state.wxVisible2}>
        <div className='invit-wxbox' style={{ height: 'auto', padding: 24, width: 333, boxSizing: 'border-box' }}>
          <span style={{ fontSize: 16, fontWeight: 600, margin: 0 }}>成为纪元主理人</span>
          <p style={{ fontSize: 14, marginTop: 10, lineHeight: '35px' }}>
            A用户若同时满足以下条件：
            <br />
            1、邀请50名以上用户成功购买任意数量纪元闪卡
            <br />
            2、持有至少60张基石原卡
            <br />
            3、持有至少800个原石
            <br />
            即可申请成为纪元主理人。
            <br />
            纪元主理人可获得更高比例的额外邀新收益和更高的纪元闪卡购买资格权益(可超过50个) 。
            <br />
            纪元主理人需定期完成平台发布的纪元主理人任务维持纪元主理人身份。
            <br />
            纪元主理人名额当前限定100位。
            <br />
          </p>
          <img className='invit-wxbox-close' width={32} onClick={() => setState({ ...state, wxVisible2: false })} src={require('../../assets/imgs/close.svg').default} alt="" />
        </div>
      </CommonDialog>
    </div>
  )
}

export default inject('AppState', 'PrimaryMarket')(observer(Invite))