import { useEffect, useState } from 'react'
import moment from 'moment';
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile';

import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll';
import primaryMarket from "@/store/primaryMarket";
import appState from '@/store/appState';
import Header from "../../../component/header/header";
import './MySpiritualGold.scss';


const MySpiritualGold: React.FC = () => {
  const { getBonusUserHistory, bonusUserHistoryList, bonusUserHistoryHasMore } = primaryMarket
  const [action, setAction] = useState<'income' | 'outcome'>('income')
  const { commonData } = appState
  const navigate = useNavigate()

  useEffect(() => {
    getBonusUserHistory({
      bonusType: 'lingjin',
      action: action
    })
  }, [action])

  return (
    <div id="MySpiritualGold">
      <Header text='我的灵金' fontColor="white" menu={<span onClick={() => navigate('/indexPoster')}>玩法介绍</span>} />
      <div className="MySpiritualGold-top">
        <div className="MySpiritualGold-top-my">
          <div>我的灵金</div>
          <img src={require('../../../assets/imgs/MySpiritualGold-small.svg').default} />
        </div>
        <div className="MySpiritualGold-top-time">{Math.floor(commonData?.lingjin) || '0.00'}</div>
        <div className="MySpiritualGold-top-btn" onClick={() => navigate('/mining/AlchemyInstitute')}>兑换黄金</div>
      </div>
      <div className="MySpiritualGold-center">
        <div className="MySpiritualGold-foot">
          <div className="MySpiritualGold-foot-top">
            <span className="MySpiritualGold-icome" style={action === 'income' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setAction('income')}>收入</span>
            <span className="MySpiritualGold-font" style={action === 'outcome' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setAction('outcome')}>支出</span>
          </div>
        </div>
        {bonusUserHistoryList.length > 0 && bonusUserHistoryList.map((x, i) => (
          <div className="MySpiritualGold-foot-obtain" key={i}>
            <div className="MySpiritualGold-foot-obtain-left">
              <h3>{x.title}</h3>
              <p>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
            </div>
            <div className="MySpiritualGold-foot-obtain-right">
              {x?.bonus}
            </div>
          </div>
        ))}
      </div>


      <InfiniteScrollComponent
        hasMore={bonusUserHistoryHasMore}
        dataSource={bonusUserHistoryList}
        loadMore={async () => {
          await getBonusUserHistory({
            bonusType: 'lingjin',
            action: action,
            skip: bonusUserHistoryList.length
          })
        }}
        top={350}
      />
    </div>
  )
}

export default observer(MySpiritualGold);