import { useNavigate } from 'react-router-dom'
import './tradeCountdown.scss';

const TradeCountdown: React.FC = () => {
  const navigate = useNavigate()

  const nextHandle1 = () => {
    const node = document.getElementsByClassName('TradeCountdown-box')
    const node1 = document.getElementsByClassName('TradeCountdown-box1')
    node[0].className = 'TradeCountdown-box animate__animated animate__bounceOutLeft'
    node1[0].className = 'TradeCountdown-box1 animate__animated animate__bounceInRight'
  }

  const nextHandle2 = () => {
    const node = document.getElementsByClassName('TradeCountdown-box1')
    const node1 = document.getElementsByClassName('TradeCountdown-box2')
    node[0].className = 'TradeCountdown-box animate__animated animate__bounceOutLeft'
    node1[0].className = 'TradeCountdown-box1 animate__animated animate__bounceInRight'
  }

  return (
    <div id='TradeCountdown'>
      <div className='TradeCountdown-box'>
        <div className='TradeCountdown-center-box'>
          <div className='TradeCountdown-center'>
            <img className='TradeCountdown-center-img' src={require('@/assets/imgs/TradeCountdown-time.svg').default} alt="" />
            <h3 className='TradeCountdown-center-h3'>交易倒计时奖池玩法</h3>
          </div>
        </div>
        <div>
          <p className='TradeCountdown-text'>平台将在每天固定时间段开放「解构游戏」，例如：14:00-22:00。在「解构游戏」开放时间内，第一个链上流转记录产生后，「流转活动」游戏正式开始。在「流转活动」游戏中，每个藏品将设定一个「灵金奖池」，同时设定一个游戏倒计时时间，例如：X秒。当游戏启动后，在X秒内，若该藏品产生一笔链上流转记录，将重启倒计时。超过X秒，若该藏品无链上流转记录产生，则最后一笔链上流转记录的「接收方」将获取一定比例的灵金奖池价值。详细游戏规则，以平台对应藏品游戏公告为准。</p>
          <div className='TradeCountdown-btn' onClick={nextHandle1}>继续</div>
        </div>
      </div>

      <div className='TradeCountdown-box1 none'>
        <div className='TradeCountdown-center-box'>
          <div className='TradeCountdown-center'>
            <img className='TradeCountdown-center-img' src={require('@/assets/imgs/TradeCountdown-time.svg').default} alt="" />
            <h3 className='TradeCountdown-center-h3'>销毁活动倒计时奖池玩法</h3>
          </div>
        </div>
        <p className='TradeCountdown-text'>平台将在每天固定时间段开放「解构游戏」，例如：14:00-22:00。在「解构游戏」开放时间内，第一个链上销毁记录产生后，「销毁活动」游戏正式开始。在「销毁活动」游戏中，每个藏品将设定一个「灵金奖池」，同时设定一个游戏倒计时时间，例如：X分钟。当游戏启动后，在X分钟内，若该藏品产生一笔链上销毁记录，将重启倒计时。超过X分钟，若该藏品无链上销毁记录产生，则最后一笔链上销毁记录的「操作方」将获取较高比例的「灵金奖池价值」。每一笔销毁记录「操作方」将获得一次抽奖资格，参与抽奖活动，可获得不同数量的指定藏品。详细游戏规则，以平台对应藏品游戏公告为准。</p>
        <div className='TradeCountdown-btn' onClick={nextHandle2}>继续</div>
      </div>

      <div className='TradeCountdown-box2 none'>
        <div className='TradeCountdown-center-box'>
          <div className='TradeCountdown-center'>
            <img className='TradeCountdown-center-img' src={require('@/assets/imgs/GameIntroduction-trophy.svg').default} alt="" />
            <h3 className='TradeCountdown-center-h3'>交易排行榜奖池玩法</h3>
          </div>
        </div>
        <p className='TradeCountdown-text'>平台将在每天固定时间段开放「解构游戏」，例如：14:00-22:00。在「解构游戏」开放时间内，根据用户参与指定藏品「流转游戏」的链上记录，在每个整点小时，进行链上流入记录排行榜统计。平台将统计每一个完整小时内的「流入价值数据」，每小时流入价值最多的前3名用户将根据排名先后分别获取不同比例的「灵金奖池价值」。详细游戏规则，以平台对应藏品游戏公告为准。</p>
        <div className='TradeCountdown-btn' onClick={() => navigate(-2)}>开始游戏</div>
      </div>
    </div>
  )
}

export default TradeCountdown;