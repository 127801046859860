import styles from './allNumbers.module.scss';
import Header from '../../../component/header/header';
import { useLocation } from 'react-router-dom'


const AllNumbers: React.FC = () => {
  const { state } = useLocation()
  const bulk_child_orders = JSON.parse(state?.bulk_child_orders)

  return (
    <div id={styles['AllNumbers']}>
      <Header text='全部编号' fontColor='black' />
      <div className={styles['AllNumbers-box']}>
        {bulk_child_orders.map((x: any, i: number) => (
          <div className={styles['AllNumbers-box-center']} key={i}>
            <div className={styles['AllNumbers-box-center-left']}>
              <h3>{x.goods.title}</h3>
              <p className={styles['AllNumbers-box-center-left-p']}>#{x.goods.sid}<span className={styles['AllNumbers-box-center-left-p-span']}>/{x.goods.publish_amount}</span></p>
            </div>
            <div className={styles['AllNumbers-box-center-right']}><span className={styles['AllNumbers-box-center-right-span']}>¥</span>{Number((x.price) / 100).toFixed(2)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AllNumbers;
