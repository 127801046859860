import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '@/component/header/header';

import store from './reducer';
import styles from './synthesis.module.scss';

enum ActivityStatusEnum {
  un_start = '🚀 即将开始',
  sell_out = '已结束',
  sale = '正在合成',
  can_circulate = '可流转',
}

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    store.query();
  }, []);

  const handleDetail = (e: any) => {
    navigate(`/synthesis/synthesisDetails`, { state: { iid: e.iid } });
  };

  return (
    <div id={styles['synthesis']}>
      <Header text='藏品合成' />
      <div className={styles['HomePage-List']}>
        {store.list.length > 0 ?
          store.list.map((e, index) => (
            <div
              className={styles['HomePage-box']}
              key={`HomePage-box-${index}`}
              onClick={() => handleDetail(e)}>
              <img className={styles['HomePage-box-background']} src={e.thumb} alt='' />
              {e.grade && (
                <div id={styles[e.grade]} className={styles['HomePage-box-grade']}>
                  {e.grade}
                </div>
              )}
              <div className={styles['HomePage-box-text']}>{e.title}</div>
              <p className={styles['HomePage-box-p']}>
                限量&nbsp;{e.amount}&nbsp;·&nbsp;剩余&nbsp;{e.remain}
              </p>
              <div className={styles['HomePage-box-text2']}>
                {ActivityStatusEnum[e.state as keyof typeof ActivityStatusEnum]}
              </div>
            </div>
          )) : (
            <div className='noData'>
              <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )}
      </div>
    </div>
  );
};

export default observer(HomePage);
