import Header from "../../../component/header/header";
import './IncreaseByTransferring.scss';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import moment from "moment";
import primaryMarket from "@/store/primaryMarket";
import { observer } from 'mobx-react'


const IncreaseByTransferring: React.FC = () => {
  const [type, setType] = useState('0');
  const { state } = useLocation()
  const navigate = useNavigate()
  const { nftOrderTransferQueryOrde, transferDetail } = primaryMarket

  useEffect(() => {
    console.log(state)
  }, [])


  return (
    <div id="IncreaseByTransferring">
      <Header text='转赠详情' fontColor="black" />
      <div className="IncreaseByTransferring-box">
        <div className={`IncreaseByTransferring-top ${type === '0' ? '' : 'noneclass'}`}>
          <div className="IncreaseByTransferring-top-img"></div>
          <h3 className="IncreaseByTransferring-top-h3">转赠成功</h3>
        </div>
        <div className={`IncreaseByTransferring-top ${type === '1' ? '' : 'noneclass'}`}>
          <div className="IncreaseByTransferring-top-img2"></div>
          <h3 className="IncreaseByTransferring-top-h3">转赠失败</h3>
        </div>
        <div className="IncreaseByTransferring-center">
          <div className="IncreaseByTransferring-center-top">
            <div className="IncreaseByTransferring-center-top-imgbox">
              {state.product === 'yuanshi' && <img className="IncreaseByTransferring-top-left-logo-img" src={require('../../../assets/imgs/MyGem-gem.svg').default} />}
              {state.product === "asset" && <img className="IncreaseByTransferring-top-left-logo-img" src={state.thumb && state.thumb[state.thumb.length - 1]} />}
            </div>
            {state.product === 'yuanshi' && <div className="IncreaseByTransferring-center-top-h" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>原石扣除</div>
              <div className="IncreaseByTransferring-center-top-h-num" style={{ marginRight: 10 }}>
                <div className={`IncreaseByTransferring-twenty-four`} style={{ whiteSpace: 'nowrap' }}>× {state?.costScore}</div>
              </div>
            </div>}
            {state.product === 'asset' && <div className="IncreaseByTransferring-center-top-h">
              <div className="IncreaseByTransferring-center-top-h-top">
                {state.title}
                &nbsp;
                <span id={state.grade}>{state.grade}</span>
              </div>
              <div className="GiveNft-top-right-center">
                <img src={require('@/assets/imgs/nftDetail-small.svg').default} alt="" />
                <span className="GiveNft-top-right-center-text">#{state?.shard_id}/{state?.amount}</span>
              </div>
              <div className='box3'>
                <div className='box3-item'>
                  <span className='box3-item-text1'>发行量</span>
                  <span className='box3-item-text2'>{state?.publish_amount}份</span>
                </div>
                <div className='box3-item'>
                  <span className='box3-item-text1'>流通量</span>
                  <span className='box3-item-text2'>{state?.circulate_amount}份</span>
                </div>
              </div>
            </div>}
          </div>

          <div className="IncreaseByTransferring-center-id">
            <div className="IncreaseByTransferring-center-id-left">
              <div>转赠人</div>
              <div className="IncreaseByTransferring-center-id-num">{state.from_uid}</div>
            </div>
            <div className="IncreaseByTransferring-center-id-center">
              <img className="IncreaseByTransferring-top-left-logo-img" src={require('../../../assets/imgs/IncreaseByTransferring-more-line.svg').default} />
              <img className="IncreaseByTransferring-top-left-logo-img" src={require('../../../assets/imgs/IncreaseByTransferring-arrow-right.svg').default} />
              <img className="IncreaseByTransferring-top-left-logo-img" src={require('../../../assets/imgs/IncreaseByTransferring-more-line.svg').default} />
            </div>
            <div className="IncreaseByTransferring-center-id-right">
              <div className="IncreaseByTransferring-center-id-right-text">受赠人</div>
              <div className="IncreaseByTransferring-center-id-num">{state?.to_uid}</div>
            </div>
          </div>

          <div className="IncreaseByTransferring-center-serviceCharge">
            <div className="IncreaseByTransferring-center-serviceCharge-f">
              <h3>附加信息</h3>
              <div>技术服务扣除</div>
            </div>
            <div className="IncreaseByTransferring-center-serviceCharge-y">{state?.bonus} {state?.bonus_type}</div>
          </div>
          <h3 className="IncreaseByTransferring-center-additional">附加信息</h3>
          <div className="IncreaseByTransferring-center-record">
            <div className="IncreaseByTransferring-center-record-left">
              {state.product === 'yuanshi' && <div>实际赠送</div>}
              <div>创建时间</div>
              {state.product === 'asset' && <div>订单编号</div>}
            </div>
            <div className="IncreaseByTransferring-center-record-right">
              {state.product === 'yuanshi' && <div>{state?.transferScore}原石</div>}
              <div>{moment(state?.create_time).format('YYYY.MM.DD HH:mm')}</div>
              {state.product === 'asset' && <div className="IncreaseByTransferring-center-record-right-text">{state.order_no}</div>}
            </div>
          </div>
        </div>
        <div className="IncreaseByTransferring-btn" onClick={() => navigate(state.product === 'yuanshi' ? -1 : -3)}>返回上一页</div>
      </div>

    </div>
  )
}

export default observer(IncreaseByTransferring);