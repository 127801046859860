import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import store from './reducer'
import appState from '@/store/appState'

import Pool from './component/pool/pool'
import Destroy from './component/destroy/destroy'
import Market from './component/market/market'
import { _hmtHandle } from '@/utils/utils'

import './deal.scss'

const Dela: React.FC = () => {
  const navigate = useNavigate()
  const {
    getNftGameTotalBonus,
    nftGameTotalBonus,
    changeNavId,
    navId,
    getNftGameLastWinner,
  } = store
  const { socketData } = appState

  useEffect(() => {
    if (socketData?.action === 'nft_game_total_bonus_change') {
      getNftGameTotalBonus('lingjin')
    }

    if (socketData?.action === 'nft_game_winner') {
      getNftGameLastWinner(navId === 0 ? { activity: 'c2c' } : navId === 1 ? { activity: 'destroy' } : {})
    }
  }, [socketData])

  useEffect(() => {
    getNftGameTotalBonus('lingjin')

    if (!localStorage.getItem('typer')) {
      navigate('/secondaryMarket/typer')
      localStorage.setItem('typer', 'true')
    }
  }, [])

  useEffect(() => {
    getNftGameLastWinner(navId === 0 ? { activity: 'c2c' } : navId === 1 ? { activity: 'destroy' } : {})
  }, [navId])

  useEffect(() => {
    _hmtHandle('任何设备', '访问', '「解构」页面')
  }, [])

  return (
    <div id="Dela">
      <div className='Dela-nav'>
        <span className={navId === 0 ? 'Dela-nav-text active' : 'Dela-nav-text'} onClick={() => changeNavId(0)}>流转活动</span>
        <span className={navId === 1 ? 'Dela-nav-text active' : 'Dela-nav-text'} onClick={() => changeNavId(1)}>销毁活动</span>
        <span className={navId === 2 ? 'Dela-nav-text active' : 'Dela-nav-text'} onClick={() => changeNavId(2)}>自由流转</span>
      </div>

      {navId === 0 && <Pool nftGameTotalBonus={nftGameTotalBonus} socketData={socketData} />}
      {navId === 1 && <Destroy nftGameTotalBonus={nftGameTotalBonus} socketData={socketData} />}
      {navId === 2 && <Market />}
    </div>
  )
}

export default observer(Dela)