import styles from './onChainRecords.module.scss';
import Header from '../../../component/header/header';
import { observer } from 'mobx-react'
import secondaryMarket from '@/store/secondaryMarket';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { InfiniteScroll } from 'antd-mobile';

const OnChainRecords: React.FC = () => {
  const { state } = useLocation()
  const { getOrdersTransactionList, ordersTransactionList, ordersTransactionListHasMore } = secondaryMarket

  useEffect(() => {
    getOrdersTransactionList({
      aid: state.aid,
      sid: state.sid
    })
  }, [])

  return (
    <div id={styles['OnChainRecords']}>
      <Header fontColor='black' text='链上记录' />
      <div className={styles['OnChainRecords-center']}>
        {ordersTransactionList.length > 0 && ordersTransactionList.map((x, i) => (
          <div className={styles['OnChainRecords-center-box']} key={i}>
            <div className={styles['OnChainRecords-center-box-left']}>
              <h3>{x.name}</h3>
              <p className={styles['OnChainRecords-center-box-left-p']}>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
            </div>
            <div className={styles['OnChainRecords-center-box-right']}>买入&nbsp;¥ {x.price}</div>
          </div>
        ))}
      </div>

      <InfiniteScroll
        hasMore={ordersTransactionListHasMore}
        children={(hasMore) => {
          if (!hasMore && ordersTransactionList.length === 0) {
            return (
              <div className='noData'>
                <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
          } else {
            return '没有更多了'
          }
        }}
        loadMore={async () => {
          await getOrdersTransactionList({
            aid: state.aid,
            sid: state.sid,
            skip: ordersTransactionList.length
          })
        }}
      />
    </div>
  )
}

export default observer(OnChainRecords);