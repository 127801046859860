import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import CommonDialog from "../commonDialog/commonDialog"
import { Toast } from 'antd-mobile'
import primaryMarketStore from '@/store/primaryMarket'
import './buyModal.scss'

interface Props {
  close: () => void;
  show: boolean;
  physical_good_id?: string
}

const BuyModal: React.FC<Props> = (props) => {
  const [cardShowObj, setCardShowObj] = useState<any>({})
  const navigate = useNavigate()

  const createOrderHandle = (shopNum: number) => {
    primaryMarketStore.createOrder({
      id: cardShowObj?.physical_good_id,
      product: 'physical_good',
      amount: shopNum,
      ticket: '',
      randstr: ''
    }).then((res) => {
      if (res.code === 0) {
        navigate(`/orderPay?order_no=${res.data.order_no}`)
      } else if (res.code === 4005) {
        navigate(`/order?type=wait_paid`)
      }
    })
  }

  /**
   * 加减购买数量
   * 
   */
  const [shopNum, setShopNum] = useState(0)
  const shopNumHandler = (type: 'add' | 'cut') => {
    if (type === 'add') {
      if (shopNum < cardShowObj?.available_stock) {
        setShopNum(shopNum + 1)
      }
    }
    if (type === 'cut') {
      if (shopNum > 1) {
        setShopNum(shopNum - 1)
      }
    }
  }

  const init = (physical_good_id: string) => {
    if (localStorage.getItem('commonData')) {
      primaryMarketStore.physicalGoodDetail(physical_good_id).then((res) => {
        if (res.code === 0) {
          setCardShowObj(res.data)
          setShopNum(res.data.available_stock || 0)
        }
      })
    } else {
      navigate('/login')
    }
  }

  useEffect(() => {
    if (props.show) {
      if (props.physical_good_id) {
        init(props.physical_good_id)
      } else {
        primaryMarketStore.getHotCollectionList().then(res => {
          if (res.code === 0) {
            init(res.data.list.find((x: { show_in_index: boolean }) => x.show_in_index === true).physical_good_id)
          }
        })
      }
    }
  }, [props.show])


  /**
   * 手动改变购买数量
   */
  const onChangeHandle = (v: string) => {
    if (isNaN(Number(v))) return
    if (v > cardShowObj?.available_stock) {
      setShopNum(Number(cardShowObj?.available_stock))
    } else {
      setShopNum(Number(v || 0))
    }
  }


  return (
    <CommonDialog show={props.show} close={props.close}>
      <div className='shopNum animate__animated animate__slideInUp' onClick={(e) => e.stopPropagation()}>
        <div className='shopNum-top'>
          <img className='shopNum-top-img' src={cardShowObj?.detail_thumb_list && cardShowObj?.detail_thumb_list[0]} alt="" />
          <div className='shopNum-top-content'>
            <span className='shopNum-top-content-text'>{cardShowObj?.title}</span>
            <p className='shopNum-top-content-p'>
              <img className='shopNum-top-content-p-img' src={require('../../assets/imgs/icon.svg').default} alt="" />
              <span className='shopNum-top-content-p-text'>单次可购买一张</span>
            </p>
          </div>
        </div>
        {cardShowObj?.nft_asset_desc && <div className='shopNum-middle'>
          <img className='shopNum-middle-img' src={cardShowObj?.nft_asset_desc?.thumb} alt="" />
          <div className='shopNum-middle-content'>
            <span className='shopNum-middle-content-text1'>赠送 {shopNum}个基石原卡盲盒</span>
            <span className='shopNum-middle-content-text2'>打开盲盒后获得：{shopNum}张基石原卡 + {shopNum}个黄金源矿 + {shopNum}个白银源矿</span>
          </div>
        </div>}
        <div className='shopNum-box'>
          <span className='shopNum-box-text'>购买数量</span>
          <div className='shopNum-box-handle' onClick={() => shopNumHandler('cut')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M3.5 7.3501H12.5V8.6501H3.5V7.3501Z" fill="#4D33EF" />
            </svg>
          </div>
          <input className='shopNum-box-value' type='tel' value={shopNum} onChange={e => onChangeHandle(e.target.value)} />
          <div className='shopNum-box-handle' onClick={() => shopNumHandler('add')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M7.34998 8.64998V12.5H8.64998V8.64998H12.5V7.34998H8.64998V3.5H7.34998V7.34998H3.5V8.64998H7.34998Z" fill="#4D33EF" />
            </svg>
          </div>
          <div className='shopNum-box-max btn' onClick={() => setShopNum(cardShowObj?.available_stock || 0)}>MAX</div>
        </div>
        <div className="shopNum-footer">
          <div className="shopNum-footer-box">
            <div className="shopNum-footer-box-context">
              <span className="shopNum-footer-box-context-text1">￥</span>
              <span className="shopNum-footer-box-context-text2">{Number(cardShowObj?.price * shopNum).toFixed(2)}</span>
            </div>
          </div>
          <div className="shopNum-footer-btn btn" onClick={() => {
            if (Number(cardShowObj?.available_stock) > 0) {
              createOrderHandle(shopNum)
            } else {
              Toast.show('暂无购买资格')
            }
          }}>
            <img src={require('../../assets/imgs/shopping.svg').default} alt="" />
            <span>{cardShowObj?.available_stock === 0 ? '暂无购买资格' : '立即购买'}</span>
          </div>
        </div>
      </div>
    </CommonDialog>
  )
}

export default observer(BuyModal)