import styles from './orderPayment.module.scss';
import Header from '../../../component/header/header';
import { useState } from 'react';


const OrderPayment: React.FC = () => {
    const [type, setType] = useState('0');
    

    return (
        <div id={styles['OrderPayment']}>
            <Header text='订单支付' fontColor="black" />
            <img className={`${styles['OrderPayment-img']} ${type === '0' ? '' : styles['disappear']}`}src={require('../../../assets/imgs/OrderPayment-fill.svg').default} alt="" />
            <img className={`${styles['OrderPayment-img']} ${type === '1' ? '' : styles['disappear']}`} src={require('../../../assets/imgs/OrderPayment-fill2.svg').default} alt="" />
            <h3 className={`${styles['OrderPayment-title-h3']} ${type==='0'?'':styles['disappear']}`}>支付成功</h3>
            <h3 className={`${styles['OrderPayment-title-h3']} ${type==='1'?'':styles['disappear']}`}>支付失败</h3>
            <div className={`${styles['OrderPayment-time']} ${type==='0'?'':styles['disappear']}`}>
                <div className={styles['OrderPayment-time-box']}>
                    <img src={require('../../../assets/imgs/OrderPayment-line.svg').default} alt="" />
                    <p className={styles['OrderPayment-time-box-p']}>6&nbsp;天&nbsp;23&nbsp;小时&nbsp;59&nbsp;分&nbsp;08&nbsp;秒内未成交金额将退回</p>
                </div>
            </div>
            <div className={styles['OrderPayment-center']}>
                <div className={styles['OrderPayment-center-box']}>
                    <div className={styles['OrderPayment-center-box-top']}>
                        <div className={styles['OrderPayment-center-box-top-left']}>
                            <img src={require('../../../assets/imgs/OrderPayment-title.svg').default} alt="" />
                            <h3 className={styles['OrderPayment-center-box-top-left-h3']}>数字藏品</h3>
                        </div>
                        <div className={styles['OrderPayment-center-box-top-right']}>
                            <h4 className={styles['OrderPayment-center-box-top-right-h4']}><span className={styles['OrderPayment-center-box-top-right-h4-span']}>¥&nbsp;</span>2199.50</h4>
                            <div className={styles['OrderPayment-center-box-top-right-div']}>×24</div>
                        </div>
                    </div>
                   
                    <div className= {`${styles['OrderPayment-center-box-cent']} ${type==='0'?'':styles['disappear']}`}>
                        <div className={`${styles['OrderPayment-center-box-cent-left']} ${type==='0'?'':styles['disappear']}`}>
                            <h4 className={styles['OrderPayment-center-box-cent-left-h4']}>附加信息</h4>
                            <p className={styles['OrderPayment-center-box-cent-left-p']}>支付方式:&nbsp;微信支付</p>
                        </div>
                        <div className={`${styles['OrderPayment-center-box-cent-right']} ${type==='0'?'':styles['disappear']}`}><span className={styles['OrderPayment-center-box-cent-right-span']}>¥</span>2199.50</div>
                    </div>

                    <div className={styles['OrderPayment-foot']}>
                        <h3 className={`${styles['OrderPayment-foot-h3']} ${type==='0'?'':styles['disappear']}`}>附加信息</h3>
                        <h3 className={`${styles['OrderPayment-foot-h32']} ${type==='1'?'':styles['disappear']}`}>附加信息</h3>
                        <div className={styles['OrderPayment-foot-box']}>
                            <div className={styles['OrderPayment-foot-left']}>
                                <div>商品价格</div>
                                <div>交易数量</div>
                                <div>创建时间</div>
                                <div>订单编号</div>
                            </div>
                            <div className={styles['OrderPayment-foot-right']}>
                                <div>¥120.00</div>
                                <div>24</div>
                                <div>2022.06.02&nbsp;14:52</div>
                                <div className={styles['OrderPayment-foot-right-num']}>12479179875973592755</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles['OrderPayment-btn']} ${type === '0' ? '' : styles['disappear']}`}>
                <div className={styles['OrderPayment-btn-box']}>返回市场</div>
            </div>

            <div className={`${styles['OrderPayment-btn2']} ${type === '1' ? '' : styles['disappear']}`}>
                <div className={styles['OrderPayment-btn-box']}>返回市场</div>
            </div>





        </div>
    )
}

export default OrderPayment;