import { observable, action, makeObservable } from 'mobx'
import { battleGameHistoryApi } from './serve'
import type { BattleGameHistoryType } from './type'

class Api {
  constructor() {
    makeObservable(this)
  }


  /**
   * 星际逃亡质押历史
   * @param params 
   * @returns 
   */
  @observable battleGameHistoryList: any[] = []
  @observable battleGameHistoryListHasMore = true
  @action getBattleGameHistory: BattleGameHistoryType = async (params) => {
    const data = await battleGameHistoryApi(params)
    if (data.data.code === 0) {
      if (params && params.skip && params.skip > 0) {
        this.battleGameHistoryList = [...this.battleGameHistoryList, ...data.data.data]
      } else {
        this.battleGameHistoryList = data.data.data
      }
    }
    this.battleGameHistoryListHasMore = Boolean(data.data?.data?.length > 0)
    return Promise.resolve(data)
  }

}

export default new Api()