import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import store from '../../reducer'
import moment from 'moment'
import { Clock } from 'grommet'
import { observer } from 'mobx-react'

import type { PropsType } from '@/global'

import './destroy.scss'

interface DestroyType {
  nftGameTotalBonus: number,
  socketData: PropsType['AppState']['socketData']
}

const Destroy: React.FC<DestroyType> = ({ nftGameTotalBonus, socketData }) => {
  const [sortType, setSortType] = useState<'bonus' | 'countdown_time'>('bonus')
  const {
    nftGameList,
    getNftGameList,
    nftGameLastWinner
  } = store
  const navigate = useNavigate()


  useEffect(() => {
    if (socketData?.action === 'nft_game_change_destroy') {
      getNftGameList({ limit: 10000000, sort: sortType })
    }
  }, [socketData])

  useEffect(() => {
    getNftGameList({ limit: 10000000, sort: sortType })
  }, [sortType])


  const [countDowns, setCountDowns] = useState<any[]>([])


  useEffect(() => {
    let timers: NodeJS.Timer[] = []
    nftGameList.forEach((x, i) => {
      if (x.destroyDrawTime) {
        const timer = setInterval(() => {
          const now = moment().valueOf();
          const distance = moment(x.destroyDrawTime).valueOf() - now;
          const seconds = Math.floor((distance / 1000) % 60);
          const minutes = Math.floor((distance / 1000 / 60) % 60);
          if (distance < 0) {
            clearInterval(timer);
            countDowns[i] = '- - : - -'
          } else {
            countDowns[i] = (
              <Clock
                type="digital"
                precision="minutes"
                size='small'
                time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
              />
            )
          }
          setCountDowns([...countDowns])
        }, 1000);

        timers.push(timer)
      }
    })

    return () => {
      timers.forEach(timer => clearInterval(timer)); // 清理所有定时器  
    };
  }, [nftGameList])



  return (
    <div id="Destroy">
      <div className='Destroy-box1'>
        <div className='Destroy-box1-left'>
          <div className='Destroy-box1-left-top'>
            <span className='Destroy-box1-left-top-text'>灵金池总和</span>
            <img width={30} src={require('@/assets/imgs/building_charts 1.png')} alt="" />
          </div>
          <span className='Destroy-box1-left-text'>{(nftGameTotalBonus).toFixed(2)}</span>
          <div className='Destroy-box1-left-bottom'>
            <img style={{ borderRadius: '50%' }} width={16} height={16} src={nftGameLastWinner?.avatarUrl} alt="" />
            <span className='Destroy-box1-left-bottom-text'>{nftGameLastWinner?.nickName || '--'} 获得 {nftGameLastWinner?.bonusAmount || '--'} 灵金</span>
          </div>
        </div>
      </div>

      <div className='Destroy-box2'>
        <div className='Destroy-box2-top'>
          <div className='Destroy-box2-top-item' onClick={() => setSortType('bonus')}>
            <span className='Destroy-box2-top-item-span' style={sortType === 'countdown_time' ? { color: '#000' } : {}}>累计灵金</span>
            {sortType === 'bonus' && <img width={24} src={require('@/assets/imgs/arrow-up.svg').default} alt="" />}
            {sortType === 'countdown_time' && <img width={24} src={require('@/assets/imgs/arrow-down.svg').default} alt="" />}
          </div>
          <div className='Destroy-box2-top-item' onClick={() => setSortType('countdown_time')}>
            <span className='Destroy-box2-top-item-span' style={sortType === 'countdown_time' ? { color: '#000' } : {}}>开奖时间</span>
            {sortType === 'countdown_time' && <img width={24} src={require('@/assets/imgs/arrow-up.svg').default} alt="" />}
            {sortType === 'bonus' && <img width={24} src={require('@/assets/imgs/arrow-down.svg').default} alt="" />}
          </div>
        </div>
        <div className='Destroy-box2-list'>
          {nftGameList.length > 0 ? nftGameList.map((x, i) => (
            <div className='Destroy-box2-list-item' key={i} onClick={() => navigate('/secondaryMarket/destroy', { state: { aid: x.assetId, gameId: x.gameId } })}>
              {i + 1 <= 3 ? <img width={21} src={require(`@/assets/imgs/pool-${i + 1}.png`)} alt="" /> : <span>{i + 1}</span>}
              <img className='Destroy-box2-list-item-img' width={58} height={58} src={x.assetThumbList && x.assetThumbList[x.assetThumbList.length - 1]} alt="" />
              <div className='Destroy-box2-list-item-content'>
                <span className='Destroy-box2-list-item-content-text'>{x.assetTitle}</span>
                <div className='Destroy-box2-list-item-content-bottom'>
                  <span className='Destroy-box2-list-item-content-bottom-text1'><span>{countDowns[i] || '- - : - -'}</span> 后开奖</span>
                  |
                  <span className='Destroy-box2-list-item-content-bottom-text2'>当前灵金</span>
                  <span className='Destroy-box2-list-item-content-bottom-text3'>{x.currentBonusAmount || '0.00'}</span>
                </div>
              </div>
            </div>
          )) : (
            <div className='noData' style={{ top: 150 }}>
              <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(Destroy)