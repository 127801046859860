import { useEffect, useState } from 'react'
import store from '../redurce'
import type { PropsType } from '@/global'
import moment from 'moment'
import { observer } from 'mobx-react'

interface RankingPropsType {
  gameId?: string,
  socketData: PropsType['AppState']['socketData']
}

const Ranking: React.FC<RankingPropsType> = ({ gameId, socketData }) => {
  const { getTradeLeaderboard, tradeLeaderboard } = store


  useEffect(() => {
    if (gameId) {
      getTradeLeaderboard({
        gameId
      })
    }
  }, [])

  useEffect(() => {
    if (gameId) {
      if (socketData?.action === 'nft_game_change_trade_leaderboard') {
        getTradeLeaderboard({
          gameId
        })
      }
    }
  }, [socketData])


  return (
    <>
      {/* 排行榜页面 */}
      <div className={`RankingList-PrizePool`}>
        <div className='RankingList-PrizePool-top'>
          <h3 className='RankingList-PrizePool-top-h3'>流转积分排行榜（前10）</h3>
          <p className='RankingList-PrizePool-top-p'>我的排名：<span className='RankingList-PrizePool-top-span'>No.{tradeLeaderboard.userRank || '--'}</span></p>
        </div>
        <div className='RankingList-PrizePool-center'>
          {tradeLeaderboard.list?.length > 0 ? tradeLeaderboard.list.map((x, i) => (
            <div className='RankingList-PrizePool-box' key={i}>
              <div className='RankingList-PrizePool-box-left'>
                {i < 3 ? <img className='RankingList-PrizePool-box-img' src={require(`@/assets/imgs/pool-${i + 1}.png`)} alt="" /> : <span>{i+1}</span>}
                <div className='RankingList-PrizePool-box-center'>
                  <div className='RankingList-PrizePool-box-phone' style={x.currentUserFlag ? { color: '#4D33EF' } : {}}>{x.userNickname}{x.currentUserFlag && '(我)'}</div>
                  <p>{moment(x.statStartTime).format('YYYY.MM.DD HH:mm')}</p>
                </div>
              </div>
              <div className='RankingList-PrizePool-box-text'>流转积分 <span className='RankingList-PrizePool-box-num'>{x.totalAmount}</span></div>
            </div>
          )) : (
            <div className='noData' style={{ top: 150 }}>
              <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(Ranking)