import Header from "../../../component/header/header";
import styles from './prepaid.module.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';




const Prepaid: React.FC = () => {
  const [type, setType] = useState('0');
  const navigate = useNavigate();


  return (
    <div id={styles['Prepaid']}>
      <Header text='订单支付' fontColor="black" />
      <img className={`${styles['Prepaid-img']} ${type === '0' ? '' : styles['disappear']}`} src={require('../../../assets/imgs/OrderPayment-fill.svg').default} alt="" />
      <img className={`${styles['Prepaid-img']} ${type === '1' ? '' : styles['disappear']}`} src={require('../../../assets/imgs/OrderPayment-fill2.svg').default} alt="" />
      <h3 className={`${styles['Prepaid-title-h3']} ${type === '0' ? '' : styles['disappear']}`}>预付成功</h3>
      <h3 className={`${styles['Prepaid-title-h3']} ${type === '1' ? '' : styles['disappear']}`}>预付款已退回</h3>
      <div className={`${styles['Prepaid-time']} ${type === '0' ? '' : styles['disappear']}`}>
        <div className={styles['Prepaid-time-box']}>
          <img src={require('../../../assets/imgs/OrderPayment-line.svg').default} alt="" />
          <p className={styles['Prepaid-time-box-p']}>6&nbsp;天&nbsp;23&nbsp;小时&nbsp;59&nbsp;分&nbsp;08&nbsp;秒内未成交金额将退回</p>
        </div>
      </div>
      <div className={styles['Prepaid-center']}>
        <div className={styles['Prepaid-center-box']}>
          <div className={styles['Prepaid-center-box-top']}>
            <div className={styles['Prepaid-center-box-top-left']}>
              <img src={require('../../../assets/imgs/OrderPayment-title.svg').default} alt="" />
              <h3 className={styles['Prepaid-center-box-top-left-h3']}>数字藏品</h3>
            </div>
            <div className={styles['Prepaid-center-box-top-right']}>
              <h4 className={styles['Prepaid-center-box-top-right-h4']}><span className={styles['OrderPayment-center-box-top-right-h4-span']}>¥&nbsp;</span>2199.50</h4>
              <div className={styles['Prepaid-center-box-top-right-div']}>×24</div>
            </div>
          </div>

          <div className={`${styles['Prepaid-center-box-cent']} ${type === '0' ? '' : styles['disappear']}`}>
            <div className={`${styles['Prepaid-center-box-cent-left']} ${type === '0' ? '' : styles['disappear']}`}>
              <h4 className={styles['Prepaid-center-box-cent-left-h4']}>附加信息</h4>
              <p className={styles['Prepaid-center-box-cent-left-p']}>支付方式:&nbsp;微信支付</p>
            </div>
            <div className={`${styles['Prepaid-center-box-cent-right']} ${type === '0' ? '' : styles['disappear']}`}><span className={styles['OrderPayment-center-box-cent-right-span']}>¥</span>2199.50</div>
          </div>

          <div className={styles['Prepaid-foot']}>
            <h3 className={`${styles['Prepaid-foot-h3']} ${type === '0' ? '' : styles['disappear']}`}>附加信息</h3>
            <h3 className={`${styles['Prepaid-foot-h32']} ${type === '1' ? '' : styles['disappear']}`}>附加信息</h3>
            <div className={styles['Prepaid-foot-box']}>
              <div className={styles['Prepaid-foot-left']}>
                <div>商品价格</div>
                <div>交易数量</div>
                <div>创建时间</div>
                <div>订单编号</div>
              </div>
              <div className={styles['Prepaid-foot-right']}>
                <div>¥120.00</div>
                <div>24</div>
                <div>2022.06.02&nbsp;14:52</div>
                <div className={styles['Prepaid-foot-right-num']}>12479179875973592755</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles['Prepaid-btn']} ${type === '0' ? '' : styles['disappear']}`}>
        <div className={styles['Prepaid-btn-box']}>
          <div className={styles['Prepaid-btn-box-Cancel']} onClick={() => navigate(-1)}>取消订单</div>
          <div className={styles['Prepaid-btn-box-continue']} onClick={() => navigate('/secondaryMarket/RankingList')}>继续求购</div>
        </div>
      </div>

      <div className={`${styles['Prepaid-btn2']} ${type === '1' ? '' : styles['disappear']}`}>
        <div className={styles['Prepaid-btn-box']}>
          <div className={styles['Prepaid-btn-box-Cancel']} onClick={() => navigate('/secondaryMarket/RankingList')}>返回市场</div>
          <div className={styles['Prepaid-btn-box-continue']} onClick={() => navigate('/secondaryMarket/RankingList')}>继续求购</div>
        </div>
      </div>






    </div>
  )
}

export default Prepaid;