import { observable, action, makeObservable } from 'mobx'
import {
  pledgeCardsApi,
  mineDataApi,
  groupMineDataApi,
  mineListApi,
  openMineApi,
  groupMineListApi,
  scoreHistoryApi,
  subscribeListsApi,
  subscribeTimeAxisApi,
  subscribeLuckNumbersApi,
  scoreTransferApi,
  scorePreCheckNameApi,
  activitySubscribeInputApi,
  activitySubscribeLuckNumbersApi,
  subscribeHistoryApi
} from './serve'
import type {
  PropsType,
} from '../../global'

type MiningStoreType = PropsType['MiningStore']

class MiningStore {
  constructor() {
    makeObservable(this)
  }


  /**
   * 获取质押卡列表
   * @returns 
   */
  @observable pledgeCardsList: any[] = []
  pledgeCards: MiningStoreType['pledgeCards'] = async () => {
    const { data } = await pledgeCardsApi()
    if (data.code === 0) {
      this.pledgeCardsList = data.data
    }
    return Promise.resolve(data)
  }







  /**
   * 采矿数据
   * @param type 
   * @returns 
   */
  @observable mineDataInfo = {}
  mineData: MiningStoreType['mineData'] = async (type) => {
    const { data } = await mineDataApi(type)
    if (data.code === 0) {
      this.mineDataInfo = data.data
    }
    return Promise.resolve(data)
  }









  /**
   * 组队挖矿数据
   * @returns 
   */
  @observable groupMineDataInfo: any = {}
  groupMineData: MiningStoreType['groupMineData'] = async () => {
    const { data } = await groupMineDataApi()
    if (data.code === 0) {
      this.groupMineDataInfo = data.data
    }
    return Promise.resolve(data)
  }








  @observable mineGoldList: any[] = []
  @observable mineSilverList: any[] = []
  @observable mineBronzeList: any[] = []
  @observable mineListHasMore: boolean = true
  /**
   * 挖矿列表
   * @param param 
   * @returns 
   */
  getMineList: MiningStoreType['getMineList'] = async (param) => {
    const { data } = await mineListApi(param)
    if (data.code === 0) {
      if (param.type === 'GOLD') {
        if (param.skip && param.skip > 0) {
          this.mineGoldList = [...this.mineGoldList, ...data.data]
        } else {
          this.mineGoldList = data.data
        }
      }
      if (param.type === 'SILVER') {
        if (param.skip && param.skip > 0) {
          this.mineSilverList = [...this.mineSilverList, ...data.data]
        } else {
          this.mineSilverList = data.data
        }
      }
      if (param.type === 'BRONZE') {
        if (param.skip && param.skip > 0) {
          this.mineBronzeList = [...this.mineBronzeList, ...data.data]
        } else {
          this.mineBronzeList = data.data
        }
      }
    }
    this.mineListHasMore = Boolean(data.data.length > 0)
    return Promise.resolve(data)
  }






  /**
   * 质押开矿
   * @param param 
   * @returns 
   */
  openMine: MiningStoreType['openMine'] = async (param) => {
    const { data } = await openMineApi(param)
    return Promise.resolve(data)
  }







  /**
   * 组队挖矿列表
   * @param params 
   * @returns 
   */
  @observable groupMineListHasMore: boolean = true
  @observable groupMineList: any[] = []
  getGroupMineList: MiningStoreType['getGroupMineList'] = async (params) => {
    const { data } = await groupMineListApi(params)
    if (data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.groupMineList = [...this.groupMineList, ...data.data]
      } else {
        this.groupMineList = data.data
      }
    }
    this.groupMineListHasMore = Boolean(data.data.length > 0)
    return Promise.resolve(data)
  }




  /**
   * 
   * @param params 
   * @returns 
   */
  @observable scoreHistoryList: any[] = [];
  @observable hasMore: boolean = true;
  getScoreHistory: MiningStoreType['getScoreHistory'] = async (params) => {
    const { data } = await scoreHistoryApi(params)
    if (data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.scoreHistoryList = [...this.scoreHistoryList, ...data.data]
      } else {
        this.scoreHistoryList = data.data
      }
      this.hasMore = Boolean(data.data.length > 0)
    }
    return Promise.resolve(data)
  }





  @observable subscribeList: any[] = []
  /**
   * 申购列表
   * @returns 
   */
  getSubscribeList = async () => {
    const { data } = await subscribeListsApi()
    if (data.code === 0) {
      this.subscribeList = data.data
    }
    return Promise.resolve(data)
  }





  @observable subscribeTimeAxisInfo: any = {}
  /**
   * 申购时间轴
   * @param assetId 
   * @returns 
   */
  subscribeTimeAxisApi = async (assetId: string) => {
    const { data } = await subscribeTimeAxisApi(assetId)
    if (data.code === 0) {
      this.subscribeTimeAxisInfo = data.data
    }
    return Promise.resolve(data)
  }



  @observable subscribeLuckList: any[] = []
  /**
   * 抽签码
   * @param assetId 
   * @returns 
   */
  subscribeLuckNumbers = async (assetId: string) => {
    const { data } = await subscribeLuckNumbersApi(assetId)
    if (data.code === 0) {
      this.subscribeLuckList = data.data
    }
    return Promise.resolve(data)
  }



  /**
   * 转赠
   * @param params 
   * @returns 
   */
  scoreTransfer: MiningStoreType['scoreTransfer'] = async (params, headers) => {
    const data = await scoreTransferApi(params, headers)
    return Promise.resolve(data)
  }



  @observable checkName: string = ''
  /**
   * 获取受赠人的名字
   * @param toUid 
   * @returns 
   */
  scorePreCheckName = async (toUid: number) => {
    const data = await scorePreCheckNameApi(toUid)
    if (data.data.code === 0) {
      this.checkName = data.data.data.name
    }
    return Promise.resolve(data)
  }



  /**
   * 申购投入
   * @param params 
   * @returns 
   */
  activitySubscribeInput: MiningStoreType['activitySubscribeInput'] = async (params) => {
    const data = await activitySubscribeInputApi(params)
    return Promise.resolve(data)
  }



  @observable activitySubscribeLuckNumbersList: any[] = []
  /**
   * 抽签码
   * @param assetId 
   * @returns 
   */
  activitySubscribeLuckNumbers = async (assetId: string) => {
    const data = await activitySubscribeLuckNumbersApi(assetId)
    if (data.data.code === 0) {
      this.activitySubscribeLuckNumbersList = data.data.data
    }
    return Promise.resolve(data)
  }



  @observable subscribeHistoryList: any[] = []
  /**
   * 申购成功记录
   * @returns 
   */
  subscribeHistory = async () => {
    const data = await subscribeHistoryApi()
    if (data.data.code === 0) {
      this.subscribeHistoryList = data.data.data
    }
    return Promise.resolve(data)
  }




}

export default new MiningStore()