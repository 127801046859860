import { useState } from "react";
import Header from "../../../component/header/header";
import './HelpCenter.scss';
import { Collapse } from 'antd-mobile';
import {
  DownOutline,
  RightOutline,
} from 'antd-mobile-icons'
import CommonDialog from "../../../component/commonDialog/commonDialog";



const HelpCenter: React.FC = () => {
  const [state, setState] = useState<any>({
    wxVisible: false,
    qwxVisible: false
  })

  return (
    <div id="HelpCenter">
      <Header text='帮助中心' fontColor="black" />
      <div className="HelpCenter-box">
        <h3 className="HelpCenter-h3">自助服务</h3>
        <div className="HelpCenter-top">
          <div className="HelpCenter-top-left" onClick={() => setState({ ...state, qwxVisible: true })}>
            <div>
              <p className="HelpCenter-top-left-p1">在线客服</p>
              <p className="HelpCenter-top-left-p2">7×24全天侯服务</p>
            </div>
            <div className="HelpCenter-top-left-img"></div>
          </div>
          <div className="HelpCenter-top-right" onClick={() => setState({ ...state, wxVisible: true })}>
            <div>
              <p className="HelpCenter-top-right-p1">微信客服</p>
              <p className="HelpCenter-top-right-p2">工作日9-18点</p>
            </div>
            <div className="HelpCenter-top-right-img"></div>
          </div>
        </div>
        <h3 className="HelpCenter-center-h3">常见问题</h3>
        <div className="HelpCenter-clooapse">
          <Collapse accordion >
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='1' title=' 如何获得基石原卡'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">购买纪元闪卡即可获得基石原卡盲盒，打开基石原卡盲盒即可获得任意一款独立编号的数字藏品-基石原卡。  </p>
            </Collapse.Panel>
            <Collapse.Panel key='2' title='基石原卡数字藏品如何查验？'
              className="HelpCenter-clooapse-panel"
              arrow={active =>
                active ? <DownOutline className="HelpCenter-clooapse-DownOutline" /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-p">您可登录百度超级链浏览器<a onClick={() => {
                window.open('https://xuper.baidu.com/n/scan#/')
              }} className="HelpCenter-clooapse-panel-a"><ins>数字商品查询平台-百度超级链(https://xuper.baidu.com/n/scan#/)</ins></a>输入您的数字藏品商品ID即可查询该藏品的区块链信息，您所持有的数字藏品通过百度超级链链上确权及保障。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='3' title='基石原卡怎么玩？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">拥有基石原卡，即可参与源矿开采活动获得相应原石奖励。可参与平台集卡活动获得丰厚奖励。可参与质押抽奖活动抽取星源秘钥幸运奖励。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='4' title='获得原石后怎么玩？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">您可通过转赠通道将原石赠予好友，也可在平台内使用原石兑换其他数字艺术藏品或参与原石板块相关游戏玩法。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='5' title='原石兑换的数字藏品有什么作用？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">当您成功兑换到全新数字艺术藏品后，您可以通过平台内流转市场，进行藏品相关流转，并参与相关流转销毁及质押活动获得丰厚奖励与星源秘钥。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='6' title='什么是灵金？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">灵金为平台解构游戏奖池积分，玩家可通过参与解构游戏获得灵金奖励，灵金可在平台内炼金所通过当日相应黄金价格销毁提炼为实物黄金。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='7' title='星源秘钥怎么玩？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text2">星源秘钥发行量为2100w个,比例分配如下：
                (1)36%藏品交易采矿产生,首日发行120000个,后每天发行量等于前一天发行量减100,直至采完
                (2)32%未来生态ip激励及推广相关运作,按IP生态建设需求释放
                (3)13.5%平台未来运营储备
                (4)10%原石质押抽奖活动分发
                (5)5%质押采矿奖励,总量105w,分1000天采完,每天105个
                (6)2%社群运营奖励释放
                (7)1.5%创始团队奖励,3年内按线性分配
                用户持有星源秘钥即可：
                ①参与平台内游戏活动，平台开放星源秘钥大逃杀游戏和其他各个类型的小游戏，通过游戏获取更多星源秘钥和其他平台生态价值。
                ②参与平台内生态价值投票，参与平台购入的元宇宙土地等权益的投票，通过投票决定是否将平台购入的元宇宙资产售出，从而根据比例瓜分售出的价值收益。
                ③随着平台的发展，平台将持续推进元宇宙版块的建设，当元宇宙版块的发展到一定规模，平台将打通国内外元宇宙的隔阂，迈向海外元宇宙平台，联合建设国内外元宇宙项目，星源秘钥作为平台元宇宙版块基础，将贯穿于元宇宙项目的发展过程中。
                ④以星源秘钥持有数开启平台会员体系，根据持有量获取平台内权益，如纪元闪卡的额外购买数量、源矿的额外发放、原石礼赠朋友的服务费减少...
              </p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='8' title='购买了纪元闪卡，但赠送的基石原卡和源矿未到账？'
              arrow={active =>
                active ? <DownOutline className="HelpCenter-clooapse-panel-DownOutline" /> : <RightOutline className="HelpCenter-clooapse-panel-RightOutline" />}
            >
              <p className="HelpCenter-clooapse-panel-text">赠送的藏品和源矿为24小时内发放,不一定是实时发放，请您耐心等待赠品的发放。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='9' title='藏品流转过程中，付款后藏品未到账显示区块链确认中？'
              arrow={active =>
                active ? <DownOutline className="HelpCenter-clooapse-panel-DownOutline" /> : <RightOutline className="HelpCenter-clooapse-panel-RightOutline" />}
            >
              <p className="HelpCenter-clooapse-panel-text">藏品的流转，需要百度区块链的确认，该过程在付款成功后会发起一次，若区块链确认未成功，后续将每半小时发起一次确认动作，直至藏品到达接收方账户。</p>
            </Collapse.Panel>
            <Collapse.Panel className="HelpCenter-clooapse-panel"
              key='10' title='藏品流转过程中，藏品流出后未收到款？'
              arrow={active =>
                active ? <DownOutline /> : <RightOutline />}
            >
              <p className="HelpCenter-clooapse-panel-text">收款方收款并非实时到账,需等待区块链确认藏品流转成功,钱包将在24小时内将款项发放给收款方,若24小时后仍未收到款项,请及时联系客服。</p>
            </Collapse.Panel>

          </Collapse>
        </div>
      </div>

      <CommonDialog show={state.wxVisible}>
        <div className='invit-wxbox'>
          <img width={200} src={require('@/assets/imgs/WechatIMG331.jpeg')} alt="" />
          <span>保存截图，在微信内扫码联系客服</span>
          <img className='invit-wxbox-close' width={32} onClick={() => setState({ ...state, wxVisible: false })} src={require('../../../assets/imgs/close.svg').default} alt="" />
        </div>
      </CommonDialog>
      <CommonDialog show={state.qwxVisible}>
        <div className='invit-wxbox'>
          <img width={200} src={require('@/assets/imgs/WechatIMG211.jpeg')} alt="" />
          <span>保存截图，在企业微信内扫码联系客服</span>
          <img className='invit-wxbox-close' width={32} onClick={() => setState({ ...state, qwxVisible: false })} src={require('../../../assets/imgs/close.svg').default} alt="" />
        </div>
      </CommonDialog>
    </div >
  )
}

export default HelpCenter;