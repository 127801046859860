import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import miningStore from '@/store/miningStore'
import appState from '@/store/appState'
import Header from "../../../component/header/header"
import moment from 'moment'
import './CollectionSubscription.scss'

const CollectionSubscription: React.FC = () => {
  const { commonData } = appState
  const navigate = useNavigate()

  const dateHandle = (x: any) => {
    const currentTime = moment().valueOf()
    const beginTime = moment(x.beginTime).valueOf()
    const endTime = moment(x.endTime).valueOf()

    if (currentTime < beginTime) {
      return moment(x.beginTime).format('YYYY.MM.DD HH:mm') + ' 开始'
    }

    if (currentTime > beginTime && currentTime < endTime) {
      return moment(x.endTime).format('YYYY.MM.DD HH:mm') + ' 结束'
    }

    if (currentTime > endTime) {
      return '已结束'
    }

  }

  useEffect(() => {
    miningStore.getSubscribeList()
  }, [])

  return (
    <div id="CollectionSubscription">
      <Header text='藏品申购' fontColor="white" />
      <div className="CollectionSubscription-top">
        <div className="CollectionSubscription-top-my">
          <div>可用原石</div>
          <img src={require('../../../assets/imgs/MyGem-gem.svg').default} />
        </div>
        <div className="CollectionSubscription-top-time">{commonData?.real_score}</div>
        <span className='CollectionSubscription-top-text'>申购中：{commonData?.subscribe_mine_score}</span>
        <div className="CollectionSubscription-top-btn" onClick={() => navigate('/mining/SubscriptionRecords')}>申购记录</div>
      </div>
      {miningStore.subscribeList.length > 0 ? miningStore.subscribeList.map((x, i) => (
        <div className="CollectionSubscription-center" key={i} onClick={() => navigate(`/mining/subscribe?assetId=${x.assetId}`)}>
          <div className="CollectionSubscription-center-box">
            <div className="CollectionSubscription-center-box-img">
              <div className="CollectionSubscription-center-box-img-time">{dateHandle(x)}</div>
              <img style={{ width: '100%' }} src={x.thumb && x.thumb[x.thumb.length - 1]} alt="" />
            </div>
            <h3 className="CollectionSubscription-center-box-h3">{x.title}</h3>
            <div className="CollectionSubscription-center-box-fon">
              <div className="CollectionSubscription-center-box-text">
                <div>签数剩余</div>
                <div>价格</div>
                <div>参与人数</div>
                <div>投入总量</div>
              </div>
              <div className="CollectionSubscription-center-box-num">
                <div>{x.issueCount}</div>
                <div>{x.price}原石</div>
                <div>{x.participants}</div>
                <div>{x.investmentAmount}原石</div>
              </div>
            </div>
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 350 }}>
          <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      )}
    </div>
  )
}

export default observer(CollectionSubscription)