import React, { useEffect, useState } from 'react';
import { Checkbox, Toast } from 'antd-mobile';
import { useLocation } from 'react-router-dom'
import appState from '@/store/appState';
import secondaryMarket from '@/store/secondaryMarket';

import styles from './wantToBuy.module.scss';
import Header from '../../../component/header/header';

const WantToBuy: React.FC = () => {
  const { state } = useLocation()
  const detail = JSON.parse(state)
  const [amount, setAmount] = useState(1)
  const [unit_price, setUnit_price] = useState('')
  const { commonData } = appState
  const [payChannel, setPayChannel] = useState<'sandpay' | 'huifupay' | null>(null)

  const changeAmountHnadle = (action: 'add' | 'sub') => {
    if (action === 'add') {
      setAmount(x => x + 1)
    }

    if (action === 'sub') {
      setAmount(x => {
        if (x - 1 < 0) {
          return x
        }
        return x - 1
      })
    }
  }

  const createOrderHandle = () => {
    if (payChannel === null) return Toast.show('请选择支付方式')
    if (unit_price === '') return Toast.show('请输入求购价格')

    secondaryMarket.ordersCreate({
      pay_channel: payChannel,
      aid: detail.aid,
      type: 'bid',
      unit_price: Number(unit_price) * 100,
      amount
    })

  }

  return (
    <div id={styles['WantToBuy']}>
      <Header text='发起求购' fontColor="black" />
      <div className={styles['WantToBuy-box']}>
        <div className={styles['WantToBuy-box-title']}>
          <div className={styles['WantToBuy-box-title-top']}>
            <img width={64} height={64} src={detail.thumb && detail.thumb[detail.thumb.length - 1]} alt="" />
            <div className={styles['WantToBuy-box-title-top-right']}>
              <h4 className={styles['WantToBuy-box-title-top-right-h4']}>{detail.title}</h4>
              <div className={styles['title-top-right-div']}>
                <p>最高求购价<span className={styles['title-top-right-div-span']}>&nbsp;¥&nbsp;{(detail.max_bid_unit_price / 100).toFixed(2)}</span></p>
                <img className={styles['title-top-right-div-img']} src={require('../../../assets/imgs/WantToBuy-small.svg').default} alt="" />
              </div>
            </div>
          </div>
          <div className={styles['WantToBuy-box-title-center']}>
            <span>¥</span>
            <input className={styles['WantToBuy-box-title-center-input']} type="number" value={unit_price} onChange={e => {
              if (Number(e.target.value) > (detail.max_bid_unit_price / 100)) {
                return setUnit_price(String(detail.max_bid_unit_price / 100))
              }
              if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                setUnit_price(e.target.value)
              }
            }} />
          </div>
          <div className={styles['WantToBuy-box-title-text']}>求购价格不得低于地板价的70%,当前地板价为&nbsp;<span className={styles['WantToBuy-box-title-text-span']}>{(detail.current_floor_price / 100).toFixed(2)}</span></div>
          <div className={styles['WantToBuy-box-title-foot']}>
            <div className={styles['WantToBuy-box-title-foot-left']}>求购数量</div>
            <div className={styles['WantToBuy-box-title-foot-right']}>
              <div className={styles['WantToBuy-box-title-foot-right-btn']} onClick={() => changeAmountHnadle('sub')}>-</div>
              <input className={styles['WantToBuy-box-title-foot-right-input']} type="text" value={amount} onChange={e => {
                if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                  setAmount(Number(e.target.value))
                }
              }} />
              <div className={styles['WantToBuy-box-title-foot-right-btn2']} onClick={() => changeAmountHnadle('add')}>+</div>
            </div>
          </div>
        </div>

        <div className={styles['Popup-top-center']}>
          <h4 className={styles['Popup-h4']}> 支付方式</h4>
          <div className={styles['Popup-wallet-sd']}>
            <div className={styles['Popup-wallet-sd-left']}>
              <img className={styles['Popup-wallet-sd-left-img']} src={require('@/assets/imgs/onChainInformation-money1.svg').default} alt="" />
              <div className={styles['Popup-wallet-sd-left-text']}>{commonData?.wallet?.bind ? `杉德钱包 (余额 ${((commonData?.wallet.balance) / 100).toFixed(2)})` : '杉德钱包 (未开通)'}</div>
            </div>
            <div className={styles['Popup-wallet-sd-left-checkbox']}>
              {commonData?.wallet?.bind ?
                <Checkbox checked={payChannel === 'sandpay'} onChange={() => setPayChannel('sandpay')} />
                :
                (
                  <div className={styles['Popup-wallet-hf-right']} onClick={() => window.location.href = commonData?.wallet?.url}>
                    <div className={styles['Popup-wallet-hf-right-add']}>立即开通</div>
                    <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                  </div>
                )}
            </div>
          </div>

          <div className={styles['Popup-wallet-hf']}>
            <div className={styles['Popup-wallet-hf-left']}>
              <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-money2.svg').default} alt="" />
              <div className={styles['Popup-wallet-hf-left-text']}>{commonData?.wallet2?.bind ? `汇付钱包 (余额 ${((commonData?.wallet2.balance) / 100).toFixed(2)})` : '汇付钱包 (未开通)'}</div>
            </div>
            {commonData?.wallet2?.bind ?
              <Checkbox checked={payChannel === 'huifupay'} onChange={() => setPayChannel('huifupay')} />
              :
              (
                <div className={styles['Popup-wallet-hf-right']} onClick={() => window.location.href = commonData?.wallet2?.url}>
                  <div className={styles['Popup-wallet-hf-right-add']}>立即开通</div>
                  <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                </div>
              )}
          </div>
        </div>

        <div className={styles['WantToBuy-foot']}>
          <div className={styles['WantToBuy-foot-box']}>
            <h3 className={styles['WantToBuy-foot-box-h3']}>求购说明</h3>
            <div className={styles['WantToBuy-foot-box-div']}>
              1.如果自发起求购订单后，一周内没有成交，将会自动退款<br />
              2.预付成功后，可在“我的-求购订单”中取消订单。<br />
              3.成交顺序以发起求购成功的时间，顺序成交<br />
              4.求购期间成交，无法撤销
            </div>
          </div>
        </div>
        <div className={styles['WantToBuy-btn']}>
          <div className={styles['WantToBuy-btn-box']}>
            <div className={styles['WantToBuy-btn-box-left']}>
              <div>合计</div>
              <div className={styles['WantToBuy-btn-box-left-div']}><span className={styles['WantToBuy-btn-box-left-div-span']}>¥</span>{(Number(unit_price) * Number(amount)).toFixed(2)}</div>
            </div>
            <div className={styles['WantToBuy-btn-box-right']} onClick={createOrderHandle}>预付</div>
          </div>
        </div>



      </div>


    </div>
  )
}

export default WantToBuy;