import { useNavigate } from 'react-router'
import { ProgressBar } from 'antd-mobile'

import './businessCopyright.scss'

const BusinessCopyright: React.FC = () => {
  const navgiate = useNavigate()

  return (
    <div id="BusinessCopyright">
      <div className='BusinessCopyright-header'>
        <img className='BusinessCopyright-header-img' onClick={() => navgiate(-1)} src={require('../../assets/imgs/arrow-left-white.svg').default} alt="" />
        <span className='BusinessCopyright-header-text'>商业版权</span>
      </div>
      <div className='BusinessCopyright-box1'>
        <span className='BusinessCopyright-box1-text1'>商业版权总收入 <img width={16} src={require('../../assets/imgs/main_red_big 1.png')} alt="" /></span>
        <span className='BusinessCopyright-box1-text2'>¥ 0</span>
        <span className='BusinessCopyright-box1-text3'>来源于平台合作元宇宙土地商业授权及生态价值建设收入</span>

        <span className='BusinessCopyright-box1-text4'>查看详情</span>
        <video
          className='BusinessCopyright-box1-video'
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          onClick={(e) => e.preventDefault()}
          onMouseDown={(e) => e.preventDefault()}>
          <source src={require('../../assets/imgs/businessCopyright.mp4')} type='video/mp4' />
        </video>
        <div className='BusinessCopyright-progressBar-box'>
          <span className='BusinessCopyright-progressBar-box-text'>元宇宙土地生态创建进度</span>
          <ProgressBar
            percent={70}
            style={{
              '--fill-color':
                '#4D33EF',
              flex: 0.95,
              '--track-color': '#1e1e1e',
              width: '208px',
              zIndex: 1
            }}
          />
        </div>
      </div>

      <div className='BusinessCopyright-box2'>
        <span className='BusinessCopyright-box2-title'>商业版权介绍</span>
        <p className='BusinessCopyright-box2-p'>
          随着平台的发展，平台将持续推进元宇宙版块的建设，当元宇宙版块的发展到一定规模，平台将打通国内外元宇宙的隔阂，迈向海外元宇宙平台，联合建设国内外元宇宙项目，星源秘钥作为平台元宇宙版块基础，将贯穿于元宇宙项目的发展过程中。
          在平台的元宇宙版块，平台将开启元宇宙价值权益的投票，如平台将在原卡流通量达到一定阶段性目标后联合第三方针对全球市场具有标志性代表的元宇宙土地项目进行合作生态建设等，用户可使用基石原卡和星源秘钥参与投票，投票通过后将根据相应规则委托第三方实现元宇宙商业版权价值释放，按比例为平台生态价值共建者发放相应版权价值。
        </p>
        <img className='BusinessCopyright-box2-img' src={require('../../assets/imgs/BusinessCopyright-footimg.svg').default} alt="" />
      </div>
    </div>
  )
}

export default BusinessCopyright