import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Toast, Input, Dialog, CenterPopup, Switch } from 'antd-mobile'
import { inject, observer } from 'mobx-react'
import copy from 'copy-to-clipboard/index';
import { type PropsType } from '../../global'

import Header from '../../component/header/header';
import './settings.scss'

const PersonalSetting: React.FC<PropsType> = ({ AppState }) => {
  const [visible2, setVisible2] = useState(false);
  const { commonData } = AppState
  const navigate = useNavigate()

  const updateAvaHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    AppState.uploadAva(event).then((data) => {
      if (data) {
        AppState.updateUserInfoHandle({
          avatarUrl: data
        })
      }
    })
  }

  const [nickname, setNickname] = useState('')
  const [visible, setVisible] = useState(false)
  const updateNicknameHandle = () => {
    if (nickname.length > 0) {
      AppState.updateUserInfoHandle({
        nickName: nickname
      })
      setVisible(false)
    } else {
      Toast.show('请输入昵称')
    }
  }

  return (
    <div id='setting'>
      <Header text='个人设置' />
      <ul className='setting-list'>
        <li className='setting-list-item'>
          {/* <input style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, opacity: 0 }} type='file' accept='image/*' onChange={updateAvaHandle} /> */}
          <span>头像</span>
          <div className='setting-list-item-avatar'>
            <img className='setting-list-item-avatar-img' src={commonData?.avatar_url ? commonData?.avatar_url : 'http://images.diaoqianyaner.com.cn/img/headimage.png'} alt="" />
            <img src={require('../../assets/imgs/arrow-right.svg').default} alt="" />
          </div>
        </li>
        <li className='setting-list-item' onClick={() => setVisible(true)}>
          <span>用户昵称</span>
          <div>
            <span className='Mine-top-left-text1'>{commonData?.nick_name}</span>
            <img src={require('../../assets/imgs/arrow-right.svg').default} alt="" />
          </div>
        </li>
        <li className='setting-list-item'>
          <span>绑定手机</span>
          <span className='Mine-top-left-text1'>{commonData?.mobile}</span>
        </li>
        <li className='setting-list-item' onClick={() => {
          copy(commonData?.to_add)
          Toast.show('复制成功')
        }}>
          <span>区块链地址</span>
          <span className='Mine-top-left-text1' style={{ color: 'blue' }}>{String(commonData?.to_add).slice(0, 10) + '...' + String(commonData?.to_add).slice(-10)}</span>
        </li>
        <li className='setting-list-item' onClick={() => { setVisible2(true) }}>
          <span>社交信息</span>
          <div>
            <img src={require('../../assets/imgs/arrow-right.svg').default} alt="" />
          </div>
        </li>
        <li className='setting-list-item' onClick={() => navigate('/AboutUs')}>
          <span>关于我们</span>
          <div>
            <span className='Mine-top-left-text1'>{AppState.commonData?.nickName}</span>
            <img src={require('../../assets/imgs/arrow-right.svg').default} alt="" />
          </div>
        </li>
        {/* <li className='setting-list-item' onClick={() => {
                        Dialog.show({
                            title: '温馨提示 ',
                            content: '注销账号将删除对话及用户数据，确定要注销账号么？',
                            closeOnAction: true,
                            actions: [
                                [
                                    {
                                        key: 'cancel',
                                        text: '取消',
                                    },
                                    {
                                        key: 'delete',
                                        text: '注销',
                                        bold: true,
                                        danger: true,
                                        onClick: () => {
                                            localStorage.clear();
                                            navigate('/login');
                                        },

                                    },
                                ],
                            ],
                        })
                    }}>
                        <span>注销账号</span>
                        <span className='Mine-top-left-text1'> <img src={require('../../assets/imgs/arrow-right.png')} width={16} alt="" /> </span>
                    </li> */}
      </ul>
      <button className='logout-btn btn' onClick={() => {
        Dialog.show({
          title: '温馨提示',
          content: '确定要退出登录么？',
          closeOnAction: true,
          actions: [
            [
              {
                key: 'cancel',
                text: '取消',
              },
              {
                key: 'delete',
                text: '退出',
                bold: true,
                danger: true,
                onClick: () => {
                  localStorage.clear();
                  navigate('/login/MyPer');
                },

              },
            ],
          ],
        })
      }}
      >
        退出登录
      </button>

      <Dialog
        visible={visible}
        title={'修改昵称'}
        content={<Input maxLength={8} placeholder='请在此输入昵称...' value={nickname} onChange={(e) => setNickname(e)} />}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
            },
            {
              key: 'confirm',
              text: '确定',
            },
          ]
        ]}
        onAction={(action) => {
          setNickname('')
          switch (action.key) {
            case 'cancel':
              return setVisible(false)
            case 'confirm':
              return updateNicknameHandle()
          }
        }}
      />

      <CenterPopup
        visible={visible2}
        bodyStyle={{
          borderRadius: '14px',
          minWidth: '80vw',
        }}
        onMaskClick={() => {
          setVisible2(false)
        }}
      >
        <div className='PersonalSetting-CenterPopup'>
          <h3 className='PersonalSetting-CenterPopup-h3'>社交信息</h3>
          <div className='PersonalSetting-CenterPopup-center'>
            <div className='PersonalSetting-CenterPopup-center-top'>
              <div className='PersonalSetting-CenterPopup-center-top-left'>
                <img src={require('../../assets/imgs/PersonalSetting-weix.svg').default} alt="" />
                <div className='PersonalSetting-CenterPopup-center-top-left-text'>微信号</div>
              </div>
              <Switch
                className='PersonalSetting-CenterPopup-center-top-right'
                defaultChecked={false}
                disabled
                style={{
                  '--checked-color': '#4D33EF',
                  '--height': '24px',
                  '--width': '39px',
                  background: '../../assets/imgs/PersonalSetting-eye2.svg no-repeat center center / contain',
                }}
              />
            </div>
            <div className='PersonalSetting-CenterPopup-center-bottom'>
              <div className='PersonalSetting-CenterPopup-center-bottom-left'>
                <img src={require('../../assets/imgs/PersonalSetting-qq.svg').default} alt="" />
                <div className='PersonalSetting-CenterPopup-center-bottom-left-text'>微信号</div>
              </div>
              <Switch
                className='PersonalSetting-CenterPopup-center-bottom-right'
                disabled
                style={{
                  '--checked-color': '#4D33EF',
                  '--height': '24px',
                  '--width': '39px',
                }}
              />
            </div>
            <div>
            </div>
          </div>
          <div className='PersonalSetting-CenterPopup-btnBox'>
            <div className='PersonalSetting-CenterPopup-btnBox-btn' onClick={() => { setVisible2(false) }}>敬请期待</div>
          </div>
        </div>
      </CenterPopup>
    </div >
  );
}


export default inject('AppState')(observer(PersonalSetting));
