import Axios from '@/request/request';
import qs from 'qs';
import type { IQueryPledgeDetailResponse } from './type';

export const queryPledgeDetail = () => {
  return Axios.get(`/activity/pledge/detail`);
};

export const submitPledge = (params: any) => {
  return Axios.post(`/activity/pledge/input`, params);
};

export const queryPledgeHistory = (params: any) => {
  return Axios.get(`/activity/pledge/history`, { params });
};

export const computedPledgeIncome = (params: { count: number; weeks: number }) => {
  return Axios.get(`/activity/pledge/cal`, { params });
};
