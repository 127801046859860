import styles from './announcement.module.scss';
import Header from '../../component/header/header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react'
import appState from '@/store/appState';
import moment from 'moment';


const Announcement: React.FC = () => {
  const [type, setType] = useState('');
  const navigate = useNavigate()


  useEffect(() => {
    appState.getNftNotice(type)
  }, [type])


  const click = (x: any) => {
    navigate(`/announcement/noticeDetails?url=${x.url}`)
  }


  return (
    <div id={styles['Announcement']}>
      <Header text='官方公告' fontColor='black'
        menu={
          <div className={styles['Announcement-menu-box']} onClick={() => navigate('/indexPoster')}>
            <div className={styles['Announcement-menu']}>
              <img className={styles['Announcement-menu-img']} src={require('../../assets/imgs/Announcement-game.svg').default} alt="" />
              <div>玩法</div>
            </div>
          </div>}
      />

      <div className={styles['Announcement-title']}>
        <div className={styles["search-input"]}>
          <img className={styles["search-icon"]} src={require('../../assets/imgs/Announcement-sousuo.svg').default} alt="Search Icon" />
          <input className={styles['search-inp']} type="text" placeholder="请输入要搜索的内容" />
        </div>

        <div className={styles['Announcement-select']} style={{ width: '100vw', overflow: 'auto' }}>
          <div className={styles['Announcement-select-box']}>
            <div className={`${styles['Announcement-select-box-item']} ${type === '' ? styles['active'] : ''}`} onClick={() => setType('')}>最新公告</div>
            <div className={`${styles['Announcement-select-box-item']} ${type === 'huodong' ? styles['active'] : ''}`} onClick={() => setType('huodong')}>活动公告</div>
            {/* <div className={`${styles['Announcement-select-box-item']} ${type === 'hecheng' ? styles['active'] : ''}`} onClick={() => setType('hecheng')}>合成公告</div> */}
            <div className={`${styles['Announcement-select-box-item']} ${type === 'yunying' ? styles['active'] : ''}`} onClick={() => setType('yunying')}>运营公告</div>
            {/* <div className={`${styles['Announcement-select-box-item']} ${type === 'funeng' ? styles['active'] : ''}`} onClick={() => setType('funeng')}>赋能公告</div> */}
          </div>
        </div>
      </div>


      <div className={styles['Announcement-center-Bigbox']}>
        {appState?.nftNoticeList.length > 0 ? appState?.nftNoticeList.map((x, i) => (
          <div className={styles['Announcement-center']} key={i} onClick={() => click(x)}>
            <div className={styles['Announcement-center-box']}>
              <h3 className={styles['Announcement-center-box-h3']}>{x.title}</h3>
              <p className={styles['Announcement-center-box-p']}>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
            </div>
          </div>
        )) :
          (
            <div className='noData' style={{ top: 300 }}>
              <img className='noData-img' src={require('../../assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default observer(Announcement);