import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import secondaryMarket from '@/store/secondaryMarket';
import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll';
import { AddCircleOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react'
import appState from '@/store/appState';

interface AskBuyType {
  sort: 'sku.sid' | 'price'
}

const AskBuy: React.FC<AskBuyType> = ({ sort }) => {
  const navigate = useNavigate();
  const { goodsSearcExtra, getOrdersBidOrderList, ordersBidOrderList, ordersBidOrderListHasMore } = secondaryMarket
  const { commonData } = appState


  useEffect(() => {
    getOrdersBidOrderList({
      aid: goodsSearcExtra.aid
    })
  }, [])

  return (
    <>
      <div className={`RankingList-center-foot`}>
        {ordersBidOrderList.map((item, i) => (
          <div>
            <div className='RankingList-center-foot-box' style={{ flex: 1, margin: 0 }} key={i} onClick={() => {
              if (commonData.uid !== item.to_uid) {
                navigate('/secondaryMarket/priceSold', { state: JSON.stringify(item) })
              }
            }}>
              <div className='RankingList-center-foot-left' style={{ alignItems: 'center' }}>
                <div className='RankingList-center-foot-left-top' >
                  <div className='RankingList-center-foot-left-top-box'>
                    <h4>{item.goods.title}</h4>
                    {commonData.uid === item.to_uid && <div className='RankingList-center-foot-left-top-div'>我求购的</div>}
                    <img src={require('@/assets/imgs/RankingList-fill2.svg').default} key={i} />
                  </div>
                </div>
              </div>
              <div className='RankingList-center-foot-ritgh'>
                <div className='RankingList-center-foot-ritgh-div'><span className='RankingList-center-foot-ritgh-span'>¥</span>&nbsp;{((item.bid_unit_price) / 100).toFixed(2)}</div>
                <img className='RankingList-center-sort-right-img' src={require('@/assets/imgs/RankingList-fill.svg').default} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ position: 'relative' }}>
        <InfiniteScrollComponent
          hasMore={ordersBidOrderListHasMore}
          dataSource={ordersBidOrderList}
          loadMore={async () => {
            await getOrdersBidOrderList({
              aid: goodsSearcExtra.aid,
              skip: ordersBidOrderList.length,
            })
          }}
          top={100}
        />
      </div>

      <div className='Jackpot-footer'>
        <div className='Jackpot-footer-btn btn' onClick={() => navigate('/secondaryMarket/WantToBuy', { state: JSON.stringify({ ...goodsSearcExtra }) })}>
          <AddCircleOutline color='#000' width={24} height={24} style={{ background: '#fff', borderRadius: '50%', marginRight: 8 }} />
          发布求购
        </div>
      </div>
    </>
  )
}

export default observer(AskBuy)