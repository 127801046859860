import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CenterPopup, NumberKeyboard } from 'antd-mobile'
import appState from '@/store/appState'
import './dealPassword.scss'

interface PropsType {
  visible: boolean,
  onConfirm: (value: string) => void,
  onClose: () => void,
}

const DealPassword: React.FC<PropsType> = (props) => {
  const [state, setState] = useState<{ authCodeItem: string[] }>({
    authCodeItem: [],
  });
  const navigate = useNavigate()
  const { commonData } = appState

  const handleChangeCode = (e: string) => {
    state.authCodeItem.push(e)
    setState({ ...state });
    if (state.authCodeItem.length >= 6) {
      props.onConfirm(state.authCodeItem.join(""))
      props.onClose()
      setTimeout(() => {
        setState({ authCodeItem: [] })
      }, 200)
      return
    }
  };

  useEffect(() => {
    if (props.visible) {
      if (!commonData.has_password) {
        navigate('/password')
      }
    }
  }, [props.visible])

  return (
    <CenterPopup
      visible={props.visible}
      getContainer={null}
      className='DealPassword'
    >
      <div id='DealPassword'>
        <span className='DealPassword-text'>请输入交易密码</span>
        <div className='DealPassword-center'>
          <div className="showCode">
            <div className="codeItem" style={state.authCodeItem[0] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[0]}</div>
            <div className="codeItem" style={state.authCodeItem[1] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[1]}</div>
            <div className="codeItem" style={state.authCodeItem[2] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[2]}</div>
            <div className="codeItem" style={state.authCodeItem[3] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[3]}</div>
            <div className="codeItem" style={state.authCodeItem[4] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[4]}</div>
            <div className="codeItem" style={state.authCodeItem[5] ? {} : { backgroundColor: '#F6F6F6' }}>{state.authCodeItem[5]}</div>
          </div>
        </div>
        <span className='DealPassword-text2' onClick={() => navigate({ pathname: '/password' }, { state: { type: 'reset' } })}>忘记密码？</span>
      </div>
      <NumberKeyboard
        visible={props.visible}
        onDelete={() => { state.authCodeItem.pop(); setState({ ...state }) }}
        onInput={(e) => handleChangeCode(e)}
        onClose={() => { props.onClose() }}
      />
    </CenterPopup>
  )
}

export default DealPassword