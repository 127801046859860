import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import moment from 'moment'
import primaryMarket from '@/store/primaryMarket'
import { InfiniteScroll } from 'antd-mobile'
import secondaryMarket from '@/store/secondaryMarket'

import Header from '../../component/header/header'

import './order.scss'

enum status {
  success = '交易成功',
  wait_paid = '等待付款',
  cancel = '交易取消',
  close = '交易关闭',
  refund = '退款成功',
  fail = '失败',
  partial_success = '部分成功',
}

enum transferStatus {
  'create' = '等待支付',
  'cancel' = '取消订单',
  'pay' = '付款成功',
  'process' = '区块链确认中',
  'refund' = '交易失败，已退款',
  'confirm' = '转入成功',
  "refunding" = '退款中',
  transfer_success = '赠送成功',
  receive_success = '受赠成功'
}

type OrderType = 'wait_paid' | 'success' | 'cancel' | 'refund'

const Order: React.FC = () => {
  const [type, setType] = useState<'wait_paid' | 'success' | 'cancel' | 'refund' | 'confirm' | 'all'>('all')
  const [state, setState] = useState<'confirm' | 'create' | 'process' | 'cancel' | ''>('')
  const [transferState, setTransferState] = useState<'all' | 'transfer' | 'receive'>('all')
  const [title, setTitle] = useState<'primaryMarket' | 'secondaryMarket' | 'transfer'>(new URLSearchParams(window.location.search).get('title') as 'primaryMarket' || 'primaryMarket')
  const navigate = useNavigate()
  const { nftOrderTransferQuery, nftOrderTransferQueryList, nftOrderTransferQueryListHasMore } = primaryMarket

  const init = () => {
    if (title === 'primaryMarket') {
      primaryMarket.orderQuery({
        type,
        product: 'physical_good'
      })
    }

    if (title === 'secondaryMarket') {
      secondaryMarket.ordersMy({
        state
      })
    }

    if (title === 'transfer') {
      nftOrderTransferQuery({
        type: transferState,
        limit: 1000
      })
    }
  }

  useEffect(() => {
    init()
  }, [type, title, state, transferState])



  const loadMore = async () => {
    if (title === 'primaryMarket') {
      await primaryMarket.orderQuery({
        type,
        product: "physical_good",
        skip: primaryMarket.orderList.length
      })
    }

    if (title === 'secondaryMarket') {
      await secondaryMarket.ordersMy({
        state,
        skip: secondaryMarket.ordersMyList.length
      })
    }

    if (title === 'transfer') {
      await nftOrderTransferQuery({
        type: transferState,
        limit: 1000
      })
    }
  }

  const enumDataHandle = () => {
    switch (title) {
      case 'primaryMarket':
        return {
          hasMore: primaryMarket.orderListHasMore,
          length: primaryMarket.orderList.length
        };
      case 'secondaryMarket':
        return {
          hasMore: secondaryMarket.ordersMyListHasMore,
          length: secondaryMarket.ordersMyList.length
        };
      case 'transfer':
        return {
          hasMore: nftOrderTransferQueryListHasMore,
          length: nftOrderTransferQueryList.length
        };
    }
  }

  return (
    <div id='order'>
      <Header text={(
        <div className='order-title-right'>
          <div className={`order-title-right-sell ${title === 'primaryMarket' ? 'switch' : ''}`} onClick={() => setTitle('primaryMarket')}>首发订单</div>
          <div className={`order-title-right-sell ${title === 'secondaryMarket' ? 'switch' : ''}`} onClick={() => setTitle('secondaryMarket')}>市场订单</div>
          <div className={`order-title-right-sell ${title === 'transfer' ? 'switch' : ''}`} onClick={() => setTitle('transfer')}>转赠</div>
        </div>
      )}
      />

      <div className='order-select' style={{ width: '100vw' }}>
        {title === 'primaryMarket' && <div className='order-select-box'>
          <div className={`order-select-box-item ${type === 'all' ? 'active' : ''}`} onClick={() => setType('all')}>全部</div>
          <div className={`order-select-box-item ${type === 'success' ? 'active' : ''}`} onClick={() => setType('success')}>成功</div>
          <div className={`order-select-box-item ${type === 'wait_paid' ? 'active' : ''}`} onClick={() => setType('wait_paid')}>待支付</div>
          <div className={`order-select-box-item ${type === 'confirm' ? 'active' : ''}`} onClick={() => setType('confirm')}>确认中</div>
          <div className={`order-select-box-item ${type === 'cancel' ? 'active' : ''}`} onClick={() => setType('cancel')}>已取消</div>
        </div>}
        {title === 'secondaryMarket' && <div className='order-select-box'>
          <div className={`order-select-box-item ${state === '' ? 'active' : ''}`} onClick={() => setState('')}>全部</div>
          <div className={`order-select-box-item ${state === 'confirm' ? 'active' : ''}`} onClick={() => setState('confirm')}>成功</div>
          <div className={`order-select-box-item ${state === 'create' ? 'active' : ''}`} onClick={() => setState('create')}>待支付</div>
          <div className={`order-select-box-item ${state === 'process' ? 'active' : ''}`} onClick={() => setState('process')}>确认中</div>
          <div className={`order-select-box-item ${state === 'cancel' ? 'active' : ''}`} onClick={() => setState('cancel')}>已取消</div>
        </div>}
        {title === 'transfer' && <div className='order-select-box'>
          <div className={`order-select-box-item ${transferState === 'all' ? 'active' : ''}`} onClick={() => setTransferState('all')}>全部</div>
          <div className={`order-select-box-item ${transferState === 'transfer' ? 'active' : ''}`} onClick={() => setTransferState('transfer')}>转出</div>
          <div className={`order-select-box-item ${transferState === 'receive' ? 'active' : ''}`} onClick={() => setTransferState('receive')}>转入</div>
        </div>}
      </div>

      <div className='order-list'>
        {title === 'primaryMarket' && (primaryMarket?.orderList.length > 0 ? primaryMarket?.orderList?.map((x, i) => (
          <div className='order-box2' key={i} onClick={() => {
            if (x.state === 'wait_paid') {
              navigate(`/orderPay?order_no=${x.order_no}`)
            }
          }}>
            <div className='order-box2-top'>
              <img className='order-box2-top-img2' src={x?.detail_thumb_list && x?.detail_thumb_list[0]} />
              <div className='order-box2-top-container'>
                <div className='order-box2-top-container-title'>{x.title}
                  <div style={{ display: 'flex', alignItems: 'baseline', fontSize: '16px' }}>
                    <span style={{ fontSize: '10px' }}>￥</span>
                    {x.price}
                  </div>
                </div>
                <div className='order-box2-top-container-text' style={{ marginTop: '5px' }}>
                  <div className={`order-box2-top-container-loaction-status ${x.state}`}>{status[x.state as OrderType]}</div>
                </div>
              </div>
            </div>
            <div className='order-box2-data'>
              {x.state === 'success' &&
                <div className='order-box2-data-item'>
                  <span>实际付款</span>
                  <span style={{ color: '#000', fontWeight: 600 }}>{x.price ? `¥ ${x.price}` : '--'}</span>
                </div>
              }
              <div className='order-box2-data-item'>
                <span>交易数量</span>
                <span>{x.amount ? `x${x.amount}` : '--'}</span>
              </div>
              <div className='order-box2-data-item'>
                <span>付款时间</span>
                <span>{x.pay_time ? moment(x.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</span>
              </div>
              <div className='order-box2-data-item'>
                <span>订单编号</span>
                <span>{x.order_no}</span>
              </div>
            </div>
          </div>
        )) : null)}

        {title === 'secondaryMarket' && (secondaryMarket?.ordersMyList.length > 0 ? secondaryMarket?.ordersMyList?.map((x, i) => (
          <div className='order-box2' key={i} onClick={() => {
            navigate(`/secondaryMarket/paymentPage?order_id=${x.order_id}`)
          }}>
            <div className='order-box2-top'>
              <img className='order-box2-top-img2' src={x?.goods?.thumb && x?.goods?.thumb[x?.goods?.thumb.length - 1]} />
              <div className='order-box2-top-container'>
                <div className='order-box2-top-container-title'>{x.type === 'bid_parent' ? x.state === 'confirm' ? '求购买入：' : '求购预付：' : ''}{x.goods?.title}
                  <div style={{ display: 'flex', alignItems: 'baseline', fontSize: '16px' }}>
                    <span style={{ fontSize: '10px' }}>￥</span>
                    {((x.price) / 100).toFixed(2)}
                  </div>
                </div>
                <div className='order-box2-top-container-text' style={{ marginTop: '5px' }}>
                  <div className={`order-box2-top-container-loaction-status ${x.state}`}>{transferStatus[x.state as 'create']}</div>
                </div>
              </div>
            </div>
            <div className='order-box2-data'>
              {x.state === 'confirm' &&
                <div className='order-box2-data-item'>
                  <span>实际付款</span>
                  <span style={{ color: '#000', fontWeight: 600 }}><span style={{ fontSize: '10px' }}>￥</span>{x.price ? `${((x.price) / 100).toFixed(2)}` : '--'}</span>
                </div>
              }
              {x.bid_unit_price &&
                <div className='order-box2-data-item'>
                  <span>商品单价</span>
                  <span style={{ color: '#000', fontWeight: 600 }}><span style={{ fontSize: '10px' }}>￥</span>{((x.bid_unit_price) / 100).toFixed(2)}</span>
                </div>
              }
              <div className='order-box2-data-item'>
                <span>交易数量</span>
                <span>{x.amount ? `x${x.amount}` : '--'}</span>
              </div>
              <div className='order-box2-data-item'>
                <span>付款时间</span>
                <span>{x.pay_time ? moment(x.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</span>
              </div>
              <div className='order-box2-data-item'>
                <span>订单编号</span>
                <span>{x.order_id ?? '--'}</span>
              </div>
            </div>
          </div>
        )) : null)}

        {title === 'transfer' && (nftOrderTransferQueryList.length > 0 ? nftOrderTransferQueryList.map((item, i) => (
          <div className={'SellAndSold-box1'} key={i}>
            <div className={'SellAndSold-box1-top'}>
              <div className={'SellAndSold-box1-top-left'}>
                <img src={item.thumb[item.thumb.length - 1]} alt="" />
                <h3 className={`${'SellAndSold-box1-top-left-h3'}`}>
                  {item.title}
                  <div className={"GiveNft-top-right-center"}>
                    <img src={require('@/assets/imgs/nftDetail-small.svg').default} alt="" />
                    <span className={"GiveNft-top-right-center-text"}>#{item?.shard_id}/{item?.amount}</span>
                  </div>
                  <span><span style={{ fontSize: 10 }}>¥</span> {item.price / 100}</span>
                </h3>
              </div>
              <div className={'transfer_state'}>
                {transferStatus[item.state as 'transfer_success']}
              </div>
            </div>

            <div className={`${'SellAndSold-box1-bottom2'}`}>
              <div className={'SellAndSold-box1-bottom2-left'}>
                <div>付款时间</div>
                <div>订单编号</div>
              </div>
              <div className={'SellAndSold-box1-bottom2-right'}>
                <div>{item.pay_time ? moment(item.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</div>
                <a className={'SellAndSold-box1-bottom2-right-num'}>{item.order_no}</a>
              </div>
            </div>
          </div>
        )) : null)}
      </div>

      <InfiniteScroll
        hasMore={enumDataHandle().hasMore}
        children={(hasMore) => {
          if (!hasMore && enumDataHandle().length === 0) {
            return (
              <div className='noData'>
                <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
          } else {
            return '没有更多了'
          }
        }}
        loadMore={loadMore as unknown as () => Promise<void>}
      />
    </div>
  )
}

export default inject('PrimaryMarket')(observer(Order))