import { useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Dialog, Toast } from 'antd-mobile'
import LuckyDraw from '@/component/LuckyDraw'
import appState from '@/store/appState'
import styles from './score.module.scss'

const Score: React.FC = ({ }) => {
  const navigate = useNavigate()
  const { commonData } = appState
  const [search] = useSearchParams()
  const [visible, setVisible] = useState(false)

  const goGame = () => {
    if (search.get('type') === 'yuanshi') {
      Dialog.show({
        content: (
          <div style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
            <div>参与原石版星际逃亡游戏，每局将消耗您的星源秘钥作为房间门票，若游戏失败原石将进入质押状态。</div>
          </div>
        ),
        closeOnAction: true,
        title: '游戏须知',
        actions: [
          [
            {
              text: '取消',
              key: 'cancel',
              style: { border: '1px solid #000', fontWeight: 600, borderRadius: 12, color: '#000', margin: 10 }
            },
            {
              text: '确定',
              key: 'ok',
              style: { color: '#fff', background: '#000', borderRadius: 12, fontWeight: 600, margin: 10 },
              onClick() {
                navigate('/webview?type=game')
              },
            }
          ],
        ]
      })
    }

    if (search.get('type') === 'miyao') {
      navigate('/webview?type=game')
    }
  }

  return (
    <div id={styles.score}>
      <img src={require('@/assets/imgs/arrow-left-white.svg').default} alt="" onClick={() => navigate(-1)} />
      {search.get('type') === 'miyao' && <div className={styles.box1}>
        <span className={styles['box1-text1']}>星源秘钥 <img src={require('@/assets/imgs/Universe-dot.svg').default} alt="" /></span>
        <span className={styles['box1-text2']}>{Math.floor(commonData?.miyao)}</span>
      </div>}
      {search.get('type') === 'yuanshi' && <div className={styles.box1}>
        <span className={styles['box1-text1']}>原石 <img src={require('@/assets/imgs/stone.svg').default} width={22} alt="" /></span>
        <span className={styles['box1-text2']}>{Math.floor(commonData?.yuanshi)}</span>
      </div>}
      <div className={styles.btn} onClick={goGame}>
        进入星际逃亡游戏
      </div>
      <div className={styles.box5}>
        <div className={styles['box5-item']} onClick={() => Toast.show('敬请期待')}>
          <img className={styles['box5-item-img']} src={require('@/assets/imgs/Newspaper.svg').default} alt="" />
          <span className={styles['box5-item-text']}>玩法介绍</span>
        </div>
        {search.get('type') === 'yuanshi' && <div className={styles['box5-item']} onClick={() => navigate('/universe/score/Record')}>
          <img className={styles['box5-item-img']} src={require('@/assets/imgs/Pager.svg').default} alt="" />
          <span className={styles['box5-item-text']}>参与记录</span>
        </div>}
        <div className={styles['box5-item']} onClick={() => Toast.show('敬请期待')}>
          <img className={styles['box5-item-img']} src={require('@/assets/imgs/Mushroom.svg').default} alt="" />
          <span className={styles['box5-item-text']}>游戏彩蛋</span>
        </div>
        <div className={styles['box5-item']} onClick={() => setVisible(true)}>
          <img className={styles['box5-item-img']} src={require('@/assets/imgs/Party popper.svg').default} alt="" />
          <span className={styles['box5-item-text']}>幸运抽奖</span>
        </div>
      </div>
      <div className={styles.box3}>
        <span className={styles['box3-title']}>玩法介绍</span>
        {search.get('type') === 'miyao' && <span className={styles['box3-content']}>
          星际逃亡游戏秘钥版：用户通过投入一定的星源秘钥，来参与星际逃亡游戏。星际逃亡游戏总共设置8个星球，用户可随机选择一个星球来参与游戏。游戏开始后，黑暗领主灭霸将随机进入一个星球。最终被灭霸选中的星球用户将失去投入的星源秘钥，未被选中的星球用户，将瓜分被灭霸选中的星球用户投入星源秘钥总量的90%，剩余10%将投入到平台其他运营活动中。
          <br /><br />
          星际逃亡游戏每一局时间不定，当游戏达到最低参与人数后，游戏启动倒计时时间。当星源秘钥数量不足时，将无法参与游戏。详细游戏规则以公告为准！
        </span>}
        {search.get('type') === 'yuanshi' && <span className={styles['box3-content']}>
          星际逃亡游戏原石版：用户通过质押一定数量的原石，并且消耗一定数量的星源秘钥，来参与星际逃亡游戏。星际逃亡游戏总共设置8个星球，用户可随机选择一个星球来参与游戏。游戏开始后，黑暗领主灭霸将随机进入一个星球。最终被灭霸选中的星球，该星球中所有用户的原石质押状态不可改变，直到达到游戏解除质押时间后可解除质押。未被选中的星球用户，将瓜分平台设置的原石奖励，原石奖励 = 当期被灭霸选中的星球用户质押原石数量 * 90%，剩余10%将投入到平台其他运营活动中。
          <br /><br />
          星际逃亡游戏每一局时间不定，当游戏达到最低参与人数后，游戏启动倒计时时间。每一局游戏都将消耗一定数量的星源秘钥，用户可通过参与平台活动获得星源秘钥。当星源秘钥或者原石数量不足时，将无法参与游戏。详细游戏规则以公告为准！
        </span>}
      </div>
      {search.get('type') === 'miyao' && <img className={styles.box4} src={require('@/assets/imgs/Frame 1171274999.png')} alt="" />}
      {search.get('type') === 'yuanshi' && <img className={styles.box4} src={require('@/assets/imgs/Frame 1171274999 (1).png')} alt="" />}
      {search.get('type') === 'miyao' && <div className={styles.box3}>
        <span className={styles['box3-title']}>补充说明</span>
        <span className={styles['box3-content']}>
          玩家只能选择一间房间躲避杀手，玩家选中房间后在倒计时结束前都可以切换不同房间。<br />
          玩家可增加投入的秘钥数量，投入越多瓜分越多(投入的秘钥不能减少)；<br />
          如在体验过程中，因网络波动、系统故障等不可控因素导致以下情景(投入秘钥、切换房间、修房门等)操作失败，请以实际的玩法规则结算结果为准，敬请谅解!</span>
      </div>}
      {search.get('type') === 'yuanshi' && <div className={styles.box3}>
        <span className={styles['box3-title']}>补充说明</span>
        <span className={styles['box3-content']}>
          玩家只能选择一间房间躲避杀手，玩家选中房间后在倒计时结束前都可以切换不同房间。<br />
          玩家可增加投入的原石数，投入越多瓜分越多(投入的原石不能减少)。<br />
          如在体验过程中，因网络波动、系统故障等不可控因素导致以下情景(投入原石、切换房间、修房门等)操作失败，请以实际的玩法规则结算结果为准，敬请谅解!</span>
      </div>}

      <LuckyDraw
        gameId={'20000'}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </div>
  )
}

export default observer(Score)