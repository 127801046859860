import Header from "../../component/header/header";


const IndexPoster: React.FC = () => {
  return (
    <div id="IndexPoster">
      <Header text='玩法介绍  ' fontColor="white" />
      <img style={{width: '100%'}} src={require('./../../assets/imgs/WechatIMG356.jpeg')} alt="" />
    </div>
  )
}
export default IndexPoster;