import C2CAxios from '@/request/c2cRequest'
import qs from 'qs'
import type { PropsType } from '../../global'

type SecondaryMarketType = PropsType['SecondaryMarketType']

export const goodsShelveApi: SecondaryMarketType['goodsShelveApi'] = (params, headers) => {
  return C2CAxios({
    method: 'post',
    url: '/goods/shelve',
    data: params,
    headers
  })
}

export const getGoodsListApi: SecondaryMarketType['getGoodsListApi'] = (params) => {
  return C2CAxios.get(`/goods?${qs.stringify(params)}`)
}

export const getGoodsSearchListApi: SecondaryMarketType['getGoodsSearchListApi'] = (params) => {
  return C2CAxios.get(`/goods/search?${qs.stringify(params)}`)
}

export const ordersCreateApi: SecondaryMarketType['ordersCreateApi'] = (params) => {
  return C2CAxios.post('/orders/create', params)
}

export const ordersPayApi: SecondaryMarketType['ordersPayApi'] = (params) => {
  return C2CAxios.post('/orders/pay', params)
}

export const goodsMySalesApi = () => {
  return C2CAxios.get('/goods/my/sales?limit=10000')
}

export const ordersSaleApi = (params?: { type: 'match_bid' }) => {
  return C2CAxios.get(`/orders/sale?limit=10000&${qs.stringify(params)}`)
}

export const goodsModifyApi: SecondaryMarketType['goodsModifyApi'] = (params) => {
  return C2CAxios.post('/goods/modify', params)
}

export const goodsUnshelveApi = (params: { goods_id: string }) => {
  return C2CAxios.post('/goods/unshelve', params)
}

export const ordersMyApi: SecondaryMarketType['ordersMyApi'] = (params) => {
  return C2CAxios.get(`/orders/my?${qs.stringify(params)}`)
}

export const ordersDetailApi: SecondaryMarketType['ordersDetailApi'] = (params) => {
  return C2CAxios.get(`/orders/detail?${qs.stringify(params)}`)
}

export const ordersCancelApi: SecondaryMarketType['ordersCancelApi'] = (params) => {
  return C2CAxios.post(`/orders/cancel`, params)
}

export const ordersTransactionListApi: SecondaryMarketType['ordersTransactionListApi'] = (params) => {
  return C2CAxios.get(`/orders/transactionList?${qs.stringify(params)}`)
}

export const ordersBidOrderListApi: SecondaryMarketType['ordersBidOrderListApi'] = (params) => {
  return C2CAxios.get(`/orders/bidOrderList?${qs.stringify(params)}`)
}

export const ordersMatchBidApi: SecondaryMarketType['ordersMatchBidApi'] = async (params, headers) => {
  return C2CAxios.post('/orders/matchBid', params, { headers })
}