import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '@/component/header/header';
import { observer } from 'mobx-react'
import secondaryMarket from '@/store/secondaryMarket';
import store from './redurce'
import { ProgressBar } from 'antd-mobile';
import appState from '@/store/appState';
import { RedoOutline, UnorderedListOutline } from 'antd-mobile-icons'
import moment from 'moment';
import { Clock } from 'grommet';
import Jackpot from './component/jackpot';
import Ranking from './component/ranking';

import './freeDeal.scss';



const FreeDeal: React.FC = () => {
  const { socketData } = appState
  const { nftGameDetail, getNftGameDetail } = store
  const location = useLocation()
  const navigate = useNavigate()
  const { goodsSearcExtra } = secondaryMarket
  const [title, setTitle] = useState<'jackpot' | 'rank'>(new URLSearchParams(window.location.search).get('title') as 'jackpot' || 'jackpot')


  useEffect(() => {
    const gameId = location.state.gameId
    if (gameId) {
      getNftGameDetail({ gameId })
    }
  }, [])

  useEffect(() => {
    if (socketData?.action === 'nft_game_change_c2c' || socketData?.action === 'nft_game_winner') {
      const gameId = location.state.gameId
      if (gameId) {
        getNftGameDetail({ gameId })
      }
    }
  }, [socketData])


  const [countDowns, setCountDowns] = useState<any[]>([])
  const [c2cCountdownProgress, setC2cCountdownProgress] = useState(0)
  const [tradeLeaderboardCountdownProgress, setTradeLeaderboardCountdownProgress] = useState(0)


  useEffect(() => {
    let c2cDrawTimer: NodeJS.Timer
    if (nftGameDetail?.c2cDrawTime) {
      c2cDrawTimer = setInterval(() => {
        const now = moment().valueOf();
        const distance = moment(nftGameDetail?.c2cDrawTime).valueOf() - now;
        const seconds = Math.floor((distance / 1000) % 60);
        const minutes = Math.floor((distance / 1000 / 60) % 60);
        if (distance < 0) {
          clearInterval(c2cDrawTimer);
          countDowns[0] = '- - : - -'
        } else {
          countDowns[0] = (
            <Clock
              type="digital"
              precision="minutes"
              time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
              size='small'
            />
          )
          setC2cCountdownProgress(((nftGameDetail?.c2cCountdownInterval - Math.floor((distance / 1000))) / nftGameDetail?.c2cCountdownInterval) * 100)
        }
        setCountDowns([...countDowns])
      }, 1000);
    }

    let tradeLeaderboardDrawTimer: NodeJS.Timer
    if (nftGameDetail?.tradeLeaderboardDrawTime) {
      tradeLeaderboardDrawTimer = setInterval(() => {
        const now = moment().valueOf();
        const distance = moment(nftGameDetail?.tradeLeaderboardDrawTime).valueOf() - now;
        const seconds = Math.floor((distance / 1000) % 60);
        const minutes = Math.floor((distance / 1000 / 60) % 60);
        if (distance < 0) {
          clearInterval(tradeLeaderboardDrawTimer);
          countDowns[1] = '- - : - -'
        } else {
          countDowns[1] = (
            <Clock
              type="digital"
              precision="minutes"
              time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
              size='small'
            />
          )
          setTradeLeaderboardCountdownProgress(((nftGameDetail?.tradeLeaderboardCountdownInterval - Math.floor((distance / 1000))) / nftGameDetail?.tradeLeaderboardCountdownInterval) * 100)
        }
        setCountDowns([...countDowns])
      }, 1000);
    }

    return () => {
      clearInterval(c2cDrawTimer)
      clearInterval(tradeLeaderboardDrawTimer)
    };
  }, [nftGameDetail])


  return (
    <div id='FreeDeal'>
      <Header
        text={
          location.state.gameId ? (
            <div className='FreeDeal-title-right'>
              <div className={`FreeDeal-title-right-sell ${title === 'jackpot' ? 'switch' : ''}`} onClick={() => setTitle('jackpot')}>流转活动</div>
              <div className={`FreeDeal-title-right-sell ${title === 'rank' ? 'switch' : ''}`} onClick={() => setTitle('rank')}>排行榜</div>
            </div>
          )
            :
            '自由流转'
        }
        menu={
          location.state.gameId ?
            <UnorderedListOutline className='RankingList-UnorderedListOutline' fontSize={24} color='#000' onClick={() => navigate('/secondaryMarket/partitionRecord', { state: { gameId: location.state.gameId } })} />
            :
            <RedoOutline
              style={{ fontSize: 20 }}
              onClick={() => {
                secondaryMarket.getGoodsSearhList({
                  'sku.aid': location?.state?.aid,
                  sort: 'price'
                })
              }}
            />
        }
      />


      {location.state.gameId ? (
        <div className='RankingList-top'>
          <div className='RankingList-bronze' onClick={() => setTitle('jackpot')}>
            <div className='RankingList-bronze-top'>
              <div className='RankingList-bronze-top-left'>
                <div style={{ fontSize: 8 }}>当前最后接收方：</div>
                <img className='RankingList-bronze-top-left-img' src={nftGameDetail?.c2cWinner?.avatarUrl} alt="" />
                <div>{nftGameDetail?.c2cWinner?.nickName}</div>
              </div>
              <div className='RankingList-bronze-top-right'>
                <img className='RankingList-bronze-top-right-img' src={require('../../../assets/imgs/RankingList-bj2.svg').default} alt="" />
                <div className='RankingList-bronze-top-right-num'>{Number(nftGameDetail?.currentBonusAmount).toFixed(2)}</div>
              </div>
            </div>
            <div className='RankingList-bronze-accumulate'>
              <span className='RankingList-bronze-accumulate-text1'>倒计时结束最后接收方得灵金&nbsp;</span>
              <span className='RankingList-bronze-accumulate-text2'>
                {countDowns[0] || '- - : - -'}
              </span>
            </div>
            <div className='RankingList-Progressbar'>
              <ProgressBar percent={c2cCountdownProgress} style={{
                '--track-color': 'rgba(255, 255, 255, 0.12)',
                '--fill-color': '#4800E0',
                '--track-width': '7px',
              }} />
              <div style={{ fontSize: 7, color: '#eaeaea', marginTop: 4 }}>倒计时仅供参考，实际以链上数据为准</div>
            </div>
          </div>
          <div className='RankingList-bronze2' onClick={() => setTitle('rank')}>
            <div className='RankingList-bronze-top'>
              <div className='RankingList-bronze-top-left'>
                <div>当前No.1：</div>
                <img className='RankingList-bronze-top-left-img' src={nftGameDetail?.tradeLeaderboardWinnerList && nftGameDetail?.tradeLeaderboardWinnerList[0]?.avatarUrl} alt="" />
                <div>{nftGameDetail?.tradeLeaderboardWinnerList && nftGameDetail?.tradeLeaderboardWinnerList[0]?.nickName}</div>
              </div>
              <div className='RankingList-bronze-top-right'>
                <img className='RankingList-bronze-top-right-img' src={require('../../../assets/imgs/RankingList-bj2.svg').default} alt="" />
                <div className='RankingList-bronze-top-right-num'>{Number(nftGameDetail?.currentBonusAmount).toFixed(2)}</div>
              </div>
            </div>
            <div className='RankingList-bronze-accumulate'>
              <span className='RankingList-bronze-accumulate-text1'>每小时积分数前三名得灵金&nbsp;</span>
              <span className='RankingList-bronze-accumulate-text2'>
                {countDowns[1] || '- - : - -'}
              </span>
            </div>
            <div className='RankingList-Progressbar'>
              <ProgressBar percent={tradeLeaderboardCountdownProgress} style={{
                '--track-color': 'rgba(255, 255, 255, 0.12)',
                '--fill-color': '#4800E0',
                '--track-width': '7px',
              }} />
              <div style={{ fontSize: 7, color: '#eaeaea', marginTop: 4 }}>倒计时仅供参考，实际以链上数据为准</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='FreeDeal-bj'>
          <img src={goodsSearcExtra?.thumb && goodsSearcExtra?.thumb[goodsSearcExtra?.thumb.length - 1]} alt="" />
        </div>
      )}

      {title === 'jackpot' && <Jackpot socketData={socketData} />}

      {title === 'rank' && <Ranking gameId={location.state.gameId} socketData={socketData} />}
    </div>
  )
}

export default observer(FreeDeal);