import Header from "../../../component/header/header";
import './ConsignmentGoods.scss';
import { useEffect, useState } from 'react';
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import type { PropsType } from '../../../global'
import { Toast, InfiniteScroll } from "antd-mobile";
import primaryMarket from "@/store/primaryMarket";
import appState from "@/store/appState";

enum State {
  not_delivered = '立即发货',
  delivered = '已发货',
  wait_paid = '待支付',
  pay = '付款成功',
  fail = '交易失败',
  partial_success = '部分成功',
  success = '等待发货',
  cancel = '订单取消',
  refund = '退款'
}

const ConsignmentGoods: React.FC<PropsType> = () => {
  const [type, setType] = useState<any>('all');
  const navigate = useNavigate()
  const { commonData } = appState

  useEffect(() => {
    primaryMarket.orderQuery({
      type,
      product: "physical_good_express",
    })
  }, [type])


  const goPayHandle = (x: {
    state: string; order_no: string;
  }) => {
    if (commonData.default_delivery_info) {
      if (x.state === 'not_delivered' || x.state === 'wait_paid') {
        navigate(`/freightPay?order_no=${x.order_no}`)
      }
    } else {
      navigate(`/My/ShippingAddress`)
      setTimeout(() => {
        Toast.show('请添加收获地址')
      }, 100)
    }
  }

  return (
    <div id="ConsignmentGoods">
      <Header text='寄存商品' fontColor="black" menu={<span onClick={() => navigate('/My/HelpCenter')}>联系客服</span>} />
      <div className='ConsignmentGoods-select' style={{ width: '100vw' }}>
        <div className='ConsignmentGoods-select-box'>
          <div className={`ConsignmentGoods-select-box-item ${type === 'all' ? 'active' : ''}`} onClick={() => setType('all')}>全部</div>
          <div className={`ConsignmentGoods-select-box-item ${type === 'not_delivered' ? 'active' : ''}`} onClick={() => setType('not_delivered')}>未发货</div>
          <div className={`ConsignmentGoods-select-box-item ${type === 'delivered' ? 'active' : ''}`} onClick={() => setType('delivered')}>已发货</div>
          <div className={`ConsignmentGoods-select-box-item ${type === 'cancel' ? 'active' : ''}`} onClick={() => setType('cancel')}>已取消</div>
        </div>
      </div>
      <div style={{ paddingTop: 100 }}>
        {primaryMarket.orderList.length > 0 ? primaryMarket.orderList.map((x, i) => (
          <div className='ConsignmentGoods-list' key={i} onClick={() => goPayHandle(x)}>
            <div className='order-box2'>
              <div className='order-box2-top'>
                <img className='order-box2-top-img2' src={x.physical_good_detail_thumb_list && x.physical_good_detail_thumb_list[0]} />
                <div className='order-box2-top-container'>
                  <div className='order-box2-top-container-title'>
                    <div className="order-box2-top-container-title-div">{x.title}</div>
                    <div style={{ display: 'flex', alignItems: 'baseline', fontSize: '16px' }}>
                      <div>
                        <div>
                          <span style={{ fontSize: '10px' }}>￥</span>
                          {x.physical_good_order_unit_price}
                        </div>
                        <div className="order-box2-top-container-title-num">×{x.physical_good_order_amount}</div>
                      </div>
                    </div>

                  </div>
                  <div className='order-box2-top-container-text' style={{ marginTop: '5px' }}>
                    <div className={`order-box-ing ${x.state}`}>{State[x.state]}</div>
                  </div>
                </div>
              </div>
              <div className='order-box2-data'>
                <div className='order-box2-data-item'>
                  <span>实际付款</span>
                  <span style={{ color: '#000', fontWeight: 600 }}>{x.physical_good_order_price || '--'}</span>
                </div>
                <div className='order-box2-data-item'>
                  <span>付款时间</span>
                  <span>{x.pay_time ? moment(x.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</span>
                </div>
                <div className='order-box2-data-item'>
                  <span>订单编号</span>
                  <span>{x.order_no}</span>
                </div>
              </div>
            </div>
          </div>
        )) : null}
      </div>

      <InfiniteScroll
        hasMore={primaryMarket.orderListHasMore}
        children={(hasMore) => {
          if (!hasMore && primaryMarket.orderList.length === 0) {
            return (
              <div className='noData'>
                <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
          } else {
            return '没有更多了'
          }
        }}
        loadMore={async () => {
          await primaryMarket.orderQuery({
            type,
            product: "physical_good_express",
            skip: primaryMarket.orderList.length
          })
        }}
      />
    </div>
  )
}

export default observer(ConsignmentGoods);