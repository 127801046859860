import { observable, action, makeObservable } from 'mobx'
import {
  nftGameListApi,
  nftGameTotalBonusApi,
  nftGameLastWinnerApi
} from './serve'
import type {
  nftGameListType,
  nftGameTotalBonusType,
  nftGameLastWinnerType
} from './type'


class Store {
  constructor() {
    makeObservable(this)
  }

  @observable navId: 0 | 1 | 2 = 0

  @action changeNavId = (x: 0 | 1 | 2) => {
    this.navId = x
  }








  /**
   * 游戏列表
   * @param params 
   * @returns 
   */
  @observable nftGameList: any[] = []
  @action getNftGameList: nftGameListType = async (params) => {
    const data = await nftGameListApi(params)
    if (data.data.code === 0) {
      if (params && params.skip && params.skip > 0) {
        this.nftGameList = [...this.nftGameList, ...data.data.data]
      } else {
        this.nftGameList = data.data.data
      }
    }
    return Promise.resolve(data)
  }








  /**
  * 列表内所有游戏的当前积分总和
  * @param bonusType 
  * @returns 
  */
  @observable nftGameTotalBonus: number = 0
  @action getNftGameTotalBonus: nftGameTotalBonusType = async (bonusType) => {
    const data = await nftGameTotalBonusApi(bonusType)
    if (data.data.code === 0) {
      this.nftGameTotalBonus = data.data.data
    }
    return Promise.resolve(data)
  }





  /**
   * 广播最近的一个获奖者
   */
  @observable nftGameLastWinner: any = {}
  @action getNftGameLastWinner: nftGameLastWinnerType = async (params) => {
    const data = await nftGameLastWinnerApi(params)
    if (data.data.code === 0) {
      this.nftGameLastWinner = data.data.data[0]
    }
    return Promise.resolve(data)
  }

}

export default new Store()