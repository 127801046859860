import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { _hmtHandle } from '@/utils/utils'

import Header from '../../component/header/header'
import { PropsType } from '../../global'

import './inviteCode.scss'

const InviteCode: React.FC<PropsType> = ({ AppState }) => {
  const navigate = useNavigate()
  const [inviteCode, setInviteCode] = useState('')

  const bindInviteIdHandle = () => {
    AppState.bindInviterIdHnadle({ inviterId: inviteCode }).then(({ data }) => {
      if (data.code === 0) {
        Toast.show({ icon: 'success', content: '绑定成功' })
        navigate('/')
      } else {
        alert(data.message)
      }
    })
  }

  useEffect(() => {
    const inviteCode = localStorage.getItem('inviteCode')
    if (inviteCode) {
      setInviteCode(inviteCode)
    }
  }, [])

  return (
    <div id='inviteCode'>
      <Header />
      <p className='text1'>绑定邀请码（选填）</p>
      <input
        id='input'
        value={inviteCode}
        type="number"
        className="codeInput"
        placeholder="请填写邀请码"
        autoComplete='false'
        autoFocus
        onChange={(e) => {
          setInviteCode(e.target.value)
        }}
      />
      <div className='btn' onClick={bindInviteIdHandle}>
        绑定邀请码
      </div>
      <div className='btn-skip' onClick={() => { navigate('/'); _hmtHandle('任何设备', '点击按钮', '获取验证码') }}>
        没有邀请码，直接进入
        <img style={{ transform: ' rotateY(180deg) ' }} src={require('../../assets/imgs/arrow-left.png')} alt="" />
      </div>
      {/* <div className='btn-skip' onClick={() => navigate('/')}>
        邀请人：159****7293
      </div> */}
    </div>
  )
}

export default inject('AppState')(observer(InviteCode))