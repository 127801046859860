import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secondaryMarket from '@/store/secondaryMarket'
import { observer } from 'mobx-react'

import './market.scss'

const Market: React.FC = () => {
  const [sortType, setSortType] = useState<0 | 1>(0)
  const navigate = useNavigate()


  const init = () => {
    secondaryMarket.getGoodsList({
      series: '',
      sort: sortType === 0 ? 'price' : 'create_time',
      direction: 'DESC'
    })
  }

  useEffect(() => {
    init()
  }, [sortType])

  return (
    <div id="Market">
      <div className='Market-nav'>
        <div className='Market-nav-item' onClick={() => setSortType(0)}>
          <span className='Market-nav-item-text' style={sortType === 0 ? { color: '#fff', fontWeight: 500 } : {}}>全部系列</span>
          {sortType === 0 && <img width={24} src={require('@/assets/imgs/arrow-up-white.svg').default} alt="" />}
          {sortType === 1 && <img width={24} src={require('@/assets/imgs/arrow-down.svg').default} alt="" />}
        </div>
        <div className='Market-nav-item' onClick={() => setSortType(1)}>
          <span className='Market-nav-item-text' style={sortType === 1 ? { color: '#fff', fontWeight: 500 } : {}}>最新上架</span>
          {sortType === 1 && <img width={24} src={require('@/assets/imgs/arrow-up-white.svg').default} alt="" />}
          {sortType === 0 && <img width={24} src={require('@/assets/imgs/arrow-down.svg').default} alt="" />}
        </div>
      </div>
      <div className='Market-content'>
        <div className='Market-content-search'>
          <img width={18} src={require('@/assets/imgs/search.svg').default} alt="" />
          <input className='Market-content-search-input' type="text" placeholder='搜索藏品或系列名称' />
        </div>
        <div className='Market-content-list'>
          {secondaryMarket.goodsList.length > 0 ? secondaryMarket.goodsList.map((x, i) => (
            <div className='Market-content-list-item' key={i} onClick={() => navigate('/secondaryMarket/freeDeal', { state: { aid: x.aid } })}>
              {x.grade && <img className='Market-content-list-item-grade' width={32} height={32} src={require(`@/assets/imgs/${x.grade}.svg`)} alt="" />}
              <img className='Market-content-list-item-img' src={x.thumb && x.thumb[x.thumb.length - 1]} alt="" />
              <span className='Market-content-list-item-text1'>{x.title}</span>
              <span className='Market-content-list-item-text2'><span style={{ fontSize: 10 }}>¥</span> {(x.price) / 100}起</span>
            </div>
          )) : (
            <div className='noData' style={{ top: 200 }}>
              <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(Market)