import styles from './cornerstoneCard.module.scss';
import Header from '../../component/header/header';



const CornerstoneCard: React.FC=()=>{
  return (
    <div id={styles['CornerstoneCard']}>
      <Header text='基石原卡' fontColor='black'/>
      <div className={styles['CornerstoneCard-center']}>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;1</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;2</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;3</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;4</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;5</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;6</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;7</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;8</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;9</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
        <div className={styles['CornerstoneCard-center-box']}>
          <img className={styles['CornerstoneCard-center-box-img']} src={require('../../assets/imgs/CornerstoneCard-img.svg').default} alt="" />
          <div className={styles['CornerstoneCard-center-box-text']}>#&nbsp;10</div>
        </div>
      </div>
    </div>
  )
}

export default CornerstoneCard;