import { observable, action, makeObservable } from 'mobx'
import {
  hotCollectionListApi,
  physicalGoodDetailApi,
  collectionDetailApi,
  createOrderApi,
  queryOrderDetailApi,
  cancelOrderApi,
  orderQueryApi,
  payApi,
  nftAssetsApi,
  boxListApi,
  openBoxApi,
  inviteRecordApi,
  initiateExpressOrderApi,
  getAssetDetailApi,
  inviteRankApi,
  nftBoxesMyApi,
  nftBoxesOpenApi,
  bonusShopItemListApi,
  bonusUserRedeemApi,
  bonusUserHistoryApi,
  nfOrderPreCheckApi,
  nftOrderTransferCreateApi,
  nftOrderTransferQueryOrderApi,
  nftOrderTransferQueryApi
} from './serve'
import type { PropsType } from '../../global'

type PrimaryMarketType = PropsType['PrimaryMarket']

class PrimaryMarket {
  constructor() {
    makeObservable(this)
  }

  /**
   * 获取市场列表
   */
  @observable hotCollectionList: PrimaryMarketType['hotCollectionList'] = []
  @action
  getHotCollectionList: PrimaryMarketType['getHotCollectionList'] = async (params) => {
    const { data } = await hotCollectionListApi(params)
    if (data.code === 0) {
      this.hotCollectionList = data.data.list
    }
    return Promise.resolve(data)
  }


  /**
   * 获取商品详情
   */
  @observable detailInfo = {}
  @action
  physicalGoodDetail: PrimaryMarketType['physicalGoodDetail'] = async (physicalGoodId) => {
    const { data } = await physicalGoodDetailApi(physicalGoodId)
    if (data.code === 0) {
      this.detailInfo = data.data
    }
    return Promise.resolve(data)
  }








  /**
   * 获取藏品品详情
   */
  @observable collectionDetailInfo: any = {}
  @action
  getCollectionDetail: PrimaryMarketType['getCollectionDetail'] = async (asset_id) => {
    const { data } = await collectionDetailApi(asset_id)
    if (data.code === 0) {
      this.collectionDetailInfo = data.data
    }
    return Promise.resolve(data)
  }







  /**
   * 生成订单
   */
  @action
  createOrder: PrimaryMarketType['createOrder'] = async (params) => {
    const { data } = await createOrderApi(params)
    return Promise.resolve(data)
  }

  /**
   * 查询订单详情
   */
  @observable orderDetailInfo = {}
  @action
  queryOrderDetail: PrimaryMarketType['queryOrderDetail'] = async (order_no) => {
    const { data } = await queryOrderDetailApi(order_no)
    if (data.code === 0) {
      this.orderDetailInfo = data.data
    }
  }



  /**
   * 取消订单
   */
  @action
  cancelOrder: PrimaryMarketType['cancelOrder'] = async (order_no, type) => {
    const { data } = await cancelOrderApi(order_no, type)
    return Promise.resolve(data.code)
  }

  /**
   * 订单查询
   */
  @observable orderList: any[] = []
  @observable orderListHasMore = true
  @action
  orderQuery: PrimaryMarketType['orderQuery'] = async (params) => {
    const { data } = await orderQueryApi(params)
    if (data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.orderList = [...this.orderList, ...data.data]
      } else {
        this.orderList = data.data
      }
      this.orderListHasMore = Boolean(data.data.length > 0)
    }
    return Promise.resolve(data)
  }


  /**
   * 支付宝支付
   */
  @action
  payHandle: PrimaryMarketType['payHandle'] = async (order_no, type, pay_channel) => {
    const { data } = await payApi(order_no, type, pay_channel)
    return Promise.resolve(data)
  }


  /**
   * 获取nft列表
   */
  @observable assetsList: Array<any> = []
  @action
  nftAssets: PrimaryMarketType['nftAssets'] = async () => {
    const { data } = await nftAssetsApi()
    if (data.code === 0) {
      this.assetsList = data.data
    }
    return Promise.resolve(data)
  }



  /**
   * 获取盲盒列表
   * @returns 
   */
  @observable boxList: any = {}
  getBoxList: PrimaryMarketType['getBoxList'] = async () => {
    const { data } = await boxListApi()
    if (data.code === 0) {
      this.boxList = data.data
    }
    return Promise.resolve(data)
  }






  /**
   * 开启盲盒
   * @param param 
   * @returns 
   */
  openBox: PrimaryMarketType['openBox'] = async (param) => {
    const { data } = await openBoxApi(param)
    return Promise.resolve(data)
  }






  /**
   * 数字盲盒列表
   * @returns 
   */
  @observable nftBoxesMyList: any[] = []
  nftBoxesMy = async () => {
    const data = await nftBoxesMyApi()
    if (data.data.code === 0) {
      this.nftBoxesMyList = data.data.data
    }
    return Promise.resolve(data)
  }








  /**
   * 打开数字盲盒
   * @param params 
   * @returns 
   */
  nftBoxesOpen: PrimaryMarketType['nftBoxesOpenApi'] = async (params) => {
    const data = await nftBoxesOpenApi(params)
    return Promise.resolve(data)
  }









  /**
   * 好友列表
   * @param invite_level 1：为一级好友；2：为二级还有
   * @returns 
   */
  @observable hasMore1: boolean = true;
  @observable hasMore2: boolean = true;
  @observable inviteRecordList1: { invite_count: number, invitees: any[] } = { invite_count: 0, invitees: [] }
  @observable inviteRecordList2: { invite_count: number, invitees: any[] } = { invite_count: 0, invitees: [] }
  inviteRecord: PrimaryMarketType['inviteRecord'] = async (parasm) => {
    const { data } = await inviteRecordApi(parasm)
    if (data.code === 0) {
      if (parasm.invite_level === 1) {
        if (parasm.skip && parasm.skip > 0) {
          this.inviteRecordList1.invitees = [...this.inviteRecordList1.invitees, ...data.data.invitees]
        } else {
          this.inviteRecordList1 = data.data
        }
        this.hasMore1 = Boolean(data.data.invitees.length > 0)
      }
      if (parasm.invite_level === 2) {
        if (parasm.skip && parasm.skip > 0) {
          this.inviteRecordList2.invitees = [...this.inviteRecordList2.invitees, ...data.data.invitees]
        } else {
          this.inviteRecordList2 = data.data
        }
        this.hasMore2 = Boolean(data.data.invitees.length > 0)
      }
    }
    return Promise.resolve(data)
  }



  /**
   * 发起寄存商品（快递）订单
   * @param params 
   * @returns 
   */
  initiateExpressOrder: PrimaryMarketType['initiateExpressOrder'] = async (params) => {
    const { data } = await initiateExpressOrderApi(params)
    return Promise.resolve(data)
  }





  /**
   * 
   */
  @observable assetsDetail: any = {}
  getAssetDetail: PrimaryMarketType['getAssetDetail'] = async (params) => {
    const { data } = await getAssetDetailApi(params)
    if (data.code === 0) {
      this.assetsDetail = data.data
    }
    return Promise.resolve(data)
  }






  /**
   * 邀请排行
   */
  @observable inviteRankList: any = {}
  getinviteRankList: PrimaryMarketType['getinviteRankList'] = async (type) => {
    const { data } = await inviteRankApi(type)
    if (data.code === 0) {
      this.inviteRankList = data.data
    }
    return Promise.resolve(data)
  }







  /**
   * 积分商城
   * @param params 
   * @returns 
   */
  @observable bonusShopItemList: any[] = []
  @observable bonusShopItemListHasMore = true
  getBonusShopItemList: PrimaryMarketType['bonusShopItemListApi'] = async (params) => {
    const data = await bonusShopItemListApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.bonusShopItemList = [...this.bonusShopItemList, ...data.data.data]
      } else {
        this.bonusShopItemList = data.data.data
      }
      this.bonusShopItemListHasMore = Boolean(data.data.data.length > 0)
    }
    return Promise.resolve(data)
  }






  /**
   * 积分兑换
   * @param params 
   * @returns 
   */
  bonusUserRedeem: PrimaryMarketType['bonusUserRedeemApi'] = async (params) => {
    const data = await bonusUserRedeemApi(params)
    return Promise.resolve(data)
  }




  /**
   * 用户积分历史
   * @param params 
   * @returns 
   */
  @observable bonusUserHistoryList: any[] = []
  @observable bonusUserHistoryHasMore = true
  getBonusUserHistory: PrimaryMarketType['bonusUserHistoryApi'] = async (params) => {
    const data = await bonusUserHistoryApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.bonusUserHistoryList = [...this.bonusUserHistoryList, ...data.data.data]
      } else {
        this.bonusUserHistoryList = data.data.data
      }
      this.bonusUserHistoryHasMore = Boolean(data.data.data.length > 0)
    } else {
      this.bonusUserHistoryHasMore = false
    }
    return Promise.resolve(data)
  }








  /**
   * 
   * @param params 
   * @param headers 
   * @returns 
   */
  @action nfOrderPreCheck: PrimaryMarketType['nfOrderPreCheckApi'] = async (params) => {
    const data = await nfOrderPreCheckApi(params)
    return Promise.resolve(data)
  }









  /**
   * 
   * @param params 
   * @param headers 
   * @returns 
   */
  @action nftOrderTransferCreate: PrimaryMarketType['nftOrderTransferCreateApi'] = async (params, headers) => {
    const data = await nftOrderTransferCreateApi(params, headers)
    return Promise.resolve(data)
  }



  @observable transferDetail: any = {}
  @action nftOrderTransferQueryOrde: PrimaryMarketType['nftOrderTransferQueryOrderApi'] = async (params) => {
    const data = await nftOrderTransferQueryOrderApi(params)
    if (data.data.code === 0) {
      this.transferDetail = data.data.data
    }
    return Promise.resolve(data)
  }





  /**
   * 转赠列表
   * @param params 
   * @returns 
   */
  @observable nftOrderTransferQueryList: any[] = []
  @observable nftOrderTransferQueryListHasMore: boolean = false
  @action nftOrderTransferQuery: PrimaryMarketType['nftOrderTransferQueryApi'] = async (params) => {
    const data = await nftOrderTransferQueryApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.nftOrderTransferQueryList = [...this.nftOrderTransferQueryList, ...data.data.data]
      } else {
        this.nftOrderTransferQueryList = data.data.data
      }
      // this.nftOrderTransferQueryListHasMore = Boolean(data.data.data.length > 0)
    } else {
      this.nftOrderTransferQueryListHasMore = false
    }
    return Promise.resolve(data)
  }


}

export default new PrimaryMarket()