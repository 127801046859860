import { useNavigate } from 'react-router';
import Header from '@/component/header/header';
import moment from 'moment';
import { useState } from 'react';
import LuckyDraw from '@/component/LuckyDraw';
import styles from './castingStatus.module.scss';

const CastingStatus: React.FC = () => {
  const navigate = useNavigate();
  const compositeAssetResquestBody = JSON.parse(localStorage.getItem('compositeAssetResquestBody') || '{}');

  const type = compositeAssetResquestBody.code === 0 ? 'ok' : 'no';

  return (
    <div id={styles['CastingStatus']}>
      <Header text='藏品合成' />
      <div className={`${styles['CastingStatus-title']} ${type === 'ok' ? '' : styles['noClass']}`}>
        <img
          className={styles['CastingStatus-title-img']}
          src={require('@/assets/imgs/CastingStatus-ok.svg').default}
          alt=''
        />
        <h3 className={styles['CastingStatus-title-h3']}>铸造成功</h3>
      </div>
      <div className={`${styles['CastingStatus-title2']} ${type === 'no' ? '' : styles['noClass']}`}>
        <img
          className={styles['CastingStatus-title2-img']}
          src={require('@/assets/imgs/CastingStatus-no.svg').default}
          alt=''
        />
        <h3 className={styles['CastingStatus-title2-h3']}>铸造失败</h3>
      </div>
      {
        <div className={styles['CastingStatus-center']}>
          <div className={styles['CastingStatus-center-box']}>
            <div className={styles['CastingStatus-center-box-top']}>
              <img
                className={styles['CastingStatus-center-box-top-img']}
                src={
                  compositeAssetResquestBody.data.thumb &&
                  compositeAssetResquestBody.data.thumb[compositeAssetResquestBody.data.thumb.length - 1]
                }
                alt=''
              />
              <div className={styles['CastingStatus-center-box-top-right']}>
                <div className={styles['CastingStatus-center-box-top-right-title']}>
                  {compositeAssetResquestBody.data?.title}
                </div>
                <div className={styles['CastingStatus-center-box-top-right-bottom']}>
                  <div className={styles['CastingStatus-center-box-top-right-bottom-box']}>
                    <div className={styles['CastingStatus-center-box-top-right-bottom-box-left']}>发行</div>
                    <div className={styles['CastingStatus-center-box-top-right-bottom-box-right']}>
                      {compositeAssetResquestBody.data?.publish_amount}份
                    </div>
                  </div>
                  <div className={styles['CastingStatus-center-box-top-right-bottom-box']}>
                    <div className={styles['CastingStatus-center-box-top-right-bottom-box-left']}>流通</div>
                    <div className={styles['CastingStatus-center-box-top-right-bottom-box-right']}>
                      {compositeAssetResquestBody.data?.circulate_amount}份
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['CastingStatus-center-box-foot']}>
              <h4 className={styles['CastingStatus-center-box-foot-h4']}>附加信息</h4>
              <div className={styles['CastingStatus-center-box-foot-text']}>
                <div className={styles['CastingStatus-center-box-foot-text-left']}>
                  <div>合成数量</div>
                  <div>创建时间</div>
                  <div>合成时间</div>
                  <div>链上哈希</div>
                </div>
                <div className={styles['CastingStatus-center-box-foot-text-right']}>
                  <div>{compositeAssetResquestBody.data?.count || 0}</div>
                  <div>{moment(compositeAssetResquestBody.data?.create_time).format('YYYY.MM.DD HH:mm')}</div>
                  <div>{moment(compositeAssetResquestBody.data?.composite_time).format('YYYY.MM.DD HH:mm')}</div>
                  <div className={styles['CastingStatus-center-box-foot-text-right-num']}>
                    {compositeAssetResquestBody.data?.tx_id || '--'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {type === 'ok' && (
        <div
          className={styles['CastingStatus-btnBox']}
          onClick={() => {
            navigate('/myAssets', { replace: true });
          }}>
          查看藏品
        </div>
      )}
    </div>
  );
};

export default CastingStatus;
