import Axios from '@/request/request';

export const queryCollectionSynthesisActivitylist = (params: { page: number; limit: number }) => {
  return Axios.get(`/nft/composite/list`, { params });
};

export const queryCollectionSynthesisDetail = (params: any) => {
  return Axios.get(`/nft/composite/detail`, { params });
};

export const collectionSynthesisAction = (params: any) => {
  return Axios.post(`/nft/composite/asset`, params);
};
