import { observable, action, makeObservable } from 'mobx'
import {
  nftGameDetailApi,
  tradeLeaderboardApi,
} from './serve'
import type {
  nftGameDetailType,
  tradeLeaderboardType,
} from './type'

class Store {
  constructor() {
    makeObservable(this)
  }

  @observable type: 'sale' | 'buy' = 'sale'

  @action onChangeType = (type: 'sale' | 'buy') => {
    this.type = type
  }


  /**
   * 游戏详情
   * @param params 
   * @returns 
   */
  @observable nftGameDetail: any = {}
  @action getNftGameDetail: nftGameDetailType = async (params) => {
    const data = await nftGameDetailApi(params)
    if (data.data.code === 0) {
      this.nftGameDetail = data.data.data
    }
    return Promise.resolve(data)
  }





  /**
   * 交易排行榜
   * @param params 
   * @returns 
   */
  @observable tradeLeaderboard: { userRank: number, list: any[] } = {
    userRank: 0,
    list: []
  }
  @action getTradeLeaderboard: tradeLeaderboardType = async (params) => {
    const data = await tradeLeaderboardApi(params)
    if (data.data.code === 0) {
      this.tradeLeaderboard = data.data.data
    }
    return Promise.resolve(data)
  }






}

export default new Store()