import React, { useEffect } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'mobx-react'
import reportWebVitals from './reportWebVitals';
import AnimatedRouter from 'react-animated-router';
import 'react-animated-router/animate.css';
import 'animate.css';

import store from './store/store';
import routers from './router/router'

import './index.scss';
// import 'remixicon/fonts/remixicon.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// Render the app
root.render(
  <React.StrictMode>
    <Router>
      <Provider {...store}>
        <AnimatedRouter>
          {
            routers.map((item: any, i) => (
              <Route key={i} path={item.path} element={<item.element />} />
            ))
          }
        </AnimatedRouter>
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
