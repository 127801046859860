import { useEffect, useState } from "react";
import { observer } from 'mobx-react'
import Header from "../../../component/header/header";
import './giveNft.scss';
import { Dialog, Checkbox, Toast } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import miningStore from "@/store/miningStore";
import appState from "@/store/appState";
import DealPassword from "@/component/dealPassword/dealPassword";
import primaryMarket from "@/store/primaryMarket";


const GiveNft: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [dealVidible, setDealVidible] = useState(false)
  const [dealPassword, setDealPassword] = useState('')
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('')
  const [toAdd, setToAdd] = useState('')
  const [name, setName] = useState('')
  const { commonData } = appState
  const location = useLocation()
  const assetsDetail = JSON.parse(location.state)
  const [state, setState] = useState<{ '*': string }>({ '*': '' })

  useEffect(() => {
    console.log(assetsDetail)
  }, [])

  const add = () => {
    if (!commonData?.has_password) {
      Toast.show('请设置交易密码')
      return navigate('/password')
    }
    if (phoneNumber === '') {
      return Toast.show({ content: '请输入Wyper ID' })
    }
    if (toAdd === '') {
      return Toast.show({ content: '请输入区块链地址' })
    }
    primaryMarket.nfOrderPreCheck({
      toUid: phoneNumber,
      address: toAdd
    }).then(({ data }) => {
      if (data.code === 0) {
        setState({ '*': data.data.name })
        setDealVidible(true)
      }
    })
  }

  const handleAction = (actions: string | undefined) => {

    if (actions === 'confirm' && name !== '') {
      primaryMarket.nftOrderTransferCreate({
        toUid: phoneNumber,
        address: toAdd,
        product: assetsDetail?.product,
        name,
        shard_id: assetsDetail?.shard_id,
        idx: assetsDetail?.idx,
        asset_id: assetsDetail?.asset_id,
        box_id: assetsDetail?.box_id,
      }, { password: dealPassword }).then(({ data }) => {
        setVisible(false)
        setName('')
        if (data.code === 0) {
          navigate('/mining/IncreaseByTransferring', { state: data.data })
          Toast.show({ content: '转赠成功', icon: 'success' })
          appState.getMenuData()
        } else if (data.code === 40010) {
          navigate('/password')
        }
      })
    }
  }
  const close = (actions: string | undefined) => {

    if (actions === 'cancel') {
      setVisible(false);
      setName('')
    }
  }


  return (
    <div id="GiveNft">
      <Header text='赠送藏品' />
      <div className="GiveNft-top">
        <img className="GiveNft-top-left" src={assetsDetail?.thumb && assetsDetail?.thumb[assetsDetail?.thumb.length - 1]} alt="" />
        <div className="GiveNft-top-right">
          <div className="GiveNft-top-right-top"><span>{assetsDetail?.title}</span></div>
          <div className="GiveNft-top-right-center">
            <img src={require('@/assets/imgs/nftDetail-small.svg').default} alt="" />
            <span className="GiveNft-top-right-center-text">#{assetsDetail?.shard_id}/{assetsDetail?.amount}</span>
          </div>
          <div className='box3'>
            <div className='box3-item'>
              <span className='box3-item-text1'>发行量</span>
              <span className='box3-item-text2'>{assetsDetail?.publish_amount}份</span>
            </div>
            <div className='box3-item'>
              <span className='box3-item-text1'>流通量</span>
              <span className='box3-item-text2'>{assetsDetail?.circulate_amount}份</span>
            </div>
          </div>
        </div>
      </div>
      <div className="GiveNft-center">
        <div className="GiveNft-center-illustrate">
          <p>1.藏品一经转赠，操作不可撤销，请您谨慎确认。</p>
          <p>2.被转赠对象必须为年满十八周岁的实名注册用户。</p>
          <p>3.WYPER空间严禁用户参与任何炒作行为。</p>
        </div>
        <div className="GiveNft-center-box">
          <div className="GiveNft-center-id">
            <input className="GiveNft-center-id-p" type="number" placeholder="受赠人 ID" value={phoneNumber} onChange={x => setPhoneNumber(x.target.value)} />
          </div>
          <div className="GiveNft-center-num">
            <input className="GiveNft-center-num-p" type="text" placeholder="受赠方区块链地址" value={toAdd} onChange={x => setToAdd(x.target.value)} />
          </div>
          <span style={{ color: '#ff0f0f', fontSize: 12 }}>*本次转赠技术服务需要消耗 {assetsDetail?.transfer_bonus} {assetsDetail?.transfer_bonus_type}</span>
          <div className="GiveNft-center-notice">
            <Checkbox
              checked={checked} onChange={(e: any) => setChecked(e)}
              style={{
                '--icon-size': '15px',
              }}
            />
            <p className='GiveNft-center-notice-p'>您已同意 <a className="GiveNft-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a>、<a className="GiveNft-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a>、<a className="GiveNft-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/riskterms.html', text: '风险提示' } })}>《风险提示》</a></p>
          </div>
          <button className="GiveNft-center-notice-btn" onClick={() => add()}>确认赠送</button>
        </div>

      </div>

      <Dialog
        visible={visible}
        content={(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              gap: 13,
              marginTop: 10,
            }}
          >
            <span style={{ fontSize: 16, fontWeight: "bold" }}>确认朋友身份</span>
            <span style={{ fontSize: 14, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)', display: 'block', marginTop: '5px' }}>请填写对方通过实名认证的姓名首字，以确认对方身份</span>
            <div className='Given-auth' style={{ display: 'flex', alignItems: 'center', marginTop: '18px', color: '#040415', fontWeight: 700, height: '48px' }}>
              <input
                maxLength={1}
                style={{
                  width: "50px",
                  borderRadius: '4px',
                  height: '48px',
                  paddingLeft: 17,
                  boxSizing: "border-box",
                  backgroundColor: '#F3F3F3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                  color: '#000',
                  fontWeight: 700,
                  fontSize: 14
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />&nbsp;&nbsp;
              {state["*"]}
            </div>
          </div>
        )}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
              style: { color: '#000' },
              onClick: () => close('cancel')
            },
            {
              key: 'confirm',
              text: '确定',
              style: { fontWeight: 600 },
              onClick: () => handleAction('confirm')
            },
          ]
        ]}
      />

      <DealPassword
        visible={dealVidible}
        onConfirm={(v) => {
          setDealPassword(v);
          setDealVidible(false);
          setVisible(true);
        }}
        onClose={() => setDealVidible(false)}
      />
    </div>
  )
}

export default observer(GiveNft);