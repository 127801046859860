import { useState, useEffect } from 'react'
import { Toast, Popup } from 'antd-mobile'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import appState from '@/store/appState'
import copy from 'copy-to-clipboard'

import './mine.scss'
import { _hmtHandle } from '@/utils/utils'

// import 'remixicon/fonts/remixicon.css'


const Mine: React.FC = () => {
  const navigate = useNavigate()
  const [walletVisible, setWalletVisible] = useState(false)
  const { commonData } = appState

  let userEquity = [
    {
      text: "原卡",
      value: `0`,
    },
    {
      text: "原石",
      value: Number(commonData?.real_score)?.toFixed(2),
      onClick() {
        navigate('/mining/mygem')
      }
    },
    {
      text: "灵金",
      value: Number(commonData?.lingjin)?.toFixed(2),
      onClick() {
        navigate('/mining/MySpiritualGold')
      }
    },
    {
      text: "秘钥",
      value: Math.floor(commonData?.miyao),
      onClick() {
        navigate('/universe/MyMiyaoList')
      }
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem('commonData')) {
      navigate('/login')
    }

    _hmtHandle('任何设备', '访问', '「我的」页面')
  }, [])

  const walletHandle = (type: 'sandpay' | 'huifupay') => {
    if (type === 'sandpay') {
      return window.location.href = commonData?.wallet?.url
    }

    if (type === 'huifupay') {
      return window.location.href = commonData?.wallet2?.url
    }
  }

  return (
    <div id="Mine">
      <div className="userBox">
        <div className="userCard">
          <div className="header">
            <div className="userInfo">
              <img
                className="userImg"
                src={commonData?.avatar_url ? commonData?.avatar_url : "http://images.diaoqianyaner.com.cn/img/headimage.png"}
                onClick={() => navigate('/settings')}
              />
              <div className="userInfo-right">

                <div className="userName">
                  {commonData?.nick_name}
                </div>
                <div className='userInfo'><span>{commonData?.userId}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
                  <span onClick={() => {
                    copy(commonData?.to_add)
                    Toast.show('复制成功')
                  }}>
                    {String(commonData?.to_add).slice(0, 6) + '...' + String(commonData?.to_add).slice(-6)}
                    <i className="ri-file-copy-fill"></i>
                  </span></div>

              </div>

            </div>
          </div>

          <div className="myMeony">
            <ul className="userEquity">
              {userEquity.map((item, i) => (
                <li key={i} onClick={item.onClick}>
                  <div className="userStatus">{item.value}</div>
                  <div className="userText">{item.text}</div>
                </li>
              ))}
            </ul>
          </div>

          <div className='userCard-box'>
            <div className='userCard-box-item' onClick={() => { navigate('/my/SellAndSold?type=sell') }}>
              <div className='userCard-box-item-top'>
                <img src={require('../../../assets/imgs/mine-icon1.svg').default} alt="" />
              </div>
              <span className='userCard-box-item-text'>发布</span>
            </div>
            <div className='userCard-box-item' onClick={() => navigate('/order')}>
              <div className='userCard-box-item-top'>
                <img src={require('../../../assets/imgs/mine-icon2.svg').default} alt="" />
              </div>
              <span className='userCard-box-item-text'>买入</span>
            </div>
            <div className='userCard-box-item' onClick={() => { navigate('/my/SellAndSold?type=sold') }}>
              <div className='userCard-box-item-top'>
                <img src={require('../../../assets/imgs/mine-icon3.svg').default} alt="" />
              </div>
              <span className='userCard-box-item-text'>卖出</span>
            </div>
            <div className='userCard-box-item' onClick={() => {
              if (commonData?.bind_identity_number) {
                setWalletVisible(true)
              } else {
                navigate('/realname')
                Toast.show('请先通过实人认证')
              }
            }}>
              <div className='userCard-box-item-top'>
                <img src={require('../../../assets/imgs/mine-icon4.svg').default} alt="" />
              </div>
              <span className='userCard-box-item-text'>钱包</span>
            </div>
          </div>
          {/* <div className='Mine-invite' onClick={() => { navigate('/invite'); _hmtHandle('任何设备', '点击', '「我的」页面「立即邀请」banner位') }}>
            <img height={42} src={require('../../../assets/imgs/invitTitle.png')} alt="" />
            <span className='Mine-invite-text'>立即邀请</span>
          </div> */}
        </div>
      </div>

      <main>
        <ul>
          <li onClick={() => { navigate('/invite'); _hmtHandle('任何设备', '点击', '「我的」页面「立即邀请」banner位') }}>
            <div>
              <img src={require("../../../assets/imgs/share-box-line.svg").default} />
              <span>邀请好友</span>
            </div>
            <img src={require("../../../assets/imgs/arrow-right.svg").default} />
          </li>
          <li onClick={() => { navigate('/announcement/announcement') }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon7.svg").default} />
              <span>官方公告</span>
            </div>
            <img src={require("../../../assets/imgs/arrow-right.svg").default} />
          </li>
          <li onClick={() => { navigate('/my/ConsignmentGoods'); _hmtHandle('任何设备', '点击', '「我的」页面「寄存藏品」按钮') }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon1.svg").default} />
              <span>寄存商品</span>
            </div>
            <img src={require("../../../assets/imgs/arrow-right.svg").default} />
          </li>
          <li onClick={() => { navigate('/myAssets'); _hmtHandle('任何设备', '点击', '「我的」页面「潮玩藏品」按钮') }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon2.svg").default} />
              <span>潮玩藏品</span>
            </div>
            <img src={require("../../../assets/imgs/arrow-right.svg").default} />
          </li>
          <li onClick={() => navigate('/My/ShippingAddress')}>
            <div>
              <img src={require("../../../assets/imgs/mineicon3.svg").default} />
              <span>收货地址</span>
            </div>
            <p>
              <img src={require("../../../assets/imgs/arrow-right.svg").default} />
            </p>
          </li>
          <li onClick={() => {
            if (commonData?.bind_identity_number) {
              Toast.show({ content: '身份已认证' })
            } else {
              navigate('/realname')
            }
          }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon4.svg").default} />
              <span>身份认证</span>
            </div>
            <div>
              <img src={require("../../../assets/imgs/arrow-right.svg").default} />
            </div>
          </li>
          <li onClick={() => {
            if (commonData?.has_password) {
              Toast.show({ content: '已设置交易密码' })
            } else {
              navigate('/password')
            }
          }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon8.svg").default} />
              <span>交易密码</span>
            </div>
            <div>
              <img src={require("../../../assets/imgs/arrow-right.svg").default} />
            </div>
          </li>
          <li onClick={() => {
            if (commonData?.inviter_id) {
              Toast.show(`已有邀请人:${commonData?.inviter_id}`)
            } else {
              navigate('/inviteCode')
            }
          }}>
            <div>
              <img src={require("../../../assets/imgs/mineicon6.svg").default} />
              <span>绑定邀请码</span>
            </div>
            <div>
              <span style={{ color: '#999' }}>{commonData?.inviter_id}</span>
              <img src={require("../../../assets/imgs/arrow-right.svg").default} />
            </div>
          </li>
          <li onClick={() => navigate('/My/HelpCenter')}>
            <div>
              <img src={require("../../../assets/imgs/mineicon5.svg").default} />
              <span>帮助中心</span>
            </div>
            <img src={require("../../../assets/imgs/arrow-right.svg").default} />
          </li>
        </ul>
      </main>

      <Popup
        visible={walletVisible}
        onClose={() => setWalletVisible(false)}
        getContainer={null}
        closeOnMaskClick
      >
        <div className='Mine-wallet' onClick={(e) => e.stopPropagation()}>
          <div className='Mine-wallet-item'>
            <div className='Mine-wallet-item-left'>
              <div className='Mine-wallet-item-left-img'>
                <img src={require('../../../assets/imgs/sd.svg').default} alt="" />
              </div>
              <div className='Mine-wallet-item-left-content'>
                <span className='Mine-wallet-item-left-content-text1'>杉德钱包</span>
                <span className='Mine-wallet-item-left-content-text2'>余额：¥{(Number(commonData?.wallet?.balance || 0) / 100).toFixed(2)}</span>
              </div>
            </div>
            <div
              className='Mine-wallet-item-btn btn'
              onClick={() => walletHandle('sandpay')}
              style={commonData?.wallet?.bind ? { backgroundColor: '#4D33EF' } : {}}
            >
              {commonData?.wallet?.bind ? '进入' : '开通'}
            </div>
          </div>
          <div className='Mine-wallet-item'>
            <div className='Mine-wallet-item-left'>
              <div className='Mine-wallet-item-left-img'>
                <img src={require('../../../assets/imgs/hf.svg').default} alt="" />
              </div>
              <div className='Mine-wallet-item-left-content'>
                <span className='Mine-wallet-item-left-content-text1'>汇付钱包</span>
                <span className='Mine-wallet-item-left-content-text2'>余额：¥{(Number(commonData?.wallet2?.balance || 0) / 100).toFixed(2)}</span>
              </div>
            </div>
            <div
              className='Mine-wallet-item-btn btn'
              onClick={() => walletHandle('huifupay')}
              style={commonData?.wallet2?.bind ? { backgroundColor: '#4D33EF' } : {}}
            >
              {commonData?.wallet2?.bind ? '进入' : '开通'}
            </div>
          </div>
        </div>
      </Popup>
    </div >
  )
}

export default observer(Mine)