import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'antd-mobile';
import Header from '@/component/header/header';
import { queryCollectionSynthesisDetail } from '../../serve';
import styles from './synthesisDetails.module.scss';

export const initData = {
  mainImage: '',
  title: '',
  remain: 0,
  amount: 0,
  available_composite_count: 0,
  box_assets: [],
  materials: [],
  need_materials: [],
  all_materials: [],
  author_desc: '',
  long_desc: '',
  iid: '',
  grade: '',
};

const SynthesisDetails: React.FC = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { iid } = state;
  const [data, setData] = useState(initData);

  const query = async (iid: string) => {
    const queryCollectionSynthesisDetailRes = await queryCollectionSynthesisDetail({ iid });
    if (queryCollectionSynthesisDetailRes.data?.code !== 0) return;
    const result = queryCollectionSynthesisDetailRes.data?.data || {};
    const { thumb = [] } = result;
    const mainImage = thumb[0] || [];
    setData({ mainImage, ...result });
  };

  const compositeAction = () => {
    navigate(`/synthesis/CollectionSynthesis`, { state: { iid: data.iid } });
  };

  useEffect(() => {
    if (iid) query(iid);
  }, [iid]);

  return (
    <div id={styles['SynthesisDetails']}>
      <Header text='藏品合成' />
      <img className={styles['SynthesisDetails-img']} src={data.mainImage} alt='' />
      <div className={styles['SynthesisDetails-nav']}>
        <h3 className={styles['SynthesisDetails-nav-h3']}>{data.title}</h3>
        <div id={styles[data.grade]} className={styles['SynthesisDetails-nav-div']}>{data.grade}</div>
      </div>
      <div className={styles['SynthesisDetails-ProgressBarbox']}>
        <ProgressBar
          className={styles['SynthesisDetails-ProgressBar']}
          percent={((data.amount - data.remain) / data.amount) * 100}
          style={{
            '--fill-color': '#4D33EF',
            '--track-color': '#F6F6F6',
          }}
        />
        <div>
          剩余:&nbsp;{data.remain}/{data.amount}
        </div>
      </div>
      <div className={styles['SynthesisDetails-centerBox1']}>
        {data.box_assets?.length > 0 && (
          <div className={styles['SynthesisDetails-centerBox1-title']}>
            <div>合成铸造藏品</div>
            <p className={styles['SynthesisDetails-centerBox1-title-p']}>您可能合成铸造以下藏品中的1个</p>
          </div>
        )}
        {data.box_assets &&
          data.box_assets.map((item: any, index: number) => (
            <div
              className={styles['SynthesisDetails-centerBox1-list']}
              key={`SynthesisDetails-centerBox1-list-${index}`}>
              <img
                className={styles['SynthesisDetails-centerBox1-list-mainImage']}
                src={item.thumb[item.thumb.length - 1]}
                alt=''
              />
              <div className={styles['SynthesisDetails-centerBox1-list-right']}>
                <h4 className={styles['SynthesisDetails-centerBox1-list-right-h4']}>{item.title}</h4>
                <p className={styles['SynthesisDetails-centerBox1-list-right-p']}>
                  概率&nbsp;{item.percent}%&nbsp; |&nbsp; 数量 {item.amount}
                </p>
              </div>
            </div>
          ))}
      </div>

      <div className={styles['SynthesisDetails-centerBox2']}>
        <div className={styles['SynthesisDetails-centerBox2-title']}>
          <div>合成所需材料</div>
        </div>
        {data.materials &&
          data.materials.map((item: any, index: number) => (
            <div className={styles['SynthesisDetails-centerBox2-list']}>
              <div className={styles['SynthesisDetails-centerBox2-list-left']}>
                <img
                  className={styles['SynthesisDetails-centerBox2-list-mainImage']}
                  src={item.thumb[item.thumb.length - 1]}
                  alt=''
                />
                <div className={styles['SynthesisDetails-centerBox2-list-right']}>
                  <h4 className={styles['SynthesisDetails-centerBox2-list-h4']}>{item.title}</h4>
                  <p className={styles['SynthesisDetails-centerBox2-list-p']}>
                    消耗量&nbsp;{item.amount}&nbsp; |&nbsp; 持有量 {item.owned_amount}
                  </p>
                </div>
              </div>
              <div className={styles['SynthesisDetails-centerBox2-list-right-OneBox']}>
                <div className={styles['SynthesisDetails-centerBox2-list-right-c']}>×</div>
                <div className={styles['SynthesisDetails-centerBox2-list-right-one']}>{item.amount}</div>
              </div>
            </div>
          ))}
      </div>

      {data.author_desc && (
        <div className={styles['SynthesisDetails-textBox']}>
          <h3 className={styles['SynthesisDetails-textBox-h3']}>艺术家介绍</h3>
          <p className={styles['SynthesisDetails-textBox-p']}>{data.author_desc}</p>
        </div>
      )}
      {data.long_desc && (
        <div className={styles['SynthesisDetails-textBox']}>
          <h3 className={styles['SynthesisDetails-textBox-h3']}>作品描述</h3>
          <p className={styles['SynthesisDetails-textBox-p']}>{data.long_desc}</p>
        </div>
      )}

      <div className={styles['SynthesisDetails-btnBox']} onClick={compositeAction}>
        <div className={styles['SynthesisDetails-btnBox-btn']}>合成藏品</div>
      </div>
    </div>
  );
};

export default SynthesisDetails;
