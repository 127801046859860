import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import secondaryMarket from '@/store/secondaryMarket';
import { observer } from 'mobx-react'
import Sale from './sale';
import AskBuy from './askBuy';
import type { PropsType } from '@/global'
import Store from '../redurce'

interface JackpotPropsType {
  socketData: PropsType['AppState']['socketData']
}

const Jackpot: React.FC<JackpotPropsType> = ({ socketData }) => {
  const [sort, setSort] = useState<'sku.sid' | 'price'>('price');
  const location = useLocation()
  const { goodsSearcExtra } = secondaryMarket
  const { type, onChangeType } = Store

  const init = () => {
    secondaryMarket.getGoodsSearhList({
      'sku.aid': location?.state?.aid,
      sort
    })
  }

  useEffect(() => {
    init()
  }, [sort, socketData])


  return (
    <>
      {/*奖池交易中间页面*/}
      <div className={`RankingList-center`}>
        <div className='RankingList-center-title'>
          <h3 className='RankingList-center-title-h3'>
            {goodsSearcExtra?.title}
            {goodsSearcExtra.grade && <img className='Market-content-list-item-grade' width={24} height={24} src={require(`@/assets/imgs/${goodsSearcExtra.grade}.svg`)} alt="" />}
          </h3>
          <div className='RankingList-center-title-div'>
            <div className='RankingList-center-title-box'>
              <div className='RankingList-center-title-box-left'>发行</div>
              <div className='RankingList-center-title-box-right'>{goodsSearcExtra?.publish_amount ?? '--'}份</div>
            </div>
            <div className='RankingList-center-title-box'>
              <div className='RankingList-center-title-box-left'>流通</div>
              <div className='RankingList-center-title-box-right'>{goodsSearcExtra?.circulate_amount ?? '--'}份</div>
            </div>
            <div className='RankingList-center-title-box'>
              <div className='RankingList-center-title-box-left'>销毁</div>
              <div className='RankingList-center-title-box-right'>{goodsSearcExtra?.destroy_amount ?? '--'}份</div>
            </div>
          </div>
        </div>

        <div className='RankingList-center-sort-box'>
          <div className='RankingList-center-sort'>
            <div className='RankingList-center-sort-left'>
              <div className={`RankingList-center-sort-left-price`} style={type === 'sale' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => onChangeType('sale')}>寄售价格</div>
              {goodsSearcExtra.can_bid && <div className={`RankingList-center-sort-left-price`} style={type === 'buy' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => onChangeType('buy')}>求购价格</div>}
            </div>
            {type === 'sale' && <div className={`RankingList-center-sort-right background`}>
              <div className={`RankingList-center-sort-right-box1  ${sort === 'price' ? 'active2' : 'none'}`} onClick={() => setSort('price')}>价格排序</div>
              <div className={`RankingList-center-sort-right-box2  ${sort === 'sku.sid' ? 'active2' : 'RankingList-center-sort-right-box1'}`} onClick={() => setSort('sku.sid')}>编号排序</div>
            </div>}
          </div>
        </div>

        {type === 'sale' && <Sale sort={sort} />}

        {type === 'buy' && <AskBuy sort={sort} />}
      </div >
    </>
  )
}

export default observer(Jackpot)