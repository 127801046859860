import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import moment from 'moment';

import store from './reducer'
import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll';
import Header from "../../../component/header/header";

import './partitionRecord.scss';

const PartitionRecord: React.FC = () => {
  const { bonusDivideHistoryList, bonusDivideHistoryHasMore, getBonusDivideHistory } = store
  const { state } = useLocation()

  useEffect(() => {
    getBonusDivideHistory({
      gameId: state.gameId
    })
  }, [])

  return (
    <div id="PartitionRecord">
      <Header text='灵金获得记录' fontColor="black" />
      <div className="PartitionRecord-box">
        <div className="partitionRecord-center-title">
          <h3>获得灵金用户</h3>
          {/* <p className="partitionRecord-center-title-p">已累计发放灵金1222个</p> */}
        </div>
      </div>

      <div className="partitionRecord-center">
        {bonusDivideHistoryList.length > 0 && bonusDivideHistoryList.map((x, i) => (
          <div className="partitionRecord-center-box" key={i}>
            <div className="partitionRecord-center-left">
              <p>{x.userNickname}</p>
              <p className="partitionRecord-center-left-time">{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
            </div>
            <div className="partitionRecord-center-right">
              <img className='RankingList-bronze-top-right-img' src={require('../../../assets/imgs/RankingList-bj2.svg').default} alt="" />
              <div>&nbsp;×&nbsp;{x.bonusAmount}</div>
            </div>
          </div>
        ))}
      </div>

      <InfiniteScrollComponent
        hasMore={bonusDivideHistoryHasMore}
        dataSource={bonusDivideHistoryList}
        loadMore={async () => {
          await getBonusDivideHistory({
            gameId: state.gameId,
            skip: bonusDivideHistoryList.length
          })
        }}
      />
    </div>
  )
}

export default observer(PartitionRecord);