import Header from '../../component/header/header';

const miningPoster: React.FC = () => {
  return (
    <div id='MiningPoster'>
      <Header text='挖源矿得原石' fontColor="black" />
      <img style={{ width: '100%' }} src={require('./../../assets/imgs/MiningPoster-bj.jpeg')} alt="" />
    </div>
  )
}

export default miningPoster;