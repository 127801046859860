import styles from './paymentPage.module.scss';
import Header from '../../../component/header/header';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react'
import secondaryMarket from '@/store/secondaryMarket';
import moment from 'moment';
import { Dialog, Toast } from 'antd-mobile';
import { Clock } from 'grommet';

type State = 'create' | 'cancel' | 'pay' | 'process' | 'confirm' | 'pre_refund' | 'refunding' | 'refund'

enum PayChannel {
  'huifupay' = '汇付钱包',
  'sandpay' = '杉德钱包'
}

const PaymentPage: React.FC = () => {
  const { getOrdersDetail, ordersDetail, ordersCancel } = secondaryMarket
  const [type, setType] = useState<State>('create')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const order_id = searchParams.get('order_id')


  const init = () => {
    if (order_id) {
      getOrdersDetail({ order_id }).then(({ data }) => {
        if (data.code === 0) {
          setType(data.data.state)
        }
      })
    }
  }

  useEffect(() => {
    init()
  }, [])



  /**
    * 支付倒计时
    */
  const [countDown, setCountDown] = useState("00:00")
  let interval: NodeJS.Timer;
  useEffect(() => {
    if (ordersDetail.state === 'create') {
      if (ordersDetail.expire_time) {
        let diff = parseInt(moment(ordersDetail.expire_time).diff(moment()) / 1000 + '')
        if (diff > 0) {
          interval = setInterval(() => {
            diff -= 1
            if (diff > 0) {
              let m = Math.floor((diff / 60 % 60)) > 9 ? Math.floor((diff / 60 % 60)) : Math.floor((diff / 60 % 60)) >= 1 ? `0${Math.floor((diff / 60 % 60))}` : '00';  //分
              let s = parseInt((diff % 60) + '') > 9 ? parseInt((diff % 60) + '') : `0${parseInt((diff % 60) + '')}`;  //秒
              setCountDown(`${m}:${s}`)
            } else {
              Toast.show({ content: '订单超时' })
              if (type === 'create') {
                init()
              } else {
                clearInterval(interval)
              }
            }
          }, 1000)
        } else {
          Toast.show({ content: '订单已失效' })
          setTimeout(() => {
            clearInterval(interval)
          }, 1000)
        }
      }
    }
    return () => {
      clearInterval(interval)
    }
  }, [ordersDetail.expire_time, type])




  const cancelOrderHandle = () => {
    Dialog.show({
      content: '确认要取消订单吗',
      closeOnAction: true,
      actions: [
        [
          {
            text: '取消',
            key: 'cancel',
          },
          {
            text: '确认',
            key: 'ok',
            danger: true,
            onClick() {
              if (order_id) {
                ordersCancel({
                  order_id
                }).then(({ data }) => {
                  if (data.code === 0) {
                    Toast.show({ content: '订单已取消' })
                    setTimeout(() => navigate(-2), 1000);
                  }
                })
              }
            },
          }
        ],
      ]
    })
  }




  return (
    <div id={styles['PaymentPage']}>
      <Header backHandle={() => navigate(-2)} text='订单支付' fontColor='black' />
      <img className={`${styles['PaymentPage-img']} ${type === 'create' ? '' : styles['noneClass']}`} src={require('../../../assets/imgs/PaymentPage-wait.svg').default} alt="" />
      <h3 className={`${styles['PaymentPage-h3']} ${type === 'create' ? '' : styles['noneClass']}`}>{ordersDetail.type === 'bid_parent' ? '正在预付' : '等待支付'}</h3>

      <img className={`${styles['PaymentPage-img']} ${(type === 'confirm' || type === 'process') ? '' : styles['noneClass']}`} src={require('../../../assets/imgs/PaymentPage-ok.svg').default} alt="" />
      <h3 className={`${styles['PaymentPage-h3']} ${(type === 'confirm' || type === 'process') ? '' : styles['noneClass']}`}>交易成功</h3>

      {ordersDetail.type === 'bid_parent' && (
        <>
          {type === 'pay' && <img className={`${styles['PaymentPage-img']}`} src={require('../../../assets/imgs/chat-check-fill.svg').default} alt="" />}
          {(type === 'refund' || type === 'refunding') && <img className={`${styles['PaymentPage-img']}`} src={require('../../../assets/imgs/PaymentPage-no.svg').default} alt="" />}
          {type === 'pay' && <h3 className={`${styles['PaymentPage-h3']}`}>预付成功</h3>}
          {type === 'refund' && <h3 className={`${styles['PaymentPage-h3']}`}>预付款已退回</h3>}
          {type === 'refunding' && <h3 className={`${styles['PaymentPage-h3']}`}>退款中</h3>}
        </>
      )}

      <img className={`${styles['PaymentPage-img']} ${type === 'cancel' ? '' : styles['noneClass']}`} src={require('../../../assets/imgs/PaymentPage-no.svg').default} alt="" />
      <h3 className={`${styles['PaymentPage-h3']} ${type === 'cancel' ? '' : styles['noneClass']}`}>交易取消</h3>


      <div className={styles['PaymentPage-center']}>
        <div className={styles['PaymentPage-center-box']}>
          <div className={styles['PaymentPage-center-box-top']}>
            <img src={ordersDetail?.goods?.thumb && ordersDetail?.goods?.thumb[ordersDetail?.goods?.thumb.length - 1]} alt="" />
            <div className={styles['PaymentPage-center-box-top-right']}>
              <h4 className={styles['PaymentPage-center-box-top-right-h4']}>{ordersDetail?.goods?.title}</h4>
              <div className={styles['PaymentPage-center-box-top-right-footBox']}>
                <div className={styles['PaymentPage-center-box-top-right-footBox-div']}>
                  <div className={styles['PaymentPage-center-footBox-div-fa']}>发行</div>
                  <div className={styles['PaymentPage-center-footBox-div-num']}>{ordersDetail?.goods?.publish_amount ?? '--'}份</div>
                </div>
                <div className={styles['PaymentPage-center-box-top-right-footBox-div']}>
                  <div className={styles['PaymentPage-center-footBox-div-fa']}>流通</div>
                  <div className={styles['PaymentPage-center-footBox-div-num']}>{ordersDetail?.goods?.circulate_amount ?? '--'}份</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['PaymentPage-center-box-centerBox']}>
            <h4 className={styles['PaymentPage-center-box-centerBox-h4']}>交易信息</h4>
            {ordersDetail.type === 'match_bid' ? (
              <div className={styles['PaymentPage-center-box-centerBox-foot']}>
                <div className={styles['PaymentPage-center-box-centerBox-foot-text']}>
                  <span className={styles['PaymentPage-center-box-centerBox-foot-text-left']}>
                    收款方式：{PayChannel[ordersDetail.pay_channel as 'huifupay']}
                  </span>
                  <div className={styles['PaymentPage-center-box-centerBox-foot-text-right']}>
                    <span style={{ fontSize: 14 }}>¥ </span>
                    {((ordersDetail?.price2) / 100).toFixed(2)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles['PaymentPage-center-box-centerBox-foot']}>
                <div className={styles['PaymentPage-center-box-centerBox-left']}>
                  <div>支付方式</div>
                  {ordersDetail?.goods?.sid && <div>藏品编号</div>}
                  {ordersDetail?.bulk_child_orders && <div>藏品编号</div>}
                </div>
                <div className={styles['PaymentPage-center-box-centerBox-right']}>
                  <div>快捷支付</div>
                  {ordersDetail?.goods?.sid && <div>#{ordersDetail?.goods?.sid}</div>}
                  {ordersDetail?.bulk_child_orders && <div className={styles['PaymentPage-center-box-centerBox-right-foot']} onClick={() => navigate('/secondaryMarket/AllNumbers', { state: { bulk_child_orders: JSON.stringify(ordersDetail?.bulk_child_orders) } })}>
                    <div>查看全部编号</div>
                    <img src={require('../../../assets/imgs/PaymentPage-right-line.svg').default} alt="" />
                  </div>}
                </div>
              </div>
            )}
          </div>
          <div className={styles['PaymentPage-center-box-foot']}>
            <h4 className={styles['PaymentPage-center-box-foot-h4']}>附加信息</h4>
            <div className={styles['PaymentPage-center-box-foot-box']}>
              <div className={styles['PaymentPage-center-box-foot-box-left']}>
                <div>{ordersDetail.type === 'match_bid' ? '商品价格' : '实际付款'}</div>
                <div>交易数量</div>
                <div>创建时间</div>
                <div>订单编号</div>
              </div>
              <div className={styles['PaymentPage-center-box-foot-box-right']}>
                <div><span className={styles['PaymentPage-center-box-foot-box-right-span']}>¥</span>{((ordersDetail?.price) / 100).toFixed(2)}</div>
                <div>{ordersDetail?.amount || 1}</div>
                <div>{moment(ordersDetail?.create_time).format('YYYY.MM.DD HH:mm')}</div>
                <div className={styles['PaymentPage-center-box-foot-box-right-text']}>{ordersDetail?.order_id}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {type === 'create' && <div className={styles['PaymentPage-btn-box']}>
        <div className={`${styles['PaymentPage-btn-box-Cancel']}`} onClick={cancelOrderHandle}>取消订单</div>
        <div className={`${styles['PaymentPage-btn-box-continue']}`} onClick={() => {
          secondaryMarket.ordersPay(ordersDetail?.order_id)
        }}>
          <Clock
            type="digital"
            precision="minutes"
            time={`T${countDown}`}
          />,
          <span>继续支付</span>
        </div>
      </div>}

      <div className={`${styles['PaymentPage-ok-btn']} ${type === 'cancel' ? '' : styles['noneClass']}`} onClick={() => navigate(-2)}>返回市场</div>

      {ordersDetail.type === 'bid_parent' && (
        <>
          {type === 'pay' && (
            <div className={styles['PaymentPage-btn-box']}>
              <div className={`${styles['PaymentPage-btn-box-Cancel']}`} onClick={cancelOrderHandle}>取消订单</div>
              <div className={`${styles['PaymentPage-btn-box-continue']}`} onClick={() => navigate(-2)}>
                <span>继续求购</span>
              </div>
            </div>
          )}
          {type === 'confirm' && (
            <div
              className={`${styles['PaymentPage-ok-btn']}`}
              onClick={() => navigate(-2)}
            >
              {type === 'confirm' ? '查看藏品' : '藏品正在上链中'}
            </div>
          )}
        </>
      )}

      {ordersDetail.type === 'match_bid' &&
        (
          <>
            {(type === 'confirm' || type === 'process') && <div className={`${styles['PaymentPage-ok-btn']}`} onClick={() => navigate(-2)}>返回市场</div>}
          </>
        )
      }

      {ordersDetail.type !== 'bid_parent' && ordersDetail.type !== 'match_bid' && (
        <>
          {type === 'confirm' && (
            <div
              className={`${styles['PaymentPage-ok-btn']}`}
              onClick={() => navigate(-2)}
            >
              {type === 'confirm' ? '查看藏品' : '藏品正在上链中'}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default observer(PaymentPage);