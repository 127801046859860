import { useLocation, useNavigate } from 'react-router-dom'
import appState from '@/store/appState'
import Header from '../../component/header/header'
import './webview.scss'

const WebView: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { commonData } = appState

  return (
    <div id="WebView">
      {location.search === '?type=game' &&
        <>
          <img className='WebView-back' src={require('@/assets/imgs/arrow-left-white.svg').default} alt="" onClick={() => navigate(-1)} />
          <iframe className="container" style={{ padding: 0 }} src={`https://xingjitaowang.wyper.art?token=${commonData?.authorization}`} />
          <audio
            loop
            controls={false}
            autoPlay
          >
            <source src={require('@/assets/audio/kill_bgm.mp3')} type="audio/mp3" />
            <embed src={require('@/assets/audio/kill_bgm.mp3')} />
          </audio>
        </>
      }

      {location.state &&
        <>
          <Header text={location.state?.text} />
          <iframe className="container" src={location.state?.src} />
        </>
      }
    </div>
  );
}

export default WebView;
