// 防抖
export function debounce(fn: (value?: any) => void, delay = 500) {
  let timer: NodeJS.Timeout;
  return function (value?: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(value);
    }, delay);
  };
}

// 节流
export function throttle(fn: () => void, delay = 500) {
  let valid = true;
  return function () {
    if (!valid) return false;
    valid = false;
    setTimeout(() => {
      fn();
      valid = true;
    }, delay);
  };
}

// 百度统计事件追踪代码
declare const _hmt: any
export const _hmtHandle = (type: string, action: string, name: string) => {
  _hmt.push(['_trackEvent', type, action, name]);
}