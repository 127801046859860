import Axios from '@/request/request'
import GameAxios from '@/request/gameRequest'
import qs from 'qs'
import type {
  consumeShardListType,
  nftGameDetailType,
  consumeType,
  nftHistorType
} from './type'


export const consumeShardListApi: consumeShardListType = (params) => {
  return GameAxios.get(`/nftGame/${params.gameId}/consume/shardList?${qs.stringify(params)}`)
}

export const nftGameDetailApi: nftGameDetailType = (params) => {
  return GameAxios.get(`/nftGame/${params.gameId}/detail?${qs.stringify(params)}`)
}

export const consumeApi: consumeType = (params, headers) => {
  return GameAxios({
    method: 'post',
    url: `/nftGame/${params.gameId}/consume`,
    data: params,
    headers
  })
}

export const nftHistoryApi: nftHistorType = (params) => {
  return Axios.get(`/nft/xuper/history/${params.assetId}?${qs.stringify(params)}`)
}