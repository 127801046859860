import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll'
import appState from '@/store/appState'

import store from './reducer'

import Header from "../../../component/header/header";
import './AlchemyInstitute.scss';
import moment from 'moment';

const AlchemyInstitute: React.FC = () => {
  const navigate = useNavigate()
  const { redeemGoldHistory, totalRedeemAmount, redeemGoldHistoryHasMore, getRedeemGoldHistory } = store
  const { commonData } = appState


  useEffect(() => {
    getRedeemGoldHistory({ type: 'all' })
  }, [])

  return (
    <div id="AlchemyInstitute">
      <Header text='炼金所' fontColor="white" menu={<span onClick={() => navigate('/indexPoster')}>玩法介绍</span>} />
      <div className="AlchemyInstitute-top">
        <div className="AlchemyInstitute-top-left">
          <p className="AlchemyInstitute-top-left-p">1克黄金&nbsp;&nbsp;(含鉴定证书)</p>
          <h3 className="AlchemyInstitute-top-left-h3">价值约{commonData?.gold_unit_price || 0}元</h3>
        </div>
        <div className="AlchemyInstitute-top-gold">
          <div className="AlchemyInstitute-top-gold-left">
            <p className="AlchemyInstitute-top-gold-left-p">提炼一克实物黄金需销毁灵金</p>
            <div className="AlchemyInstitute-top-gold-left-img">
              <img src={require('../../../assets/imgs/AlchemyInstitute-gold.svg').default} />
              <h3 className="AlchemyInstitute-top-gold-left-img-h3">{commonData?.gold_unit_price || 0}</h3>
            </div>
          </div>
          <div className="AlchemyInstitute-top-gold-refining" onClick={() => navigate('/AlchemyInstitute/Active')}>提炼黄金</div>
        </div>
      </div>
      <div className="AlchemyInstitute-center">
        <div className="AlchemyInstitute-center-title">
          <h3 className="AlchemyInstitute-center-title-h3">炼金大厅</h3>
          <p className="AlchemyInstitute-center-title-p">已累计提炼黄金&nbsp;{totalRedeemAmount || '0'} 克</p>
        </div>
        <div className="AlchemyInstitute-center-all">
          {redeemGoldHistory.map((x, i) => (
            <div className="AlchemyInstitute-center-box" key={i}>
              <div className="AlchemyInstitute-center-box-left">
                <h4 cl-h4assName="AlchemyInstitute-center-box-left-h4">{x.userNickname}</h4>
                <p className="AlchemyInstitute-center-box-left-p">{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
              </div>
              <div className="AlchemyInstitute-center-box-center">
                <img src={require('../../../assets/imgs/AlchemyInstitute-gold.svg').default} />
                <div className="AlchemyInstitute-center-box-center-div">&nbsp;{x.bonus}</div>
              </div>
              <div className="AlchemyInstitute-center-box-right">提炼{x.shopItem?.amount}克</div>
            </div>
          ))}
          <InfiniteScrollComponent
            hasMore={redeemGoldHistoryHasMore}
            dataSource={redeemGoldHistory}
            loadMore={async () => {
              await getRedeemGoldHistory({ skip: redeemGoldHistory.length, type: 'all' })
            }}
            top={100}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(AlchemyInstitute);