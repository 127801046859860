import Header from '@/component/header/header';
import moment from 'moment';
import { useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { queryLuckyDrawHistory } from './serve';
import styles from './WinningRecord.module.scss';

const WinningRecord: React.FC = () => {
  const [listStatus, setListStatus] = useState<'loading' | 'loaded'>('loading');
  const paginatorRef = useRef({
    skip: 0,
    hasNextPage: true,
  });

  const {
    state: { gameId },
  } = useLocation();

  const [list, setList] = useState<any>([]);

  const query = async () => {
    const { skip } = paginatorRef.current;
    setListStatus('loading');
    const res = await queryLuckyDrawHistory({ skip: String(skip), limit: 10000, gameId });
    setListStatus('loaded');
    if (res.data.code !== 0 || !res.data.data) return;
    if (res.data.data?.length < 10) {
      paginatorRef.current.hasNextPage = false;
    }
    setList([...list, ...res.data.data]);
  };

  useEffect(() => {
    query();
  }, []);

  return (
    <div id={styles['WinningRecord']}>
      <Header text='中奖记录' fontColor='black' />
      <div className={styles['WinningRecord-title']}>
        <div className={styles['WinningRecord-title-box']}>
          <p>·如果您抽奖获得了实物奖励，平台核实后将主动与您注册时使用的手机号码进行联系并发放奖品。</p>
          <p>·如果您抽奖获得了数字藏品奖励，平台核实后将在 3 个工作日内空投藏品至您的账户内。</p>
        </div>
      </div>
      <div className={styles['WinningRecord-center']}>
        {list.length > 0 ? (
          list.map((item: any, index: number) => (
            <div className={styles['WinningRecord-center-box']} key={`WinningRecord-center-box-${index}`}>
              <div className={styles['WinningRecord-center-box-left']}>{item.title}</div>
              <div>{moment(item.time).format('YYYY.MM.DD HH:mm')}</div>
            </div>
          ))
        ) : (
          <div className='noData'>
            <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} alt='' />
            <span className='noData-text'>暂无数据</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinningRecord;
