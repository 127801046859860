import Axios from '@/request/gameRequest'
import qs from 'qs'
import type {
  nftGameDetailType,
  tradeLeaderboardType,
} from './type'

export const nftGameDetailApi: nftGameDetailType = (params) => {
  return Axios.get(`/nftGame/${params.gameId}/detail?${qs.stringify(params)}`)
}

export const tradeLeaderboardApi: tradeLeaderboardType = (params) => {
  return Axios.get(`/nftGame/${params.gameId}/tradeLeaderboard?${qs.stringify(params)}`)
}