import Axios from '../../request/request';
import qs from 'qs'
import type { PropsType } from '../../global'

type MiningStore = PropsType['MiningStore']

// 响应接口
type ResponseType = Promise<{
  data: {
    /* */
    code: number;

    /* */
    message: string;

    /* */
    data: Record<string, unknown> | unknown | any;
  }
}>


export const pledgeCardsApi = (): ResponseType => {
  return Axios.get(`/nft/pledgeCards`)
}

export const mineDataApi = (type: 'GOLD' | 'SILVER'): ResponseType => {
  return Axios.get(`/mine/mineData?type=${type}`)
}

export const groupMineDataApi = (): ResponseType => {
  return Axios.get(`/mine/groupMineData`)
}

export const mineListApi = (params: { state: 'START' | 'END', skip?: number, type: 'GOLD' | 'SILVER' | "BRONZE" }): ResponseType => {
  return Axios.get(`/mine/mineList?${qs.stringify(params)}`)
}

export const openMineApi = (params: { mineId: string, ids: Array<string> }): ResponseType => {
  return Axios.post('/mine/openMine', params)
}

export const groupMineListApi = (params: { state: 'START' | 'END', skip?: number }): ResponseType => {
  return Axios.get(`/mine/groupMineList?${qs.stringify(params)}`)
}

export const scoreHistoryApi = (params: { type: 'INCOME' | 'EXPENDITURE' | 'TRANSFER', skip?: number }): ResponseType => {
  return Axios.get(`/score/history?${qs.stringify(params)}`)
}

export const subscribeListsApi = (): ResponseType => {
  return Axios.get(`/activity/subscribe/lists`)
}

export const subscribeTimeAxisApi = (assetId: string): ResponseType => {
  return Axios.get(`/activity/subscribe/timeAxis?assetId=${assetId}`)
}

export const subscribeLuckNumbersApi = (assetId: string): ResponseType => {
  return Axios.get(`/activity/subscribe/luckNumbers?assetId=${assetId}`)
}

export const scoreTransferApi: MiningStore['scoreTransfer'] = (params, headers) => {
  return Axios({
    url: '/score/transfer',
    method: 'post',
    data: params,
    headers
  })
}

export const scorePreCheckNameApi = (toUid: number): ResponseType => {
  return Axios.post('/score/preCheckName', { toUid })
}

export const activitySubscribeInputApi: MiningStore['activitySubscribeInput'] = (params) => {
  return Axios.post('/activity/subscribe/input', params)
}

export const activitySubscribeLuckNumbersApi = (assetId: string) => {
  return Axios.get(`/activity/subscribe/luckNumbers?assetId=${assetId}`)
}

export const subscribeHistoryApi = () => {
  return Axios.get('/activity/subscribe/history')
}