import styles from './pointsMall.module.scss';
import { useEffect, useState } from 'react';
import { CenterPopup, InfiniteScroll, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react'
import primaryMarket from '@/store/primaryMarket';
import appState from '@/store/appState';
import moment from 'moment';

const PointsMall: React.FC = () => {
  const [bonusType, setBonusType] = useState<'yuanshi' | 'lingjin' | 'miyao'>('yuanshi');
  const [type2, setType2] = useState('0');
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const navigate = useNavigate()
  const [cardInfo, setCardInfo] = useState<any>({})
  const { commonData } = appState


  const init = () => {
    primaryMarket.getBonusShopItemList({
      bonusType,
      itemType: 'nft_box'
    })
  }

  useEffect(() => {
    init()
  }, [bonusType])


  const dateHandle = (x: any) => {
    const currentTime = moment().valueOf()
    const beginTime = moment(x.beginTime).valueOf()
    const endTime = moment(x.endTime).valueOf()
    if (currentTime < beginTime) {
      return moment(beginTime).format('MM.DD HH:mm') + ' 开始'
    }
    if (currentTime > beginTime && currentTime < endTime) {
      return moment(endTime).format('MM.DD HH:mm') + ' 结束'
    }
    if (currentTime > endTime) {
      return '已结束'
    }
  }


  /**
  * 加减购买数量
  * 
  */
  const [shopNum, setShopNum] = useState(0)
  const shopNumHandler = (type: 'add' | 'cut') => {
    if (type === 'add') {
      if (shopNum < cardInfo?.userAvailableStock) {
        setShopNum(shopNum + 1)
      }
    }
    if (type === 'cut') {
      if (shopNum > 1) {
        setShopNum(shopNum - 1)
      }
    }
  }


  /**
   * 手动改变购买数量
   */
  const onChangeHandle = (v: string) => {
    if (isNaN(Number(v))) return
    if (v > cardInfo?.userAvailableStock) {
      setShopNum(Number(cardInfo?.userAvailableStock))
    } else {
      setShopNum(Number(v || 0))
    }
  }


  const conversion = () => {
    primaryMarket.bonusUserRedeem({
      shopItemId: cardInfo?.id,
      amount: shopNum
    }).then(({ data }) => {
      if (data.code === 0) {
        setVisible2(false)
        appState.getMenuData()
        init()
        setTimeout(() => {
          Toast.show({ icon: 'success', content: '兑换成功' })
        }, 500);
      }
    })
  }

  return (
    <div id={styles['PointsMall']}>
      <div className={styles['PointsMall-title']}>
        <div className={styles['PointsMall-title-nav']}>
          <img src={require('../../../assets/imgs/PointsMall-left.svg').default} alt="" onClick={() => navigate(-1)} />
          <div className={styles['PointsMall-title-nav-name']}>WYPER&nbsp;商城</div>
          <div onClick={() => navigate('/game/ExchangeRecord')}>兑换记录</div>
        </div>
        <div className={styles['PointsMall-title-numBox']}>
          <div className={styles['PointsMall-title-numBox-box']}>
            <div className={styles['PointsMall-title-numBox-box-small']}>
              <h3 className={styles['PointsMall-title-numBox-box-small-h3']}>{commonData?.real_score}</h3>
              <p className={styles['PointsMall-title-numBox-box-small-p']}>可用原石</p>
            </div>
            <div className={styles['PointsMall-title-numBox-box-small']}>
              <h3 className={styles['PointsMall-title-numBox-box-small-h3']}>00.00</h3>
              <p className={styles['PointsMall-title-numBox-box-small-p']}>可用灵金</p>
            </div>
            <div className={styles['PointsMall-title-numBox-box-small']}>
              <h3 className={styles['PointsMall-title-numBox-box-small-h3']}>00.00</h3>
              <p className={styles['PointsMall-title-numBox-box-small-p']}>可用秘钥</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['PointsMall-navBox']}>
        <div className={`${styles['PointsMall-navBox-box']} ${bonusType === 'yuanshi' ? styles['action'] : ''}`} onClick={() => setBonusType('yuanshi')}>原石专区</div>
        <div className={`${styles['PointsMall-navBox-box']} ${bonusType === 'lingjin' ? styles['action'] : ''}`} onClick={() => setBonusType('lingjin')}>灵金专区</div>
        <div className={`${styles['PointsMall-navBox-box']} ${bonusType === 'miyao' ? styles['action'] : ''}`} onClick={() => setBonusType('miyao')}>秘钥专区</div>
      </div>
      {/* <div className={styles['PointsMall-quantityBox']}>
        <div className={`${styles['PointsMall-quantityBox-quantity']} ${type2 === '0' ? styles['black'] : ''}`} onClick={() => setType2('0')}>全部</div>
        <div className={`${styles['PointsMall-quantityBox-quantity']} ${type2 === '1' ? styles['black'] : ''}`} onClick={() => setType2('1')}>0-100</div>
        <div className={`${styles['PointsMall-quantityBox-quantity']} ${type2 === '2' ? styles['black'] : ''}`} onClick={() => setType2('2')}>100-1000</div>
        <div className={`${styles['PointsMall-quantityBox-quantity']} ${type2 === '3' ? styles['black'] : ''}`} onClick={() => setType2('3')}>1000-10000</div>
        <div className={`${styles['PointsMall-quantityBox-quantity']} ${type2 === '4' ? styles['black'] : ''}`} onClick={() => setType2('4')}>10000-100000</div>
      </div> */}

      <div className={styles['PointsMall-center']}>
        {primaryMarket.bonusShopItemList.length > 0 ? primaryMarket.bonusShopItemList.map((x, i) => (
          <div className={styles['PointsMall-center-box']} onClick={() => {
            setVisible(true);
            setCardInfo(x);
            setShopNum(x.userAvailableStock)
          }} key={i}>
            <div className={styles['PointsMall-center-box-card']}>
              <div className={styles['PointsMall-center-box-card-top']}>
                <div className={styles['PointsMall-center-box-card-top-text']}>{dateHandle(x)}</div>
                <img src={x.thumbList && x.thumbList[x.thumbList.length - 1]} alt="" />
              </div>
              <h3 className={styles['PointsMall-center-box-card-h3']}>{x.title}</h3>
              <div className={styles['PointsMall-center-box-card-bottom']}>
                <h3 className={styles['PointsMall-center-box-card-bottom-h3']}>{x.unitPrice}原石</h3>
                {/* <div>剩余&nbsp;{x.stock}&nbsp;份</div> */}
              </div>
            </div>
          </div>
        )) : null}
      </div>

      <InfiniteScroll
        hasMore={primaryMarket.bonusShopItemListHasMore}
        children={(hasMore) => {
          if (!hasMore && primaryMarket.bonusShopItemList.length === 0) {
            return (
              <div className='noData' style={{ marginTop: 100 }}>
                <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
          } else {
            return '没有更多了'
          }
        }}
        loadMore={async () => {
          await primaryMarket.getBonusShopItemList({
            bonusType,
            itemType: 'nft_box',
            skip: primaryMarket.bonusShopItemList.length
          })
        }}
      />

      <CenterPopup
        className={styles['CenterPopup']}
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
      >
        <div className={styles['PointsMall-CenterPopup']}>
          <div className={styles['PointsMall-CenterPopup-box']}>
            <h3 className={styles['PointsMall-CenterPopup-box-h3']}>兑换奖励</h3>
            <img className={styles['PointsMall-CenterPopup-box-img']} src={require('../../../assets/imgs/PointsMall-line.svg').default} alt="" />
          </div>
          <div className={styles['PointsMall-CenterPopup-center']}>
            <img style={{ width: '100%' }} src={cardInfo.thumbList && cardInfo.thumbList[cardInfo.thumbList.length - 1]} alt="" />
            <h3 className={styles['PointsMall-CenterPopup-center-h3']}>{cardInfo?.title}</h3>
          </div>
          <div className={styles['shopNum-box']}>
            <span className={styles['shopNum-box-text']}>兑换数量</span>
            <div className={styles['shopNum-box-handle']} onClick={() => shopNumHandler('cut')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.5 7.3501H12.5V8.6501H3.5V7.3501Z" fill="#4D33EF" />
              </svg>
            </div>
            <input className={styles['shopNum-box-value']} type='tel' value={shopNum} onChange={e => onChangeHandle(e.target.value)} />
            <div className={styles['shopNum-box-handle']} onClick={() => shopNumHandler('add')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M7.34998 8.64998V12.5H8.64998V8.64998H12.5V7.34998H8.64998V3.5H7.34998V7.34998H3.5V8.64998H7.34998Z" fill="#4D33EF" />
              </svg>
            </div>
            <div className={styles['shopNum-box-max']} onClick={() => setShopNum(cardInfo?.userAvailableStock || 0)}>MAX</div>
          </div>
          <div className={styles['PointsMall-CenterPopup-btnBox']} onClick={() => {
            if (cardInfo?.userAvailableStock > 0) {
              setVisible2(true);
              setVisible(false)
            }
          }}>{cardInfo?.userAvailableStock > 0 ? `使用${cardInfo?.unitPrice * shopNum}原石兑换` : '暂无兑换资格'}</div>
        </div>
      </CenterPopup>

      <CenterPopup
        getContainer={null}
        visible={visible2}
        onMaskClick={() => {
          setVisible2(false)
        }}
      >
        <div className={styles['Pledge-CenterPopup-box']}>
          <h3 className={styles['Pledge-CenterPopup-box-h3']}>确认兑换</h3>
          <p className={styles['Pledge-CenterPopup-box-p']}>确认使用 {cardInfo?.unitPrice * shopNum} 原石兑换《{cardInfo?.title}》*{shopNum}</p>
          <div className={styles['Pledge-CenterPopup-box-btn']}>
            <div className={styles['Pledge-CenterPopup-box-btn-left']} onClick={() => setVisible2(false)}>取消</div>
            <div className={styles['Pledge-CenterPopup-box-btn-right']} onClick={conversion}>确定</div>
          </div>
        </div>
      </CenterPopup>
    </div>
  )
}

export default observer(PointsMall);