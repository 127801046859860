import { useEffect, useState } from 'react';
import { observer } from 'mobx-react'
import { RightOutline, } from 'antd-mobile-icons';
import { Space, Steps, Popup, Checkbox, Toast } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import miningStore from "@/store/miningStore";
import primaryMarket from '@/store/primaryMarket';
import appState from '@/store/appState';
import moment from 'moment';
import { Clock } from 'grommet';
import copy from 'copy-to-clipboard';

import Header from "../../../component/header/header";
import './subscribe.scss';


const Subscribe: React.FC = () => {
  const [type, setType] = useState('0');
  const { Step } = Steps;
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const location = useLocation()
  const { subscribeTimeAxisInfo } = miningStore
  const [codeVisible, setCodeVisible] = useState(false)
  const [buyAmount, setBuyAmount] = useState('')
  const assetId = new URLSearchParams(location.search).get('assetId')
  const { collectionDetailInfo } = primaryMarket
  const { commonData } = appState


  const init = () => {
    if (assetId) {
      miningStore.subscribeTimeAxisApi(assetId).then((data) => {
        if (data.code === 0) {
          if (data.data.product === 'asset') {
            primaryMarket.getCollectionDetail(assetId).then(({ data }) => {
              const long_desc_div = document.querySelector('.long_desc') as Element
              const author_desc_div = document.querySelector('.author_desc') as Element
              const remark_div = document.querySelector('.remark') as Element
              long_desc_div.innerHTML = data.long_desc
              author_desc_div.innerHTML = data.author_desc
              remark_div.innerHTML = data.remark
            })
          }
        }
      })
    }
  }


  useEffect(() => {
    init()
  }, [])


  const stateHandle = () => {
    const div = document.querySelectorAll('.adm-step-icon-container')
    const { state } = subscribeTimeAxisInfo
    if (state === 'SHOW') {
      div[0].style = 'background: #000; color: #fff'
      div[1].style = 'background: #F5F5F5; color: #000'
      div[2].style = 'background: #F5F5F5; color: #000'
      div[3].style = 'background: #F5F5F5; color: #000'
      return
    }
    if (state === 'INPUT') {
      div[0].style = 'background: #F5F5F5; color: #000'
      div[1].style = 'background: #000; color: #fff'
      div[2].style = 'background: #F5F5F5; color: #000'
      div[3].style = 'background: #F5F5F5; color: #000'
      return
    }
    if (state === 'CAL') {
      div[0].style = 'background: #F5F5F5; color: #000'
      div[1].style = 'background: #F5F5F5; color: #000'
      div[2].style = 'background: #000; color: #fff'
      div[3].style = 'background: #F5F5F5; color: #000'
      return
    }
    if (state === 'GRANT') {
      div[0].style = 'background: #F5F5F5; color: #000'
      div[1].style = 'background: #F5F5F5; color: #000'
      div[2].style = 'background: #F5F5F5; color: #000'
      div[3].style = 'background: #000; color: #fff'
      return
    }
  }

  useEffect(() => {
    if (subscribeTimeAxisInfo.state === 'CAL' || subscribeTimeAxisInfo.state === 'GRANT') {
      if (assetId) {
        miningStore.activitySubscribeLuckNumbers(assetId)
      }
    }
    stateHandle()
  }, [subscribeTimeAxisInfo.state])



  const [countdown, setCountdown] = useState<any>('00:00')
  let interval: NodeJS.Timer
  useEffect(() => {
    clearInterval(interval)
    interval = setInterval(() => {
      const currentTime = moment().valueOf()
      const calTime = moment(subscribeTimeAxisInfo.calTime).valueOf()
      const grantTime = moment(subscribeTimeAxisInfo.grantTime).valueOf()
      if (currentTime > calTime && currentTime < grantTime) {
        if (subscribeTimeAxisInfo.state !== 'CAL') {
          init()
        } else {
          // 计算两个时间之间的差值，以秒为单位
          let timeRemaining = Math.floor((grantTime - currentTime) / 1000);
          timeRemaining = timeRemaining - 1
          if (timeRemaining < 0) {
            return
          }
          if (timeRemaining > 3600) {
            const days = Math.floor(timeRemaining / (3600 * 24));
            const hours = Math.floor((timeRemaining % (3600 * 24)) / 3600);
            return setCountdown(`${days} 天零 ${hours} 小时`)
          }
          const minutes = Math.floor(timeRemaining / 60);
          const seconds = timeRemaining % 60;

          setCountdown(
            <Clock
              type="digital"
              precision="minutes"
              style={{fontSize: 12}}
              time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
            />
          )
        }
      }
      if (currentTime > grantTime) {
        if (subscribeTimeAxisInfo.state !== 'GRANT') {
          init()
        } else {
          clearInterval(interval)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [subscribeTimeAxisInfo.state])





  const btnHandle = () => {
    const { state, luckResult, luckyCount } = subscribeTimeAxisInfo

    if (state === 'SHOW' || state === 'INPUT') {
      if (visible) {
        return (
          <div className="nftDetail-btn" onClick={activitySubscribeInputHandle}>确认投入</div>
        )
      }
      return (
        <div className="nftDetail-btn" onClick={() => { setVisible(true) }}>投入原石，参与申购</div>
      )
    }
    if (state === 'CAL') {
      if (miningStore.activitySubscribeLuckNumbersList.length > 0) {
        return (
          <div className="nftDetail-btn2" onClick={() => setCodeVisible(true)}>
            <div className='nftDetail-btn2-text'>抽签码 {miningStore.activitySubscribeLuckNumbersList.length} | 距抽签&nbsp;
              {countdown}
            </div>
          </div>
        )
      } else {
        return (
          <div className="nftDetail-btn2">
            <img className="nftDetail-btn-img" src={require('../../../assets/imgs/nftDetail-machine.svg').default} />
            <div>抽签中...</div>
          </div>
        )
      }
    }
    if (state === 'GRANT') {
      if (luckResult === 'UN_LUCK') {
        return (
          <div className="nftDetail-btn4">
            <img className="nftDetail-btn-emoj" src={require('../../../assets/imgs/nftDetail-emoj.svg').default} />
            <div>很遗憾，未中签</div>
          </div>
        )
      }
      if (luckResult === 'LUCK') {
        return (
          <div className="nftDetail-btn3" onClick={() => {
            if (codeVisible) {
              navigate('/myAssets')
            } else {
              setCodeVisible(true)
            }
          }}>
            <p>
              <span className="nftDetail-btn3-span">已中签{luckyCount}</span>
              <span>查看藏品</span>
            </p>
          </div>
        )
      }
    }
  }


  const activitySubscribeInputHandle = () => {
    if (assetId) {
      miningStore.activitySubscribeInput({
        assetId,
        buyAmount: Number(buyAmount)
      }).then(({ data }) => {
        if (data.code === 0) {
          setVisible(false)
          init()
          appState.getMenuData()
          setTimeout(() => {
            Toast.show({ icon: 'success', content: '投入成功' })
          }, 100);
        }
      })
    }
  }


  return (
    <div id="subscribe">
      <Header text={subscribeTimeAxisInfo.product === 'asset' ? "藏品申购" : '盲盒申购'} />
      <img className="nftDetail-img" src={subscribeTimeAxisInfo.product === 'asset' ? collectionDetailInfo?.thumb && collectionDetailInfo?.thumb[collectionDetailInfo?.thumb?.length - 1] : require('@/assets/imgs/WechatIMG1728.jpeg')} />
      <div className="nftDetail-box">
        <div className='nftDetail-select' style={{ width: '100vw' }}>
          <div className='nftDetail-select-box'>
            <div className="nftDetail-select-box-top">
              <div className={`nftDetail-select-box-item ${type === '0' ? 'active' : ''}`} onClick={() => setType('0')}>时间线</div>
              {subscribeTimeAxisInfo.product === 'asset' && <div className={`nftDetail-select-box-item ${type === '1' ? 'active' : ''}`} onClick={() => setType('1')}>藏品详情</div>}
            </div>
            {/* <div className={`nftDetail-tutorial ${type === '0' ? '' : 'noneClass'}`}>申购教程
              <Space>
                <RightOutline fontSize={12} className="nftDetail-icon" />
              </Space>
            </div> */}
          </div>
        </div>
        <div className={`nftDetail-timeline ${type === '0' ? '' : 'noneClass'}`}>
          <div className="nftDetail-cneter">
            <div>
              <p className="nftDetail-cneter-p">最大可申购签数</p>
              <div className="nftDetail-cneter-num">
                <h3>{subscribeTimeAxisInfo?.canInputAmount || '--'}</h3>
              </div>
            </div>
            <div>
              <p className="nftDetail-cneter-p">已申购签数</p>
              <div className="nftDetail-cneter-num">
                <h3>{subscribeTimeAxisInfo?.amount || '--'}</h3>
              </div>
            </div>
          </div>
          <div className="nftDetail-steps">
            <Steps
              current={1}
              direction={'vertical'}
            >
              <Step title='公示阶段' description={moment(subscribeTimeAxisInfo?.showTime).format('YYYY-MM-DD HH:mm')}
                icon={'✓'}
              />
              <Step title='投入阶段' description={moment(subscribeTimeAxisInfo?.inputTime).format('YYYY-MM-DD HH:mm')} icon={'2'} />
              <div className="nftDetail-steps-text">投入原石申购，未成功申购的部分将返还至您的账户</div>
              <Step title='统计阶段' description={moment(subscribeTimeAxisInfo?.calTime).format('YYYY-MM-DD HH:mm')} icon={'3'} />
              <div className="nftDetail-steps-text2">系统计算中，请耐心等待</div>
              <Step title='藏品发放' description={moment(subscribeTimeAxisInfo?.grantTime).format('YYYY-MM-DD HH:mm')} icon={'4'} />
            </Steps>
            <div className="nftDetail-steps-text3">系统计算已结束，我们将根据您所获得的藏品数量扣除相应原石，成功申购的藏品将发放至您的藏品仓库中，剩余原石将返还至您的账户</div>
          </div>
          <div className="nftDetail-foot">
            <div className='nftDetail-foot-item'>
              <span className='nftDetail-foot-item-text1'>成功申购藏品</span>
              <span className='nftDetail-foot-item-text2'>{subscribeTimeAxisInfo?.luckyCount}</span>
            </div>
            <div className='nftDetail-foot-item'>
              <span className='nftDetail-foot-item-text1'>消耗原石</span>
              <span className='nftDetail-foot-item-text2'>{subscribeTimeAxisInfo?.consumeScore}</span>
            </div>
            <div className='nftDetail-foot-item'>
              <span className='nftDetail-foot-item-text1'>返还原石</span>
              <span className='nftDetail-foot-item-text2'>{subscribeTimeAxisInfo?.returnScore}</span>
            </div>
          </div>
        </div>
        <div className={`main ${type === '1' ? '' : 'noneClass'}`}>
          <div className='box1'>
            <img className='box1-img' src={require('@/assets/imgs/nftDetail-small.svg').default} />
            <span className='box1-text'>该作品拥有 Wyper 官方认证</span>
          </div>
          <div className='box2'>
            <span className='box2-text'>{collectionDetailInfo.title}#{collectionDetailInfo.shard_id}</span>
            {collectionDetailInfo.grade && <img className='MyAssets-box2-item-grade' width={32} height={32} src={require(`@/assets/imgs/${collectionDetailInfo.grade}.svg`)} alt="" />}
          </div>
          <div className='box3'>
            <div className='box3-item'>
              <span className='box3-item-text1'>发行量</span>
              <span className='box3-item-text2'>{collectionDetailInfo.publish_amount ?? '--'}份</span>
            </div>
            <div className='box3-item'>
              <span className='box3-item-text1'>流通量</span>
              <span className='box3-item-text2'>{collectionDetailInfo.circulate_amount ?? '--'}份</span>
            </div>
          </div>
          <div className='box4'>
            <span className='box4-title'>链上信息</span>
            <div className='box4-data' onClick={() => { copy(collectionDetailInfo.asset_address); Toast.show({ icon: 'success', content: '复制成功' }) }}>
              <span className='box4-data-text1'>藏品地址：</span>
              <span className='box4-data-text2'>{collectionDetailInfo.asset_address}</span>
            </div>
            <div className='box4-data' onClick={() => { copy(collectionDetailInfo.tx_id); Toast.show({ icon: 'success', content: '复制成功' }) }}>
              <span className='box4-data-text1'>交易哈希：</span>
              <span className='box4-data-text2'>{collectionDetailInfo.tx_id}</span>
            </div>
            <div className='box4-data' onClick={() => { copy(collectionDetailInfo.asset_id); Toast.show({ icon: 'success', content: '复制成功' }) }}>
              <span className='box4-data-text1'>藏品编号：</span>
              <span className='box4-data-text2'>{collectionDetailInfo.asset_id}</span>
            </div>
            <div className='box4-data'>
              <span className='box4-data-text1'>转入时间：</span>
              <span className='box4-data-text2'>{moment(collectionDetailInfo.granted_time).format('YYYY-MM-DD HH:mm')}</span>
            </div>
            <div className='box4-data'>
              <span className='box4-data-text1'>链上记录：</span>
              <img className='box4-data-img' onClick={() => window.location.href = `https://xuper.baidu.com/n/scan#/goods/${collectionDetailInfo.asset_id}`} src={require('@/assets/imgs/baidu.png')} />
            </div>
          </div>
          <div className="box7">
            {collectionDetailInfo.author_desc && <h2 className="title">艺术家介绍</h2>}
            <p className="desc author_desc" style={collectionDetailInfo.author_desc ? {} : { display: 'none' }}></p>
            {collectionDetailInfo.long_desc && <h2 className="title">作品描述</h2>}
            <p className="desc long_desc" style={collectionDetailInfo.long_desc ? {} : { display: 'none' }}></p>
            {collectionDetailInfo.remark && <h2 className="title">作品权益</h2>}
            <p className="desc remark" style={collectionDetailInfo.remark ? {} : { display: 'none' }}></p>
            <h2 className="title">交易须知</h2>
            <p className="desc">
              <span>1.所有作品信息由创作者上传，购买前须仔细甄别并自行判断，平台不为创作者的承诺做担保，如果创作者末兑现其承诺，一切与平台无关。</span>
              <span>2.除“数字许可作品”以外，用户末经授权不得将持有的数字作品用于任何商业用途。</span>
              <span>3.平台坚决反对数字作品炒作，禁止用户进行任何形式的数字作品炒作及私下交易数字作品。</span>
              <span>4.数字作品一经交易不支持退换，不支持7天无理由退款，请谨慎购买。</span>
              <span>5.如因发布数字作品的用户或者作品原创作者侵权或发布危害国家利益、违反社会道德与公序良俗以及其他负面言论或行为或作品存在权利瑕疵或违法违规等原因</span>
            </p>
          </div>
        </div>
      </div>


      <div className="nftDetail-btn-box">
        {btnHandle()}
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '22px',
          borderTopRightRadius: '22px',
          minHeight: '25vh',
        }}
        getContainer={null}
      >
        <div className="nftDetail-notification">
          <h3 className="nftDetail-notification-h3">投入原石,参与申购</h3>
          <p className="nftDetail-notification-p">输入要申购的签数 ,未中签将返还</p>
          <div className="nftDetail-notification-inpbox">
            <input
              className="nftDetail-notification-inpbox-inp"
              type="number"
              placeholder="输入申购签数"
              onChange={(e) => {
                const maxBuyNum = (Number(subscribeTimeAxisInfo.canInputAmount * subscribeTimeAxisInfo.price) - Number(commonData.real_score)) > 0 ? Math.floor(Number(commonData.real_score) / subscribeTimeAxisInfo.price) : subscribeTimeAxisInfo.canInputAmount
                if (Number(e.target.value) > maxBuyNum) {
                  return setBuyAmount(maxBuyNum)
                }
                setBuyAmount(e.target.value)
              }}
              value={buyAmount}
            />
            <div className="nftDetail-notification-big" onClick={() => {
              const maxBuyNum = (Number(subscribeTimeAxisInfo.canInputAmount * subscribeTimeAxisInfo.price) - Number(commonData.real_score)) > 0 ? Math.floor(Number(commonData.real_score) / subscribeTimeAxisInfo.price) : subscribeTimeAxisInfo.canInputAmount
              setBuyAmount(maxBuyNum)
            }}>最大</div>
          </div>
          <div className='nftDetail-notification-text'>
            <span>预计投入原石：</span>
            <img width={20} height={20} src={require('@/assets/imgs/stone.svg').default} alt="" />
            <span>{Number(buyAmount || 0) * subscribeTimeAxisInfo.price}</span>
          </div>
        </div>
      </Popup>

      <Popup
        visible={codeVisible}
        getContainer={null}
        onMaskClick={() => {
          setCodeVisible(false)
        }}
      >
        <div className='subscribe-codeVisible'>
          <img width={71} height={71} src={require('@/assets/imgs/card 1.png')} alt="" />
          <span className='subscribe-codeVisible-text'>登记成功</span>
          <span className='subscribe-codeVisible-text2' onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/randomRule.html', text: '抽签规则' } })}>当前抽签码数量 {miningStore.activitySubscribeLuckNumbersList.length}，查看抽签规则 {'>'} </span>
          <div className='subscribe-codeVisible-content'>
            {miningStore.activitySubscribeLuckNumbersList.map((x, i) => (
              <div className={subscribeTimeAxisInfo.state === 'GRANT' ? `subscribe-codeVisible-content-item ${x.luck === '未中签' ? 'no' : 'yes'}` : 'subscribe-codeVisible-content-item'} key={i}>
                <span className='subscribe-codeVisible-content-item-text1'>{subscribeTimeAxisInfo.state === 'GRANT' ? x.luck : '抽签码'}</span>
                <span className='subscribe-codeVisible-content-item-text2'>{x.number}</span>
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </div >
  )
}

export default observer(Subscribe);