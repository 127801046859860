import { InfiniteScroll } from 'antd-mobile'

interface InfiniteScrollComponentPropsType {
  hasMore: boolean,
  dataSource: any[],
  loadMore: (isRetry: boolean) => Promise<void>,
  top?: number
}

const InfiniteScrollComponent: React.FC<InfiniteScrollComponentPropsType> = (props) => {
  return (
    <InfiniteScroll
      hasMore={props.hasMore}
      children={(hasMore) => {
        if (!hasMore && props.dataSource.length === 0) {
          return (
            <div className='noData' style={props.top ? { top: props.top } : {}}>
              <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
              <span className='noData-text'>暂无数据</span>
            </div>
          )
        } else {
          return '没有更多了'
        }
      }}
      loadMore={props.loadMore}
    />
  )
}

export default InfiniteScrollComponent