import { observable, makeObservable, action } from 'mobx';
import appState from '@/store/appState';
import { queryPledgeDetail, submitPledge, queryPledgeHistory, computedPledgeIncome } from './serve';

export type ChangeType = 'INCOME' | 'EXPENDITURE';

class Pledge {
  constructor() {
    makeObservable(this);
  }
  @observable detail = {
    pledgeRealScore: 0,
    pledgeSecretKeyScore: 0,
    pledgeTotalLuckCount: 0,
    sumPledgeTotal: 0,
    todayLuckCount: 0,
    todaySecret: 0,
    gameId: '',
  };

  @observable listStatus: 'loading' | 'loaded' = 'loading';

  @observable changeType: ChangeType = 'INCOME';

  @observable paginator = {
    skip: 0,
    hasNextPage: true,
  };

  @observable list: any[] = [];

  @observable secretCount = 0;
  @observable globalSecretCount = 0;
  @observable luckCount = 0;

  @action queryPledgeDetail = async () => {
    const res = await queryPledgeDetail();
    const { data, code } = res?.data;
    if (code !== 0) return res;
    this.detail = data;
    return data;
  };

  @action queryPledgeHistory = async () => {
    const { skip } = this.paginator;
    this.listStatus = 'loading';
    const res = await queryPledgeHistory({ changeType: this.changeType, skip });
    this.listStatus = 'loaded';
    if (res.data.code !== 0 || !res.data.data) return;
    if (skip > 0) {
      this.list = [...this.list, ...res.data.data];
    } else {
      this.list = res.data.data;
    }

    this.paginator.hasNextPage = Boolean(res.data.data?.length > 0);
  };

  @action pageTurn = async () => {
    const { hasNextPage, skip } = this.paginator;
    if (!hasNextPage || this.listStatus === 'loading') return;
    this.paginator = { ...this.paginator, skip: skip + 10 };
    return await this.queryPledgeHistory();
  };

  @action onChangePledgeHistoryTab = (changeType: ChangeType) => {
    this.paginator = { skip: 0, hasNextPage: true };
    this.changeType = changeType;
    this.list = [];
    this.queryPledgeHistory();
  };

  @action submitPledge = async (params: any) => {
    const res = await submitPledge(params);
    const { code } = res?.data;
    if (code === 0) {
      this.queryPledgeDetail();
      this.onChangePledgeHistoryTab(this.changeType);
      this.computedglobalPledgeIncome();
      appState.getMenuData()
    }
    return res;
  };

  @action computedPledgeIncome = async (params: { count: number; weeks: number }) => {
    const res = await computedPledgeIncome(params);
    const { code } = res?.data;
    if (code === 0) {
      const { luckCount, secretCount } = res.data.data;
      this.luckCount = luckCount;
      this.secretCount = secretCount;
    }
    return res;
  };

  @action computedglobalPledgeIncome = async () => {
    const res = await computedPledgeIncome({ count: 100, weeks: 1 });
    const { code } = res?.data;
    if (code === 0) {
      const { secretCount } = res.data.data;
      this.globalSecretCount = secretCount;
    }
    return res;
  };
}

export default new Pledge();
