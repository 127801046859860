import { Key, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import secondaryMarket from '@/store/secondaryMarket';
import { Checkbox, InfiniteScroll, Popup, Toast } from 'antd-mobile';
import appState from '@/store/appState';
import { observer } from 'mobx-react'

enum Status {
  shelve = '寄售',
  lock = '锁定',
  self_lock = '我锁定的，立即支付',
  self_publish = '我寄售的'
}

interface SaleType {
  sort: 'sku.sid' | 'price'
}

const Sale: React.FC<SaleType> = ({ sort }) => {
  const [visible, setVisible] = useState(false)
  const { commonData } = appState
  const navigate = useNavigate();
  const location = useLocation()
  const { goodsSearcExtra } = secondaryMarket
  const [payChannel, setPayChannel] = useState<'sandpay' | 'huifupay' | null>(null)
  const [itemInfo, setItemInfo] = useState<any>({})
  const [batchVisible, setBatchVisibl] = useState(false)
  const [batchList, setBatchList] = useState<any[]>([])

  const buyHandle = (type?: 'batch') => {
    if (type === 'batch') {
      let goods_ids: string[] = []
      batchList.forEach(x => goods_ids.push(x.goods_id))
      if (goods_ids.length > 0) {
        secondaryMarket.ordersCreate({
          goods_ids: goods_ids,
          pay_channel: 'huifupay',
          type: 'bulk'
        })
      }
    } else {
      if (payChannel) {
        secondaryMarket.ordersCreate({
          goods_id: itemInfo?.goods_id,
          pay_channel: payChannel
        })
      } else {
        Toast.show('请选择支付方式')
      }
    }
  }


  const quickBuyHandle = () => {
    secondaryMarket.getGoodsSearhList({
      'sku.aid': location?.state?.aid,
      sort: 'price',
      limit: 100000
    }).then(({ data }) => {
      if (data.code === 0) {
        let item = data.data.list.filter((x: { state: string; }) => x.state === 'shelve').reduce((prev: { price: number; }, curr: { price: number; }) => prev.price < curr.price ? prev : curr)
        secondaryMarket.ordersCreate({
          goods_id: item?.goods_id,
          pay_channel: 'huifupay'
        })
      }
    })
  }

  return (
    <>
      <Checkbox.Group onChange={(e) => setBatchList(e)} value={batchList}>
        <div className={`RankingList-center-foot`}>
          {secondaryMarket.goodsSearcList.map((item, i) => (
            <div style={(batchVisible && (item.state === 'self_publish' || !(item.pay_channels.some((x: string) => x === 'huifupay')) || item.state === 'lock' || item.state === 'self_lock')) ? { display: 'none' } : { display: 'flex', justifyContent: 'space-between' }}>
              {batchVisible && <Checkbox style={{ marginRight: 12 }} value={item} />}
              <div className='RankingList-center-foot-box' style={{ flex: 1 }} key={i} onClick={() => {
                if (item.state === 'shelve') {
                  setVisible(true)
                  setItemInfo(item)
                }
                if (item.state === 'self_lock') {
                  navigate(`/order?title=secondaryMarket`)
                }
              }}>
                <div className='RankingList-center-foot-left'>
                  <div className='RankingList-center-foot-left-top'>
                    <div className='RankingList-center-foot-left-top-box'>
                      <h4>{item.title}</h4>
                      <div className={`RankingList-center-foot-left-top-div ${item.state}`}>{Status[item.state as 'shelve']}</div>
                      {item.pay_channels && item.pay_channels.map((x: string, i: Key | null | undefined) => (
                        <img src={x === 'sandpay' ? require('@/assets/imgs/RankingList-fill1.svg').default : require('@/assets/imgs/RankingList-fill2.svg').default} key={i} />
                      ))}
                    </div>
                    <p className='RankingList-center-foot-left-p'>#{item.sid}<span className='RankingList-center-foot-box-span'>/{secondaryMarket.goodsSearcExtra?.publish_amount}</span></p>
                  </div>
                </div>
                <div className='RankingList-center-foot-ritgh'>
                  <div className='RankingList-center-foot-ritgh-div'><span className='RankingList-center-foot-ritgh-span'>¥</span>&nbsp;{((item.price) / 100).toFixed(2)}</div>
                  <img className='RankingList-center-sort-right-img' src={require('@/assets/imgs/RankingList-fill.svg').default} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Checkbox.Group >

      <div style={{ position: 'relative' }}>
        <InfiniteScroll
          hasMore={secondaryMarket.goodsSearcListHasMore}
          children={(hasMore) => {
            if (!hasMore && secondaryMarket.goodsSearcList.length === 0) {
              return (
                <div className='noData' style={{ top: 78, paddingBottom: 50 }}>
                  <img width={'52%'} src={require('@/assets/imgs/noData-1.png')} />
                </div>
              )
            } else {
              return '没有更多了'
            }
          }}
          loadMore={async () => {
            await secondaryMarket.getGoodsSearhList({
              'sku.aid': location?.state?.aid,
              sort,
              skip: secondaryMarket.goodsSearcList.length
            })
          }}
        />
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
        getContainer={null}
      >
        <div className='Popup-top'>
          <div className='Popup-top-title'>
            <img className='Popup-top-img' src={goodsSearcExtra?.thumb && goodsSearcExtra?.thumb[goodsSearcExtra?.thumb?.length - 1]} alt="" />
            <div className='Popup-top-right'>
              <div className='Popup-top-right-name'>{goodsSearcExtra?.title}</div>
              <div className='Popup-top-right-two'>
                <img className='Popup-top-right-img' src={require('@/assets/imgs/onChainInformation-yes.svg').default} alt="" />
                <div className='Popup-top-right-number'>#{itemInfo?.sid}/{goodsSearcExtra?.publish_amount}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='Popup-top-center'>
          <h4 className='Popup-h4'> 支付方式</h4>
          <div className='Popup-wallet-sd'>
            <div className='Popup-wallet-sd-left'>
              <img className='Popup-wallet-sd-left-img' src={require('@/assets/imgs/onChainInformation-money1.svg').default} alt="" />
              <div className='Popup-wallet-sd-left-text'>{commonData?.wallet?.bind ? `杉德钱包 (余额 ${((commonData?.wallet.balance) / 100).toFixed(2)})` : '杉德钱包 (未开通)'}</div>
            </div>
            <div className='Popup-wallet-sd-left-checkbox'>
              {commonData?.wallet?.bind ?
                <Checkbox checked={payChannel === 'sandpay'} onChange={() => setPayChannel('sandpay')} />
                :
                (
                  <div className='Popup-wallet-hf-right' onClick={() => window.location.href = commonData?.wallet?.url}>
                    <div className='Popup-wallet-hf-right-add'>立即开通</div>
                    <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                  </div>
                )}
            </div>
          </div>

          <div className='Popup-wallet-hf'>
            <div className='Popup-wallet-hf-left'>
              <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-money2.svg').default} alt="" />
              <div className='Popup-wallet-hf-left-text'>{commonData?.wallet2?.bind ? `汇付钱包 (余额 ${((commonData?.wallet2.balance) / 100).toFixed(2)})` : '汇付钱包 (未开通)'}</div>
            </div>
            {commonData?.wallet2?.bind ?
              <Checkbox checked={payChannel === 'huifupay'} onChange={() => setPayChannel('huifupay')} />
              :
              (
                <div className='Popup-wallet-hf-right' onClick={() => window.location.href = commonData?.wallet2?.url}>
                  <div className='Popup-wallet-hf-right-add'>立即开通</div>
                  <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                </div>
              )}
          </div>
        </div>

        <div className='Popup-top-foot'>
          <h3 className='Popup-top-foot-h3'>购买须知</h3>
          <p className='Popup-top-foot-p'>注意：同一账号一段时间内连续锁单 3 次，将被限制购买</p>
        </div>

        <div className='Popup-top-btn-box' onClick={() => buyHandle()}>
          <div className='Popup-top-btn-box-left'>
            <span className='Popup-top-btn-box-left-text1'>合计</span>
            <span className='Popup-top-btn-box-left-text2'><span style={{ fontSize: 14 }}>¥</span> {(itemInfo?.price || 0) / 100}</span>
          </div>
          <div className='Popup-top-btn btn'>立即购买</div>
        </div>
      </Popup>

      {
        secondaryMarket.goodsSearcList.length > 0 &&
        (
          <div className='Jackpot-footer'>
            {!batchVisible && (
              <>
                <div className='Jackpot-footer-left btn' onClick={() => {
                  if (commonData?.wallet2?.bind) {
                    setBatchVisibl(true)
                  } else {
                    window.location.href = commonData?.wallet2?.url
                  }
                }}>批量购买</div>
                <div className='Jackpot-footer-right btn' onClick={quickBuyHandle}>快捷下单</div>
              </>
            )}
            {batchVisible && (
              <>
                <div className='Jackpot-footer-left btn' onClick={() => { setBatchVisibl(false); setBatchList([]) }}>取消</div>
                <div className='Jackpot-footer-right btn' onClick={() => buyHandle('batch')}>选择购买 {batchList.length} 个</div>
              </>
            )}
          </div>
        )
      }
    </>
  )
}

export default observer(Sale)