import { useEffect } from 'react'
import { observer } from 'mobx-react'
import store from '../../reducer'
import styles from '../active.module.scss'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll'
import { Toast } from 'antd-mobile'

const Record: React.FC = () => {
  const { redeemGoldHistory, totalRedeemAmount, redeemGoldHistoryHasMore, getRedeemGoldHistory } = store

  useEffect(() => {
    getRedeemGoldHistory()
  }, [])

  return (
    <div className={styles.Record}>
      <div className={styles['Record-title']}>
        <span className={styles['Record-title-text1']}>提炼记录</span>
        <span className={styles['Record-title-text2']}>已累计提炼黄金 {totalRedeemAmount || 0} 克</span>
      </div>
      <div className={styles['Record-list']}>
        {redeemGoldHistory.map((x, i) => (
          <div className={styles['Record-list-item']} key={i}>
            <div className={styles['Record-list-item-top']}>
              <span className={styles['Record-list-item-top-left']}>销毁 <img width={14} height={14} src={require('@/assets/imgs/icon_boring_ape_index_gold_sands~iphone 1.svg').default} alt="" /> {x.bonus}</span>
              <span className={styles['Record-list-item-top-right']}>提炼 {x.shopItem?.amount} 克</span>
            </div>
            <div className={styles['Record-list-item-content']}>
              <span className={styles['Record-list-item-content-left']}>销毁时间</span>
              <span className={styles['Record-list-item-content-right']}>{moment(x.time).format('YYYY.MM.DD HH:mm')}</span>
            </div>
            <div className={styles['Record-list-item-content']}>
              <span className={styles['Record-list-item-content-left']}>订单状态</span>
              <span className={styles['Record-list-item-content-right']} style={x.deliverFlag ? { color: '#4D33EF' } : {}}> <img src={x.deliverFlag ? require('@/assets/imgs/checkbox-circle-fill.svg').default : require('@/assets/imgs/time-fill.svg').default} alt="" /> &nbsp;{x.deliverFlag ? '已发货' : '未发货'}</span>
            </div>
            {x.expressOrderInfoList && x.expressOrderInfoList.map((y: any, d: any) => (
              <div className={styles['Record-list-item-content']} key={d}>
                <span className={styles['Record-list-item-content-left']}>{y.expressCompany}</span>
                <span className={styles['Record-list-item-content-right']} style={{ color: '#4D33EF' }} onClick={() => {
                  copy(y.expressNo)
                  Toast.show('复制成功')
                }}>{y.expressNo}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <InfiniteScrollComponent
        hasMore={redeemGoldHistoryHasMore}
        dataSource={redeemGoldHistory}
        loadMore={async () => {
          await getRedeemGoldHistory({ skip: redeemGoldHistory.length })
        }}
      />
    </div>
  )
}

export default observer(Record)