import { observable, action, makeObservable } from 'mobx'
import {
  goodsShelveApi,
  getGoodsListApi,
  getGoodsSearchListApi,
  ordersCreateApi,
  ordersPayApi,
  goodsMySalesApi,
  ordersSaleApi,
  goodsModifyApi,
  goodsUnshelveApi,
  ordersMyApi,
  ordersDetailApi,
  ordersCancelApi,
  ordersTransactionListApi,
  ordersBidOrderListApi,
  ordersMatchBidApi
} from './serve'
import type { PropsType } from '../../global'

type SecondaryMarketType = PropsType['SecondaryMarketType']


class SecondaryMarket {
  constructor() {
    makeObservable(this)
  }

  /**
     * 发布寄售
     * @param params 
     * @returns 
     */
  goodsShelve: SecondaryMarketType['goodsShelveApi'] = async (params, headers) => {
    const data = await goodsShelveApi(params, headers)
    return Promise.resolve(data)
  }





  /**
   * 获取二级市场商品列表
   * @param params 
   * @returns 
   */
  @observable goodsList: any[] = []
  getGoodsList: SecondaryMarketType['getGoodsListApi'] = async (params) => {
    const data = await getGoodsListApi(params)
    if (data.data.code === 0) {
      this.goodsList = data.data.data
    }
    return Promise.resolve(data)
  }






  /**
   * 获取二级市场商品价格列表
   * @param params 
   * @returns 
   */
  @observable goodsSearcExtra: any = {}
  @observable goodsSearcList: any[] = []
  @observable goodsSearcListHasMore = true
  getGoodsSearhList: SecondaryMarketType['getGoodsSearchListApi'] = async (params) => {
    const data = await getGoodsSearchListApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.goodsSearcList = [...this.goodsSearcList, ...data.data.data.list]
      } else {
        this.goodsSearcList = data.data.data.list
      }
      this.goodsSearcExtra = data.data.data.extra
      this.goodsSearcListHasMore = Boolean(data.data.data.list.length > 0)
    } else {
      this.goodsSearcListHasMore = false
    }
    return Promise.resolve(data)
  }




  /**
   * 获取求购列表
   * @param params 
   * @returns 
   */
  @observable ordersBidOrderExtra: any = {}
  @observable ordersBidOrderList: any[] = []
  @observable ordersBidOrderListHasMore: boolean = true
  @action getOrdersBidOrderList: SecondaryMarketType['ordersBidOrderListApi'] = async (params) => {
    const data = await ordersBidOrderListApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.ordersBidOrderList = [...this.ordersBidOrderList, ...data.data.data.list]
      } else {
        this.ordersBidOrderList = data.data.data.list
      }
      this.ordersBidOrderListHasMore = Boolean(data.data.data.list.length > 0)
      this.ordersBidOrderExtra = data.data.data.extra
    } else {
      this.ordersBidOrderListHasMore = false
    }
    return Promise.resolve(data)
  }



  /**
   * 卖家根据求购订单出售手中的藏品
   * @param params 
   * @param headers 
   * @returns 
   */
  @action ordersMatchBid: SecondaryMarketType['ordersMatchBidApi'] = async (params, headers) => {
    const data = await ordersMatchBidApi(params, headers)
    return Promise.resolve(data)
  }




  /**
   * 生成二级订单支付
   * @param params 
   * @returns 
   */
  ordersCreate: SecondaryMarketType['ordersCreateApi'] = async (params) => {
    const data = await ordersCreateApi(params)
    if (data.data.code === 0) {
      this.ordersPay(data.data.data.order_id)
    }
    return Promise.resolve(data)
  }




  /**
   * 二级订单支付
   * @param order_id 
   */
  ordersPay = (order_id: string) => {
    ordersPayApi({
      order_id: order_id,
      return_url: process.env.NODE_ENV === 'production' ? `https://${window.location.host}/secondaryMarket/paymentPage?order_id=${order_id}` : `http://${window.location.host}/secondaryMarket/paymentPage?order_id=${order_id}`
    }).then(({ data }) => {
      if (data.code === 0) {
        window.location.href = data.data.pay_url
      }
    })
  }





  /**
   * 寄售中列表
   * @returns 
   */
  @observable goodsMySalesList: any[] = []
  goodsMySales = async () => {
    const data = await goodsMySalesApi()
    if (data.data.code === 0) {
      this.goodsMySalesList = data.data.data.list
    }
    return Promise.resolve(data)
  }




  /**
   * 已出售列表
   * @returns 
   */
  @observable ordersSaleList: any[] = []
  ordersSale = async (params?: { type: 'match_bid' }) => {
    const data = await ordersSaleApi(params)
    if (data.data.code === 0) {
      this.ordersSaleList = data.data.data.list
    }
    return Promise.resolve(data)
  }








  /**
   * 修改价格
   * @param params 
   * @returns 
   */
  goodsModify: SecondaryMarketType['goodsModifyApi'] = async (params) => {
    const data = await goodsModifyApi(params)
    return Promise.resolve(data)
  }







  /**
   * 取消寄售
   * @param params 
   * @returns 
   */
  goodsUnshelve = async (params: { goods_id: string }) => {
    const data = await goodsUnshelveApi(params)
    return Promise.resolve(data)
  }







  /**
   * 二级订单列表           
   * @param params 
   * @returns 
   */
  @observable ordersMyList: any[] = []
  @observable ordersMyListHasMore = true
  ordersMy: SecondaryMarketType['ordersMyApi'] = async (params) => {
    const data = await ordersMyApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.ordersMyList = [...this.ordersMyList, ...data.data.data.list]
      } else {
        this.ordersMyList = data.data.data.list
      }
      this.ordersMyListHasMore = Boolean(data.data.data.list.length > 0)
    }
    return Promise.resolve(data)
  }








  /**
   * 获取二级订单详情
   * @param params 
   * @returns 
   */
  @observable ordersDetail: any = {}
  getOrdersDetail: SecondaryMarketType['ordersDetailApi'] = async (params) => {
    const data = await ordersDetailApi(params)
    if (data.data.code === 0) {
      this.ordersDetail = data.data.data
    }
    return Promise.resolve(data)
  }







  /**
   * 取消二级订单
   * @param params 
   * @returns 
   */
  ordersCancel: SecondaryMarketType['ordersCancelApi'] = async (params) => {
    const data = await ordersCancelApi(params)
    return Promise.resolve(data)
  }




  /**
   * 链上记录
   * @param params 
   * @returns 
   */
  @observable ordersTransactionList: any[] = []
  @observable ordersTransactionListHasMore = true
  getOrdersTransactionList: SecondaryMarketType['ordersTransactionListApi'] = async (params) => {
    const data = await ordersTransactionListApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.ordersTransactionList = [...this.ordersTransactionList, ...data.data.data]
      } else {
        this.ordersTransactionList = data.data.data
      }
      this.ordersTransactionListHasMore = Boolean(data.data.data.length > 0)
    }
    return Promise.resolve(data)
  }
}

export default new SecondaryMarket()