import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import appState from '@/store/appState'
import { Dialog, Toast } from 'antd-mobile'
import { observer } from 'mobx-react'
import store from '../../reducer'

import styles from '../active.module.scss'

const GetGold: React.FC = () => {
  const { commonData, getMenuData } = appState
  const [amount, setAmount] = useState('')
  const { bonusUserRedeemGold } = store
  const navigate = useNavigate()

  const submitHandle = () => {
    if (amount === '') return
    Dialog.show({
      content: (
        <div style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
          <div>即将提炼 {amount} 克实物黄金</div>
          <div>销毁灵金 {(Number(amount) * commonData?.gold_unit_price).toFixed(2) || 0} 个</div>
        </div>
      ),
      closeOnAction: true,
      title: '提炼黄金',
      actions: [
        [
          {
            text: '取消',
            key: 'cancel',
            style: { border: '1px solid #000', fontWeight: 600, borderRadius: 12, color: '#000', margin: 10 }
          },
          {
            text: '确定',
            key: 'ok',
            style: { color: '#fff', background: '#000', borderRadius: 12, fontWeight: 600, margin: 10 },
            onClick() {
              bonusUserRedeemGold({
                amount: Number(amount)
              }).then(({ data }) => {
                if (data.code === 0) {
                  getMenuData()
                  setAmount('')
                  setTimeout(() => {
                    Toast.show({ icon: 'success', content: '提炼成功' })
                  }, 100)
                }
              })
            },
          }
        ],
      ]
    })
  }
  return (
    <>
      <div className={styles.box}>
        <div className={styles['box-item']}>
          <span className={styles['box-item-text']}>提炼黄金</span>
          <div className={styles['box-item-content']}>
            <img className={styles['box-item-content-img']} src={require('@/assets/imgs/Gold.svg').default} alt="" />
            <span className={styles['box-item-content-text']} style={{ fontSize: 16 }}>
              实物黄金
            </span>
          </div>
        </div>
        <div className={styles['box-item']} style={{ alignItems: 'end' }}>
          <span className={styles['box-item-text']}>提炼克数</span>
          <div className={styles['box-item-content']}>
            <span className={styles['box-item-content-text']} style={{ borderBottom: '1px solid #E1E1E1' }}>
              <input className={styles['box-item-content-input']} type="number" value={amount} onChange={(e) => {
                if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                  setAmount(e.target.value)
                }
              }} /> 克
            </span>
          </div>
        </div>
      </div>
      <div className={styles.img}>
        <img src={require('@/assets/imgs/Frame 1171275067.svg').default} alt="" />
      </div>
      <div className={styles.box}>
        <div className={styles['box-item']}>
          <span className={styles['box-item-text']}>销毁灵金</span>
          <div className={styles['box-item-content']}>
            <img className={styles['box-item-content-img']} src={require('@/assets/imgs/Gold.svg').default} alt="" />
            <span className={styles['box-item-content-text']} style={{ fontSize: 16 }}>
              灵金
            </span>
          </div>
        </div>
        <div className={styles['box-item']} style={{ alignItems: 'end' }}>
          <span className={styles['box-item-text']}>灵金余额：<span style={{ color: '#4D33EF' }}>{Math.floor(commonData?.lingjin)}</span></span>
          <div className={styles['box-item-content']}>
            <span className={styles['box-item-content-text']}>
              {(Number(amount) * commonData?.gold_unit_price).toFixed(2) || 0}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.box2}>
        <div className={styles['box2-item']}>
          <span className={styles['box2-item-text']}>当前汇率</span>
          <span className={styles['box2-item-text']}>1 : {commonData?.gold_unit_price}</span>
        </div>
        {/* <div className={styles['box2-item']}>
          <span className={styles['box2-item-text']}>手续费率</span>
          <span className={styles['box2-item-text']}>2%</span>
        </div> */}
        <div className={styles['box2-item']}>
          <span className={styles['box2-item-text']}>兑换规则</span>
          <span className={styles['box2-item-text']} style={{ color: '#4D33EF' }}>查看规则</span>
        </div>
      </div>
      <div className={styles.btn} onClick={submitHandle}>
        提炼黄金
      </div>
    </>
  )
}

export default observer(GetGold)