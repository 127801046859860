import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Header from '@/component/header/header';
import { ProgressBar, Switch, Toast, Popup, Dialog, InfiniteScroll } from 'antd-mobile';
import BuyModal from '@/component/buyModal/buyModal'
import moment from 'moment';
import './myMiningCave.scss'
import miningStore from '@/store/miningStore';

const MyMiningCave: React.FC = () => {
  const [goldData, setGoldData] = useState<any>({})
  const [silverData, setSilverData] = useState<any>({})
  const [bronzeData, setBronzeData] = useState<any>({})
  const [buyModalVisibe, setBuyModalVisibe] = useState(false)
  const [status, setStatus] = useState<'START' | 'END'>('START')
  const [type, setType] = useState<'GOLD' | 'SILVER' | 'TEAM' | 'BRONZE'>('GOLD')
  const [popupVisible, setPopupVisible] = useState(false)
  const navigate = useNavigate()
  const [currentCard, setCurrentCard] = useState<any>({})
  const [mineId, setMineId] = useState('')
  const location = useLocation()


  const pledgeCardHandle = (x: {
    mineId: string; state: string;
  }) => {
    if (x.state === 'UNSTART') {
      setPopupVisible(true)
      setMineId(x.mineId)
      return miningStore.pledgeCards()
    }
    if (x.state === 'START') {
      return Toast.show('不可结束采矿')
    }
  }


  const openPledge = (x: any) => {
    if (x.state === 'AVAILABLE' || x.state === 'END') {
      setCurrentCard(x)
      Dialog.show({
        content: `确定质押：原卡 #${x.shardId} 开启采矿吗`,
        closeOnAction: true,
        title: '质押采矿',
        actions: [
          [
            {
              text: '取消',
              key: 'cancel',
            },
            {
              text: '确认',
              key: 'ok',
              onClick() {
                miningStore.openMine({
                  mineId: mineId,
                  ids: [x.id]
                }).then(res => {
                  if (res.code === 0) {
                    setPopupVisible(false)
                    miningStore.getMineList({ state: status, type, limit: (type === 'GOLD' ? miningStore.mineGoldList.length : miningStore.mineSilverList.length) })
                    Toast.show({ icon: 'success', content: '质押成功' })
                  }
                })
              },
            }
          ],
        ]
      })
    }
  }


  useEffect(() => {
    miningStore.mineData('GOLD').then((res) => {
      if (res.code === 0) {
        setGoldData(res.data)
      }
    })
    miningStore.mineData('SILVER').then((res) => {
      setSilverData(res.data)
    })
    miningStore.mineData('BRONZE').then((res) => {
      setBronzeData(res.data)
    })
    miningStore.groupMineData()
  }, [])

  useEffect(() => {
    if (type === 'GOLD' || type === 'SILVER' || type === 'BRONZE') {
      miningStore.getMineList({ state: status, type })
    } else if (type === 'TEAM') {
      miningStore.getGroupMineList({ state: status })
    }
  }, [status, type])

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')
    if (type) {
      setType(type as 'GOLD' | 'SILVER' | 'TEAM')
    }
  }, [])


  const loadMore = async () => {
    if (type === 'GOLD' || type === 'SILVER') {
      await miningStore.getMineList({ state: status, type, skip: (type === 'GOLD' ? miningStore.mineGoldList.length : miningStore.mineSilverList.length) })
    } else if (type === 'TEAM') {
      await miningStore.getGroupMineList({ state: status, skip: miningStore.groupMineList.length })
    }
  }

  return (
    <div id='MyMiningCave'>
      <Header text='原石源矿' fontColor="black" menu={<span onClick={() => navigate('/mining/mygem')}>我的原石</span>} />
      <div className='MyMiningCave-center'>
        {new URLSearchParams(location.search).get('type') === 'TEAM' ? (
          <>
            <div className='MyMiningCave-team' onClick={() => setType('TEAM')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>组队源矿×{miningStore?.groupMineDataInfo?.mineCount}</h3>
                <div onClick={() => navigate('/invite')}>邀请好友</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{miningStore?.groupMineDataInfo?.mineScore}/{miningStore?.groupMineDataInfo?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(miningStore?.groupMineDataInfo?.mineScore / miningStore?.groupMineDataInfo?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
            <div className='MyMiningCave-gold' onClick={() => setType('GOLD')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>黄金源矿×{goldData?.mineCount}</h3>
                <div onClick={() => setBuyModalVisibe(true)}>购买闪卡</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{goldData?.mineScore}/{goldData?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(goldData?.mineScore / goldData?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
            <div className='MyMiningCave-silver' onClick={() => setType('SILVER')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>白银源矿×{silverData?.mineCount}</h3>
                <div onClick={() => setBuyModalVisibe(true)}>购买闪卡</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{silverData?.mineScore}/{goldData?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(silverData?.mineScore / silverData?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='MyMiningCave-gold' onClick={() => setType('GOLD')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>黄金源矿×{goldData?.mineCount}</h3>
                <div onClick={() => setBuyModalVisibe(true)}>购买闪卡</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{goldData?.mineScore}/{goldData?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(goldData?.mineScore / goldData?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
            <div className='MyMiningCave-silver' onClick={() => setType('SILVER')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>白银源矿×{silverData?.mineCount}</h3>
                <div onClick={() => setBuyModalVisibe(true)}>购买闪卡</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{silverData?.mineScore}/{silverData?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(silverData?.mineScore / silverData?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
            <div className='MyMiningCave-bronze' onClick={() => setType('BRONZE')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>活动源矿×{bronzeData?.mineCount}</h3>
                <div onClick={() => setBuyModalVisibe(true)}>购买闪卡</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{bronzeData?.mineScore}/{bronzeData?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(bronzeData?.mineScore / bronzeData?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
            <div className='MyMiningCave-team' onClick={() => setType('TEAM')}>
              <div className='MyMiningCave-bronze-top'>
                <h3>组队源矿×{miningStore?.groupMineDataInfo?.mineCount}</h3>
                <div onClick={() => navigate('/invite')}>邀请好友</div>
              </div>
              <div className='MyMiningCave-bronze-accumulate'>累计产出原石{miningStore?.groupMineDataInfo?.mineScore}/{miningStore?.groupMineDataInfo?.totalMineScore}</div>
              <div className='MyMingCave-Progressbar'>
                <ProgressBar
                  percent={(miningStore?.groupMineDataInfo?.mineScore / miningStore?.groupMineDataInfo?.totalMineScore) * 100}
                  style={{
                    '--track-color': '#2D343E',
                    '--fill-color': '#4800E0'
                  }} />
              </div>
            </div>
          </>
        )}
      </div>
      <div className='MyMiningCave-foot'>
        <div className="MyGem-foot-top">
          <span className="MyMiningCave-mining" style={status === 'START' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setStatus('START')}>采矿中</span>
          <span className="MyMiningCave-end" style={status === 'END' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setStatus('END')}>已结束</span>
        </div>
      </div>

      {type === 'GOLD' && (
        miningStore.mineGoldList.length > 0 ? miningStore.mineGoldList.map((x, i) => (
          <div className='MyMiningCave-foot-Bronze' key={i}>
            <div className='MyMiningCave-foot-Bronze-left'>
              <div onClick={() => { pledgeCardHandle(x) }}>
                <Switch
                  checked={x.state === 'START'}
                  disabled={x.state === 'END'}
                  style={{
                    '--checked-color': '#4D33EF',
                    '--height': '24px',
                    '--width': '39px',
                  }}
                />
              </div>
              <div className='MyMiningCave-foot-Bronze-left-fon'>
                <h3>{x.mineName}#{x.num}</h3>
                <div>{moment(x.createTime).format('YYYY.MM.DD HH:mm')}</div>
              </div>
            </div>
            <div className='MyMiningCave-foot-Bronze-right'>
              <h3>{x.hasRewardScore}/{x.maxRewardScore}</h3>
              <div className='MyMiningCave-foot-Bronze-right-fn'>
                <img className='MyMiningCave-img' src={require('../../../assets/imgs/myMiningCave.svg').default} />
                <div>已产出原石</div>
              </div>
            </div>
          </div>
        )) : (
          <div className='noData' style={{ top: 374 }}>
            <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
            <span className='noData-text'>暂无数据</span>
          </div>
        )
      )}

      {type === 'SILVER' && (
        miningStore.mineSilverList.length > 0 ? miningStore.mineSilverList.map((x, i) => (
          <div className='MyMiningCave-foot-Bronze' key={i}>
            <div className='MyMiningCave-foot-Bronze-left'>
              <div onClick={() => { pledgeCardHandle(x) }}>
                <Switch
                  checked={x.state === 'START'}
                  disabled={x.state === 'END'}
                  style={{
                    '--checked-color': '#4D33EF',
                    '--height': '24px',
                    '--width': '39px',
                  }}
                />
              </div>
              <div className='MyMiningCave-foot-Bronze-left-fon'>
                <h3>{x.mineName}#{x.num}</h3>
                <div>{moment(x.createTime).format('YYYY.MM.DD HH:mm')}</div>
              </div>
            </div>
            <div className='MyMiningCave-foot-Bronze-right'>
              <h3>{x.hasRewardScore}/{x.maxRewardScore}</h3>
              <div className='MyMiningCave-foot-Bronze-right-fn'>
                <img className='MyMiningCave-img' src={require('../../../assets/imgs/myMiningCave.svg').default} />
                <div>已产出原石</div>
              </div>
            </div>
          </div>
        )) : (
          <div className='noData' style={{ top: 374 }}>
            <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
            <span className='noData-text'>暂无数据</span>
          </div>
        )
      )}

      {type === 'BRONZE' && (
        miningStore.mineBronzeList.length > 0 ? miningStore.mineBronzeList.map((x, i) => (
          <div className='MyMiningCave-foot-Bronze' key={i}>
            <div className='MyMiningCave-foot-Bronze-left'>
              <div onClick={() => { pledgeCardHandle(x) }}>
                <Switch
                  checked={x.state === 'START'}
                  disabled={x.state === 'END'}
                  style={{
                    '--checked-color': '#4D33EF',
                    '--height': '24px',
                    '--width': '39px',
                  }}
                />
              </div>
              <div className='MyMiningCave-foot-Bronze-left-fon'>
                <h3>{x.mineName}#{x.num}</h3>
                <div>{moment(x.createTime).format('YYYY.MM.DD HH:mm')}</div>
              </div>
            </div>
            <div className='MyMiningCave-foot-Bronze-right'>
              <h3>{x.hasRewardScore}/{x.maxRewardScore}</h3>
              <div className='MyMiningCave-foot-Bronze-right-fn'>
                <img className='MyMiningCave-img' src={require('../../../assets/imgs/myMiningCave.svg').default} />
                <div>已产出原石</div>
              </div>
            </div>
          </div>
        )) : (
          <div className='noData' style={{ top: 374 }}>
            <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
            <span className='noData-text'>暂无数据</span>
          </div>
        )
      )}

      {type === 'TEAM' && (
        miningStore.groupMineList.length > 0 ? miningStore.groupMineList.map((x, i) => (
          <div className='MyMiningCave-foot-Bronze' key={i}>
            <div className='MyMiningCave-foot-Bronze-left'>
              <div className='MyMiningCave-foot-Bronze-left-fon'>
                <h3>{(x.inviteRewardScore === '0.00' && x.hasRewardScore === '0.00') ? '未开矿' : x.groupName}</h3>
                <div>{moment(x.createTime).format('YYYY.MM.DD HH:mm')}</div>
              </div>
            </div>
            <div className='MyMiningCave-foot-Bronze-right'>
              <h3>{x.inviteRewardScore}/{x.hasRewardScore}</h3>
              <div className='MyMiningCave-foot-Bronze-right-fn'>
                <img className='MyMiningCave-img' src={require('../../../assets/imgs/myMiningCave.svg').default} />
                <div>你的奖励/好友已产出</div>
              </div>
            </div>
          </div>
        )) : (
          <div className='noData' style={{ top: 374 }}>
            <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
            <span className='noData-text'>暂无数据</span>
          </div>
        )
      )}

      <BuyModal
        show={buyModalVisibe}
        close={() => setBuyModalVisibe(false)}
      />

      <Popup
        visible={popupVisible}
        getContainer={null}
        onClose={() => setPopupVisible(false)}
        closeOnMaskClick
      >
        <div className='MyMiningCave-Popup'>
          <div className='MyMiningCave-Popup-top'>
            <div className='MyMiningCave-Popup-top-img'>
              <img width={currentCard.thumb ? 68 : 0} src={currentCard.thumb && currentCard.thumb[0]} alt="" />
            </div>
            <div className='MyMiningCave-Popup-top-content'>
              <span className='MyMiningCave-Popup-top-content-text1'>原卡</span>
              <span className='MyMiningCave-Popup-top-content-text2'>选择要质押采矿的原卡/共{miningStore.pledgeCardsList.length}个</span>
            </div>
          </div>
          <div className='MyMiningCave-Popup-list'>
            {miningStore.pledgeCardsList.map((x, i) => (
              <div className={x.id === currentCard.id ? 'MyMiningCave-Popup-list-item active' : 'MyMiningCave-Popup-list-item'} key={i} onClick={() => openPledge(x)}>
                {x.state === 'LOCK' && <div className='MyMiningCave-Popup-list-item-LOCK'>采矿中</div>}
                {x.title}#{x.shardId}
              </div>
            ))}
          </div>
        </div>
      </Popup>

      <InfiniteScroll
        hasMore={(type === 'GOLD' || type === 'SILVER' || type === 'BRONZE') ? miningStore.mineListHasMore : miningStore.groupMineListHasMore}
        loadMore={loadMore}
      />
    </div>
  )
}

export default inject()(observer(MyMiningCave))