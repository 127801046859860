import { observable, action, makeObservable } from 'mobx'
import {
  consumeShardListApi,
  nftGameDetailApi,
  consumeApi,
  nftHistoryApi
} from './serve'
import type {
  consumeShardListType,
  nftGameDetailType,
  consumeType,
  nftHistorType
} from './type'

class Store {
  constructor() {
    makeObservable(this)
  }



  /**
  * 可供核销的碎片列表
  * @param params 
  * @returns 
  */
  @observable consumeShardListHasMore = true
  @observable consumeShardList: any[] = []
  @action getConsumeShardList: consumeShardListType = async (params) => {
    const data = await consumeShardListApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.consumeShardList = [...this.consumeShardList, ...data.data.data]
      } else {
        this.consumeShardList = data.data.data
      }
      this.consumeShardListHasMore = Boolean(data.data.data.length > 0)
    }
    return Promise.resolve(data)
  }






  /**
   * 游戏详情
   * @param params 
   * @returns 
   */
  @observable nftGameDetail: any = {}
  @action getNftGameDetail: nftGameDetailType = async (params) => {
    const data = await nftGameDetailApi(params)
    if (data.data.code === 0) {
      this.nftGameDetail = data.data.data
    }
    return Promise.resolve(data)
  }




  /**
   * 碎片核销
   * @param params 
   * @returns 
   */
  @action consume: consumeType = async (params, headers) => {
    const data = await consumeApi(params, headers)
    return Promise.resolve(data)
  }







  /**
   * asset 链上记录
   * @param params 
   * @returns 
   */
  @observable nftHistoryList: any[] = []
  @observable nftHistoryListHasMore: boolean = true
  @action getNftHistory: nftHistorType = async (params) => {
    const data = await nftHistoryApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.nftHistoryList = [...this.nftHistoryList, ...data.data.data]
      } else {
        this.nftHistoryList = data.data.data
      }
      this.nftHistoryListHasMore = Boolean(data.data.data.length > 0)
    } else {
      this.nftHistoryListHasMore = false
    }
    return Promise.resolve(data)
  }

}

export default new Store()