import { useNavigate, useLocation } from 'react-router';
import { useState, useEffect } from 'react';
import { CenterPopup, Popup, Toast } from 'antd-mobile';
import Header from '@/component/header/header';
import { initData } from '../synthesisDetails/synthesisDetails';
import { queryCollectionSynthesisDetail, collectionSynthesisAction } from '../../serve';
import styles from './collectionSynthesis.module.scss';

const CollectionSynthesis: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const {
    state: { iid },
  } = useLocation();

  const [state, setState] = useState({
    isAnimated: false,
    isUpdateNft: false,
  });
  const [userKnow, setUserKnow] = useState(false);
  const [data, setData] = useState(initData);

  /**
   * 替换藏品 & 更换编号
   *
   * @materialsData body集合
   *
   * @shardIdArr 当前需要跟换藏品的shardId集合
   *
   * @getshardIdArrHandler 获取 shardIdArr
   *
   * @updateNftHandler 替换藏品
   */
  type materials = { asset_id: string; shard_id: string; title: string; thumb: string };
  const [materialsData, setMaterialsData] = useState<Array<materials>>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  let [shardIdArr, setShardIdArr] = useState<Array<{ shard_id: string; publish_amount: string; title: string }>>([]);

  const getshardIdArrHandler = (asset_id: string, type?: 'init') => {
    shardIdArr = [];
    data.all_materials.forEach((item: any) => {
      if (item.asset_id === asset_id) {
        shardIdArr.push({ shard_id: item.shard_id, publish_amount: item.publish_amount, title: item.title });
      }
    });

    if (type) return shardIdArr;

    setState({ ...state, isAnimated: true });
    setShardIdArr(shardIdArr);
  };

  const updateNftHandler = (item: any) => {
    if (item.asset_id === materialsData[currentIndex].asset_id) {
      return Toast.show({ content: '该藏品已被选中' });
    }
    let shardId = getshardIdArrHandler(item.asset_id, 'init')![
      materialsData.filter((value) => value.asset_id === item.asset_id).length
    ]?.shard_id;
    if (!shardId) {
      Toast.show({ content: '藏品数量不足' });
      return;
    }

    const arr = getshardIdArrHandler(item.asset_id, 'init')!.filter((data) => {
      return materialsData
        .filter((value) => value.asset_id === item.asset_id)
        .every((value) => {
          return data.shard_id !== value.shard_id;
        });
    });

    materialsData.splice(currentIndex, 1, {
      asset_id: item.asset_id,
      thumb: item.thumb[item.thumb.length - 1],
      title: item.title,
      shard_id: arr[0].shard_id,
    });
    setMaterialsData(materialsData);
    setState({ ...state, isUpdateNft: false });
  };

  const updateShardIdHandler = (shard_id: string) => {
    const object = materialsData[currentIndex];
    if (materialsData.filter((item) => item.asset_id === object.asset_id).some((item) => item.shard_id === shard_id)) {
      Toast.show({ content: '该编号藏品已被选中' });
      return;
    }
    materialsData.splice(currentIndex, 1, { ...object, shard_id });
    setMaterialsData(materialsData);
    setState({ ...state, isAnimated: false });
  };

  const query = async (iid: string) => {
    const queryCollectionSynthesisDetailRes = await queryCollectionSynthesisDetail({ iid });
    if (queryCollectionSynthesisDetailRes.data?.code !== 0) return;
    const result = queryCollectionSynthesisDetailRes.data?.data || {};
    const { thumb = [] } = result;
    const mainImage = thumb[0] || [];
    setData({ mainImage, ...result });
  };

  const synthesisHandle = async () => {
    // var captcha = new TencentCaptcha('198946596', async (res) => {}, {});

    const collectionSynthesisActionRes = await collectionSynthesisAction({
      composite_id: data.iid,
      materials: materialsData,
      // ticket: res.ticket,
      // randstr: res.randstr,
    });
    setVisible(false);
    if (collectionSynthesisActionRes.data.code === 0) {
      localStorage.setItem('compositeAssetResquestBody', JSON.stringify(collectionSynthesisActionRes.data));
      navigate('/synthesis/CastingStatus');
    }
  };

  useEffect(() => {
    if (data.need_materials) {
      const arr: Array<materials> = [];
      let asset_id = '';
      let addIndex = 0;
      data.need_materials.forEach((item: any) => {
        if (item.asset_id === asset_id) {
          addIndex += 1;
        } else {
          addIndex = 0;
        }
        arr.push({
          asset_id: item.asset_id,
          title: item.title,
          thumb: item.thumb[item.thumb.length - 1],
          shard_id: getshardIdArrHandler(item.asset_id, 'init')![addIndex].shard_id,
        });
        asset_id = item.asset_id;
      });
      setMaterialsData(arr);
    }
  }, [data]);

  useEffect(() => {
    if (iid) query(iid);
  }, [iid]);

  return (
    <div id={styles['CollectionSynthesis']}>
      <Header text='藏品合成' />
      <div className={styles['CollectionSynthesis-title']}>
        <p className={styles['CollectionSynthesis-title-p']}>
          注意：合成操作一旦发起，过程中不可撤销，成功后将会销毁消耗藏品。合成过程中藏品将被锁定不能进行任何操作。
        </p>
      </div>
      <h3 className={styles['CollectionSynthesis-h3']}>合成藏品</h3>
      <div className={styles['CollectionSynthesis-center']}>
        <img className={styles['CollectionSynthesis-center-mainImage']} src={data.mainImage} alt='' />
        <div className={styles['CollectionSynthesis-center-left']}>
          <div className={styles['CollectionSynthesis-center-left-top']}>
            <div>{data.title}</div>
            <span id={data.grade} className={styles['CollectionSynthesis-center-left-top-span']}>
              {data.grade}
            </span>
          </div>
          <div className={styles['CollectionSynthesis-center-left-bottom']}>
            <div className={styles['CollectionSynthesis-center-left-bottom-left']}>发行</div>
            <div className={styles['CollectionSynthesis-center-left-bottom-right']}>{data.amount}份</div>
          </div>
        </div>
      </div>
      <h3 className={styles['CollectionSynthesis-text']}>消耗藏品</h3>
      {data.need_materials &&
        data.need_materials.map((item: any, i) => (
          <div className={styles['CollectionSynthesis-center2']} key={`CollectionSynthesis-center2-${i}`}>
            <img className={styles['CollectionSynthesis-center2-mainImage']} src={materialsData[i]?.thumb} alt='' />
            <div className={styles['CollectionSynthesis-center2-right']}>
              <div className={styles['CollectionSynthesis-center2-right-top']}>
                <div>
                  {materialsData[i]?.title || ''} {'#' + materialsData[i]?.shard_id || ''}
                </div>
                <div id={item.grade} className={styles['CollectionSynthesis-center2-right-top-SSR']}>
                  {item.grade}
                </div>
              </div>
              <div className={styles['CollectionSynthesis-center2-right-bottom']}>
                {item.assets.length > 1 && (
                  <div
                    className={styles['CollectionSynthesis-center2-right-bottom-left']}
                    onClick={() => {
                      setState({ ...state, isUpdateNft: true });
                      setCurrentIndex(i);
                    }}>
                    <img
                      className={styles['CollectionSynthesis-center2-right-bottom-left-img']}
                      src={require('@/assets/imgs/CollectionSynthesis-line.svg').default}
                      alt=''
                    />
                    <div className={styles['CollectionSynthesis-center2-right-bottom-left-text']}>替换藏品</div>
                  </div>
                )}

                <div
                  className={styles['CollectionSynthesis-center2-right-bottom-right']}
                  onClick={() => {
                    getshardIdArrHandler(materialsData[i].asset_id);
                    setCurrentIndex(i);
                  }}>
                  <img
                    className={styles['CollectionSynthesis-center2-right-bottom-right-img']}
                    src={require('@/assets/imgs/CollectionSynthesis-line2.svg').default}
                    alt=''
                  />
                  <div className={styles['CollectionSynthesis-center2-right-bottom-right-text']}>更换编号</div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <div className={styles['CollectionSynthesis-footer']}>
        <div className={styles['CollectionSynthesis-center-notice']}>
          <input
            type='checkbox'
            className={styles['CollectionSynthesis-center-notice-checkbox']}
            onChange={(e) => {
              setUserKnow(e.target.checked);
            }}
          />
          <p className={styles['CollectionSynthesis-center-notice-p']}>
            藏品合成不可撤销，请同意Wyper
            <a
              className={styles['CollectionSynthesis-center-notice-p-a']}
              onClick={() => window.open('https://wyper.art/userterms.html')}>
              《用户协议》
            </a>
          </p>
        </div>
        <div
          className={styles['CollectionSynthesis-btn']}
          onClick={() => {
            if (!userKnow) {
              return;
            }
            setVisible(true);
          }}>
          确认合成
        </div>
      </div>

      {/*确认合成弹窗*/}
      <CenterPopup
        visible={visible}
        bodyStyle={{
          borderRadius: '12px',
          minWidth: '80vw',
        }}
        onMaskClick={() => {
          setVisible(false);
        }}>
        <div className={styles['CollectionSynthesis-CenterPopup']}>
          <h3 className={styles['CollectionSynthesis-CenterPopup-h3']}>合成须知</h3>
          <p className={styles['CollectionSynthesis-CenterPopup-p']}>
            合成销毁操作均不可撤回，数字藏品仅用于欣赏及收藏，而不是用于投资炒作。
          </p>
          <div className={styles['CollectionSynthesis-CenterPopup-btnBox']}>
            <div className={styles['CollectionSynthesis-CenterPopup-btnBox-btn']} onClick={synthesisHandle}>
              确认合成
            </div>
          </div>
        </div>
      </CenterPopup>

      {/*替换藏品弹窗*/}
      <Popup
        getContainer={null}
        visible={state.isUpdateNft}
        onMaskClick={() => {
          setState({ ...state, isUpdateNft: false });
        }}>
        <div className={styles['CollectionSynthesis-boot']}>
          {data.need_materials[currentIndex]?.assets.map((item: any, i) => (
            <div className={styles['CollectionSynthesis-boot-box']} key={`CollectionSynthesis-boot-box-${i}`}>
              <div className={styles['CollectionSynthesis-boot-box-left']}>
                <img
                  className={styles['CollectionSynthesis-boot-box-left-mainImage']}
                  src={item.thumb[item.thumb.length - 1]}
                  alt=''
                />
                <div className={styles['CollectionSynthesis-boot-box-left-text']}>
                  <h3 className={styles['CollectionSynthesis-boot-box-left-text-h3']}>{item.title}</h3>
                  <p>持有 {item.owned_amount} 个</p>
                </div>
              </div>
              <div
                className={styles['CollectionSynthesis-boot-box-right']}
                onClick={() => {
                  updateNftHandler(item);
                }}>
                <img
                  className={styles['CollectionSynthesis-boot-box-right-img']}
                  src={require('@/assets/imgs/CollectionSynthesis-group.svg').default}
                  alt=''
                />
                <div>选择</div>
              </div>
            </div>
          ))}
        </div>
      </Popup>

      {/*更换编号弹窗*/}
      <Popup
        getContainer={null}
        visible={state.isAnimated}
        onMaskClick={() => {
          setState({ ...state, isAnimated: false });
        }}>
        <div className={styles['CollectionSynthesis-Popup']}>
          {shardIdArr.map((item: any) => (
            <div
              className={styles['CollectionSynthesis-Popup-box2']}
              onClick={(e) => {
                e.stopPropagation();
                updateShardIdHandler(item.shard_id);
              }}>
              #{item.shard_id}
              {/* <div className={styles['CollectionSynthesis-Popup-box2-text']}>质押中</div>*/}
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default CollectionSynthesis;
