import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { _hmtHandle } from '@/utils/utils'
import appState from '@/store/appState'
import Header from '../../component/header/header'

import './login.scss'

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [checked, setChecked] = useState(true)
  const navigate = useNavigate()



  const getCodeHandler = () => {
    if (checked) {
      if (phoneNumber !== '' && !Number.isNaN(Number(phoneNumber)) && phoneNumber.length === 11) {
        appState.sendCodeHandle(phoneNumber).then((res) => {
          if (res === 0) {
            navigate('/sms', { state: phoneNumber })
            _hmtHandle(`${appState.osType}`, '点击按钮', '获取验证码')
          }
        })
      } else {
        Toast.show('请输入正确的手机号格式')
      }
    } else {
      Toast.show('请同意用户协议和隐私条款')
    }
  }

  useEffect(() => {
    if (localStorage.getItem('commonData')) {
      window.location.replace('/')
    }
  }, [])

  return (

    <div id='Login'>
      {/* <AnimateBg /> */}
      <Header />

      <div className='login-ctx1'>
        <div className='login-ctx1-left'>
          <span className='login-ctx1-left-text1'>快捷登录</span>
          <span className='login-ctx1-left-text2'>未注册的账号将自动注册为新账号</span>
        </div>
        {/* <img className='login-ctx1-img' src={require('../../assets/imgs/robot.png')} alt="" /> */}

        {/* <Player className='login-ctx1-img'
                    autoplay
                    loop
                    src="avatar.json"
                >
                </Player> */}
      </div>

      {/* <input
                className='login-input'
                type="tel"
                placeholder='请输入手机号'
                autoComplete='on'
                value={phoneNumber}
                maxLength={11}
                // onInput={
                //     setPhoneNumber(e.target.value)
                // }
                onInput={(e: any) => {
                    // if (e.target.value.length <= 11) {
                    //     // 如果输入的长度小于 11，才更新状态
                    //     setPhoneNumber(e.target.value)
                    // }
                    setPhoneNumber(e.target.value)
                }}
                autoFocus
            /> */}
      <input
        className='login-input'
        type="tel"
        placeholder='请输入手机号'
        autoComplete='on'
        value={phoneNumber}
        maxLength={11}
        onInput={(e: any) => {
          setPhoneNumber(e.target.value)
        }}
        autoFocus
      />
      <div className='login-ctx2'>
        <Checkbox className='login-ctx2-check' checked={checked} onChange={(e: any) => setChecked(e)} />

        <p className='login-ctx2-p'>我已同意 <a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a>与<a onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a></p>
      </div>

      <div className='login-ctx3' onClick={getCodeHandler}>
        获取验证码
      </div>
    </div>
  )
}

export default inject()(observer(Login))