import Index from '../views/index/index'
import MyAssets from '../views/myAssets/myAssets'
import Login from "../views/login/login"
import Sms from "../views/login/sms"
import InviteCode from '../views/inviteCode/inviteCode'
import RealName from "../views/realName/realName"
import CommodityDetail from '../views/commodityDetail/commodityDetail'
import OrderPay from '../views/orderPay/orderPay'
import Settings from '../views/settings/settings'
import Invite from '../views/invite/invite'
import Order from '../views/order/order'
import BusinessCopyright from '../views/businessCopyright/businessCopyright'
import MyGem from '../views/mining/myGem/myGem'
import MyMiningCave from '../views/mining/myMiningCave/myMiningCave'
import CollectionSubscription from '../views/mining/Collectionsubscription/CollectionSubscription'
import SubscriptionRecords from '../views/mining/SubscriptionRecords/SubscriptionRecords'
import GiveRoug from '../views/mining/giveRoug/giveRoug'
import Subscribe from '../views/mining/subscribe/subscribe'
import IncreaseByTransferring from '../views/mining/IncreaseByTransferring/IncreaseByTransferring'
import MySpiritualGold from '../views/mining/MySpiritualGold/MySpiritualGold'
import AlchemyInstitute from '../views/mining/AlchemyInstitute/AlchemyInstitute'
import AlchemyInstituteActive from '@/views/mining/AlchemyInstitute/active/active'
import MyPer from '../views/login/myper/myper'
import HelpCenter from '../views/My/HelpCenter/HelpCenter'
import ConsignmentGoods from '../views/My/ConsignmentGoods/ConsignmentGoods'
import ShippingAddress from '../views/My/ShippingAddress/ShippingAddress'
import EditAddress from '../views/My/EditAddress/EditAddress'
import SellAndSold from '../views/My/sellAndSold/sellAndSold'
import WebView from '../views/webView/webview'
import indexPoster from '../views/poster/indexPoster'
import miningPoster from '../views/poster/miningPoster'
import PartitionRecord from '../views/secondaryMarket/partitionRecord/partitionRecord'
import TradeCountdown from '../views/secondaryMarket/tradeCountdown/tradeCountdown'
import Destroy from '../views/secondaryMarket/destroy/destroy'
import onChainInForMation from '../views/secondaryMarket/onChainInformation/onChainInformation'
import WantToBuy from '../views/secondaryMarket/wantToBuy/wantToBuy'
import OrderPayment from '../views/secondaryMarket/orderPayment/orderPayment'
import Prepaid from '../views/secondaryMarket/prepaid/prepaid'
import PriceSold from '../views/secondaryMarket/priceSold/priceSold'
import TradeOk from '../views/secondaryMarket/tradeOk/tradeOk'
import PaymentPage from '../views/secondaryMarket/paymentPage/paymentPage'
import OnChainRecords from '../views/secondaryMarket/onChainRecords/onChainRecords'
import Announcement from '../views/announcement/announcement'
import NoticeDetails from '../views/announcement/noticeDetails'
import InviteRankingList from '../views/invite/inviteRankingList'
import AboutUs from '../views/settings/aboutUs'
import AllNumbers from '../views/secondaryMarket/paymentPage/allNumbers'
import Password from '../views/password/password'
import Pledge from '../views/mining/pledge/pledge'
import StarSourceKey from '../views/index/universe/starSourceKey/starSourceKey'
import FreightPay from '../views/orderPay/freightPay'
import AssetsDetail from '../views/assetsDetail/assetsDetail'
import CornerstoneCard from '../views/commodityDetail/cornerstoneCard'
import CollectCards from '../views/businessCopyright/collectCards'
import synthesis from '../views/synthesis/synthesis'
import PointsMall from '../views/Game/pointsMall/pointsMall'
import ExchangeRecord from '../views/Game/exchangeRecord/exchangeRecord'
import FreeDeal from '@/views/secondaryMarket/freeDeal/freeDeal'
import Typer from '@/views/secondaryMarket/tradeCountdown/typer'
import SynthesisDetails from '../views/synthesis/component/synthesisDetails/synthesisDetails';
import CollectionSynthesis from '../views/synthesis/component/CollectionSynthesis/collectionSynthesis';
import CastingStatus from '../views/synthesis/component/castingStatus/castingStatus';
import WinningRecord from '../views/WinningRecord/WinningRecord';
import MyMiyaoList from '@/views/index/universe/miyaoList/miyaoList'
import GiveNft from '@/views/assetsDetail/giveNft/giveNft'
import Score from '@/views/Game/score/score'
import Record from '@/views/Game/score/record/record'


export default [
  {
    path: '/*',
    element: Index
  },
  {
    path: '/login',
    element: Login
  },
  {
    path: '/myAssets',
    element: MyAssets
  },
  {
    path: '/webView',
    element: WebView,
  },
  {
    path: '/sms',
    element: Sms
  },
  {
    path: '/inviteCode',
    element: InviteCode
  },
  {
    path: '/realName',
    element: RealName
  },
  {
    path: '/commodityDetail',
    element: CommodityDetail
  },
  {
    path: '/orderPay',
    element: OrderPay
  },
  {
    path: '/settings',
    element: Settings
  },
  {
    path: '/invite',
    element: Invite
  },
  {
    path: '/order',
    element: Order
  },
  {
    path: '/businessCopyright',
    element: BusinessCopyright
  },
  {
    path: '/mining/MyGem',
    element: MyGem
  },
  {
    path: '/mining/MyMiningCave',
    element: MyMiningCave
  },
  {
    path: '/mining/CollectionSubscription',
    element: CollectionSubscription
  },
  {
    path: '/mining/SubscriptionRecords',
    element: SubscriptionRecords
  },
  {
    path: '/mining/GiveRoug',
    element: GiveRoug
  },
  {
    path: '/mining/subscribe',
    element: Subscribe
  },
  {
    path: '/mining/IncreaseByTransferring',
    element: IncreaseByTransferring
  },
  {
    path: '/mining/MySpiritualGold',
    element: MySpiritualGold
  },
  {
    path: '/mining/AlchemyInstitute',
    element: AlchemyInstitute
  },
  {
    path: '/login/MyPer',
    element: MyPer
  },
  {
    path: '/My/HelpCenter',
    element: HelpCenter
  },
  {
    path: '/My/ConsignmentGoods',
    element: ConsignmentGoods
  },
  {
    path: '/My/ShippingAddress',
    element: ShippingAddress
  },
  {
    path: '/My/EditAddress',
    element: EditAddress
  },
  {
    path: '/indexPoster',
    element: indexPoster
  },
  {
    path: '/miningPoster',
    element: miningPoster
  },
  {
    path: '/secondaryMarket/partitionRecord',
    element: PartitionRecord
  },
  {
    path: '/secondaryMarket/TradeCountdown',
    element: TradeCountdown
  },
  {
    path: '/secondaryMarket/Destroy',
    element: Destroy
  },
  {
    path: '/secondaryMarket/onChainInForMation',
    element: onChainInForMation
  },
  {
    path: '/secondaryMarket/WantToBuy',
    element: WantToBuy
  },
  {
    path: '/secondaryMarket/orderPayment',
    element: OrderPayment
  },
  {
    path: '/secondaryMarket/Prepaid',
    element: Prepaid
  },
  {
    path: '/secondaryMarket/PriceSold',
    element: PriceSold
  },
  {
    path: '/secondaryMarket/TradeOk',
    element: TradeOk
  },
  {
    path: '/secondaryMarket/PaymentPage',
    element: PaymentPage
  },
  {
    path: '/secondaryMarket/OnChainRecords',
    element: OnChainRecords
  },
  {
    path: '/freightPay',
    element: FreightPay
  },
  {
    path: '/assetsDetail',
    element: AssetsDetail
  },
  {
    path: '/announcement/announcement',
    element: Announcement
  },
  {
    path: '/announcement/NoticeDetails',
    element: NoticeDetails
  },
  {
    path: '/invite/InviteRankingList',
    element: InviteRankingList
  },
  {
    path: '/AboutUs',
    element: AboutUs
  },
  {
    path: '/secondaryMarket/AllNumbers',
    element: AllNumbers
  },
  {
    path: '/Password',
    element: Password
  },
  {
    path: '/mining/Pledge',
    element: Pledge
  },
  {
    path: '/universe/StarSourceKey',
    element: StarSourceKey
  },
  {
    path: '/WinningRecord',
    element: WinningRecord
  },
  {
    path: '/commodityDetail/CornerstoneCard',
    element: CornerstoneCard
  },
  {
    path: '/CollectCards',
    element: CollectCards
  },
  {
    path: '/synthesis',
    element: synthesis
  },
  {
    path: '/synthesis/synthesisDetails',
    element: SynthesisDetails
  },
  {
    path: '/synthesis/CollectionSynthesis',
    element: CollectionSynthesis
  },
  {
    path: '/synthesis/CastingStatus',
    element: CastingStatus
  },
  {
    path: '/my/SellAndSold',
    element: SellAndSold
  },
  {
    path: '/Game/PointsMall',
    element: PointsMall
  },
  {
    path: '/Game/ExchangeRecord',
    element: ExchangeRecord
  },
  {
    path: '/secondaryMarket/freeDeal',
    element: FreeDeal
  },
  {
    path: '/secondaryMarket/typer',
    element: Typer
  },
  {
    path: '/universe/MyMiyaoList',
    element: MyMiyaoList
  },
  {
    path: '/giveNft',
    element: GiveNft
  },
  {
    path: '/AlchemyInstitute/Active',
    element: AlchemyInstituteActive
  },
  {
    path: '/universe/score',
    element: Score
  },
  {
    path: '/universe/score/Record',
    element: Record
  },
]