import { observable, action, makeObservable } from 'mobx'
import { bonusDivideHistoryApi } from './serve'
import type { bonusDivideHistoryType } from './type'

class Store {
  constructor() {
    makeObservable(this)
  }

  /**
   * 游戏奖池瓜分记录
   * @param params 
   * @returns 
   */
  @observable bonusDivideHistoryList: any[] = []
  @observable bonusDivideHistoryHasMore: boolean = true
  @action getBonusDivideHistory: bonusDivideHistoryType = async (params) => {
    const data = await bonusDivideHistoryApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.bonusDivideHistoryList = [...this.bonusDivideHistoryList, ...data.data.data]
      } else {
        this.bonusDivideHistoryList = data.data.data
      }
      this.bonusDivideHistoryHasMore = Boolean(data.data.data.length > 0)
    } else {
      this.bonusDivideHistoryHasMore = false
    }
    return Promise.resolve(data)
  }

}

export default new Store()