import React, { FC } from 'react';
import styles from './index.module.scss';

interface ModalProps {
  visible: boolean;
  maskClosable?: boolean;
  onClose?: () => void;
  position: 'center' | 'bottom' | 'top'; // 内容位置
  children: any;
}

const Modal: FC<ModalProps> = (props) => {
  const { visible, maskClosable, children, onClose, position = 'center' } = props;

  const onClickMask = (e) => {
    maskClosable && onClose && onClose();
  };

  return visible ? (
    <div className={styles['modalLayout']}>
      <div className={styles['mask']} onClick={onClickMask}></div>
      <div className={`${styles['modalContant']} ${styles[position]}`}>{children}</div>
    </div>
  ) : null;
};

export default Modal;
