import { observable, action, makeObservable } from 'mobx'
import {
  redeemGoldHistoryApi,
  bonusUserRedeemGoldApi
} from './serve'
import {
  RedeemGoldHistoryType,
  BonusUserRedeemGoldType
} from './type'

class Store {
  constructor() {
    makeObservable(this)
  }






  /**
   * 用户积分兑换黄金历史
   * @param params 
   * @returns 
   */
  @observable redeemGoldHistory: any[] = []
  @observable totalRedeemAmount: number = 0
  @observable redeemGoldHistoryHasMore: boolean = true
  @action getRedeemGoldHistory: RedeemGoldHistoryType = async (params = {}) => {
    const data = await redeemGoldHistoryApi(params)
    if (data.data.code === 0) {
      if (params.skip && params.skip > 0) {
        this.redeemGoldHistory = [...this.redeemGoldHistory, ...data.data.data.list]
      } else {
        this.redeemGoldHistory = data.data.data.list
      }
      this.totalRedeemAmount = data.data.data.totalRedeemAmount
      this.redeemGoldHistoryHasMore = Boolean(data.data.data.list.length > 0)
    } else {
      this.redeemGoldHistoryHasMore = false
    }
    return Promise.resolve(data)
  }






  /**
   * 使用积分兑换黄金
   * @param params 
   * @returns 
   */
  @action bonusUserRedeemGold: BonusUserRedeemGoldType = async (params) => {
    const data = await bonusUserRedeemGoldApi(params)
    return Promise.resolve(data)
  }


}

export default new Store()