import { useNavigate } from 'react-router-dom'

import styles from './aboutUs.module.scss';
import Header from '../../component/header/header';


const AboutUs: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div id={styles['AboutUs']}>
      <Header text='关于我们' />
      <div className={styles['AboutUs-logo']}>
        <div className={styles['AboutUs-logo-box']}>
          <img className={styles['AboutUs-logo-img1']} src={require('../../assets/imgs/AboutUs-log.svg').default} alt="" />
          <div>
            <img className={styles['AboutUs-logo-img2']} src={require('../../assets/imgs/AboutUs-text.svg').default} alt="" />
          </div>
        </div>
      </div>
      <div className={styles['AboutUs-Terms-box']}>
        <div>WYPER 空间白皮书</div>
        <img className={styles['AboutUs-Terms-box-img']} src={require('../../assets/imgs/AboutUs-Vector.svg').default} alt="" />
      </div>
      <div className={styles['AboutUs-Terms-box']} onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>
        <div>隐私条款</div>
        <img className={styles['AboutUs-Terms-box-img']} src={require('../../assets/imgs/AboutUs-Vector.svg').default} alt="" />
      </div>
      <div className={styles['AboutUs-Terms-box']} onClick={() => navigate('/webView', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>
        <div>用户协议</div>
        <img className={styles['AboutUs-Terms-box-img']} src={require('../../assets/imgs/AboutUs-Vector.svg').default} alt="" />
      </div>

      <div className={styles.AboutUsICP}>沪 ICP 备 2022001874号-6</div>
    </div>
  )
}

export default AboutUs;