import { useEffect } from "react";
import Header from "../../../component/header/header";
import './SubscriptionRecords.scss'
import miningStore from "@/store/miningStore";
import { observer } from 'mobx-react'
import moment from "moment";

const SubscriptionRecords: React.FC = () => {

  useEffect(() => {
    miningStore.subscribeHistory()
  }, [])

  return (
    <div id="SubscriptionRecords">
      <Header text='申购记录' fontColor="black" />
      {miningStore.subscribeHistoryList.length > 0 ? miningStore.subscribeHistoryList.map((x, i) => (
        <div className="SubscriptionRecords-box" key={i}>
          <div className="SubscriptionRecords-box-name">{x.title}</div>
          <div className="SubscriptionRecords-box-time">
            <h3>{x.score}</h3>
            <p>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 300 }}>
          <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      )}
    </div>
  )
}
export default observer(SubscriptionRecords);