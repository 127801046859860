import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Toast } from 'antd-mobile'
import Header from '../../component/header/header'
import appState from '@/store/appState'

import './sms.scss'

const Sms = () => {
  const [state, setState] = useState({
    authCodeItem: ["", "", "", "", "", "", "", ""],
  });
  const [countdown, setCountdown] = useState(30);
  const loaction = useLocation()
  const navigate = useNavigate()
  const handleChangeCode = (e: any) => {
    let value: string = e.target.value;
    const data = value.split("");
    if (data.length > 6) return
    const authCodeItem = ["", "", "", "", "", ""];
    data.map((item, index) => {
      authCodeItem[index] = item;
    });
    setState({ ...state, authCodeItem });
    return value;
  };
  const countdownHandler = () => {
    let num = 30;
    const interval = setInterval(() => {
      if (num <= 0) {
        clearInterval(interval);
      }
      setCountdown(num);
      num--;
    }, 1000);
  };
  const init = () => {
    if (localStorage.getItem('commonData')) {
      window.location.replace('/')
    }
    countdownHandler()
  }
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!state.authCodeItem.some(item => item === "")) {
      const params: any = {
        mobile: loaction.state,
        code: state.authCodeItem.join(""),
        biz: 'nft',
        app: 'nft',
      }
      if (localStorage.getItem('utmSource')) {
        params.utmSource = localStorage.getItem('utmSource')
      }
      if (localStorage.getItem('inviterId')) {
        params.inviterId = localStorage.getItem('inviterId')
      }
      appState.loginHandle(params).then(() => {
        if (appState.commonData.can_bind_inviter_id) {
          navigate('/inviteCode')
        } else {
          navigate('/')
        }
      })
    }
  }, [state.authCodeItem])

  return (
    <div id='sms' >
      <Header />
      <span className='text1'>请输入验证码</span>
      <span className='text2'>验证码已通过短信发送至 {loaction.state} </span>
      <div className="authCode">
        <input
          className="keyword"
          maxLength={6}
          type="tel"
          onInput={handleChangeCode}
          autoComplete='on'
          autoFocus
        />
        <div className="showCode">
          <div className="codeItem">{state.authCodeItem[0]}</div>
          <div className="codeItem">{state.authCodeItem[1]}</div>
          <div className="codeItem">{state.authCodeItem[2]}</div>
          <div className="codeItem">{state.authCodeItem[3]}</div>
          <div className="codeItem">{state.authCodeItem[4]}</div>
          <div className="codeItem">{state.authCodeItem[5]}</div>
        </div>
      </div>
      <span className='text3'>没收到验证码？
        <span
          onClick={() => {
            if (countdown <= 0) countdownHandler();
          }}
          style={{ color: '#4475f2' }}
        >
          {countdown > 0 ? countdown + "秒后重发" : "点击重发"}
        </span>
      </span>
    </div>
  )
}
export default observer(Sms)