import { Toast } from "antd-mobile";
import { useNavigate } from 'react-router-dom'
import CommonDialog from "../../component/commonDialog/commonDialog";

import './boxDialog.scss'

interface PropsType {
  show: boolean;
  action: 'opening' | 'opened';
  onclose: (action?: 'checkNft') => void;
  openBox: () => void;
  boxItem: any;
  type: 0 | 1 | 2
}

const BoxDialog: React.FC<PropsType> = (props) => {
  const navigate = useNavigate()

  return (
    <CommonDialog show={props.show} close={props.onclose}>
      <>
        {props.type === 1 && (<div onClick={(e) => e.stopPropagation()}>
          {props.action === 'opening' &&
            <div id="BoxDialog-opening" className="animate__animated animate__zoomIn">
              <div className="BoxDialog-title">
                <span className="BoxDialog-title-text">开启盲盒</span>
                <span className="BoxDialog-title-line"></span>
              </div>
              <img className="BoxDialog-img" src={props.boxItem?.thumbList && props.boxItem?.thumbList[props.boxItem?.thumbList?.length - 1]} alt="" />
              <span className="BoxDialog-text">
                {props.boxItem?.title}
              </span>
              <div className="BoxDialog-btn btn" onClick={props.openBox}>
                开启盲盒
              </div>
              <div className="BoxDialog-bottom" onClick={() => Toast.show('转增即将上线')}>
                <img width={20} src={require('../../assets/imgs/gift-line.svg').default} alt="" />
                <span className="BoxDialog-bottom-text">送给朋友</span>
              </div>
            </div>
          }
          {props.action === 'opened' &&
            <div id="BoxDialog-opened" className="animate__animated animate__zoomIn">
              <img className="BoxDialog-img" src={props.boxItem?.thumb && props.boxItem?.thumb[props.boxItem?.thumb?.length - 1]} alt="" />
              <img className="BoxDialog-bj" src={require('../../assets/imgs/coinBoxSplitBg 1.png')} alt="" />
              <div className="BoxDialog-btn btn" onClick={() => navigate('/mining/MyMiningCave')}>
                立即采矿
              </div>
              <div className="BoxDialog-bottom" onClick={() => navigate('/miningPoster')}>
                <img width={20} src={require('../../assets/imgs/hammer-line.svg').default} alt="" />
                <span className="BoxDialog-bottom-text">采矿玩法介绍</span>
              </div>
            </div>
          }
        </div>
        )}

        {props.type === 2 && (<div onClick={(e) => e.stopPropagation()}>
          {props.action === 'opening' &&
            <div id="BoxDialog-opening" className="animate__animated animate__zoomIn">
              <div className="BoxDialog-title">
                <span className="BoxDialog-title-text">开启盲盒</span>
                <span className="BoxDialog-title-line"></span>
              </div>
              <img className="BoxDialog-img" src={props.boxItem?.thumb && props.boxItem?.thumb[props.boxItem?.thumb?.length - 1]} alt="" />
              <span className="BoxDialog-text">
                {props.boxItem?.title}
              </span>
              <div className="BoxDialog-btn btn" onClick={props.openBox}>
                开启盲盒
              </div>
              <div className="BoxDialog-bottom" onClick={() => Toast.show('敬请期待')}>
                <img width={20} src={require('../../assets/imgs/gift-line.svg').default} alt="" />
                <span className="BoxDialog-bottom-text">送给朋友</span>
              </div>
            </div>
          }
          {props.action === 'opened' &&
            <div id="BoxDialog-opened" className="animate__animated animate__zoomIn">
              <img className="BoxDialog-img" src={props.boxItem?.asset_thumb && props.boxItem?.asset_thumb[props.boxItem?.asset_thumb?.length - 1]} alt="" />
              <img className="BoxDialog-bj" src={require('../../assets/imgs/coinBoxSplitBg 1.png')} alt="" />
              <div className="BoxDialog-btn btn" onClick={() => props.onclose('checkNft')}>
                存入我的藏品
              </div>
            </div>
          }
        </div>
        )}
      </>
    </CommonDialog>
  )
}

export default BoxDialog