import Axios from '@/request/request'
import qs from 'qs'
import {
  RedeemGoldHistoryType,
  BonusUserRedeemGoldType
} from './type'

export const redeemGoldHistoryApi: RedeemGoldHistoryType = (params) => {
  return Axios.get(`/bonus/user/redeemGoldHistory?${qs.stringify(params)}`)
}

export const bonusUserRedeemGoldApi: BonusUserRedeemGoldType = (params) => {
  return Axios.post('/bonus/user/redeemGold', params)
}