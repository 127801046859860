import { observer } from 'mobx-react'
import styles from './inviteRankingList.module.scss';
import { useNavigate } from 'react-router-dom';
import { Picker, Button, Space, Toast } from 'antd-mobile';
import primaryMarket from '@/store/primaryMarket';
import { useEffect, useState } from 'react';
import moment from 'moment';

enum Title {
  inviteeBought = '邀请达人榜',
  userScore = '原石达人榜'
}

enum Num {
  inviteeBought = '邀请指数',
  userScore = '原石持仓'
}

const InviteRankingList: React.FC = () => {
  const navigate = useNavigate()
  const { inviteRankList } = primaryMarket
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<Array<'userScore' | 'inviteeBought'>>(['userScore']);
  const basicColumns = [
    [
      { label: '邀请达人榜', value: 'inviteeBought' },
      { label: '原石达人榜', value: 'userScore' },
    ],
  ]

  useEffect(() => {
    primaryMarket.getinviteRankList(value[0])
  }, [value])

  return (
    <div id={styles['InviteRankingList']}>
      <div className={styles['InviteRankingList-header']}>
        <img onClick={() => navigate(-1)} width={24} src={require('../../assets/imgs/arrow-left-white.svg').default} alt="" />
        <div className={styles['InviteRankingList-header-topBtn']} onClick={() => { setVisible(true) }}>
          <div className={styles['InviteRankingList-header-topBtn-div']}>{Title[value[0]]}</div>
          <img src={require('../../assets/imgs/InviteRankingList-fill.svg').default} alt="" />
        </div>

        <div >
          <Picker
            columns={basicColumns}
            visible={visible}
            popupClassName={styles['InviteRankingList-Picker']}
            onClose={() => {
              setVisible(false)
            }}
            value={value}
            onConfirm={(v: any) => {
              setValue(v)
            }}
            style={{
              '--header-button-font-size': '16px',
              '--item-font-size': '20px',
              '--item-height': '34px',
              height: '260px',
            }}
          />
        </div>

      </div>

      <div className={styles['InviteRankingList-nav']}>
        <div className={styles['InviteRankingList-nav-left']}>
          <div className={styles['InviteRankingList-nav-left-time']}>202312期</div>
          <h2 className={styles['InviteRankingList-nav-left-h2']}>{Title[value[0]]}</h2>
          <p className={styles['InviteRankingList-nav-left-p']}>我的排名：{inviteRankList.user_rank ? `NO.${inviteRankList.user_rank}` : '未上榜'}</p>
        </div>
        <img className={styles['InviteRankingList-nav-img2']} width={185} src={require('../../assets/imgs/image 387.png')} alt="" />
      </div>

      <div className={styles['InviteRankingList-center']}>
        <div className={styles['InviteRankingList-center-box']}>
          <div className={styles['InviteRankingList-center-top']}>
            <h3 className={styles['InviteRankingList-center-top-h3']}>排行榜</h3>
            <p className={styles['InviteRankingList-center-top-p']}>更新时间:{moment(inviteRankList?.update_time).format('YYYY.MM.DD HH:mm')}</p>
          </div>
          <div className={styles['InviteRankingList-center-title']}>
            <div className={styles['InviteRankingList-center-title-left']}>
              <div className={styles['InviteRankingList-center-title-span1']}>排名</div>
              <div className={styles['InviteRankingList-center-title-span2']}>用户昵称</div>
            </div>
            <div className={styles['InviteRankingList-center-title-span3']}>{Num[value[0]]}</div>
          </div>

          <div className={styles['InviteRankingList-list']}>
            {inviteRankList?.rank_list?.length > 0 ? inviteRankList?.rank_list.map((x: any, i: number) => (
              <div className={styles['InviteRankingList-listBox']} key={i}>
                <div className={styles['InviteRankingList-listBox-left']}>
                  <div>NO.{i + 1}</div>
                  <div className={styles['InviteRankingList-listBox-center']}>
                    <img height={45} src={x.avatar_url} alt="" />
                    <div>{x.nick_name}</div>
                  </div>
                </div>
                <div>{x.total_count}</div>
              </div>
            )) : (
              <div className='noData' style={{ top: 50 }}>
                <img className='noData-img' src={require('../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  )
}

export default observer(InviteRankingList);