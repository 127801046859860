import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Popup, CenterPopup } from 'antd-mobile';
import appState from '@/store/appState';
import moment from 'moment';
import store from './reducer';
import Header from '../../../component/header/header';
import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll';

import MINE_SCORE from '@/assets/imgs/Pledge-grade.svg'; // 原石
import LOTTERY_SCORE from '@/assets/imgs/Pledge-left2.svg'; // 抽奖奖卷
import SECRET_SCORE from '@/assets/imgs/Pledge-left.svg'; // 密钥

import styles from './pledge.module.scss';
import LuckyDraw from '@/component/LuckyDraw';

const staticResource = { MINE_SCORE, LOTTERY_SCORE, SECRET_SCORE };

const Pledge: React.FC = () => {
  const navigate = useNavigate();
  const [LuckyDrawVisible, setLuckyDrawVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const [weeks, setWeeks] = useState(4);
  const real_score = appState.commonData.real_score;

  const openPledgeModal = () => {
    if (real_score >= 10) {
      setQuantity(10);
    } else {
      setQuantity(0);
    }
    setVisible(true);
  };

  const onChangeQuantity = (type: 'add' | 'subtract' | 'max') => {
    if (type === 'add' && quantity + 10 <= Number(real_score)) {
      setQuantity(quantity + 10);
    } else if (type === 'subtract' && quantity >= 10) {
      setQuantity(quantity - 10);
    } else if (type === 'max') {
      setQuantity(Math.floor(real_score / 10) * 10);
    }
  };

  const onChangeWeeks = (type: 'add' | 'subtract' | 'max') => {
    if (type === 'add' && weeks < 4) {
      setWeeks(weeks + 1);
    } else if (type === 'subtract' && weeks >= 1) {
      setWeeks(weeks - 1);
    } else if (type === 'max') {
      setWeeks(4);
    }
  };

  const onSubmitPledge = () => {
    if (quantity >= 10 && weeks >= 1) setVisible2(true);
  };

  const onConfirm = async () => {
    setVisible(false);
    setVisible2(false);
    await store.submitPledge({ count: quantity, weeks });
  };

  const onCancel = () => {
    setVisible2(false);
  };

  useEffect(() => {
    store.computedPledgeIncome({ count: quantity, weeks });
  }, [quantity, weeks]);

  useEffect(() => {
    store.queryPledgeDetail();
    store.queryPledgeHistory();
    store.computedglobalPledgeIncome();
  }, []);

  return (
    <div id={styles['Pledge']}>
      <Header text='原石质押' menu={<span onClick={() => navigate('/indexPoster')}>玩法介绍</span>} fontColor='white' />
      <LuckyDraw
        gameId={store.detail.gameId}
        visible={LuckyDrawVisible}
        onClose={() => {
          setLuckyDrawVisible(false);
        }}
      />
      <div className={styles['Pledge-title']}>
        <div className={styles['Pledge-title-left']}>
          <p className={styles['Pledge-title-left-p']}>预计每100个原石今日可获得星源秘钥</p>
          <div className={styles['Pledge-title-left-foot']}>
            <div>{store.globalSecretCount}</div>
            <img className={styles['Pledge-title-left-foot-img1']} src={SECRET_SCORE} alt='' />
            {/* <div className={styles['Pledge-title-left-foot-two']}>+&nbsp;0</div>
            <img className={styles['Pledge-title-left-foot-img2']} src={LOTTERY_SCORE} alt='' /> */}
          </div>
        </div>
        <img
          className={styles['Pledge-title-right']}
          src={require('../../../assets/imgs/Pledge-img.svg').default}
          alt=''
        />
      </div>

      <div className={styles['Pledge-nav']}>
        <div className={styles['Pledge-nav-left']}>
          <div>我质押的原石</div>
          <div className={styles['Pledge-nav-left-num']}>
            <h3 className={styles['Pledge-nav-left-num-h3']}>{store.detail.pledgeRealScore}</h3>
            <img className={styles['Pledge-nav-left-num-img']} src={MINE_SCORE} alt='' />
          </div>
        </div>
        <div className={styles['Pledge-nav-right']}>
          <div className={styles['Pledge-nav-right-btn1']} onClick={openPledgeModal}>
            质押原石
          </div>
          <div
            className={styles['Pledge-nav-right-btn2']}
            onClick={() => {
              setLuckyDrawVisible(true);
            }}>
            奖券抽奖
          </div>
        </div>
      </div>

      <div className={styles['Pledge-award']}>
        <div className={styles['Pledge-award-left']}>
          <div className={styles['Pledge-award-left-top']}>
            <div>今日预计获得</div>
            <img className={styles['Pledge-award-left-top-img']} src={SECRET_SCORE} alt='' />
            <div className={styles['Pledge-award-left-top-num']}>{Math.floor(store.detail.todaySecret)}</div>
          </div>
          <div className={styles['Pledge-award-left-fot']}>
            <div className={styles['Pledge-award-left-fot-tadady']}>今日预计获得</div>
            <img className={styles['Pledge-award-left-fot-img']} src={LOTTERY_SCORE} alt='' />
            <div>{Math.floor(store.detail.todayLuckCount)}</div>
          </div>
        </div>

        <div className={styles['Pledge-award-right']}>
          <div className={styles['Pledge-award-right-top']}>
            <div>累计获得</div>
            <img className={styles['Pledge-award-right-top-img']} src={SECRET_SCORE} alt='' />
            <div className={styles['Pledge-award-right-top-num']}>{Math.floor(store.detail.pledgeSecretKeyScore)}</div>
          </div>
          <div className={styles['Pledge-award-right-fot']}>
            <div className={styles['Pledge-award-right-fot-tadady']}>累计获得</div>
            <img className={styles['Pledge-award-right-fot-img']} src={LOTTERY_SCORE} alt='' />
            <div>{Math.floor(store.detail.pledgeTotalLuckCount)}</div>
          </div>
        </div>
      </div>

      <div className={styles['Pledge-incomeBox']}>
        <div className={styles['Pledge-income']}>
          <div
            className={`${styles['Pledge-income-left']} ${store.changeType === 'INCOME' ? styles['black'] : ''}`}
            onClick={() => store.onChangePledgeHistoryTab('INCOME')}>
            收入
          </div>
          <div
            className={`${styles['Pledge-income-right']} ${store.changeType === 'EXPENDITURE' ? styles['black'] : ''}`}
            onClick={() => {
              store.onChangePledgeHistoryTab('EXPENDITURE');
            }}>
            支出
          </div>
        </div>
        <div className={styles.scrollView}>
          {store.list.map((item, index) => (
            <div className={styles['Pledge-incomeBox-content']} key={`Pledge-incomeBox-content-${index}`}>
              <div className={styles['Pledge-incomeBox-content-left']}>
                <div>{item.title}</div>
                <p className={styles['Pledge-incomeBox-content-left-p']}>
                  {moment(item.time).format('YYYY.MM.DD HH:mm')}{' '}
                </p>
              </div>
              <div className={styles['Pledge-incomeBox-content-right']}>
                <div>{item.score}</div>
                <img
                  className={styles['Pledge-incomeBox-content-right-img']}
                  src={staticResource[item.type as 'MINE_SCORE' | 'LOTTERY_SCORE' | 'SECRET_SCORE']}
                  alt=''
                />
              </div>
            </div>
          ))}
          <InfiniteScrollComponent
            hasMore={store.paginator.hasNextPage}
            dataSource={store.list}
            loadMore={store.pageTurn}
            top={50}
          />
        </div>
      </div>

      <div className={styles['Pledge-text']}>
        <h3 className={styles['Pledge-text-h3']}>活动描述</h3>
        <div className={styles['Pledge-text-box']}>
          <p>
            用户通过质押原石可参与质押采矿瓜分星源秘钥活动。质押瓜分的星源秘钥每天等量释放21000000个，分1000天采完。
          </p>
          <p>
            采矿规则：平台根据用户每天参与质押的原石总量，以相应比例瓜分当天释放的星源秘钥数量。每个原石可瓜分的星源秘钥数量均等。
          </p>
          <p>1、原石计算: 以用户当天实际可计算的原石有效质押量为准，汇总所有用户的有效质押数量；</p>
          <p>2、有效质押数量：以用户实际参与质押的时间为准，参与当日即可计入当天原石有效质押量；</p>
          <p>3、用户每天瓜分的星源秘钥数量 = （每个用户当天原石有效质押量/当天有效质押总数） * 21000000 ；</p>
          <p>4、发放规则：次日统一发放前一日获得的星源秘钥数量；</p>
          <p>5、质押解锁：根据选择质押的时间确定质押天数，质押时间结束后，相应原石将解锁；</p>
          <p>6、本活动的最终解释权归平台所有。</p>
        </div>
      </div>

      <Popup
        getContainer={null}
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '22px',
          borderTopRightRadius: '22px',
          minHeight: '40vh',
        }}>
        {
          <div className={styles['Pledge-popup']}>
            <div className={styles['Pledge-popup-title']}>
              <img
                className={styles['Pledge-popup-title-left']}
                src={require('../../../assets/imgs/Pledge-popup.svg').default}
                alt=''
              />
              <div className={styles['Pledge-popup-title-right']}>
                <div>质押原石</div>
                <div className={styles['Pledge-popup-title-right-foot']}>
                  <img
                    className={styles['Pledge-popup-title-right-img']}
                    src={require('../../../assets/imgs/Pledge-popup-icon.svg').default}
                    alt=''
                  />
                  <p>可用原石:{appState.commonData.real_score}</p>
                </div>
              </div>
            </div>
            <div className={styles['Pledge-popup-center']}>
              <div className={styles['Pledge-popup-center-left']}>质押数量</div>
              <div className={styles['Pledge-popup-center-right']}>
                <div
                  className={styles['Pledge-popup-center-right-reduce']}
                  onClick={() => onChangeQuantity('subtract')}>
                  －
                </div>
                <div className={styles['Pledge-popup-center-right-input']}>{quantity}</div>
                <div className={styles['Pledge-popup-center-right-add']} onClick={(e) => onChangeQuantity('add')}>
                  +
                </div>
                <div className={styles['Pledge-popup-center-right-max']} onClick={() => onChangeQuantity('max')}>
                  MAX
                </div>
              </div>
            </div>
            <div className={styles['Pledge-popup-center2']}>
              <div className={styles['Pledge-popup-center2-left']}>质押时间&nbsp;(周)</div>
              <div className={styles['Pledge-popup-center2-right']}>
                <div className={styles['Pledge-popup-center2-right-reduce']} onClick={() => onChangeWeeks('subtract')}>
                  －
                </div>
                <div className={styles['Pledge-popup-center2-right-input']}>{weeks}</div>
                <div className={styles['Pledge-popup-center2-right-add']} onClick={() => onChangeWeeks('add')}>
                  +
                </div>
                <div className={styles['Pledge-popup-center2-right-max']} onClick={() => onChangeWeeks('max')}>
                  MAX
                </div>
              </div>
            </div>
            <div className={styles['Pledge-popup-foot']}>
              <div className={styles['Pledge-popup-foot-left']}>
                <div className={styles['Pledge-popup-secretCount']}>
                  <div className={styles['Pledge-popup-secretCount-title']}>预计每日产出星源秘钥</div>
                  <div className={styles['Pledge-popup-secretCount-count']}>{store.secretCount}</div>
                </div>
                <div className={styles['Pledge-popup-luckCount']}>
                  <div className={styles['Pledge-popup-luckCount-title']}>获得奖券</div>
                  <div className={styles['Pledge-popup-luckCount-count']}>{store.luckCount}</div>
                </div>
              </div>
              <div className={styles['Pledge-popup-foot-right']} onClick={onSubmitPledge}>
                确认质押
              </div>
            </div>
          </div>
        }
      </Popup>

      <CenterPopup
        getContainer={null}
        visible={visible2}
        min-width='190px'
        onMaskClick={() => {
          setVisible2(false);
        }}>
        <div className={styles['Pledge-CenterPopup-box']}>
          <h3 className={styles['Pledge-CenterPopup-box-h3']}>原石质押</h3>
          <p className={styles['Pledge-CenterPopup-box-p']}>
            即将质押{quantity}个原石,时间{weeks}周
          </p>
          <div className={styles['Pledge-CenterPopup-box-btn']}>
            <div className={styles['Pledge-CenterPopup-box-btn-left']} onClick={onCancel}>
              取消
            </div>
            <div className={styles['Pledge-CenterPopup-box-btn-right']} onClick={onConfirm}>
              确定
            </div>
          </div>
        </div>
      </CenterPopup>
    </div>
  );
};

export default observer(Pledge);
