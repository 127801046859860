import CommonDialog from "../commonDialog/commonDialog"

import './authModal.scss'

const AuthModal: React.FC<{ close: () => void, show: boolean, type: 'startAuth' | 'authFail' }> = (props) => {
    return (
        <CommonDialog show={props.show}>
            <div id="AuthModal">
                <span className="AuthModal-text1">{props.type === 'authFail' ? '认证失败' : '实名认证'}</span>
                <p className="AuthModal-text2">{props.type === 'authFail' ? '认证信息与注册手机号实名不一致，请检查后重新认证' : '为确保交易公平性，请完成实名信息认证后购买'}</p>
                <div className='AuthModal-btn' onClick={props.close}>{props.type === 'authFail' ? '我知道了' : '立即认证'}</div>
            </div>
        </CommonDialog>
    )
}

export default AuthModal