import { useEffect } from 'react'
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom'
import { TabBar } from 'antd-mobile'

import Card from './card/card'
import Mining from './mining/mining'
import Mine from './mine/mine'
import Deal from './deal/deal'
import Universe from './universe/universe'

import './index.scss'


const Index: React.FC = () => {
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    useEffect(() => {
        const utmSource = new URLSearchParams(search).get('utmSource')
        if (utmSource) localStorage.setItem('utmSource', utmSource)
        const inviterId = new URLSearchParams(search).get('inviterId')
        if (inviterId) localStorage.setItem('inviterId', inviterId)
        if (!JSON.parse(localStorage.getItem('commonData') || "{}").authorization) {
            // navigate('/Intro')
        }
    }, [])

    const tabs = [
        {
            key: '/',
            title: '闪卡',
            icon: (
                pathname === '/' ?
                    <img src={require('../../assets/imgs/tarbar/1-active.svg').default} alt="" />
                    :
                    <img src={require('../../assets/imgs/tarbar/1.svg').default} alt="" />
            )
        },
        {
            key: '/mining',
            title: '采矿',
            icon: (
                pathname === '/mining' ?
                    <img src={require('../../assets/imgs/tarbar/2-active.svg').default} alt="" />
                    :
                    <img src={require('../../assets/imgs/tarbar/2.svg').default} alt="" />
            )
        },
        {
            key: '/deal',
            title: '解构',
            icon: (
                pathname === '/deal' ?
                    <img src={require('../../assets/imgs/tarbar/3-active.svg').default} alt="" />
                    :
                    <img src={require('../../assets/imgs/tarbar/3.svg').default} alt="" />
            )
        },
        {
            key: '/universe',
            title: '宇宙',
            icon: (
                pathname === '/universe' ?
                    <img src={require('../../assets/imgs/tarbar/4-active.svg').default} alt="" />
                    :
                    <img src={require('../../assets/imgs/tarbar/4.svg').default} alt="" />
            )
        },
        {
            key: '/mine',
            title: '我的',
            icon: (
                pathname === '/mine' ?
                    <img src={require('../../assets/imgs/tarbar/5-active.svg').default} alt="" />
                    :
                    <img src={require('../../assets/imgs/tarbar/5.svg').default} alt="" />
            )
        },
    ]

    return (
        <div id='Index'>
            <Routes>
                <Route path='/*' element={<Card />} />
                <Route path='/mining' element={<Mining />} />
                <Route path='/deal' element={<Deal />} />
                <Route path='/universe' element={<Universe />} />
                <Route path='/mine' element={<Mine />} />
            </Routes>

            <TabBar activeKey={pathname} onChange={x => navigate(x)}>
                {tabs.map(item => {
                    return <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                })}
            </TabBar>
        </div>
    )
}

export default Index