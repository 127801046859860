import styles from './exchangeRecord.module.scss';
import Header from '../../../component/header/header';
import { observer } from 'mobx-react'
import { useEffect } from 'react';
import primaryMarket from '@/store/primaryMarket';
import { InfiniteScroll } from 'antd-mobile'
import moment from 'moment';


const ExchangeRecord: React.FC = () => {

  const init = () => {
    primaryMarket.getBonusUserHistory({
      bonusType: 'yuanshi',
      action: 'redeem'
    })
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div id={styles['ExchangeRecord']}>
      <Header text='兑换记录' />
      <div className={styles['ExchangeRecord-textBox']}>
        <div className={styles['ExchangeRecord-textBox-text']}>
          <p>如您兑换实物，平台核实后将与您注册时使用的手机号码进行联系，具体发货规则以活动公告为准。</p>
          <p>如您兑换数字版权品，平台核实后将在3个工作日内空投至您的账户，具体空投时间以活动公告为准。</p>
        </div>
      </div>
      {primaryMarket.bonusUserHistoryList.length > 0 ? primaryMarket.bonusUserHistoryList.map((x, i) => (
        <div className={styles['ExchangeRecord-center']} key={i}>
          <div className={styles['ExchangeRecord-center-left']}>{x.title}x{x?.shopItem?.amount}</div>
          <div className={styles['ExchangeRecord-center-right']}>
            <h3 className={styles['ExchangeRecord-center-right-h3']}>{x.bonus} 原石</h3>
            <p className={styles['ExchangeRecord-center-right-p']}>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
          </div>
        </div>
      )) : null}

      <InfiniteScroll
        hasMore={primaryMarket.bonusUserHistoryHasMore}
        children={(hasMore) => {
          if (!hasMore && primaryMarket.bonusUserHistoryList.length === 0) {
            return (
              <div className='noData'>
                <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
          } else {
            return '没有更多了'
          }
        }}
        loadMore={async () => {
          await primaryMarket.getBonusUserHistory({
            bonusType: 'yuanshi',
            action: 'redeem',
            skip: primaryMarket.bonusUserHistoryList.length
          })
        }}
      />
    </div>
  )
}

export default observer(ExchangeRecord);

