import Header from '../../component/header/header';
import styles from './password.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react'
import appState from '@/store/appState';
import { Toast } from 'antd-mobile';
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'


const Password: React.FC = () => {
  const navigate = useNavigate();
  const [type, setType] = useState<'reset' | 'trade'>('trade')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [checked, setChecked] = useState(true)
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [countdown, setCountdown] = useState(0);
  const [code, setCode] = useState('')
  const { state } = useLocation()

  const countdownHandler = () => {
    if (countdown <= 0) {
      appState.sendMsg().then(({ data }) => {
        if (data.code === 0) {
          let num = 30;
          const interval = setInterval(() => {
            if (num <= 0) {
              clearInterval(interval);
            }
            setCountdown(num);
            num--;
          }, 1000);
        }
      })
    }
  };

  const setPwdHandle = () => {
    if (type === 'trade') {
      if (checked) {
        appState.usersPassword({
          password,
          repeatPassword
        }).then(({ data }) => {
          if (data.code === 0) {
            Toast.show({ icon: 'success', content: '设置成功' })
            navigate(-1)
            appState.getMenuData()
          }
        })
      }
    } else if (type === 'reset') {
      if (checked) {
        appState.resetPassword({
          password,
          repeatPassword,
          code
        }).then(({ data }) => {
          if (data.code === 0) {
            Toast.show({ icon: 'success', content: '重置成功' })
            navigate(-1)
            appState.getMenuData()
          }
        })
      }
    }
  }

  useEffect(() => {
    if (state?.type) {
      setType(state.type)
    }
  }, [])

  return (
    <div id={styles['Password']}>
      <Header />
      <h3 className={`${styles['Password-h3']} ${type === 'trade' ? '' : styles['noClass']}`}>请输入交易密码</h3>
      <h3 className={`${styles['Password-h3']} ${type === 'reset' ? '' : styles['noClass']}`}>重置交易密码</h3>

      <div className={styles['Password-center']}>
        <div className={`${styles['Password-inp']} ${type === 'reset' ? '' : styles['noClass']}`}>
          <input type="number" placeholder='输入短信验证码' className={styles['Password-inp-one']} value={code} onChange={e => setCode(e.target.value)} />
          <div className={styles['Password-inp-one-send'] + ' btn'} onClick={() => countdownHandler()}>{countdown > 0 ? countdown + " 秒后重发" : "发送"}</div>
        </div>
        <div className={styles['Password-inputBox']}>
          <div style={{ position: 'relative' }}>
            <input className={styles['Password-inputBox-inp1']} value={password} onChange={e => setPassword(e.target.value)} type={visible ? 'tel' : 'password'} name="" id="" placeholder='请输入&nbsp;6&nbsp;位数交易密码' />
            <div className={styles['Password-inputBox-eye']}>
              {!visible ? (
                <EyeInvisibleOutline onClick={() => setVisible(true)} />
              ) : (
                <EyeOutline onClick={() => setVisible(false)} />
              )}
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: 14 }}>
            <input className={styles['Password-inputBox-inp2']} value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} type={visible1 ? 'tel' : 'password'} name="" id="" placeholder='请重复确认交易密码' />
            <div className={styles['Password-inputBox-eye']}>
              {!visible1 ? (
                <EyeInvisibleOutline onClick={() => setVisible1(true)} />
              ) : (
                <EyeOutline onClick={() => setVisible1(false)} />
              )}
            </div>
          </div>
        </div>

        <div className={styles["Password-center-notice"]}>
          <input type="checkbox" className={styles['Password-center-notice-checkbox']} checked={checked} onChange={e => setChecked(e.target.checked)} />
          <p className={styles['Password-center-notice-p']}>您已同意 <a className={styles["Password-center-notice-p-a"]} onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a><span className={styles["giveRoug-center-notice-p-span"]}>与</span><a className={styles["giveRoug-center-notice-p-a"]} onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a></p>
        </div>
        <div className={styles['Password-btn-box']}>
          <div className={styles['Password-btn']} onClick={setPwdHandle}>确认设置</div>
        </div>
      </div>
    </div>
  )
}

export default observer(Password);