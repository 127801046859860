import { useState, useRef, useEffect, FC } from 'react';
import { LuckyWheel } from 'react-luck-draw';
import { Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import turntableBtn from '@/assets/imgs/turntable-btn.png';
import turntableBottomBackground from '@/assets/imgs/turntable-bottom-background.png';
import close from '@/assets/imgs/close.svg';
import Modal from '../Modal';
import { queryPrizeList, LuckyDrawAction, queyLuckyDrawCount } from './serve';
import CommonDialog from '../commonDialog/commonDialog';
import styles from './index.module.scss';

interface LuckyDrawProps {
  gameId: string;
  visible: boolean;
  onClose: () => void;
}

const LuckyDraw: FC<LuckyDrawProps> = (props) => {
  const { gameId = '', visible, onClose } = props;

  const navigate = useNavigate();

  const [prizes, setPrizes] = useState({ title: '', url: '', index: 0 });
  const [LuckyDrawCount, setLuckyDrawCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [luckyDrawResultModalVisible, setLuckyDrawResultModalVisible] = useState(false);
  const [LuckyWheelConfig, setLuckyWheelConfig] = useState({});
  const [noticeUrl, setNoticeUrl] = useState('');
  const [describe, setDescribe] = useState('');
  const myLucky = useRef({});

  const buttons = [
    {
      pointer: true,
      imgs: [
        {
          src: turntableBtn,
          width: '200px',
          top: '-101px',
        },
      ],
    },
  ];

  const handleLuckyDraw = async (count: 1 | 10) => {
    if (loading) return;
    setLoading(true);
    myLucky.current.play();

    const res = await LuckyDrawAction({ gameId, count });
    setLoading(false);
    if (res.data.code !== 0 || !res.data.data) {
      Toast.show({ content: '抽奖失败' });
      return;
    }
    myLucky.current.stop(res.data.data?.index);
    queryCount();
    setPrizes(res.data.data);
    setTimeout(() => {
      setLuckyDrawResultModalVisible(true);
    }, 5000);
  };

  const query = async () => {
    const res = await queryPrizeList({ gameId });
    if (res.data.code !== 0 || !res.data.data) return;
    setNoticeUrl(res.data.data.noticeUrl);
    const { count, url, describe = '' } = res.data.data;
    setDescribe(describe);
    setLuckyWheelConfig({
      prizes: Array.from({ length: count }, () => ({})),
      blocks: [
        {
          imgs: [
            {
              src: url,
              width: '100%',
              rotate: true,
            },
          ],
        },
      ],
    });
  };

  const queryCount = async () => {
    const queyLuckyDrawCountRes = await queyLuckyDrawCount({ gameId });
    if (queyLuckyDrawCountRes.data.code !== 0 || !queyLuckyDrawCountRes.data.data) return;
    setLuckyDrawCount(queyLuckyDrawCountRes.data?.data?.count || 0);
  };

  useEffect(() => {
    if (gameId) {
      query();
      queryCount();
    }
  }, [gameId]);

  return (
    <CommonDialog show={visible}>
      <>
        <div className={`${styles['LuckyDraw']} animate__animated animate__backInUp`}>
          <LuckyWheel ref={myLucky} width='400px' height='400px' buttons={buttons} {...LuckyWheelConfig} />
          <div className={styles['LuckyWheelStart']} onClick={() => handleLuckyDraw(1)} />
          <div className={styles['LuckyDrawBottom']}>
            <img className={styles['LuckyDraw-background']} src={turntableBottomBackground} alt='' />
            <div className={styles['LuckyDraw-stock']}>
              剩余奖券 <span className={styles['LuckyDraw-stockCount']}>{LuckyDrawCount}</span>
            </div>
            <div className={styles['LuckyDraw-desc']}>{describe}</div>

            <div className={styles['LuckyDraw-Btns']}>
              <div className={styles['LuckyDraw-Btns-ten']} onClick={() => handleLuckyDraw(10)}>
                十连抽
              </div>
              <div
                className={styles['LuckyDraw-Btns-all']}
                onClick={() => {
                  Toast.show({ content: '敬请期待' });
                }}>
                百连抽
              </div>
            </div>

            <div className={styles['LuckyDraw-Links']}>
              <div
                className={styles['LuckyDraw-Btns-rule']}
                onClick={() => navigate(`/announcement/noticeDetails?url=${noticeUrl}`)}>
                抽奖规则
              </div>
              <div
                className={styles['LuckyDraw-Btns-record']}
                onClick={() => {
                  navigate(`/WinningRecord`, { state: { gameId } });
                }}>
                中奖纪录
              </div>
            </div>

            <img
              className={styles['LuckyDraw-close']}
              src={close}
              onClick={() => {
                onClose && onClose();
              }}
              alt=''
            />
          </div>
        </div>
        <Modal visible={luckyDrawResultModalVisible} position='center'>
          <div className={styles['LuckyDrawResultModal']}>
            <div className={styles['LuckyDrawResultModal-title']}>恭喜获得奖励</div>
            <img className={styles['LuckyDrawResultModal-image']} src={prizes.url} alt='' />
            <div className={styles['LuckyDrawResultModal-name']}>{prizes.title}</div>
            <div
              className={styles['LuckyDrawResultModal-btn']}
              onClick={() => {
                setLuckyDrawResultModalVisible(false);
              }}>
              好的
            </div>
          </div>
        </Modal>
      </>
    </CommonDialog>
  );
};

export default LuckyDraw;
