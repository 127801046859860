import Axios from '@/request/request';
import qs from 'qs'
import { PropsType } from '../../global'

type PrimaryMarketType = PropsType['PrimaryMarket']

// 响应接口
type ResponseType = Promise<{
  data: {
    /* */
    code: number;

    /* */
    message: string;

    /* */
    data: Record<string, unknown> | unknown | any;
  }
}>


/** 
 * newCollection
 * @param {string} payment 
 * @param {string} bonus_type 
 * @returns
 */
export const hotCollectionListApi = (data?: { payment: string, bonus_type: string }): ResponseType => {
  return Axios.get(`/physicalGood/list?${qs.stringify(data)}`)
}


// 生成订单
export const createOrderApi = (data: any): ResponseType => {
  return Axios.get(`/nft/order/create?${qs.stringify(data)}`)
}

// 查询订单详情
export const queryOrderDetailApi = (order_no: string): ResponseType => {
  return Axios.get(`/nft/order/queryOrder?${qs.stringify({ order_no })}`)
}


/** 
 * collectionDetail
 * @param {string} physicalGoodId 
 * @returns
 */
export const physicalGoodDetailApi = (physicalGoodId: string): ResponseType => {
  return Axios.get(`/physicalGood/detail?physicalGoodId=${physicalGoodId}`);
}


//取消订单
export const cancelOrderApi = (order_no: string, type: 'wyper' | 'express'): ResponseType => {
  return Axios.get(`/nft/order/cancel?order_no=${order_no}&type=${type}`)
}

//订单查询
export const orderQueryApi: PrimaryMarketType['orderQuery'] = (params): ResponseType => {
  return Axios.get(`/nft/order/query?${qs.stringify(params)}`)
}

//支付
export const payApi = (order_no: string, type: 'wyper' | 'express', pay_channel: 'alipay' | 'sandpay' | 'huifupay'): Promise<{ data: string }> => {
  return Axios.get(`/pay/aliPay/h5?order_no=${order_no}&pay_channel=${pay_channel}&type=${type}`)
}

// nft资产
export const nftAssetsApi = (): ResponseType => {
  return Axios.get('/nft/assets')
}

// 盲盒列表
export const boxListApi = (): ResponseType => {
  return Axios.get('/nft/notRevealedAssetList')
}

// 开启盲盒
export const openBoxApi = (params: { user_asset_id: string }): ResponseType => {
  return Axios.post(`/nft/revealAsset`, params)
}

// 邀请记录
export const inviteRecordApi = (params: { invite_level: 1 | 2, skip?: number }): ResponseType => {
  return Axios.get(`/users/inviteRecord?${qs.stringify(params)}`)
}

// 发起寄存商品（快递）订单
export const initiateExpressOrderApi = (params: {
  express_order_no: string,
  delivery_mobile: string,
  delivery_name: string,
  delivery_address: string,
}): ResponseType => {
  return Axios.get(`/nft/order/initiateExpressOrder?${qs.stringify(params)}`)
}

export const getAssetDetailApi = (params: { asset_id: string, shard_id: string }): ResponseType => {
  return Axios.get(`/nft/assetDetail?${qs.stringify(params)}`)
}

export const inviteRankApi = (type: 'inviteeBought' | 'userScore' = 'inviteeBought'): ResponseType => {
  return Axios.get(`/users/inviteRank?type=${type}`)
}


/** 
 * collectionDetail
 * @param {string} asset_id 
 * @returns
 */
export const collectionDetailApi = (asset_id: string): ResponseType => {
  return Axios.get(`nft/collection/detail?asset_id=${asset_id}`);
}


export const nftBoxesMyApi = (): ResponseType => {
  return Axios.get('/nft/boxes/my')
}

export const nftBoxesOpenApi: PrimaryMarketType['nftBoxesOpenApi'] = (params) => {
  return Axios.get(`/nft/boxes/open?${qs.stringify(params)}`)
}

export const bonusShopItemListApi: PrimaryMarketType['bonusShopItemListApi'] = (params) => {
  return Axios.get(`/bonus/shop/itemList?${qs.stringify(params)}`)
}

export const bonusUserRedeemApi: PrimaryMarketType['bonusUserRedeemApi'] = (params) => {
  return Axios.post('/bonus/user/redeem', params)
}

export const bonusUserHistoryApi: PrimaryMarketType['bonusUserHistoryApi'] = (params) => {
  return Axios.get(`/bonus/user/history?${qs.stringify(params)}`)
}

export const nfOrderPreCheckApi: PrimaryMarketType['nfOrderPreCheckApi'] = (params) => {
  return Axios.post('/nft/order/preCheck', params)
}

export const nftOrderTransferCreateApi: PrimaryMarketType['nftOrderTransferCreateApi'] = async (params, headers) => {
  return Axios.post('/nft/order/transfer/create', params, { headers })
}

export const nftOrderTransferQueryOrderApi: PrimaryMarketType['nftOrderTransferQueryOrderApi'] = async (params) => {
  return Axios.get(`/nft/order/transfer/queryOrder?${qs.stringify(params)}`)
}

export const nftOrderTransferQueryApi: PrimaryMarketType['nftOrderTransferQueryApi'] = async (params) => {
  return Axios.get(`/nft/order/transfer/query?${qs.stringify(params)}`)
}