import { useEffect, useRef, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router'
import BuyModal from '../../../component/buyModal/buyModal'
import type {
  PropsType
} from '../../../global'


import './mining.scss'
import { _hmtHandle } from '@/utils/utils'

const Mining: React.FC<PropsType> = ({ MiningStore, AppState }) => {
  const [state, setState] = useState({
    buyModalVisibe: false
  })
  const navigate = useNavigate()
  const [type, setType] = useState<'GOLD' | 'SILVER' | 'BRONZE'>('GOLD')
  const mineDataInfo = MiningStore.mineDataInfo
  const groupMineDataInfo = MiningStore.groupMineDataInfo
  const { commonData } = AppState

  useEffect(() => {
    MiningStore.mineData(type)
  }, [type])

  useEffect(() => {
    MiningStore.groupMineData()

    _hmtHandle('任何设备', '访问', '「挖矿」页面')
  }, [])

  return (
    <div id="Mining">
      <div className='Mining-box1'>
        <span className='Mining-box1-title'>采源矿，得原石</span>
        <span className='Mining-box1-text' onClick={() => navigate('/miningPoster')}>
          玩法攻略
        </span>
        <div className='Mining-box1-btn btn' onClick={() => { navigate('/mining/mygem'); _hmtHandle('任何设备', '点击', '「挖矿」页面 「我的原石」按钮') }}>
          <div className='Mining-box1-btn-top'>
            <img className='Mining-box1-btn-top-img' src={require('../../../assets/imgs/stone.svg').default} alt="" />
            <span className='Mining-box1-btn-top-text'>{commonData?.real_score}</span>
          </div>
          <span className='Mining-box1-btn-text'>我的原石</span>
        </div>
      </div>
      <div className='Mining-box2'>
        <span className='Mining-box2-title'>原石应用</span>
        <div className='Mining-box2-content'>
          <div className='Mining-box2-content-item' onClick={() => navigate('/mining/pledge')}>
            <span className='Mining-box2-content-item-text1'>原石质押</span>
            <span className='Mining-box2-content-item-text2'>参与抽奖</span>
            <img className='Mining-box2-content-item-img' src={require('../../../assets/imgs/Ten oclock.png')} alt="" />
          </div>
          <div className='Mining-box2-content-item' onClick={() => navigate('/game/pointsMall')}>
            <span className='Mining-box2-content-item-text1'>WYPER商城</span>
            <span className='Mining-box2-content-item-text2'>兑换藏品</span>
            <img className='Mining-box2-content-item-img' src={require('../../../assets/imgs/1F579_FE0F_Joystick_01_01.png')} alt="" />
          </div>
          <div className='Mining-box2-content-item' onClick={() => navigate('/synthesis')}>
            <span className='Mining-box2-content-item-text1'>藏品合成</span>
            <span className='Mining-box2-content-item-text2'>现已开启</span>
            <img className='Mining-box2-content-item-img' src={require('../../../assets/imgs/1F5C4_FE0F_FileCabinet 1.png')} alt="" />
          </div>
        </div>
      </div>
      <div className='Mining-box3'>
        <div className='Mining-box3-top'>
          <span className='Mining-box3-top-item' style={type === 'GOLD' ? { backgroundColor: '#2A2F36' } : {}} onClick={() => { setType('GOLD') }}>黄金源矿</span>
          <span className='Mining-box3-top-item' style={type === 'SILVER' ? { backgroundColor: '#2A2F36' } : {}} onClick={() => { setType('SILVER') }}>白银源矿</span>
          <span className='Mining-box3-top-item' style={type === 'BRONZE' ? { backgroundColor: '#2A2F36' } : {}} onClick={() => { setType('BRONZE') }}>活动源矿</span>
        </div>
        <div className='Mining-box3-content'>
          <div className='Mining-box3-content-box'>
            <div className='Mining-box3-content-box-top' onClick={() => { navigate('/mining/MyMiningCave') }}>
              <div className='Mining-box3-content-box-top-title'>
                <div className='Mining-box3-content-box-top-title-item'>
                  产出
                  <img src={require('../../../assets/imgs/stone.svg').default} alt="" />
                  {mineDataInfo?.mineScore}
                </div>
                <div className='Mining-box3-content-box-top-title-item'>
                  {mineDataInfo?.openedCount}
                  <img src={require('../../../assets/imgs/tou.svg').default} alt="" />
                  正在采矿
                </div>
              </div>
              <img className='Mining-box3-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box3-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box3-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
            </div>
            <div className='Mining-box3-content-box-p'>
              <span className='Mining-box3-content-box-p-text1'>
                源矿 x {mineDataInfo?.mineCount}
              </span>
              <span className='Mining-box3-content-box-p-text2'>
                <img src={require('../../../assets/imgs/stone.svg').default} alt="" />
                {mineDataInfo?.mineRate}/天
              </span>
            </div>
            <div className='Mining-box3-content-box-btn btn' onClick={() => { setState({ ...state, buyModalVisibe: true }); _hmtHandle('任何设备', '点击', '「挖矿」页面 「获闪卡 开源矿」按钮') }}>
              获闪卡 开源矿
            </div>
          </div>
        </div>
      </div>

      <div className='Mining-box4'>
        <div className='Mining-box4-content'>
          <div className='Mining-box4-content-box'>
            <div className='Mining-box4-content-box-img'>
              组队采矿
            </div>
            <div className='Mining-box4-content-box-top' onClick={() => { navigate('/mining/MyMiningCave') }}>
              <div className='Mining-box4-content-box-top-title'>
                <div className='Mining-box4-content-box-top-title-item'>
                  产出
                  <img src={require('../../../assets/imgs/stone.svg').default} alt="" />
                  {groupMineDataInfo?.mineScore}
                </div>
                <div className='Mining-box4-content-box-top-title-item'>
                  {groupMineDataInfo?.openedCount}
                  <img src={require('../../../assets/imgs/tou.svg').default} alt="" />
                  正在采矿
                </div>
              </div>
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
              <img className='Mining-box4-content-box-top-gif' src={require('../../../assets/imgs/wa.gif')} alt="" />
            </div>
            <div className='Mining-box4-content-box-p'>
              <span className='Mining-box4-content-box-p-text1'>
                源矿 x {groupMineDataInfo?.mineCount}
              </span>
              <span className='Mining-box4-content-box-p-text2'>
                <img src={require('../../../assets/imgs/stone.svg').default} alt="" />
                {groupMineDataInfo?.mineRate}/天
              </span>
            </div>
            <div className='Mining-box4-content-box-btn btn' onClick={() => { navigate('/invite'); _hmtHandle('任何设备', '点击', '「挖矿」页面 「邀请好友组队采矿」按钮') }}>
              邀请好友组队采矿
            </div>
          </div>
        </div>
      </div>

      <BuyModal
        show={state.buyModalVisibe}
        close={() => setState({ ...state, buyModalVisibe: false })}
      />
    </div>
  )
}

export default inject('MiningStore', 'AppState')(observer(Mining))