import { useEffect, useState } from 'react'
import moment from 'moment';
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile';

import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll';
import primaryMarket from "@/store/primaryMarket";
import appState from '@/store/appState';
import Header from "@/component/header/header";
import './miyaoList.scss';


const MiyaoList: React.FC = () => {
  const { getBonusUserHistory, bonusUserHistoryList, bonusUserHistoryHasMore } = primaryMarket
  const [action, setAction] = useState<'income' | 'outcome'>('income')
  const { commonData } = appState
  const navigate = useNavigate()

  useEffect(() => {
    getBonusUserHistory({
      bonusType: 'miyao',
      action: action
    })
  }, [action])

  return (
    <div id="MiyaoList">
      <Header text='我的星源秘钥' fontColor="white" menu={<span onClick={() => navigate('/indexPoster')}>玩法介绍</span>} />
      <div className="MiyaoList-top">
        <div className="MiyaoList-top-my">
          <div>星源秘钥</div>
          <img src={require('@/assets/imgs/Universe-dot.svg').default} />
        </div>
        <div className="MiyaoList-top-time">{Math.floor(commonData?.miyao) || '0.00'}</div>
        <div className="MiyaoList-top-btn" onClick={() => Toast.show('敬请期待')}>参与活动</div>
      </div>
      <div className="MiyaoList-center">
        <div className="MiyaoList-foot">
          <div className="MiyaoList-foot-top">
            <span className="MiyaoList-icome" style={action === 'income' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setAction('income')}>收入</span>
            <span className="MiyaoList-font" style={action === 'outcome' ? { color: '#000', fontWeight: 600 } : {}} onClick={() => setAction('outcome')}>支出</span>
          </div>
        </div>
        {bonusUserHistoryList.length > 0 && bonusUserHistoryList.map((x, i) => (
          <div className="MiyaoList-foot-obtain" key={i}>
            <div className="MiyaoList-foot-obtain-left">
              <h3>{x.title}</h3>
              <p>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
            </div>
            <div className="MiyaoList-foot-obtain-right">
              {action === 'income' ? '+ ' : ''}
              {x?.bonus}
              <img width={22} src={require('@/assets/imgs/Universe-dot.svg').default} />
            </div>
          </div>
        ))}
      </div>


      <InfiniteScrollComponent
        hasMore={bonusUserHistoryHasMore}
        dataSource={bonusUserHistoryList}
        loadMore={async () => {
          await getBonusUserHistory({
            bonusType: 'miyao',
            action: action,
            skip: bonusUserHistoryList.length
          })
        }}
        top={350}
      />
    </div>
  )
}

export default observer(MiyaoList);