import { observable, action, makeObservable } from 'mobx';
import { queryCollectionSynthesisActivitylist, queryCollectionSynthesisDetail } from './serve';

interface IPaginator {
  page: number;
  pageSize: number;
  hasNextPage: boolean;
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable paginator: IPaginator = {
    page: 1,
    pageSize: 100000,
    hasNextPage: true,
  };

  @observable list: any[] = [];

  @observable listStatus: 'loading' | 'loaded' = 'loading';

  @action pageTurn = async () => {
    const { page, hasNextPage } = this.paginator;
    if (!hasNextPage || this.listStatus === 'loading') return;
    this.paginator = { ...this.paginator, page: page + 1 };
    this.query();
  };

  @action query = async () => {
    const { page, pageSize } = this.paginator;
    this.listStatus = 'loading';
    const res = await queryCollectionSynthesisActivitylist({ page, limit: pageSize });
    if (res?.data?.code === 0) {
      this.list = res?.data?.data?.list;
    }
    this.listStatus = 'loaded';
    return res;
  };

  @action queryCollectionSynthesisDetail(iid: string) {
    queryCollectionSynthesisDetail({ iid });
  }
}

export default new Store();
