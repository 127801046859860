import styles from './starSourceKey.module.scss';
import Header from '@/component/header/header';
import { useState } from 'react';


const StarSourceKey: React.FC = () => {
  const [type, setType] = useState('0')

  return (
    <div id={styles['starSourceKey']}>
      <Header text='交易挖矿' fontColor='white' menu={'玩法介绍'} />
      <div className={styles["starSourceKey-top"]}>
        <div className={styles["starSourceKey-top-left"]}>
          <p className={styles["starSourceKey-top-left-p"]}>我的星源秘钥</p>
          <div className={styles["starSourceKey-top-left-div"]}>
            <div>538</div>
            <img className={styles['starSourceKey-top-left-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
          </div>
          <p className={styles['starSourceKey-top-left-p2']}>每日0点将自动根据交易金额瓜分星源秘钥</p>
        </div>

        <div className={styles["starSourceKey-title"]}>
          <div className={styles['starSourceKey-title-left']}>
            <div className={styles['starSourceKey-title-left-top']}>1060</div>
            <div className={styles['starSourceKey-title-left--text']}>当日交易次数</div>
          </div>
          <div className={styles['starSourceKey-title-center']}>
            <div className={styles['starSourceKey-title-left-top']}>1060.50</div>
            <div className={styles['starSourceKey-title-left--text']}>当日交易金额(¥)</div>
          </div>
          <div className={styles['starSourceKey-title-right']}>
            <div className={styles['starSourceKey-title-left-top']}>87960.50</div>
            <div className={styles['starSourceKey-title-left--text']}>累计交易金额(¥)</div>
          </div>
        </div>
      </div>
      <div className={styles['starSourceKey-center']}>
        <div className={styles['starSourceKey-center-nav']}>
          <div className={`${styles['starSourceKey-center-nav-two']} ${type === '0' ? styles['act'] : ''}`} onClick={() => setType('0')}>排行榜</div>
          <div className={`${styles['starSourceKey-center-nav-two']} ${type === '1' ? styles['act'] : ''}`} onClick={() => setType('1')}>收入</div>
          <div className={`${styles['starSourceKey-center-nav-two']} ${type === '2' ? styles['act'] : ''}`} onClick={() => setType('2')}>支出</div>
        </div>

        {/*排行榜*/}
        <div className={`${styles['starSourceKey-foot']} ${type === '0' ? '' : styles['noStyles']}`}>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.1</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>

          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.2</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>

          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.3</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>
          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.4</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>
          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.1</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>
          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.1</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>
          </div>
          <div className={styles['starSourceKey-center-box']}>
            <div className={styles['starSourceKey-center-box-left']}>
              <h3 className={styles['starSourceKey-center-box-left-h3']}>NO.1</h3>
              <div className={styles['starSourceKey-center-box-left-div']}>
                <img className={styles['starSourceKey-center-box-left-div-img']} src={require('@/assets/imgs/starSourceKey-f.svg').default} alt="" />
                <div>159****7293</div>
              </div>
            </div>
            <div className={styles['starSourceKey-center-box-right']}>
              <img className={styles['starSourceKey-center-box-right-div-img']} src={require('@/assets/imgs/Pledge-left.svg').default} alt="" />
              <div>×&nbsp;538</div>
            </div>
          </div>
        </div>

        {/*收入*/}
        <div className={`${styles['starSourceKey-foot2']} ${type === '1' ? '' : styles['noStyles']}`}>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>+&nbsp;0.32</div>
          </div>
        </div>
        {/*收入*/}
        <div className={`${styles['starSourceKey-foot2']} ${type === '2' ? '' : styles['noStyles']}`}>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
          <div className={styles['starSourceKey-foot2-box']}>
            <div className={styles['starSourceKey-foot2-left']}>
              <div>挖矿获得</div>
              <p className={styles['starSourceKey-foot2-left-p']}>2022.06.22&nbsp;15:44</p>
            </div>
            <div className={styles['starSourceKey-foot2-right']}>-&nbsp;0.32</div>
          </div>
        </div>

        <div className={styles['starSourceKey-btn']}>去交易</div>
      </div>

    </div>
  )
}

export default StarSourceKey;