
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { Dialog, SwipeAction, Toast } from 'antd-mobile';
import Header from "../../../component/header/header";
import type { PropsType } from '../../../global'
import './ShippingAddress.scss'

const ShippingAddress: React.FC<PropsType> = ({ AppState }) => {
  const navigate = useNavigate()

  const init = () => {
    AppState.getDeliveryInfoList()
  }

  useEffect(() => {
    init()
  }, [])

  const maskPhoneNumber = (phoneNumber: string | any[]) => {
    const prefix = phoneNumber.slice(0, 3);
    const suffix = phoneNumber.slice(-4);

    const masked = Array(phoneNumber.length - 7).fill("*").join("");

    return prefix + masked + suffix;
  };

  return (
    <div id="HarvestAddress">
      <Header text='收货地址' fontColor="black" />

      <div className="HarvestAddress-margin">
        {AppState.deliveryInfoList.length > 0 ? AppState.deliveryInfoList.map((x, i) => (
          <SwipeAction
            key={i}
            rightActions={[
              {
                key: 'delete',
                text: '删除',
                color: 'danger',
                onClick: async () => {
                  await Dialog.confirm({
                    content: '确定要删除吗？',
                    onConfirm() {
                      AppState.deleteDeliveryInfo(x.id).then((res) => {
                        if (res.code === 0) {
                          Toast.show('删除成功');
                          init()
                        }
                      })
                    },
                  })
                },
              },
            ]}
          >
            <div className="HarvestAddress-box" onClick={() => navigate('/My/EditAddress', { state: JSON.stringify(x) })}>
              <img className="HarvestAddress-top-left-img" src={require('../../../assets/imgs/HarvestAddress-bj1.svg').default} />
              <div className="HarvestAddress-text">{x.name},&nbsp;{maskPhoneNumber(x.mobile)},&nbsp;{x.address}</div>
              <img className="HarvestAddress-top-right-mg" src={require('../../../assets/imgs/HarvestAddress-bj2.svg').default} />
            </div>
          </SwipeAction>
        )) : (
          <div className='noData'>
            <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
            <span className='noData-text'>暂无数据</span>
          </div>
        )}
      </div>
      <div className="HarvestAddress-btn btn" onClick={() => navigate('/My/EditAddress')}>
        <img className="HarvestAddress-btn-img" src={require('../../../assets/imgs/HarvestAddress-add.svg').default} alt="" />
        <div>新增收货地址</div>
      </div>
    </div>
  )
}

export default inject('AppState')(observer(ShippingAddress));