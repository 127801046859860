import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import commonStore from '@/store/commonStore'

import InfiniteScrollComponent from '@/component/infiniteScroll/infiniteScroll'
import Header from '@/component/header/header'
import styles from './record.module.scss'
import moment from 'moment'

enum State {
  START = '质押',
  END = '质押结束',
  CAL = '质押计算中'
}

const Record: React.FC = () => {
  const [filter, setFilter] = useState(0)
  const { getBattleGameHistory, battleGameHistoryList, battleGameHistoryListHasMore } = commonStore

  useEffect(() => {
    getBattleGameHistory()
  }, [])

  return (
    <div id={styles.record}>
      <Header text='原石星际逃亡' />
      {/* <div className={styles['record-nav']}>
        <span className={styles['record-nav-text']} style={filter === 0 ? { color: '#000', fontSize: 18, fontWeight: 600 } : {}} onClick={() => setFilter(0)}>全部</span>
        <span className={styles['record-nav-text']} style={filter === 1 ? { color: '#000', fontSize: 18, fontWeight: 600 } : {}} onClick={() => setFilter(1)}>胜利</span>
        <span className={styles['record-nav-text']} style={filter === 2 ? { color: '#000', fontSize: 18, fontWeight: 600 } : {}} onClick={() => setFilter(2)}>失败</span>
      </div> */}
      <div className={styles['record-list']}>
        {battleGameHistoryList.map((x, i) => (
          <div className={styles['record-list-item']} key={i}>
            <div className={styles['record-list-item-left']}>
              <span className={styles['record-list-item-left-top']}>{moment(x.date).format('YYYY.MM.DD')}</span>
              <span className={styles['record-list-item-left-bottom']}>质押天数：{x.days}</span>
            </div>
            <div className={styles['record-list-item-right']}>
              <div className={styles['record-list-item-right-top']}>
                <span className={`${styles['record-list-item-right-top-text']} ${x.state !== 'START' && styles['record-list-item-right-top-lose']}`}>{State[x.state as 'START']} {x.score !== 0 && x.score} </span>
                <img width={25} src={require('@/assets/imgs/stone.svg').default} alt="" />
              </div>
              <div className={styles['record-list-item-right-bottom']}>
                累计消耗 <span style={{ fontWeight: 600 }}>{x.secret}</span> 密钥
              </div>
            </div>
          </div>
        ))}
      </div>

      <InfiniteScrollComponent
        hasMore={battleGameHistoryListHasMore}
        dataSource={battleGameHistoryList}
        loadMore={async () => {
          await getBattleGameHistory({ skip: battleGameHistoryList.length })
        }}
      />
    </div>
  )
}

export default observer(Record)