import "./myGem.scss";
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Header from '@/component/header/header'
import moment from "moment";
import { Toast, InfiniteScroll } from "antd-mobile";
import miningStore from "@/store/miningStore";
import appState from "@/store/appState";


const MyGem: React.FC = () => {
  const [type, setType] = useState<'INCOME' | 'EXPENDITURE' | 'TRANSFER'>('INCOME');
  const navigate = useNavigate()
  const { commonData } = appState

  useEffect(() => {
    miningStore.getScoreHistory({ type })
  }, [type])



  return (
    <div id="MyGem">
      <Header text='我的原石' fontColor="white" menu={<span onClick={() => navigate('/indexPoster')}>玩法介绍</span>} />
      <div className="MyGem-center">
        <div className="MyGem-left">
          <div className="MyGem-my">
            <div>我的原石</div>
            <img src={require('../../../assets/imgs/MyGem-gem.svg').default} />
          </div>
          <div className="MyGem-time">{commonData?.real_score}</div>
          <div className="MyGem-btn">
            <div className="MyGem-subscribe" onClick={() => navigate('/mining/CollectionSubscription')}>申购藏品</div>
            <div className="MyGem-gift" onClick={() => navigate('/mining/giveRoug')}>礼赠朋友</div>
          </div>
        </div>
        <div className="MyGem-img"></div>
      </div>
      <div className="MyGem-foot">
        <div className="MyGem-foot-top">
          <span className="MyGem-font" style={type === 'INCOME' ? { color: '#000' } : {}} onClick={() => setType('INCOME')}>收入</span>
          <span className="MyGem-font" style={type === 'EXPENDITURE' ? { color: '#000' } : {}} onClick={() => setType('EXPENDITURE')}>支出</span>
          <span className="MyGem-font" style={type === 'TRANSFER' ? { color: '#000' } : {}} onClick={() => setType('TRANSFER')}>赠送</span>
        </div>
      </div>
      {miningStore.scoreHistoryList.length > 0 ? miningStore.scoreHistoryList.map((x, i) => (
        <div className="MyGem-foot-obtain" key={i}>
          <div className="MyGem-foot-obtain-left">
            <h3>{x.remark}</h3>
            <p>{moment(x.createTime).format('YYYY.MM.DD HH:mm')}</p>
          </div>
          <div className="MyGem-foot-obtain-right">
            {x.score}
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 400 }}>
          <img className='noData-img' src={require('../../../assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      )}

      {miningStore.scoreHistoryList.length > 0 && <InfiniteScroll
        hasMore={miningStore.hasMore}
        loadMore={async () => {
          await miningStore.getScoreHistory({ type, skip: miningStore.scoreHistoryList.length })
        }}
      />}
    </div >
  )
}

export default inject()(observer(MyGem))