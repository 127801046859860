import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Toast } from 'antd-mobile'
import { observer } from 'mobx-react'
import { _hmtHandle } from '@/utils/utils'
import appState from '@/store/appState'

import './universe.scss'

const Universe: React.FC = () => {
  const navigate = useNavigate()
  const { commonData } = appState

  return (
    <div id="Universe">
      <img className='Universe-title' src={require('../../../assets/imgs/Universe-logo.png')} alt="" />
      <div className='Universe-text'>已产出星源秘钥 <span className='Universe-text-span'>--</span></div>
      <div className='Universe-svg'>
        <img src={require('../../../assets/imgs/universe.gif')} alt="" />
      </div>

      <div className='Universe-box1'>
        <div className='Universe-btn btn' onClick={() => navigate('/universe/MyMiyaoList')}>
          <div className='Universe-btn-top'>
            <img style={{ marginRight: 5 }} src={require('../../../assets/imgs/Universe-dot.svg').default} />
            <span>{commonData?.miyao}</span>
          </div>
          <span className='Universe-btn-bottom'>我的星源秘钥</span>
        </div>
        <div className='Universe-box1-title'>
          <h3 className='Universe-box1-h3'>星源秘钥玩法</h3>
        </div>
        <div className='Universe-box1-itemBox'>
          <div className='Universe-box1-itemBox-item' onClick={() => navigate('/mining/pledge')}>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>原石质押</span>
              <span className='Universe-box1-itemBox-item-top-text2'>参与抽奖</span>
            </div>
            <img src={require('../../../assets/imgs/Universe-oclock.svg').default} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item2'>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>交易挖矿</span>
              <span className='Universe-box1-itemBox-item-top-text2'>自动产出原石</span>
            </div>
            <img src={require('../../../assets/imgs/Universe-oclock2.svg').default} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item3' onClick={() => navigate('/universe/score?type=yuanshi')}>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>原石·星际逃亡</span>
              <span className='Universe-box1-itemBox-item-top-text2'>组队瓜分平台原石</span>
            </div>
            <img src={require('../../../assets/imgs/Universe-oclock3.svg').default} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item4' onClick={() => navigate('/universe/score?type=miyao')}>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>秘钥·星际逃亡</span>
              <span className='Universe-box1-itemBox-item-top-text2'>组队瓜分好友秘钥</span>
            </div>
            <img src={require('../../../assets/imgs/Universe-oclock4.svg').default} alt="" />
          </div>
        </div>
      </div>

      {/* <div className='Universe-box2' onClick={() => {
        Toast.show({
          content: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ marginRight: 5 }} width={18} src={require('../../../assets/imgs/qiu.svg').default} />
              <span>星球建造中，敬请期待...</span>
            </div>
          ),
        })
      }}>
        <div className='Universe-box1-title'>
          <h3 className='Universe-box1-h3'>使用星源秘钥</h3>
        </div>
        <div className='Universe-box1-itemBox'>
          <div className='Universe-box1-itemBox-item'>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>原石质押</span>
              <span className='Universe-box1-itemBox-item-top-text2'>开发中，敬请期待</span>
            </div>
            <img src={require('../../../assets/imgs/Purple Planet.png')} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item'>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>藏品交易</span>
              <span className='Universe-box1-itemBox-item-top-text2'>开发中，敬请期待</span>
            </div>
            <img src={require('../../../assets/imgs/Purple Planet (1).png')} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item'>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>藏品质押</span>
              <span className='Universe-box1-itemBox-item-top-text2'>开发中，敬请期待</span>
            </div>
            <img src={require('../../../assets/imgs/Purple Planet (2).png')} alt="" />
          </div>
          <div className='Universe-box1-itemBox-item4'>
            <div className='Universe-box1-itemBox-item-top'>
              <span className='Universe-box1-itemBox-item-top-text1'>秘钥大逃杀</span>
              <span className='Universe-box1-itemBox-item-top-text2'>开发中，敬请期待</span>
            </div>
            <img src={require('../../../assets/imgs/Purple Planet (3).png')} alt="" />
          </div>ƒ
        </div>
      </div> */}

      <div className='Universe-btn btn' onClick={() => navigate('/businessCopyright')}>
        商业版权收入
      </div>

      <div className='Universe-foot'>
        <div className='Universe-foot-box1'>
          <img src={require('../../../assets/imgs/Universe-small1.svg').default} alt="" />
          <div className='Universe-foot-box1-play'>玩法介绍</div>
        </div>
        <p className='Universe-foot-text'>星源秘钥为元宇宙版块的基础积分，平台通过释放限量的星源秘钥，打通用户与合作方进入元宇宙版块的通道。元宇宙版块是平台建设与探索的最终版块，星源秘钥作为进入元宇宙版块的钥匙，连接用户协助平台一起投入到建设元宇宙的生态中。</p>
      </div>
      {/* <div className='Universe-foot2'>
        <div className='Universe-foot-box1'>
          <img src={require('../../../assets/imgs/Universe-small2.svg').default} alt="" />
          <div className='Universe-foot-box1-play'>秘钥获取方式</div>
        </div>
        <p className='Universe-foot-text'>星源秘钥发行量为2100w个，比例分配如下：
          36%藏品交易采矿产生，首日发行120000个，后每天发行量等于前一天发行量减100，直至采完
          32%未来生态ip激励及推广相关运作，按IP生态建设需求释放
          13.5%平台未来运营储备
          10%原石质押抽奖活动分发
          5%质押采矿奖励，总量105w，分1000天采完，每天105个
          2%社群运营奖励释放
          1.5%创始团队奖励，3年内按线性分配
        </p>
      </div>

      <div className='Universe-foot2'>
        <div className='Universe-foot-box1'>
          <img src={require('../../../assets/imgs/Universe-small2.svg').default} alt="" />
          <div className='Universe-foot-box1-play'>秘钥使用</div>
        </div>
        <p className='Universe-foot-text'>
          用户持有星源秘钥即可：
          ①参与平台内游戏活动，平台开放星源秘钥大逃杀游戏和其他各个类型的小游戏，通过游戏获取更多星源秘钥和其他平台生态价值。
          ②参与平台内生态价值投票，参与平台购入的元宇宙土地等权益的投票，通过投票决定是否将平台购入的元宇宙资产售出，从而根据比例瓜分售出的价值收益。
          ③随着平台的发展，平台将持续推进元宇宙版块的建设，当元宇宙版块的发展到一定规模，平台将打通国内外元宇宙的隔阂，迈向海外元宇宙平台，联合建设国内外元宇宙项目，星源秘钥作为平台元宇宙版块基础，将贯穿于元宇宙项目的发展过程中。
          ④以星源秘钥持有数开启平台会员体系，根据持有量获取平台内权益，如纪元闪卡的额外购买数量、源矿的额外发放、原石礼赠朋友的服务费减少...
        </p>
      </div> */}
    </div>
  )
}

export default observer(Universe)