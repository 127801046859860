import styles from './collectCards.module.scss';
import Header from '../../component/header/header';
import { ProgressBar } from 'antd-mobile';
import React, { useState } from 'react';


const CollectCards: React.FC = () => {
  const [percent, setPercent] = useState<number>(20)

  return (
    <div id={styles['CollectCards']}>
      <Header text='集卡得超级大奖' fontColor='black' menu={<img src={require('../../assets/imgs/CollectCards-question.svg').default} alt="" />} />
      <div className={styles['CollectCards-title']}>
        <div className={styles['CollectCards-title-box']}>
          <div className={styles['CollectCards-title-box-top']}>
            <div className={styles['CollectCards-title-box-left']}>
              <h3 className={styles['CollectCards-title-box-left-h3']}>集齐&nbsp;1-100&nbsp;号纪元闪卡得大</h3>
              <div className={styles['CollectCards-title-box-left-div']}>收集进度 12/100</div>
            </div>
          </div>
          <ProgressBar className={styles['CollectCards-ProgressBar']}
            percent={30}
            style={{
              '--fill-color': '#4800E0',
              '--track-color': '#333',
            }}
          />
        </div>
      </div>
      
      <div className={styles['CollectCards-nav']}>
        <div className={styles['CollectCards-nav-left']}>集卡详情&nbsp;(4/100)</div>
        <div>已累计发放大奖122个</div>
      </div>

      <div className={styles['CollectCards-center']}>
        <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div>
        <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div>
        <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div>
        <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div>
        <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div> <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div> <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div> <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div> <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div> <div className={styles['CollectCards-center-box']}>
          <div className={styles['CollectCards-center-box-imgbox']}>
            <img className={styles['CollectCards-center-box-img']} src={require('../../assets/imgs/CollectCards-boring.svg').default} alt="" />
          </div>
          <div>1号纪元闪卡</div>
        </div>

      </div>

      <div className={styles['CollectCards-btnBox']}>
        <div className={styles['CollectCards-btn']}>
          立即兑换
        </div>
      </div>
    </div>
  )
}

export default CollectCards;