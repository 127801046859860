import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Popup } from 'antd-mobile'
import Header from '../../component/header/header'
import BoxDialog from './boxDialog'
import primaryMarket from '@/store/primaryMarket'
import appState from '@/store/appState'

import './myAssets.scss'

const MyAssets: React.FC = () => {
  const [type, setType] = useState<0 | 1 | 2>(0)
  const [isVisible, setIsVisible] = useState(false)
  const [isDialog, setIsDialog] = useState(false)
  const [dialogType, setDialogType] = useState<'opening' | 'opened'>('opening')
  const [assetsItem, setAssetsItem] = useState<any>({})
  const [boxItem, setBoxItem] = useState<any>()
  const [nftBoxItem, setNftBoxItem] = useState<any>()
  const navigate = useNavigate()
  const { commonData, getMenuData } = appState
  const location = useLocation()

  const init = () => {
    if (type === 0) {
      primaryMarket.nftAssets()
    }
    if (type === 1) {
      primaryMarket.getBoxList()
    }
    if (type === 2) {
      primaryMarket.nftBoxesMy()
    }
  }

  useEffect(() => {
    init()
    const type = new URLSearchParams(location.search).get('type')
    if (type) {
      setType(Number(type) as 0 | 1 | 2)
    }
  }, [type])

  const openBoxHandle = () => {
    if (type === 1) {
      primaryMarket.openBox({ user_asset_id: boxItem.id }).then((res) => {
        if (res.code === 0) {
          setBoxItem({ ...boxItem, ...res.data })
          setDialogType('opened')
          primaryMarket.nftAssets()
          primaryMarket.getBoxList()
          getMenuData()
        }
      })
    }

    if (type === 2) {
      primaryMarket.nftBoxesOpen({
        box_id: nftBoxItem.box_id,
        idx: nftBoxItem.idx
      }).then(({ data }) => {
        if (data.code === 0) {
          setBoxItem({ ...boxItem, ...data.data })
          setDialogType('opened')
          primaryMarket.nftAssets()
          primaryMarket.nftBoxesMy()
          getMenuData()
        }
      })
    }
  }

  const actionHandle = (x: any) => {
    if (type === 0) {
      navigate('/assetsDetail', { state: JSON.stringify({ asset_id: assetsItem.asset_id, shard_id: x.shard_id }) })
    } else if (type === 2) {
      setNftBoxItem(x)
      setIsDialog(true)
      setIsVisible(false)
    }
  }

  return (
    <div id='MyAssets'>
      <Header backHandle={() => {
        if (location?.state?.type === 'replace') {
          navigate('/', {})
        } else {
          navigate(-1)
        }
      }} text='我的藏品' />
      <div className='MyAssets-box1'>
        <div>
          <span className='MyAssets-box1-text' style={type === 0 ? {} : { color: '#666', fontWeight: 400 }} onClick={() => setType(0)}>藏品 {commonData?.asset_num}</span>
          <span className='MyAssets-box1-text' style={type === 1 ? {} : { color: '#666', fontWeight: 400 }} onClick={() => setType(1)}>潮玩盲盒 {commonData?.wait_reveal_asset_num}</span>
          <span className='MyAssets-box1-text' style={type === 2 ? {} : { color: '#666', fontWeight: 400 }} onClick={() => setType(2)}>藏品盲盒 {commonData?.box_num}</span>
        </div>
        {type === 0 && <img src={require('@/assets/imgs/filter-3-line.svg').default} alt="" />}
      </div>
      <div className='MyAssets-box2'>
        {type === 0 && (
          primaryMarket?.assetsList.length > 0 ?
            primaryMarket?.assetsList.map((item, i) => (
              <div className='MyAssets-box2-item' key={i} onClick={() => { setIsVisible(true); setAssetsItem(item) }}>
                {item.grade && <img className='MyAssets-box2-item-grade' width={32} height={32} src={require(`@/assets/imgs/${item.grade}.svg`)} alt="" />}
                <img height={146} className='MyAssets-box2-item-img' src={item.thumb[item.thumb.length - 1]} alt="" />
                <span className='MyAssets-box2-item-text'>
                  {item.title}
                </span>
                <div className='MyAssets-box2-item-bittom'>
                  <span className='MyAssets-box2-item-bittom-text1'>
                    <img width={20} src={require('../../assets/imgs/stone.svg').default} alt="" />
                    {item.price}
                  </span>
                  <span className='MyAssets-box2-item-bittom-text2'>
                    共{item.count}个
                  </span>
                </div>
              </div>
            ))
            :
            (
              <div className='noData'>
                <img className='noData-img' src={require('../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
        )}
        {type === 1 && (
          primaryMarket?.boxList?.list?.length > 0 ?
            primaryMarket?.boxList?.list?.map((item: any, i: any) => (
              <div className='MyAssets-box2-item' key={i} onClick={() => { setIsDialog(true); setBoxItem(item) }}>
                <img className='MyAssets-box2-item-img' src={item.thumbList[item.thumbList.length - 1]} alt="" />
                <span className='MyAssets-box2-item-text'>{item.title}</span>
                {/* <div className='MyAssets-box2-item-bittom'>
                  <span className='MyAssets-box2-item-bittom-text1'>
                    <img width={20} src={require('../../assets/imgs/stone.svg').default} alt="" />
                    {item?.price}
                  </span>
                  <span className='MyAssets-box2-item-bittom-text2'>
                    共{item.count}个
                  </span>
                </div> */}
              </div>
            ))
            :
            (
              <div className='noData'>
                <img className='noData-img' src={require('../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
        )}
        {type === 2 && (
          primaryMarket?.nftBoxesMyList.length > 0 ?
            primaryMarket?.nftBoxesMyList.map((item, i) => (
              <div className='MyAssets-box2-item' key={i} onClick={() => { setIsVisible(true); setAssetsItem({ ...item, shards: item.ids }); setBoxItem(item) }}>
                <img height={146} className='MyAssets-box2-item-img' style={{ objectFit: 'cover' }} src={item.thumb[item.thumb.length - 1]} alt="" />
                <span className='MyAssets-box2-item-text'>{item.title}</span>
                <div className='MyAssets-box2-item-bittom'>
                  <span className='MyAssets-box2-item-bittom-text1'>
                    <img width={20} src={require('../../assets/imgs/stone.svg').default} alt="" />
                    {item.price}
                  </span>
                  <span className='MyAssets-box2-item-bittom-text2'>
                    共{item.count}个
                  </span>
                </div>
              </div>
            ))
            :
            (
              <div className='noData'>
                <img className='noData-img' src={require('../../assets/imgs/synthesis.png')} />
                <span className='noData-text'>暂无数据</span>
              </div>
            )
        )}
      </div>

      <Popup
        visible={isVisible}
        getContainer={null}
        onClose={() => setIsVisible(false)}
        closeOnMaskClick
      >
        <div className='MyAssets-Popup'>
          <span className='MyAssets-Popup-title'>{assetsItem?.title}</span>
          <span className='MyAssets-Popup-text'>选择藏品编号/共{assetsItem?.shards?.length}个</span>
          <div className='MyAssets-Popup-box'>
            {assetsItem?.shards?.map((x: any, i: any) => (
              <div className='MyAssets-Popup-box-item' key={i} onClick={() => actionHandle(x)}>
                {x.sale_code === 5003 && <div className='MyAssets-Popup-box-item-title'>寄售中</div>}
                #{x.shard_id || x.idx}
              </div>
            ))}
          </div>
        </div>
      </Popup>

      <BoxDialog
        show={isDialog}
        action={dialogType}
        type={type}
        onclose={() => {
          setIsDialog(false);
          setDialogType('opening');
        }}
        openBox={openBoxHandle}
        boxItem={boxItem}
      />
    </div >
  )
}

export default observer(MyAssets)