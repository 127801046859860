import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Popup, Toast } from 'antd-mobile';
import { observer } from 'mobx-react'

import primaryMarket from '@/store/primaryMarket';
import secondaryMarket from '@/store/secondaryMarket';
import DealPassword from '@/component/dealPassword/dealPassword';
import styles from './priceSold.module.scss';
import Header from '../../../component/header/header';

const PriceSold: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [assetsItem, setAssetsItem] = useState<any>({})
  const { nftAssets } = primaryMarket
  const { ordersMatchBid } = secondaryMarket
  const { state } = useLocation()
  const detail = JSON.parse(state)
  const [shardList, setShardList] = useState<any[]>([])
  const [pwdVisible, setPwdVisible] = useState(false)
  const navigate = useNavigate()


  useEffect(() => {
    console.log(detail)
    nftAssets().then(data => {
      if (data.code === 0) {
        data.data.forEach((x: any) => {
          if (x.asset_id === (detail?.goods?.aid)) {
            setAssetsItem(x)
          }
        })
      }
    })
  }, [])

  return (
    <div id={styles['PriceSold']}>
      <Header text='以求购价售出' fontColor="black" />
      <div className={styles['PriceSold-title']}>
        <div className={styles['PriceSold-title-box']}>
          <div className={styles['PriceSold-title-box-top']}>
            <img className={styles['PriceSold-title-box-top-img']} src={assetsItem.thumb && assetsItem.thumb[assetsItem.thumb.length - 1]} alt="" />
            <div className={styles['PriceSold-title-box-top-right']}>
              <h3 className={styles['PriceSold-title-box-top-right-h3']}>{assetsItem.title}</h3>
              <div className={styles['PriceSold-title-box-top-right-div']}>
                <div className={styles['PriceSold-title-box-top-right-div-num']}>求购数量 {detail.bid_remain_amount}</div>
                <div className={styles['PriceSold-title-box-top-right-div-price']}>求购单价<span className={styles['PriceSold-title-box-top-right-price-span']}>&nbsp;¥&nbsp;{(detail.bid_unit_price / 100).toFixed(2)}</span></div>
              </div>
            </div>
          </div>
          <div className={styles['PriceSold-title-box-foot']} onClick={() => setIsVisible(true)}>
            <div>选择售出编号</div>
            <div className={styles['PriceSold-title-box-foot-numbox']}>
              <div>售出数量&nbsp;{shardList.length}</div>
              <img className={styles['PriceSold-title-box-foot-img']} src={require('../../../assets/imgs/PriceSold-right-fill.svg').default} alt="" />
            </div>
          </div>

        </div>

      </div>

      <div className={styles['PriceSold-center']}>
        <div className={styles['PriceSold-center-box']}>
          <h3 className={styles['PriceSold-center-box-h3']}>预计收入</h3>
          <div className={styles['PriceSold-center-box-text']}>
            <div className={styles['PriceSold-center-box-text-left']}>
              <div>售出价格</div>
              <div>服务费5%, 最低1元</div>
              <div>实际到账</div>
            </div>
            <div className={styles['PriceSold-center-box-text-right']}>
              <div>¥&nbsp;{((detail.bid_unit_price * shardList.length) / 100).toFixed(2)}</div>
              <div>¥&nbsp;{(((detail.bid_unit_price * shardList.length) * (1 - 0.95)) / 100) > 1 ? (((detail.bid_unit_price * shardList.length) * (1 - 0.95)) / 100).toFixed(2) : '1.00'}</div>
              <div>¥&nbsp;{(((detail.bid_unit_price * shardList.length) * (1 - 0.95)) / 100) > 1 ? (((detail.bid_unit_price * shardList.length) * 0.95) / 100).toFixed(2) : ((((detail.bid_unit_price * shardList.length) / 100) - 1) > 0 ? (((detail.bid_unit_price * shardList.length) / 100) - 1) : 0).toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['PriceSold-center2']}>
        <div className={styles['PriceSold-center2-box2']}>
          <h3 className={styles['PriceSold-center-box2-h3']}>售出说明</h3>
          <div className={styles['PriceSold-center-box2-text']}>
            <p>1.如果自发起求购订单后，一周内没有成交，将会自动退款</p>
            <p>2.预付成功后，可在“我的-求购订单”中取消订单</p>
            <p>3.成交顺序以发起求购成功的时间，顺序成交</p>
            <p>4.求购期间成交，无法撤销</p>
          </div>
        </div>
      </div>

      <div className={styles['PriceSold-btn']}>
        <div className={styles['PriceSold-btn-b']}>
          <div className={styles['PriceSold-btn-box']} onClick={() => setPwdVisible(true)}>确认成交</div>
        </div>
      </div>

      <Popup
        visible={isVisible}
        getContainer={null}
        onClose={() => setIsVisible(false)}
        closeOnMaskClick
      >
        <div className={styles['MyAssets-Popup']}>
          <span className={styles['MyAssets-Popup-title']}>{assetsItem?.title}</span>
          <span className={styles['MyAssets-Popup-text']}>选择藏品编号/共{assetsItem?.shards?.length}个</span>
          <div className={styles['MyAssets-Popup-box']}>
            {assetsItem?.shards?.map((x: any, i: any) => (
              <div className={styles['MyAssets-Popup-box-item']} style={shardList.some(y => y.shard_id === x.shard_id) ? { background: '#4D33EF', color: '#fff' } : {}} key={i} onClick={() => {
                if (x.sale_code === 5003) return

                if (shardList.some(y => y.shard_id === x.shard_id)) {
                  shardList.forEach((v, i) => {
                    if (v.shard_id === x.shard_id) {
                      shardList.splice(i, 1)
                    }
                  })
                } else {
                  if (shardList.length < detail.bid_remain_amount) {
                    shardList.push({ ...x, aid: detail.goods.aid })
                  } else {
                    Toast.show('超出求购数量')
                  }
                }
                setShardList([...shardList])
              }}>
                {x.sale_code === 5003 && <div className={styles['MyAssets-Popup-box-item-title']}>寄售中</div>}
                #{x.shard_id || x.idx}
              </div>
            ))}
          </div>
        </div>
      </Popup>

      <DealPassword
        visible={pwdVisible}
        onConfirm={v => {
          ordersMatchBid({
            bidOrderId: detail.order_id,
            skuList: shardList
          }, {
            password: v
          }).then(({ data }) => {
            if (data.code === 0) {
              navigate(`/secondaryMarket/paymentPage?order_id=${data.data.order_id}`)
            }
          })
        }}
        onClose={() => {
          setPwdVisible(false)
        }}
      />
    </div>
  )
}

export default observer(PriceSold);