import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionSheet, Toast, Dialog } from 'antd-mobile'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import type { Action } from 'antd-mobile/es/components/action-sheet'
import type { PropsType } from '../../global'
import primaryMarket from '@/store/primaryMarket'

import Header from '../../component/header/header'

import './orderPay.scss'

type status = 'wait_paid' | 'success' | 'cancel' | 'refund' | 'not_delivered'

enum OrderStatus {
  'not_delivered' = '等待支付运费',
  'wait_paid' = '等待支付运费',
  'success' = '订单完成',
  'cancel' = '订单取消',
  'refund' = '退款成功',
}

const FreightPay: React.FC<PropsType> = ({ PrimaryMarket, AppState }) => {
  const order_no = new URLSearchParams(window.location.search).get('order_no')
  const { commonData } = AppState
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const { orderDetailInfo } = PrimaryMarket
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  const payHandle = (type: "wyper" | "express", pay_channel: "alipay" | "sandpay" | 'huifupay') => {
    if (order_no) {
      if (orderDetailInfo.state === 'not_delivered') {
        PrimaryMarket.initiateExpressOrder({
          express_order_no: order_no,
          delivery_address: commonData.default_delivery_info.address,
          delivery_mobile: commonData.default_delivery_info.mobile,
          delivery_name: commonData.default_delivery_info.name
        }).then(res => {
          if (res.code === 0) {
            PrimaryMarket.payHandle(order_no, type, pay_channel).then((res) => {
              if (pay_channel === 'alipay') {
                const div = document.createElement('divform')
                div.innerHTML = res
                document.body.appendChild(div)
                if (isiOS) {
                  document.forms[0].submit();
                } else {
                  document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
                  document.forms[0].submit();
                }
              } else {
                window.location.href = res
              }
            })
          } else {
            navigate(-1)
          }
        })
      } else if (orderDetailInfo.state === 'wait_paid') {
        PrimaryMarket.payHandle(order_no, type, pay_channel).then((res) => {
          if (pay_channel === 'alipay') {
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            const div = document.createElement('divform')
            div.innerHTML = res
            document.body.appendChild(div)
            if (isiOS) {
              document.forms[0].submit();
            } else {
              document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
              document.forms[0].submit();
            }
          } else {
            window.location.href = res
          }
        })
      }
    }
  }

  const actions: Action[] = [
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/zfb.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>支付宝</span>
        </div>
      ),
      key: 'zfb',
      bold: true,
      onClick: () => payHandle('express', 'alipay'),
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/wx.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>微信支付</span>
        </div>
      ),
      key: 'wx',
      bold: true,
      onClick() {
        Toast.show({ content: '暂未开放' })
      },
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/sd.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>杉德支付</span>
        </div>
      ),
      key: 'sd',
      bold: true,
      onClick: () => {
        if (commonData?.wallet?.bind) {
          payHandle('express', 'sandpay')
        } else {
          window.location.href = commonData?.wallet?.url
        }
      },
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/hf.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>汇付支付</span>
        </div>
      ),
      key: 'hf',
      bold: true,
      onClick: () => {
        if (commonData?.wallet2?.bind) {
          payHandle('express', 'huifupay')
        } else {
          window.location.href = commonData?.wallet2?.url
        }
      },
    },
  ]

  const [time, setTime] = useState(30 * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time <= 0) {
        clearInterval(timer);
        Toast.show({ content: '订单已超时' })
        setTimeout(() => navigate(-1), 1000);
      }
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes} 分 ${seconds < 10 ? `0${seconds}` : seconds} 秒后关闭订单`;
  };

  useEffect(() => {
    if (order_no) {
      PrimaryMarket.queryOrderDetail(order_no)
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (orderDetailInfo.state === 'wait_paid') {
        if (order_no) {
          PrimaryMarket.queryOrderDetail(order_no)
        }
      } else {
        clearInterval(timer)
      }
    }, 1000);

    return () => {
      clearInterval(timer)
    }
  }, [orderDetailInfo.state])

  useEffect(() => {
    const removeDivFormElements = () => {
      const divFormElements = document.querySelectorAll('divform');
      divFormElements.forEach(element => {
        element.remove();
      });
    };

    removeDivFormElements();
  }, []);

  const cancelOrderHandle = () => {
    Dialog.show({
      content: '确认要取消订单吗',
      closeOnAction: true,
      actions: [
        [
          {
            text: '取消',
            key: 'cancel',
          },
          {
            text: '确认',
            key: 'ok',
            danger: true,
            onClick() {
              Toast.show({ content: '订单已取消' })
              if (order_no) {
                primaryMarket.cancelOrder(order_no, 'express').then(code => {
                  if (code === 0) {
                    Toast.show({ content: '订单已取消' })
                    setTimeout(() => navigate(-1), 1000);
                  }
                })
              }
              setTimeout(() => navigate(-1), 1000);
            },
          }
        ],
      ]
    })
  }

  return (
    <div id='OrderPay'>
      <Header text='订单支付' backHandle={orderDetailInfo?.state === 'success' ? () => { window.location.replace('/') } : undefined} />

      <div className="OrderPay-box1">
        {(orderDetailInfo?.state === 'not_delivered' || orderDetailInfo?.state === 'wait_paid') && <img className="OrderPay-box1-img" width={56} src={require('../../assets/imgs/Group.svg').default} />}
        {orderDetailInfo?.state === 'success' && <img className="OrderPay-box1-img" src={require('../../assets/imgs/Group1.svg').default} />}
        {orderDetailInfo?.state === 'cancel' && <img className="OrderPay-box1-img" src={require('../../assets/imgs/pay3.png')} />}
        <span className="OrderPay-box1-text">{OrderStatus[orderDetailInfo?.state as status]}</span>
        {(orderDetailInfo?.state === 'not_delivered' || orderDetailInfo?.state === 'wait_paid') && <span className='OrderPay-box1-text1'>{formatTime(time)}</span>}
      </div>

      <div className='OrderPay-box3' onClick={() => { Toast.show('当前版本只支持默认地址') }}>
        <div className='OrderPay-box3-left'>
          <img className='OrderPay-box3-left-img' src={require('../../assets/imgs/icon2.svg').default} alt="" />
          <span className='OrderPay-box3-left-text'>{`${commonData.default_delivery_info.name},${commonData.default_delivery_info.mobile},${commonData.default_delivery_info.address}`}</span>
        </div>
        <img className='OrderPay-box3-right' src={require('../../assets/imgs/arrow-right-s-line.svg').default} alt="" />
      </div>

      <div className='OrderPay-box2'>
        <div className="OrderPay-box2-top">
          <div>
            <img className="OrderPay-box2-top-img" src={orderDetailInfo?.physical_good_detail_thumb_list && orderDetailInfo?.physical_good_detail_thumb_list[0]} />
          </div>
          <div className="OrderPay-box2-top-container">
            <p className="OrderPay-box2-top-container-title">{orderDetailInfo?.title}
              <span className='OrderPay-box2-top-container-title-text'>
                <span>¥ </span>
                {orderDetailInfo.physical_good_order_unit_price}
              </span>
            </p>
            <div className="OrderPay-box2-top-container-loaction">
              <p className="OrderPay-box2-top-container-loaction-text">x{orderDetailInfo?.physical_good_order_amount}</p>
            </div>
          </div>
        </div>
        <div className="OrderPay-box2-section">
          <p className="OrderPay-box2-section-title">运费信息</p>
          <div className="OrderPay-box2-section-bottom">
            <p className="OrderPay-box2-section-bottom-text1">支付方式：支付宝</p>
            <p className="OrderPay-box2-section-bottom-text2"><span style={{ fontSize: '14px' }}>¥</span> {orderDetailInfo?.price}</p>
          </div>
        </div>
        <div className="OrderPay-box2-data">
          <p className="OrderPay-box2-data-title">附加信息</p>
          <div className="OrderPay-box2-data-item">
            <p>商品价格</p>
            <p>￥{orderDetailInfo?.physical_good_order_price}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>交易数量</p>
            <p>{orderDetailInfo?.amount}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>创建时间</p>
            <p>{moment().format('YYYY.MM.DD HH:mm')}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>订单编号</p>
            <p style={{ color: '#000' }}>{orderDetailInfo?.order_no}</p>
          </div>
        </div>
      </div>

      <div className="OrderPay-footer">
        {(orderDetailInfo?.state === 'not_delivered' || orderDetailInfo?.state === 'wait_paid') &&
          <>
            <div className="OrderPay-btn1 btn" onClick={cancelOrderHandle}>
              取消订单
            </div>
            <div className="OrderPay-btn btn" onClick={() => setVisible(true)}>
              确认运费
            </div>
          </>
        }

        {orderDetailInfo?.state === 'success' &&
          <>
            <div className="OrderPay-btn1 btn" onClick={() => navigate('/My/HelpCenter')}>
              客服
            </div>
            <div className="OrderPay-btn btn" onClick={() => { Toast.show('敬请期待') }}>
              查看物流
            </div>
          </>
        }

        {orderDetailInfo?.state === 'cancel' &&
          <div className="OrderPay-btn btn" style={{ flex: 1 }} onClick={() => navigate('/')}>
            返回主页
          </div>
        }
      </div>

      <ActionSheet
        visible={visible}
        cancelText='取消'
        actions={actions}
        onClose={() => setVisible(false)}
      />
    </div>
  )
}

export default inject('PrimaryMarket', 'AppState')(observer(FreightPay))