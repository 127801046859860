import styles from './noticeDetails.module.scss'
import Header from '../../component/header/header';
import ReactMarkdown from 'react-markdown';     // 解析 markdown
import remarkGfm from 'remark-gfm';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import './md.css'


const NoticeDetails: React.FC = () => {
  const location = useLocation()
  const url = new URLSearchParams(location.search).get('url')
  const [data, setData] = useState('')

  useEffect(() => {
    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setData(xhr.responseText)
      }
    }
    // 文件目录在 public/static/test.md 这里不需要写 public 因为打包之后没有此目录。
    xhr.open("GET", url as string, true);
    xhr.send();
  }, [])

  return (
    <div id={styles['NoticeDetails']} className='markdown-body'>
      <Header text='公告详情' fontColor='black' />
      {/* <div className={styles['NoticeDetails-title']}>
        <div className={styles['NoticeDetails-title-box']}>
          <h3 className={styles['NoticeDetails-title-box-h3']}>《虚空战士》盲盒奖励发放公告</h3>
          <p className={styles['NoticeDetails-title-box-p']}>2022-08-04&nbsp;17:39</p>
          <div className={styles['NoticeDetails-title-box-text']}>
            平台近期正与各大机构、品牌、IP、潮牌厂牌进行深度合作洽谈。未来将联合打造一个全新的、更大平拍价值、拥有独具创新的元宇宙文化聚集地。为更新一代艺术创作者和年轻玩具提供更好的交流、分享、交易的数字藏品平台。
          </div>
          <div className={styles['NoticeDetails-title-text2']}>平台转增功能预计将于8月底面向全部用户开放体验,敬请期待</div>
        </div>
      </div>
      <h3 className={styles['NoticeDetails-association-h3']}>关联藏品</h3>

      <div className={styles['NoticeDetails-association']}>
        <div className={styles['NoticeDetails-association-box']}>
          <div className={styles['NoticeDetails-association-box-left']}>
            <img className={styles['NoticeDetails-association-box-left-img']} src={require('../../assets/imgs/NoticeDetails-img.svg').default} alt="" />
            <h3>藏品名称</h3>
          </div>
          <div className={styles['NoticeDetails-association-box-right']}>
            <div>去市场</div>
            <img src={require('../../assets/imgs/NoticeDetails-line.svg').default} alt="" />
          </div>
        </div>
      </div>

      <div className={styles['NoticeDetails-association']}>
        <div className={styles['NoticeDetails-association-box']}>
          <div className={styles['NoticeDetails-association-box-left']}>
            <img className={styles['NoticeDetails-association-box-left-img']} src={require('../../assets/imgs/NoticeDetails-img.svg').default} alt="" />
            <h3>藏品名称</h3>
          </div>
          <div className={styles['NoticeDetails-association-box-right']}>
            <div>去市场</div>
            <img src={require('../../assets/imgs/NoticeDetails-line.svg').default} alt="" />
          </div>
        </div>
      </div> */}
      <ReactMarkdown
        children={data}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  )
}

export default NoticeDetails;