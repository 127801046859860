import './commonDialog.scss'

interface Props {
  children: React.ReactElement;
  show: boolean;
  close?: () => void
}

const CommonDialog: React.FC<Props> = (props) => {
  return (
    <>
      {props.show &&
        <div id='commonDialog' onClick={props.close}>
          {props.children}
        </div>
      }
    </>
  )
}

export default CommonDialog