import { observable, action, makeObservable } from 'mobx'
import { Toast } from 'antd-mobile'
import {
  sendCodeApi,
  loginApi,
  bindInviterIdApi,
  menuApi,
  updateUserInfoApi,
  bindIdentityNumberApi,
  getDeliveryInfoListApi,
  deleteDeliveryInfoApi,
  updateDeliveryInfoApi,
  addDeliveryInfoApi,
  nftNoticeApi,
  usersPasswordApi,
  resetPasswordApi,
  sendMsgApi,
  usersMyApi,
  uploadApi
} from './serve'
import SockJS from 'sockjs-client';
import { type PropsType } from '@/global'
import lrz from 'lrz'

const Stomp = require('@stomp/stompjs')

declare const TencentCaptcha: any

type AppState = PropsType['AppState']

class appState {
  constructor() {
    makeObservable(this)
  }

  @observable commonData: AppState['commonData'] = JSON.parse(localStorage.getItem('commonData') as string)

  @observable osType: 'iOS' | 'Android' = /iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera) && !window.MSStream ? 'iOS' : 'Android'

  @action
  saveCommonData = (data: object) => {
    this.commonData = Object.assign({}, this.commonData, data)
    localStorage.setItem('commonData', JSON.stringify(this.commonData))
  }


  /**
  * 
  * 发送验证码 && 登录
  */
  @action
  sendCodeHandle: AppState['sendCodeHandle'] = (mobile: string) => {
    return new Promise((resolve) => {
      new TencentCaptcha('198946596', (res: any) => {
        sendCodeApi({ mobile, ticket: res.ticket, randstr: res.randstr, biz: 'chat' }).then(({ data }) => {
          if (data.code === 0) {
            Toast.show('已发送验证码')
            resolve(data.code)
          } else {
            Toast.show('发送频繁，请稍后再试')
          }
        })
      }).show()
    })
  }
  @action
  loginHandle: AppState['loginHandle'] = async (params) => {
    const { data } = await loginApi(params)
    if (data.code === 0) {
      await this.saveCommonData(data.data)
      await this.getMenuData()
      await this.usersMy()
    }
    return Promise.resolve(data)
  }




  /**
   * 
   * 获取menu数据
   */
  @action
  getMenuData: AppState['getMenuData'] = async () => {
    const { data } = await menuApi()
    if (data.code === 0) {
      this.saveCommonData(data.data)
    }
  }



  /**
   * 二级状态信息
   */
  @action
  usersMy: AppState['usersMy'] = async () => {
    const { data } = await usersMyApi()
    if (data.code === 0) {
      this.saveCommonData(data.data)
    }
  }







  /**
   * 绑定邀请码
   */
  @action
  bindInviterIdHnadle: AppState['bindInviterIdHnadle'] = (data) => {
    return bindInviterIdApi(data)
  }



  /**
    * 上传头像
    */
  uploadAva: AppState['uploadAva'] = async (e) => {
    const res = await lrz(e.target.files![0], { quality: 0.6 })
    const { data } = await uploadApi(res.formData)
    if (data.image_url) {
      return data.image_url
    } else {
      Toast.show({ icon: 'fail', content: '上传失败' })
      return null
    }
  }



  /**
  * 用户信息修改
  */
  updateUserInfoHandle: AppState['updateUserInfoHandle'] = async (params) => {
    const { data } = await updateUserInfoApi(params)
    if (data.code === 0) {
      this.getMenuData()
      Toast.show({ icon: 'success', content: '修改成功' })
    } else {
      Toast.show({ content: '修改失败，请检查网络环境' })
    }
  }



  /**
   * 实名认证
   * @param params 
   * @returns 
   */
  bindIdentityNumber: AppState['bindIdentityNumber'] = async (params) => {
    const { data } = await bindIdentityNumberApi(params)
    if (data.code === 0) {
      this.getMenuData()
    }
    return Promise.resolve(data)
  }





  /**
   * 获取用户物流信息列表
   */
  @observable deliveryInfoList = []
  getDeliveryInfoList: AppState['getDeliveryInfoList'] = async () => {
    const { data } = await getDeliveryInfoListApi()
    if (data.code === 0) {
      this.deliveryInfoList = data.data
    }
    return Promise.resolve(data)
  }


  /**
   * 新增收货地址
   * @param params 
   * @returns 
   */
  addDeliveryInfo: AppState['addDeliveryInfo'] = async (params) => {
    const { data } = await addDeliveryInfoApi(params)
    if (data.code === 0) {
      this.getMenuData()
    }
    return Promise.resolve(data)
  }



  /**
   * 删除收货地址
   */
  deleteDeliveryInfo: AppState['deleteDeliveryInfo'] = async (id) => {
    const { data } = await deleteDeliveryInfoApi(id)
    if (data.code === 0) {
      this.getMenuData()
    }
    return Promise.resolve(data)
  }



  /**
   * 编辑收货地址
   */
  updateDeliveryInfo: AppState['updateDeliveryInfo'] = async (id) => {
    const { data } = await updateDeliveryInfoApi(id)
    if (data.code === 0) {
      this.getMenuData()
    }
    return Promise.resolve(data)
  }





  @observable nftNoticeList: any[] = []
  /**
   * 获取公告列表
   * @param type 
   * @returns 
   */
  getNftNotice = async (type: string) => {
    const { data } = await nftNoticeApi(type)
    if (data.code === 0) {
      this.nftNoticeList = data.data
    }
    return Promise.resolve(data)
  }







  /**
   * 设置密码
   * @param params 
   * @returns 
   */
  usersPassword: AppState['usersPassword'] = async (params) => {
    const data = await usersPasswordApi(params)
    return Promise.resolve(data)
  }






  /**
   * 重置密码
   * @param params 
   * @returns 
   */
  resetPassword: AppState['resetPassword'] = async (params) => {
    const data = await resetPasswordApi(params)
    return Promise.resolve(data)
  }





  /**
   * 重置密码发送短信
   * @returns 
   */
  sendMsg = async () => {
    const data = await sendMsgApi()
    return Promise.resolve(data)
  }







  /** 
   * 
   * 每次启动时的自执行函数
   */
  selfExecutingHandle = (() => {

    // 每次启动wyper检测是否有邀请链接
    const inviteCode = new URLSearchParams(window.location.search).get('inviteCode')
    if (inviteCode) {
      window.localStorage.setItem('inviteCode', inviteCode)
    }


    if (window.location.pathname === '/' && !localStorage.getItem('startAnimation')) {
      window.location.replace('/login/MyPer')
    }

    if (
      !this.commonData &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/mining' &&
      window.location.pathname !== '/deal' &&
      window.location.pathname !== '/universe' &&
      window.location.pathname !== '/login/MyPer'
    ) {
      return window.location.replace('/login/MyPer')
    }

    if (this.commonData) {
      this.getMenuData()
      this.usersMy()
    }
  })()













  /**
   * 每次启动项目做webSocket长链接
   * 
   */
  @observable socketData: AppState['socketData']
  webSocket = (() => {
    const stomp = Stomp.Stomp.over(new SockJS(process.env.NODE_ENV === 'production' ? "https://nftgame.wyper.art/public" : 'https://nftgame-test.wyper.art/public'));
    stomp.connect(
      {},
      () => {
        stomp.subscribe("/broadcast", (response: { body: string; }) => {
          this.socketData = JSON.parse(response.body)
          console.log(JSON.parse(response.body))
        });
      },
      {}
    );
  })()
}

export default new appState()