import { useState } from "react"

import GetGold from "./component/getGold"
import Record from "./component/record"
import Header from "@/component/header/header"
import styles from './active.module.scss'

const Active: React.FC = () => {
  const [title, setTitle] = useState<'action' | 'record'>(new URLSearchParams(window.location.search).get('title') as 'action' || 'action')

  return (
    <div id={styles.action}>
      <Header text={(
        <div className={styles['order-title-right']}>
          <div className={`${styles['order-title-right-sell']} ${title === 'action' ? styles['switch'] : ''}`} onClick={() => setTitle('action')}>提炼黄金</div>
          <div className={`${styles['order-title-right-sell']} ${title === 'record' ? styles['switch'] : ''}`} onClick={() => setTitle('record')}>提炼记录</div>
        </div>
      )} />

      {title === 'action' && <GetGold />}

      {title === 'record' && <Record />}
    </div>
  )
}

export default Active