import { useNavigate } from 'react-router-dom'

import './header.scss'

interface Props {
  text?: string | React.ReactNode,
  menu?: string | React.ReactNode,
  fontColor?: 'black' | 'white',
  backHandle?: () => void
}

function Header(props: Props) {
  const { fontColor = 'black' } = props
  const navigate = useNavigate()

  return (
    <div id='Header' style={fontColor === 'black' ? { boxShadow: '0 1px 6px rgba(0, 0, 0, 0.05)' } : { backgroundColor: '#000' }}>
      <div className='header-goback' onClick={() => { props.backHandle ? props.backHandle() : navigate(-1) }}>
        {fontColor === 'white' && <img width={24} height={24} src={require('../../assets/imgs/arrow-left-white.svg').default} alt="" />}
        {fontColor === 'black' && <img width={16} height={16} src={require('../../assets/imgs/arrow-left.png')} alt="" />}
      </div>
      <div className='header-title'>
        <span className='header-title-text' style={fontColor === 'black' ? {} : { color: '#fff' }}>{props.text}</span>
      </div>

      <div className='header-menu' style={fontColor === 'black' ? {} : { color: '#fff' }}>{props.menu}</div>
    </div>
  )
}

export default Header