import { useState, useEffect } from 'react'
import { ProgressBar } from 'antd-mobile'
import { useNavigate } from 'react-router'
import { inject, observer } from 'mobx-react'

import Header from '@/component/header/header'
import BuyModal from '@/component/buyModal/buyModal'

import type { PropsType } from '@/global'

import './commodityDetail.scss'
import { _hmtHandle } from '@/utils/utils'

const CommodityDetail: React.FC<PropsType> = ({ PrimaryMarket }) => {
  const navigate = useNavigate()
  const [state, setState] = useState<any>({
    buyModalVisibe: false
  })
  const { detailInfo } = PrimaryMarket
  const physical_good_id = new URLSearchParams(window.location.search).get('physical_good_id') || ''

  const init = () => {

    if (physical_good_id) {
      PrimaryMarket.physicalGoodDetail(physical_good_id).then(({ data }) => {
        const long_desc_div = document.querySelector('.long_desc') as Element
        const author_desc_div = document.querySelector('.author_desc') as Element
        const remark_div = document.querySelector('.remark') as Element
        long_desc_div.innerHTML = data.long_desc
        author_desc_div.innerHTML = data.author_desc
        remark_div.innerHTML = data.remark
      })
    }
  }

  useEffect(() => {
    init()

    _hmtHandle('任何设备', '访问', '纪元闪卡商品详情页')
  }, [])


  return (
    <div id="CommodityDetail">
      <div className="main">
        <Header text="商品详情" />
        <img className="CommodityDetail-img" src={detailInfo?.detail_thumb_list && detailInfo?.detail_thumb_list[detailInfo?.detail_thumb_list?.length - 1]} />
        <div className="box2">
          <span className="box2-text1">{detailInfo.title}</span>
          <span className='box2-text2'>运费：{detailInfo.delivery_fee}元</span>
        </div>
        {/* <div className="box4">
          <ProgressBar
            percent={((detailInfo.sale_amount - detailInfo.stock) / detailInfo.sale_amount) * 100}
            style={{
              '--fill-color':
                '#4D33EF',
              flex: 0.95
            }}
          />
          <span className="box4-text">剩余：{detailInfo.stock}/{detailInfo.sale_amount}</span>
        </div> */}
        {detailInfo?.nft_asset_desc && <div className='box1'>
          <h2 className="title">赠送礼品</h2>
          <div className='box1-item'>
            <img className='box1-item-img' src={detailInfo?.nft_asset_desc?.thumb} alt="" />
            <div className='box1-item-right'>
              <span className='box1-item-right-text1'>{detailInfo?.nft_asset_desc?.title}</span>
              <span className='box1-item-right-text2'>{detailInfo?.nft_asset_desc?.desc}</span>
            </div>
          </div>
        </div>}
        {detailInfo?.nft_asset_list?.length > 0 && <div className='box3'>
          <h2 className="title">
            纪元闪卡详情
            <span className='box3-text'>您可能抽到以纪元闪卡中的1个，查看全部</span>
          </h2>
          <div className='box3-content'>
            {detailInfo?.nft_asset_list?.map((x: any, i: number) => (
              <div className='box3-item' key={i}>
                <img width={54} height={54} className='box3-item-img' src={x.thumb[0]} alt="" />
                <span className='box3-item-text'>{x.title}</span>
              </div>
            ))}
          </div>
        </div>}
        <div className="box7">
          {detailInfo.author_desc && <h2 className="title">艺术家介绍</h2>}
          <p className="desc author_desc" style={detailInfo.author_desc ? {} : { display: 'none' }}></p>
          {detailInfo.long_desc && <h2 className="title">作品描述</h2>}
          <p className="desc long_desc" style={detailInfo.long_desc ? {} : { display: 'none' }}></p>
          {detailInfo.remark && <h2 className="title">作品权益</h2>}
          <p className="desc remark" style={detailInfo.remark ? {} : { display: 'none' }}></p>
          <h2 className="title">购物须知</h2>
          <p className="desc">
            <span>1、关于退货:纪元闪卡为定制类商品,根据《消费者权益保护法》规定,因产品售出后,无法按照售卖规则二次销售,不适用7天无理由退换的规定,请理性购买。其它潮玩商品，为手工现货制作，存在个体差异和不完美之处。一经出售无严重质量问题不予退换，请谨慎购买。如存在商品自身质量瑕疵，请提供相关照片/视频联系客服进行核实或更换。</span>
            <span>2、关于换货:如有换货需要,请在签收后7日内联系人!工客服提交申请。因产品质量问题换货，需要您先行垫付寄回运费，平台确认商品收到后，统一为您退还运费。</span>
            <span>3、回寄事项:为了保障您的换货时效,请在回寄前附上一张纸条,写明:平台ID、联系人、手机号码、订单号、问题处理等内容,并联系人工客服提供回寄运单号，换货快递不支持到付或平邮形式，以免仓库拒收。</span>
            <span>4、信息修改:如需修改订单信息（尺码、地址等），请在发货前联系人工客服并提供您的订单号、修改后的尺码、联系人电话及收货地址等信息进行处理。</span>
            <span>5、关于发货:纪元闪卡实物盲盒购买成功后,将先存入平台寄存库,用户可于寄存库统一提货,填写地址发货。提货成功,预计7-14个工作日内发货。其它潮玩产品,购买成功后,预计3-7个工作日内发货。</span>
            <span>6、特别提醒:商品原厂标签（吊牌）被剪掉或撕毁；商品破损、外包装破损或丢失；商品经穿着、洗涤、遭污损或损坏等，视为影响二次销售，平台不予退换，请知悉。</span>
          </p>
        </div>
        <div className='CommodityDetail-footer' onClick={() => {setState({ ...state, buyModalVisibe: true }); _hmtHandle('任何设备', '点击', '纪元闪卡商品详情页「立即购买」按钮')}}>
          <div className='CommodityDetail-footer-btn btn'>
            ¥ {detailInfo.price}/张 立即购买
          </div>
        </div>
      </div>

      <BuyModal
        show={state.buyModalVisibe}
        close={() => setState({ ...state, buyModalVisibe: false })}
        physical_good_id={physical_good_id}
      />
    </div>
  )
}

export default inject('PrimaryMarket')(observer(CommodityDetail))
