import { Checkbox, Dialog, InfiniteScroll, Popup, ProgressBar, Toast } from 'antd-mobile';
import { observer } from 'mobx-react'
import store from './reducer'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import appState from "@/store/appState";
import moment from "moment";
import { Clock } from "grommet";
import Header from "@/component/header/header";
import styles from './destroy.module.scss';
import secondaryMarket from '@/store/secondaryMarket';
import LuckyDraw from '@/component/LuckyDraw';
import DealPassword from '@/component/dealPassword/dealPassword';

const Destroy: React.FC = () => {
  const {
    consumeShardList,
    getConsumeShardList,
    getNftGameDetail,
    nftGameDetail,
    consume,
    getNftHistory,
    nftHistoryList,
    nftHistoryListHasMore
  } = store
  const { getGoodsSearhList, goodsSearcExtra } = secondaryMarket
  const { state } = useLocation()
  const navigate = useNavigate()
  const { socketData } = appState
  const [popupVisible, setPopupVisible] = useState(false)
  const [currentCard, setCurrentCard] = useState<any>({})
  const [sort, setSort] = useState<'time' | 'shard'>('time')
  const [luckVisible, setLuckVisible] = useState(false)
  const [pwdVisible, setPwdVisible] = useState(false)

  const init = () => {
    getConsumeShardList({
      gameId: state.gameId
    })

    getNftGameDetail({ gameId: state.gameId })

    getNftHistory({
      assetId: state.aid,
      type: 'destroyed',
      sort
    })

    getGoodsSearhList({
      'sku.aid': state.aid
    })
  }

  useEffect(() => {
    if (socketData?.action === 'nft_game_change_destroy') {
      init()
    }
  }, [socketData])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    getNftHistory({
      assetId: state.aid,
      type: 'destroyed',
      sort
    })
  }, [sort])


  const [countDowns, setCountDowns] = useState<any>()
  const [destroyCountdownProgress, setDestroyCountdownProgress] = useState(0)


  useEffect(() => {
    let destroyDrawTime: NodeJS.Timer
    if (nftGameDetail?.destroyDrawTime) {
      destroyDrawTime = setInterval(() => {
        const now = moment().valueOf();
        const distance = moment(nftGameDetail?.destroyDrawTime).valueOf() - now;
        const seconds = Math.floor((distance / 1000) % 60);
        const minutes = Math.floor((distance / 1000 / 60) % 60);
        if (distance < 0) {
          clearInterval(destroyDrawTime);
          setCountDowns('- - : - -')
        } else {
          setCountDowns((
            <Clock
              type="digital"
              precision="minutes"
              time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
              size='small'
            />
          ))
          setDestroyCountdownProgress(((nftGameDetail?.destroyCountdownInterval - Math.floor((distance / 1000))) / nftGameDetail?.destroyCountdownInterval) * 100)
        }
      }, 1000);
    }

    return () => {
      clearInterval(destroyDrawTime)
    };
  }, [nftGameDetail])





  const openPledge = (x: any) => {
    if (x.saleCode !== 5003) {
      setCurrentCard(x)
      Dialog.show({
        content: (
          <div style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
            <div>解构游戏销毁操作不可撤销</div>
            <div>确定销毁：藏品 #{x.shardId} 确定销毁吗?</div>
            <div className='login-ctx2' style={{ padding: '24px 0 0', justifyContent: 'center' }}>
              <Checkbox className='login-ctx2-check' checked={true} />
              <p className='login-ctx2-p'>我已同意 <a onClick={() => { navigate('/webView', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } }); Dialog.clear() }}>《用户协议》</a>与<a onClick={() => { navigate('/webView', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } }); Dialog.clear() }}>《隐私条款》</a></p>
            </div>
          </div>
        ),
        closeOnAction: true,
        title: '藏品销毁',
        actions: [
          [
            {
              text: '取消',
              key: 'cancel',
              style: { border: '1px solid #000', fontWeight: 600, borderRadius: 12, color: '#000', margin: 10 }
            },
            {
              text: '销毁',
              key: 'ok',
              style: { color: '#fff', background: '#000', borderRadius: 12, fontWeight: 600, margin: 10 },
              onClick() {
                setPwdVisible(true)
              },
            }
          ],
        ]
      })
    }
  }

  return (
    <div id={styles['Destroy']}>
      <Header text='销毁活动' fontColor="black" menu={<span onClick={() => navigate('/mining/MySpiritualGold')}>我的灵金</span>} />
      <div className={styles['Destroy-bronze']}>
        <div className={styles['Destroy-bronze-top']}>
          <div className={styles['Destroy-bronze-top-left']}>
            <div className={styles["Destroy-bronze-top-left-text"]}>当前最后销毁人：</div>
            <img className={styles['Destroy-bronze-top-left-img']} src={nftGameDetail?.destroyWinner?.avatarUrl} alt="" />
            <div className={styles["Destroy-bronze-top-left-name"]}>{nftGameDetail?.destroyWinner?.nickName}</div>
          </div>
          <div className={styles['Destroy-bronze-top-right']}>
            <img className={styles['Destroy-bronze-top-right-img']} src={require('../../../assets/imgs/RankingList-bj2.svg').default} alt="" />
            <div>{Number(nftGameDetail?.currentBonusAmount).toFixed(2)}</div>
          </div>
        </div>
        <div className={styles['Destroy-bronze-accumulate']}>倒计时结束最后销毁人得灵金&nbsp;
          <span style={{ fontSize: 14 }}>
            {countDowns || '- - : - -'}
          </span>
        </div>
        <div className={styles['Destroy-Progressbar']}>
          <ProgressBar percent={destroyCountdownProgress} style={{
            '--track-color': 'rgba(255, 255, 255, 0.12)',
            '--fill-color': '#4800E0',
            '--track-width': '7px',
          }} />
          <div style={{ fontSize: 7, color: '#eaeaea', marginTop: 4 }}>倒计时仅供参考，实际以链上数据为准</div>
        </div>
        <div className={styles['Destroy-bronze-btn']}>
          <div className={styles['Destroy-bronze-btn-item']} onClick={() => {
            if (nftGameDetail?.destroyLotteryCount > 0) {
              setLuckVisible(true)
            } else {
              Toast.show('暂无抽奖券')
            }
          }}>
            销毁奖券 x {nftGameDetail?.destroyLotteryCount || 0}
          </div>
          <div className={styles['Destroy-bronze-btn-item']} onClick={() => navigate('/synthesis')}>
            合成铸造
          </div>
        </div>
      </div>

      <div className={styles['Destroy-center']}>
        <div className={styles['Destroy-center-title']}>
          <h3 className={styles['Destroy-center-title-h3']}>销毁：{goodsSearcExtra?.title}</h3>
          <div className={styles['Destroy-center-title-div']}>
            <div className={styles['Destroy-center-title-box']}>
              <div className={styles['Destroy-center-title-box-left']}>发行</div>
              <div className={styles['Destroy-center-title-box-right']}>{goodsSearcExtra.publish_amount ?? '--'}份</div>
            </div>
            <div className={styles['Destroy-center-title-box']}>
              <div className={styles['Destroy-center-title-box-left']}>流通</div>
              <div className={styles['Destroy-center-title-box-right']}>{goodsSearcExtra.circulate_amount ?? '--'}份</div>
            </div>
            <div className={styles['Destroy-center-title-box']}>
              <div className={styles['Destroy-center-title-box-left']}>销毁</div>
              <div className={styles['Destroy-center-title-box-right']}>{goodsSearcExtra.destroy_amount ?? '--'}份</div>
            </div>
          </div>
        </div>

        <div className={styles['Destroy-center-sort']}>
          <div className={styles['Destroy-center-sort-left']} onClick={() => setSort('time')}>
            <div className={styles['Destroy-center-sort-left-text']} style={sort === 'time' ? { color: '#4D33EF' } : {}}>最新销毁</div>
            <img className={styles['Destroy-center-sort-left-img']} src={sort === 'time' ? require('../../../assets/imgs/RankingList-up.svg').default : require('../../../assets/imgs/RankingList-down.svg').default} alt="" />
          </div>
          <div className={styles['Destroy-center-sort-right']} onClick={() => setSort('shard')}>
            <div className={styles['Destroy-center-sort-right-text']} style={sort === 'shard' ? { color: '#4D33EF' } : {}}>编号排序</div>
            <img className={styles['Destroy-center-sort-right-img']} src={sort === 'shard' ? require('../../../assets/imgs/RankingList-up.svg').default : require('../../../assets/imgs/RankingList-down.svg').default} alt="" />
          </div>
        </div>
        <div className={styles['Destroy-center-foot']}>
          {nftHistoryList.length > 0 ? nftHistoryList.map((x, i) => (
            <div className={styles['Destroy-center-foot-box']} key={i}>
              <div className={styles['Destroy-center-foot-left']}>
                <div className={styles['Destroy-center-foot-left-top']} style={x.currentUserFlag ? { color: '#4D33EF' } : {}}>
                  <h4>{x.fromUserNickname}{x.currentUserFlag && '(我)'}</h4>
                </div>
                <p className={styles["Destroy-center-foot-left-p"]}>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
              </div>
              <div className={styles['Destroy-center-foot-ritgh']}>
                <div className={styles["Destroy-center-foot-ritgh-text"]}>已销毁：</div>
                <div>{x.assetTitle}&nbsp;#{x.shardId}</div>
              </div>
            </div>
          )) : null}
          <InfiniteScroll
            hasMore={nftHistoryListHasMore}
            children={(hasMore) => {
              if (!hasMore && nftHistoryList.length === 0) {
                return (
                  <div className='noData' style={{ top: 480 }}>
                    <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
                    <span className='noData-text'>暂无数据</span>
                  </div>
                )
              } else {
                return '没有更多了'
              }
            }}
            loadMore={async () => {
              await getNftHistory({
                assetId: state.aid,
                type: 'destroyed',
                skip: nftHistoryList.length,
                sort
              })
            }}
          />
        </div>
      </div>

      <div className={styles['RankingList-foot-box']}>
        {consumeShardList.length > 0 ? <div className={styles['RankingList-quick']} onClick={() => { setPopupVisible(true); }}>选择藏品,立即销毁</div> : <div className={styles['RankingList-quick']} style={{ background: '#E1E1E1', color: '#666' }}>暂无可销毁藏品</div>}
      </div>

      <Popup
        visible={popupVisible}
        getContainer={null}
        onClose={() => setPopupVisible(false)}
        closeOnMaskClick
      >
        <div className={styles['MyMiningCave-Popup']}>
          <div className={styles['MyMiningCave-Popup-top']}>
            <div className={styles['MyMiningCave-Popup-top-img']}>
              <img width={68} height={68} style={{ objectFit: 'contain' }} src={consumeShardList[0]?.thumb && consumeShardList[0]?.thumb[0]} alt="" />
            </div>
            <div className={styles['MyMiningCave-Popup-top-content']}>
              <span className={styles['MyMiningCave-Popup-top-content-text1']}>{consumeShardList[0]?.title}</span>
              <span className={styles['MyMiningCave-Popup-top-content-text2']}>选择要销毁的藏品编号/共{consumeShardList?.length}个</span>
            </div>
          </div>
          <div className={styles['MyMiningCave-Popup-list']}>
            {consumeShardList?.map((x, i) => (
              <div className={x.shardId === currentCard.shardId ? `${styles['MyMiningCave-Popup-list-item']} ${styles['active']}` : styles['MyMiningCave-Popup-list-item']} key={i} onClick={() => openPledge(x)}>
                {x.destroying && <div className={styles['MyMiningCave-Popup-list-item-LOCK']}>销毁中</div>}
                {x.saleCode === 5003 && <div className={styles['MyMiningCave-Popup-list-item-LOCK']}>寄售中</div>}
                {x.title}#{x.shardId}
              </div>
            ))}
          </div>
        </div>
      </Popup>

      <LuckyDraw
        gameId={state.gameId}
        visible={luckVisible}
        onClose={() => setLuckVisible(false)}
      />

      <DealPassword
        visible={pwdVisible}
        onConfirm={(pwd) => {
          consume({
            gameId: state.gameId,
            assetId: currentCard.assetId,
            shardId: currentCard.shardId
          }, { password: pwd }).then(({ data }) => {
            if (data.code === 0) {
              setPopupVisible(false)
              init()
              setTimeout(() => {
                Toast.show({ icon: 'success', content: '销毁成功' })
              }, 1000);
            }
          })
        }}
        onClose={() => setPwdVisible(false)}
      />
    </div>
  )
}

export default observer(Destroy);