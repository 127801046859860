import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionSheet, Toast, Dialog } from 'antd-mobile'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import type { Action } from 'antd-mobile/es/components/action-sheet'
import type { PropsType } from '../../global'

import Header from '../../component/header/header'

import './orderPay.scss'
import primaryMarket from '../../store/primaryMarket'

type status = 'wait_paid' | 'success' | 'cancel' | 'refund' | 'confirm'

enum OrderStatus {
  'wait_paid' = '等待支付',
  'success' = '交易成功',
  'cancel' = '交易取消',
  'refund' = '退款成功',
  'confirm' = '区块链确认中',
}

const OrderPay: React.FC<PropsType> = ({ PrimaryMarket, AppState }) => {
  const order_no = new URLSearchParams(window.location.search).get('order_no')
  const navigate = useNavigate()
  const [state, setState] = useState({})
  const [visible, setVisible] = useState(false)
  const { orderDetailInfo } = PrimaryMarket
  const { commonData } = AppState
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  const actions: Action[] = [
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/zfb.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>支付宝</span>
        </div>
      ),
      key: 'zfb',
      bold: true,
      onClick() {
        if (order_no) {
          PrimaryMarket.payHandle(order_no, 'wyper', 'alipay').then((res) => {
            const div = document.createElement('divform')
            div.innerHTML = res
            document.body.appendChild(div)
            if (isiOS) {
              document.forms[0].submit();
            } else {
              document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
              document.forms[0].submit();
            }
          })
        }
      },
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/wx.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>微信支付</span>
        </div>
      ),
      key: 'wx',
      bold: true,
      onClick() {
        Toast.show({ content: '暂未开放' })
      },
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/sd.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>杉德支付</span>
        </div>
      ),
      key: 'sd',
      bold: true,
      onClick() {
        if (order_no && commonData?.wallet?.bind) {
          PrimaryMarket.payHandle(order_no, 'wyper', 'sandpay').then((data) => {
            if (data) {
              window.location.href = data
            }
          })
        } else {
          window.location.href = commonData?.wallet?.url
        }
      },
    },
    {
      text: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={require('../../assets/imgs/hf.svg').default} alt="" />
          <span style={{ marginLeft: 8, fontSize: 17 }}>汇付支付</span>
        </div>
      ),
      key: 'hf',
      bold: true,
      onClick() {
        if (order_no && commonData?.wallet2?.bind) {
          PrimaryMarket.payHandle(order_no, 'wyper', 'huifupay').then((data) => {
            if (data) {
              window.location.href = data
            }
          })
        } else {
          window.location.href = commonData?.wallet2?.url
        }
      },
    },
  ]

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (orderDetailInfo.expire_time && orderDetailInfo.state === 'wait_paid') {
      const expireTime = new Date(orderDetailInfo.expire_time).valueOf();

      const timer = setInterval(() => {
        const currentTime = new Date().valueOf();
        const remainingTime = Math.floor((expireTime - currentTime) / 1000);
        if (remainingTime <= 0) {
          clearInterval(timer);
          Toast.show({ content: '订单已超时' })
          setTimeout(() => navigate(-1), 1000);
        } else {
          setCountdown(remainingTime);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [orderDetailInfo.expire_time]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes} 分 ${seconds < 10 ? `0${seconds}` : seconds} 秒后关闭订单`;
  };

  useEffect(() => {
    if (order_no) {
      PrimaryMarket.queryOrderDetail(order_no)
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (orderDetailInfo.state === 'wait_paid') {
        if (order_no) {
          PrimaryMarket.queryOrderDetail(order_no)
        }
      } else {
        clearInterval(timer)
      }
    }, 1000);

    return () => {
      clearInterval(timer)
    }
  }, [orderDetailInfo.state])

  useEffect(() => {
    const removeDivFormElements = () => {
      const divFormElements = document.querySelectorAll('divform');
      divFormElements.forEach(element => {
        element.remove();
      });
    };

    removeDivFormElements();
  }, []);

  const cancelOrderHandle = () => {
    Dialog.show({
      content: '确认要取消订单吗',
      closeOnAction: true,
      actions: [
        [
          {
            text: '取消',
            key: 'cancel',
          },
          {
            text: '确认',
            key: 'ok',
            danger: true,
            onClick() {
              if (order_no) {
                primaryMarket.cancelOrder(order_no, 'wyper').then(code => {
                  if (code === 0) {
                    Toast.show({ content: '订单已取消' })
                    setTimeout(() => navigate(-1), 1000);
                  }
                })
              }
            },
          }
        ],
      ]
    })
  }

  return (
    <div id='OrderPay'>
      <Header text='订单支付' backHandle={orderDetailInfo?.state === 'success' ? () => { window.location.replace('/') } : undefined} />

      <div className="OrderPay-box1">
        {orderDetailInfo?.state === 'wait_paid' && <img className="OrderPay-box1-img" src={require('../../assets/imgs/pay1.png')} />}
        {orderDetailInfo?.state === 'success' && <img className="OrderPay-box1-img" src={require('../../assets/imgs/pay2.png')} />}
        {orderDetailInfo?.state === 'cancel' && <img className="OrderPay-box1-img" src={require('../../assets/imgs/pay3.png')} />}
        <span className="OrderPay-box1-text">{OrderStatus[orderDetailInfo?.state as status]}</span>
        {orderDetailInfo?.state === 'wait_paid' && <span className='OrderPay-box1-text1'>{formatTime(countdown)}</span>}
      </div>

      {orderDetailInfo?.state === 'success' && <div className='OrderPay-box3'>
        <div className='OrderPay-box3-left'>
          <img className='OrderPay-box3-left-img' src={require('../../assets/imgs/location.svg').default} alt="" />
          <span className='OrderPay-box3-left-text' style={{ width: '100%' }}>实体卡片请稍后在“我的”-“寄存商品”中提货</span>
        </div>
      </div>}

      {commonData.default_delivery_info && <div className='OrderPay-box3' onClick={() => { Toast.show('当前版本只支持默认地址') }}>
        <div className='OrderPay-box3-left'>
          <img className='OrderPay-box3-left-img' src={require('../../assets/imgs/icon2.svg').default} alt="" />
          <span className='OrderPay-box3-left-text'>{`${commonData.default_delivery_info.name},${commonData.default_delivery_info.mobile},${commonData.default_delivery_info.address}`}</span>
        </div>
        <img className='OrderPay-box3-right' src={require('../../assets/imgs/arrow-right-s-line.svg').default} alt="" />
      </div>}

      <div className='OrderPay-box2'>
        <div className="OrderPay-box2-top">
          <div>
            <img className="OrderPay-box2-top-img" src={orderDetailInfo?.detail_thumb_list && orderDetailInfo?.detail_thumb_list[0]} />
          </div>
          <div className="OrderPay-box2-top-container">
            <p className="OrderPay-box2-top-container-title">{orderDetailInfo?.title}
              <span className='OrderPay-box2-top-container-title-text'>
                <span>¥ </span>
                {(orderDetailInfo?.price) / orderDetailInfo.amount}
              </span>
            </p>
            <div className="OrderPay-box2-top-container-loaction">
              <p className="OrderPay-box2-top-container-loaction-text">x{orderDetailInfo?.amount}</p>
            </div>
          </div>
        </div>
        <div className="OrderPay-box2-section">
          <p className="OrderPay-box2-section-title">附加信息</p>
          <div className="OrderPay-box2-section-bottom">
            <p className="OrderPay-box2-section-bottom-text1">支付方式：支付宝</p>
            <p className="OrderPay-box2-section-bottom-text2"><span style={{ fontSize: '14px' }}>¥</span> {orderDetailInfo?.price}</p>
          </div>
        </div>
        <div className="OrderPay-box2-data">
          <p className="OrderPay-box2-data-title">附加信息</p>
          <div className="OrderPay-box2-data-item">
            <p>附加登记费</p>
            <p>￥{orderDetailInfo?.extra_price}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>交易数量</p>
            <p>{orderDetailInfo?.amount}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>创建时间</p>
            <p>{moment(orderDetailInfo?.create_time).format('YYYY.MM.DD HH:mm')}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>付款时间</p>
            <p>{moment(orderDetailInfo?.paid_time).format('YYYY.MM.DD HH:mm')}</p>
          </div>
          <div className="OrderPay-box2-data-item">
            <p>订单编号</p>
            <p style={{ color: '#000' }}>{orderDetailInfo?.order_no}</p>
          </div>
        </div>
      </div>

      <div className="OrderPay-footer">
        {orderDetailInfo?.state === 'wait_paid' &&
          <>
            <div className="OrderPay-btn1 btn" onClick={cancelOrderHandle}>
              取消订单
            </div>
            <div className="OrderPay-btn btn" onClick={() => setVisible(true)}>
              确认支付
            </div>
          </>
        }

        {orderDetailInfo?.state === 'success' &&
          <div className="OrderPay-btn btn" style={{ flex: 1 }} onClick={() => navigate('/myAssets?type=1')}>
            查看盲盒
          </div>
        }

        {orderDetailInfo?.state === 'cancel' &&
          <div className="OrderPay-btn btn" style={{ flex: 1 }} onClick={() => navigate('/')}>
            返回主页
          </div>
        }
      </div>

      <ActionSheet
        visible={visible}
        cancelText='取消'
        actions={actions}
        onClose={() => setVisible(false)}
      />
    </div>
  )
}

export default inject('PrimaryMarket', 'AppState')(observer(OrderPay))