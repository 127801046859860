
import { useEffect, useState } from "react";
import { observer } from 'mobx-react'
import Header from "../../../component/header/header";
import './giveRoug.scss';
import { Dialog, Checkbox, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import miningStore from "@/store/miningStore";
import appState from "@/store/appState";
import DealPassword from "@/component/dealPassword/dealPassword";


const GiveRoug: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [dealVidible, setDealVidible] = useState(false)
  const [dealPassword, setDealPassword] = useState('')
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [id, setId] = useState('')
  const [num, setNum] = useState('')
  const [name, setName] = useState('')
  const { commonData } = appState

  const add = () => {
    if (!commonData?.has_password) {
      Toast.show('请设置交易密码')
      return navigate('/password')
    }
    if (id !== '' && num !== '') {
      miningStore.scorePreCheckName(Number(id)).then(({ data }) => {
        if (data.code === 0) {
          setDealVidible(true);
        }
      })
    } else {
      Toast.show('信息不完整')
    }
  }

  const handleAction = (actions: string | undefined) => {

    if (actions === 'confirm' && name !== '') {
      miningStore.scoreTransfer({
        score: Number(num),
        toUid: Number(id),
        name
      }, { password: dealPassword }).then(({ data }) => {
        setVisible(false)
        setName('')
        if (data.code === 0) {
          navigate('/mining/IncreaseByTransferring', { state: data.data })
          Toast.show({ content: '转赠成功', icon: 'success' })
          appState.getMenuData()
        } else if (data.code === 40010) {
          navigate('/password')
        }
      })
    }
  }
  const close = (actions: string | undefined) => {

    if (actions === 'cancel') {
      setVisible(false);
      setName('')
    }
  }

  const steNumHandle = (v: string) => {
    if (Number(v) > Number(commonData?.real_score)) {
      return setNum(Math.floor(commonData?.real_score * 0.95) + '')
    }

    setNum(v)
  }


  return (
    <div id="giveRoug">
      <Header text='赠送原石' fontColor="white" />
      <div className="giveRoug-top">
        <div className="giveRoug-top-left">
          <h3 className="giveRoug-top-left-h3">赠送原石给朋友</h3>
          <div className="giveRoug-top-left-logo">
            <span>拥有原石</span>
            <img className="giveRoug-top-left-logo-img" src={require('../../../assets/imgs/MyGem-gem.svg').default} />
            <span>{commonData?.real_score}</span>
          </div>
        </div>
        <video
          className="giveRoug-top-video"
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          onClick={(e) => e.preventDefault()}
          onMouseDown={(e) => e.preventDefault()}
        >
          <source src={require('../../../assets/imgs/giveRoug.mp4')} type='video/mp4' />
        </video>
      </div>
      <div className="giveRoug-center">
        <div className="giveRoug-center-illustrate">
          <p>1.原石一经转赠，操作不可撤销，请您谨慎确认。</p>
          <p>2.被转赠对象必须为年满十八周岁的实名注册用户。</p>
          <p>3.WYPER空间严禁用户参与任何炒作行为。</p>
        </div>
        <div className="giveRoug-center-box">
          <div className="giveRoug-center-id">
            <input className="giveRoug-center-id-p" type="number" placeholder="接收人ID" value={id} onChange={x => setId(x.target.value)} />
          </div>
          <div className="giveRoug-center-num">
            <input className="giveRoug-center-num-p" type="number" placeholder="输入赠送原石数量" value={num} onChange={x => {
              if (x.target.value === "" || /^\d+$/.test(x.target.value)) {
                steNumHandle(x.target.value)
              }
            }} />
            <div className="giveRoug-center-num-big" onClick={() => setNum(Math.floor(commonData?.real_score * 0.95) + '')}>最大</div>
          </div>
          <span style={{ color: '#ff0f0f', fontSize: 12 }}>*技术服务额外扣除赠送数量的5%</span>
          <div className="giveRoug-center-notice">
            <Checkbox
              checked={checked} onChange={(e: any) => setChecked(e)}
              style={{
                '--icon-size': '15px',
              }}
            />
            <p className='giveRoug-center-notice-p'>您已同意 <a className="giveRoug-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/userterms.html', text: '用户协议' } })}>《用户协议》</a>、<a className="giveRoug-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/privacy.html', text: '隐私条款' } })}>《隐私条款》</a>、<a className="giveRoug-center-notice-p-a" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/riskterms.html', text: '风险提示' } })}>《风险提示》</a></p>
          </div>
          <button className="giveRoug-center-notice-btn" onClick={() => add()}>确认赠送</button>
        </div>

      </div>

      <Dialog
        visible={visible}
        content={(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              gap: 13,
              marginTop: 10,
            }}
          >
            <span style={{ fontSize: 16, fontWeight: "bold" }}>确认朋友身份</span>
            <span style={{ fontSize: 14, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)', display: 'block', marginTop: '5px' }}>请填写对方通过实名认证的姓名首字，以确认对方身份</span>
            <div className='Given-auth' style={{ display: 'flex', alignItems: 'center', marginTop: '18px', color: '#040415', fontWeight: 700, height: '48px' }}>
              <input
                maxLength={1}
                style={{
                  width: "50px",
                  borderRadius: '4px',
                  height: '48px',
                  paddingLeft: 17,
                  boxSizing: "border-box",
                  backgroundColor: '#F3F3F3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                  color: '#000',
                  fontWeight: 700,
                  fontSize: 14
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />&nbsp;&nbsp;
              {miningStore.checkName}
            </div>
          </div>
        )}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
              style: { color: '#000' },
              onClick: () => close('cancel')
            },
            {
              key: 'confirm',
              text: '确定',
              style: { fontWeight: 600 },
              onClick: () => handleAction('confirm')
            },
          ]
        ]}
      />

      <DealPassword
        visible={dealVidible}
        onConfirm={(v) => {
          setDealPassword(v);
          setDealVidible(false);
          setVisible(true);
        }}
        onClose={() => setDealVidible(false)}
      />
    </div>
  )
}

export default observer(GiveRoug);