import axios from "axios";
import { Toast } from "antd-mobile";

const instance = axios.create({
  timeout: 1000 * 15,
  baseURL: process.env.NODE_ENV === 'production' ? 'https://c2c-test.wyper.art' : 'https://c2c-test.wyper.art'
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://c2c.wyper.art' : 'https://c2c-test.wyper.art'
})

instance.interceptors.request.use(config => {
  Toast.show({
    icon: 'loading',
    content: '加载中...',
    duration: 0
  })

  const commonData = JSON.parse(localStorage.getItem('commonData') || '{}')
  if (commonData.authorization) {
    config.headers['Authorization'] = commonData.authorization
  }
  return config
})

instance.interceptors.response.use(response => {
  Toast.clear()

  if (response?.data?.code !== 0) {
    Toast.show({ content: response?.data?.message })
  }

  return response
})

instance.defaults.timeout = 20000;
instance.defaults.headers.post.Accept = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance
