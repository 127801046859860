import Axios from '@/request/request';

/* 查询奖品列表 */
export const queryPrizeList = (params: { gameId: string }) => {
  return Axios.get(`/activity/lottery/list`, { params });
};

/* 开始抽奖 */
export const LuckyDrawAction = (params: { gameId: string; count: 1 | 10 }) => {
  return Axios.post(`/activity/lottery/begin`, params);
};

/* 查询抽奖次数 */
export const queyLuckyDrawCount = (params: { gameId: string }) => {
  return Axios.get(`/activity/lottery/count`, { params });
};

