import styles from './tradeOk.module.scss';
import Header from '../../../component/header/header';
import { useNavigate } from 'react-router-dom';



const TradeOk: React.FC=()=>{
    const  navigate=useNavigate();

    return(
        <div id={styles['TradeOk']}>
        <Header text='订单支付' fontColor="black" />
        <img className={`${styles['TradeOk-img']}`}src={require('../../../assets/imgs/OrderPayment-fill.svg').default} alt="" />
        <h3 className={`${styles['TradeOk-title-h3']}`}>交易成功</h3>
      
        <div className={styles['TradeOk-center']}>
            <div className={styles['TradeOk-center-box']}>
                <div className={styles['TradeOk-center-box-top']}>
                    <div className={styles['TradeOk-center-box-top-left']}>
                        <img src={require('../../../assets/imgs/OrderPayment-title.svg').default} alt="" />
                        <h3 className={styles['TradeOk-center-box-top-left-h3']}>数字藏品</h3>
                    </div>
                    <div className={styles['TradeOk-center-box-top-right']}>
                        <h4 className={styles['TradeOk-center-box-top-right-h4']}><span className={styles['TradeOk-center-box-top-right-h4-span']}>¥&nbsp;</span>2199.50</h4>
                        <div className={styles['TradeOk-center-box-top-right-div']}>×24</div>
                    </div>
                </div>
               
                <div className= {`${styles['TradeOk-center-box-cent']}`}>
                    <div className={`${styles['TradeOk-center-box-cent-left']}`}>
                        <h4 className={styles['TradeOk-center-box-cent-left-h4']}>附加信息</h4>
                        <p className={styles['TradeOk-center-box-cent-left-p']}>支付方式:&nbsp;微信支付</p>
                    </div>
                    <div className={`${styles['TradeOk-center-box-cent-right']}`}><span className={styles['TradeOk-center-box-cent-right-span']}>¥</span>2199.50</div>
                </div>

                <div className={styles['TradeOk-foot']}>
                    <h3 className={`${styles['TradeOk-foot-h3']}`}>附加信息</h3>
                    <div className={styles['TradeOk-foot-box']}>
                        <div className={styles['TradeOk-foot-left']}>
                            <div>商品价格</div>
                            <div>交易数量</div>
                            <div>创建时间</div>
                            <div>订单编号</div>
                        </div>
                        <div className={styles['TradeOk-foot-right']}>
                            <div>¥120.00</div>
                            <div>24</div>
                            <div>2022.06.02&nbsp;14:52</div>
                            <div className={styles['TradeOk-foot-right-num']}>12479179875973592755</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={`${styles['TradeOk-btn']}`}>
            <div className={styles['TradeOk-btn-box']} onClick={()=>navigate('/')}>返回市场</div>
        </div>





    </div>
    )
}

export default TradeOk;