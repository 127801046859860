import Axios from '@/request/gameRequest'
import qs from 'qs'
import type {
  nftGameListType,
  nftGameTotalBonusType,
  nftGameLastWinnerType
} from './type'

export const nftGameListApi: nftGameListType = (params) => {
  return Axios.get(`/nftGame/list?${qs.stringify(params)}`)
}

export const nftGameTotalBonusApi: nftGameTotalBonusType = (bonusType) => {
  return Axios.get(`/nftGame/totalBonus?bonusType=${bonusType}`)
}

export const nftGameLastWinnerApi: nftGameLastWinnerType = (params) => {
  return Axios.get(`/nftGame/lastWinner?${qs.stringify(params)}`)
}