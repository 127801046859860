import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import './myper.scss';

const MyPer: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('startAnimation', 'true')
  }, [])

  return (
    <div id="MyPer">
      <video
        className='MyPer-video'
        loop
        autoPlay
        muted
        playsInline
        controls={false}
        onClick={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      >
        <source src={require('../../../assets/imgs/-fe9a-41a6-abb5-63eb15df0f84.mp4')} type='video/mp4' />
      </video>
      <img width={135} src={require('../../../assets/imgs/WYPER.png')} alt="" />
      <div className='MyPer-btn' onClick={() => navigate('/login')}>进入 WYPER 宇宙</div>
    </div>
  )
}

export default MyPer;