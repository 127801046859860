import Axios from '@/request/request';
import C2CAxios from '@/request/c2cRequest';
import qs from 'qs'
import { PropsType } from '../../global'

type appStateType = PropsType['AppState']

// 响应接口
type ResponseType = Promise<{
  data: {
    /* */
    code: number;

    /* */
    message: string;

    /* */
    data: Record<string, unknown> | unknown | any;
  }
}>


export const sendCodeApi = (data: object): ResponseType => {
  return Axios.get(`/noauth/sendVerificationCode?${qs.stringify(data)}`)
}

export const loginApi = (data: object): ResponseType => {
  return Axios.post('/users/login', data)
}

export const menuApi = (): ResponseType => {
  return Axios.get('/users/menu')
}

//绑定邀请码
export const bindInviterIdApi = (data: { inviterId: string }): ResponseType => {
  return Axios.post('/users/bindInviterId', data)
}


/** 
 * newCollection
 * @param {string} payment 
 * @param {string} bonus_type 
 * @returns
 */
export const hotCollectionListApi = (data?: { payment: string, bonus_type: string }): ResponseType => {
  return Axios.get(`/physicalGood/list?${qs.stringify(data)}`)
}


// 生成订单
export const createOrderApi = (data: any): ResponseType => {
  return Axios.get(`/nft/order/create?${qs.stringify(data)}`)
}

// 查询订单详情
export const queryOrderDetailApi = (order_no: string): ResponseType => {
  return Axios.get(`/nft/order/queryOrder?${qs.stringify({ order_no })}`)
}


/** 
 * collectionDetail
 * @param {string} physicalGoodId 
 * @returns
 */
export const collectionDetailApi = (physicalGoodId: string): ResponseType => {
  return Axios.get(`/physicalGood/detail?physicalGoodId=${physicalGoodId}`);
}


//取消订单
export const cancelOrderApi = (order_no: string, type: 'wyper' | 'express'): ResponseType => {
  return Axios.get(`/nft/order/cancel?order_no=${order_no}&type=${type}`)
}

//订单查询
export const orderQueryApi = (type: 'wait_paid' | 'success' | 'cancel' | 'refund' | 'confirm' | 'all', product: 'asset' | 'box' | 'physical_good' | 'physical_good_express'): ResponseType => {
  return Axios.get(`/nft/order/query?type=${type}&product=${product}`)
}

//支付
export const payApi = (order_no: string, type: 'wyper' | 'express', pay_channel: 'alipay' | 'sandpay'): Promise<{ data: string }> => {
  return Axios.get(`/pay/aliPay/h5?order_no=${order_no}&pay_channel=${pay_channel}&type=${type}`)
}

//上传图片
export const uploadApi = (formData: FormData) => {
  return Axios.post('/noauth/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

//用户信息修改
export const updateUserInfoApi = (params: { nickName?: string, avatarUrl?: string }): ResponseType => {
  return Axios.post('/users/updateUserInfo', params)
}

// nft资产
export const nftAssetsApi = (): ResponseType => {
  return Axios.get('/nft/assets')
}

// 实名认证
export const bindIdentityNumberApi = (params: { name: string, identity_number: string }): ResponseType => {
  return Axios.get(`/users/bindIdentityNumber?${qs.stringify(params)}`)
}

// 盲盒列表
export const boxListApi = (): ResponseType => {
  return Axios.get('/nft/notRevealedAssetList')
}

// 开启盲盒
export const openBoxApi = (params: { user_asset_id: string }): ResponseType => {
  return Axios.post(`/nft/revealAsset`, params)
}

// 邀请记录
export const inviteRecordApi = (invite_level: 1 | 2): ResponseType => {
  return Axios.get(`/users/inviteRecord?invite_level=${invite_level}`)
}

// 新增收货地址
export const addDeliveryInfoApi = (params: {
  id?: string,
  name: string,
  mobile: string,
  address: string,
  defaultFlag?: boolean
}): ResponseType => {
  return Axios.post('/users/addDeliveryInfo', params)
}

// 获取用户物流信息列表
export const getDeliveryInfoListApi = (): ResponseType => {
  return Axios.get('/users/getDeliveryInfoList')
}

// 删除用户物流信息
export const deleteDeliveryInfoApi = (id: string): ResponseType => {
  return Axios.post('/users/deleteDeliveryInfo', { id })
}

// 修改用户物流信息
export const updateDeliveryInfoApi = (params: {
  id: string,
  name: string,
  mobile: string,
  address: string,
  defaultFlag?: boolean
}): ResponseType => {
  return Axios.post('/users/updateDeliveryInfo', params)
}

// 发起寄存商品（快递）订单
export const initiateExpressOrderApi = (params: {
  express_order_no: string,
  delivery_mobile: string,
  delivery_name: string,
  delivery_address: string,
}) => {
  return Axios.get(`/nft/order/initiateExpressOrder?${qs.stringify(params)}`)
}

export const nftNoticeApi = (type: string): ResponseType => {
  return Axios.get(`/nft/notice?type=${type}`)
}

export const usersPasswordApi: appStateType['usersPassword'] = (params) => {
  return Axios.post(`/users/password`, params)
}

export const resetPasswordApi: appStateType['resetPassword'] = (params) => {
  return Axios.post(`/users/reset/password`, params)
}

export const sendMsgApi = () => {
  return Axios.post('/users/send/msg')
}

export const usersMyApi = (): ResponseType => {
  return C2CAxios.get('/users/my')
}
